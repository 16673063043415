import React, { useEffect, useState } from 'react';

const useTimer = (seconds: number): [number, React.Dispatch<React.SetStateAction<number>>] => {
  const [duration, setDuration] = useState(seconds);

  useEffect(() => {
    const timerId = setInterval(() => {
      setDuration((prevState) => {
        // if (prevState === 1) clearInterval(timerId);
        return prevState - 1;
      });
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return [duration, setDuration];
};

export { useTimer };
