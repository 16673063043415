import React, { useMemo, useRef } from 'react';
import { Preferences } from '@capacitor/preferences';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useTranslation } from 'react-i18next';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { useToast } from './contexts/toast-context';
import { useAuth } from './contexts/auth-context';
import { RefreshTokenDocument } from './generated/graphql';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('No API specified');
}

const endpoint1 = createUploadLink({ uri: `${API_URL}graphql/` });

// TODO: change localhost, because it will not work on android device (external device)
const endpoint2 = new HttpLink({
  // uri: "https://glowing-stingray-38.hasura.app/v1/graphql", // use https for secure endpoint
  uri: `${API_URL}graphql/`, // use https for secure endpoint
  // headers: {
  // }
});

// Create a WebSocket link:
// TODO: change localhost, because it will not work on android device (external device)
const wsLink = new WebSocketLink({
  uri: `${API_URL.replace(/^https?:\/\//, (match) => {
    // Replace http||https with ws||wss
    return match.includes('https') ? 'wss://' : 'ws://';
  })}ws/`,
  options: {
    reconnect: true,
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  split((operation) => operation.getContext().clientName === 'chat', endpoint2, endpoint1)
);

const asyncAuthLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  const { value: token } = await Preferences.get({ key: 'token' });

  return {
    headers: {
      ...headers,
      Authorization: token ? `Token ${token}` : '',
    },
  };
});

interface Props {
  children: React.ReactNode;
}

const CustomApolloProvider = ({ children }: Props) => {
  const authContext = useAuth();
  const { dispatch } = useToast();
  const { t } = useTranslation();
  const errorLink: ApolloLink = useMemo(
    () =>
      // eslint-disable-next-line consistent-return
      onError(({ graphQLErrors, networkError, forward, operation }) => {
        if (graphQLErrors) {
          // eslint-disable-next-line no-restricted-syntax
          for (const { message } of graphQLErrors) {
            if (message.includes('Variable "$refreshToken" of required type "String!" was not provided.')) {
              authContext.logout();
            } else if (message.includes('Security code is not valid')) {
              dispatch({
                type: 'DANGER',
                message: t('Invalid authorization code.'),
              });
            } else if (message.includes('User with this phone or email already exists')) {
              dispatch({
                type: 'DANGER',
                message: t('User with this phone or email already exists. Perhaps you entered them wrong.'),
              });
            } else if (message.includes('Signature has expired')) {
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              return fromPromise(getAndSetNewToken()).flatMap(() => {
                return forward(operation);
              });
            } else if (message.toLowerCase().includes('refresh token')) {
              authContext.logout();
            } else {
              // dispatch({type: "DANGER", message: 'Something went wrong, server problem.'}) PROD
              dispatch({ type: 'DANGER', message });
            }
          }
        }
        if (networkError) {
          dispatch({ type: 'DANGER', message: `Network error: ${networkError.message}` });
        }
      }),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [authContext, dispatch, getAndSetNewToken, t]
  );

  const { current: apolloClient } = useRef(
    new ApolloClient({
      link: from([errorLink, asyncAuthLink, link]),
      cache: new InMemoryCache(),
    })
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getAndSetNewToken() {
    const { value: oldRefreshToken } = await Preferences.get({ key: 'refreshToken' });
    return apolloClient
      .mutate({
        mutation: RefreshTokenDocument,
        variables: { refreshToken: oldRefreshToken },
      })
      .then(
        async ({
          data: {
            refreshToken: { refreshToken, token },
          },
        }) => {
          await Preferences.set({
            key: 'refreshToken',
            value: refreshToken,
          });
          await Preferences.set({
            key: 'token',
            value: token,
          });

          return `Token ${token}`;
        }
      )
      .catch((error) => {
        dispatch({ type: 'DANGER', message: error.message });
      });
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { CustomApolloProvider };
