import React from 'react';

import {
  IonBadge,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  useIonAlert,
} from '@ionic/react';

// styles
// icons
import { pencil, trashSharp } from 'ionicons/icons';

// i18n
import { useTranslation } from 'react-i18next';
import styles from './ItemSliding.module.scss';

interface IItemSliding {
  label: string;
  onItemClick: () => void;
  onItemRemove: (id: string) => void;
  counter?: number;
}

export const ItemSliding: React.FC<IItemSliding> = ({ onItemClick, label, onItemRemove, ...props }) => {
  const { t } = useTranslation();
  const [present] = useIonAlert();
  const deleteItem = () =>
    present({
      header: `${t('Delete')}`,
      message: `${t('Confirm deletion')}`,
      buttons: [`${t('Cancel')}`, { text: `${t('Delete')}`, handler: onItemRemove }],
    });

  return (
    <IonItemSliding>
      <IonItem className={styles.item} button onClick={onItemClick} detail={false}>
        <IonLabel className={styles.label}>{label}</IonLabel>
        {props.counter != null ? (
          <IonBadge color="primary" slot="end">
            {props.counter}
          </IonBadge>
        ) : null}
        <IonIcon className={styles.itemEditIcon} slot="end" icon={pencil} />
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color="danger" expandable>
          <IonIcon className={styles.itemDeleteIcon} size="large" icon={trashSharp} onClick={deleteItem} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

ItemSliding.defaultProps = {
  counter: undefined,
};
