import React, { useCallback } from 'react';
import { Button, withYMaps } from 'react-yandex-maps';
import { isPlatform } from '@ionic/react';
import styles from './YaBottomContainerDOM.module.scss';

const YaBottomContainerDOMComponent = ({ ymaps, styles: propStyles, ...containerProps }: any) => {
  const containerLayout = useCallback(
    () =>
      ymaps.templateLayoutFactory.createClass(
        `
      <div id="y-container" class="${styles.container ? styles.container : ''} ${
          !isPlatform('ios') ? styles.android : ''
        } ${propStyles || ''}">
        
      </div>
      `
      ),
    [propStyles, ymaps.templateLayoutFactory]
  );

  return (
    <Button
      {...containerProps}
      options={{
        maxWidth: 128,
        position: {
          bottom: 80,
          left: 0,
        },
        layout: containerLayout(),
      }}
    />
  );
};

const YaBottomContainerDOM = withYMaps(YaBottomContainerDOMComponent, true);

export { YaBottomContainerDOM };
