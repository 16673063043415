import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { CommonInfo } from '@ionic-native/bluetooth-le';
import _ from 'lodash';
import {
  DeviceDeviceType,
  SettingsValueType,
  useCreateDeviceRecordsMutation,
  useLinkDeviceMutation,
} from '../generated/graphql';
import { AuthContextType } from './auth-context';
import { noDelaySetInterval } from '../utils/utils';
import {
  connect,
  isBluetoothEnabled as enabledStatusChange,
  runWatchApp,
  StoreActionPayloadType,
} from '../utils/device';
import { ActionType, DEVICE_ENV, RecordBundle } from './watch-device-context';

export interface WatchStateType {
  appRunning: boolean;
  bluetooth: boolean;
  connected: boolean;
  device?: CommonInfo;
  battery?: number | null;
}

export const defaultState: WatchStateType = {
  bluetooth: false,
  connected: false,
  appRunning: false,
  battery: null,
};

const deviceReducer = (state: WatchStateType, action: ActionType): WatchStateType => {
  switch (action.type) {
    case 'CONNECTION_CHANGE':
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        connected: action.payload?.connected!,
        device: action.payload?.device,
      };
    case 'APP_RUNNING':
      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      return { ...state, appRunning: action.payload?.appRunning! };
    case 'BLUETOOTH_STATUS_CHANGE':
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        bluetooth: action.payload?.bluetooth!,
        connected: false,
        appRunning: false,
      };
    case 'BATTERY':
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        battery: action.payload?.battery!,
      };
    default:
      return state;
  }
};

const defaultRecordBundleValue = {
  temperatures: [],
  accelerations: [],
  gyros: [],
  ppg: [],
};

const establishDeviceSettings = (settings: SettingsValueType | undefined) => {
  return {
    historyBufferSize: (settings?.historyBufferSize ?? DEVICE_ENV.historyBufferSize) || 500,
    sendDataPeriod: (settings?.sendDataPeriod ?? DEVICE_ENV.sendDataPeriod) || 10000,
    reconnectAttemptPeriod: (settings?.reconnectAttemptPeriod ?? DEVICE_ENV.reconnectAttemptPeriod) || 10000,
    sensorReadPeriod: (settings?.sensorReadPeriod ?? DEVICE_ENV.sensorReadPeriod) || 500,
    batteryReadPeriod: (settings?.batteryReadPeriod ?? DEVICE_ENV.batteryReadPeriod) || 10000,
  };
};

const useWatchDevice = (authContext: AuthContextType): [WatchStateType, React.Dispatch<ActionType>] => {
  const [state, dispatch] = useReducer(deviceReducer, defaultState);
  const [linkDeviceMutation, { data: linkDeviceData, called: linkDeviceMutationCalled }] = useLinkDeviceMutation();
  const [pushDeviceRecord] = useCreateDeviceRecordsMutation();
  const isUserAuthorizedAndPlatformNative = authContext.user?.id && Capacitor.isNativePlatform();

  // Keep the latest state in a ref to use it in 'useEffect()'
  // without specifying it in dependency array
  const stateRef = useRef(state);
  useEffect(() => {
    stateRef.current = state;
  });

  const deviceRecordBundleRef = useRef<RecordBundle>(defaultRecordBundleValue);
  const deviceConfig: SettingsValueType | null = useMemo(() => {
    if (linkDeviceData) {
      const deviceSettings: SettingsValueType | undefined = linkDeviceData.linkDevice.device.settings?.value;
      return establishDeviceSettings(deviceSettings);
    }
    return null;
  }, [linkDeviceData]);

  const onDeviceUnsubscribe = useCallback(
    () =>
      dispatch({
        type: 'APP_RUNNING',
        payload: {
          appRunning: false,
        },
      }),
    []
  );

  const onBundleChange = useCallback(
    (record: Partial<StoreActionPayloadType>) => {
      const { temperatures, accelerations, gyros, ppg } = deviceRecordBundleRef.current;
      if (record.battery && record.battery !== stateRef.current.battery) {
        dispatch({
          type: 'BATTERY',
          payload: {
            battery: record.battery,
          },
        });
      } else if (deviceConfig) {
        deviceRecordBundleRef.current = {
          temperatures: record.temperature
            ? [..._.takeRight(temperatures, deviceConfig.historyBufferSize), record.temperature]
            : temperatures,
          accelerations: record.acceleration
            ? [..._.takeRight(accelerations, deviceConfig.historyBufferSize), record.acceleration]
            : accelerations,
          gyros: record.gyro ? [..._.takeRight(gyros, deviceConfig.historyBufferSize), record.gyro] : gyros,
          ppg: record.ppg ? [..._.takeRight(ppg, deviceConfig.historyBufferSize), record.ppg] : ppg,
        };
      }
    },
    [deviceConfig]
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    // eslint-disable-next-line prefer-const
    interval = setInterval(async () => {
      const isBluetoothEnabled = await enabledStatusChange();
      if (stateRef.current.bluetooth !== isBluetoothEnabled) {
        dispatch({
          type: 'BLUETOOTH_STATUS_CHANGE',
          payload: {
            bluetooth: await enabledStatusChange(),
          },
        });
      }
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!isUserAuthorizedAndPlatformNative) {
      return;
    }
    let interval: any;
    // eslint-disable-next-line prefer-const
    interval = noDelaySetInterval(async () => {
      if (!stateRef.current.appRunning) {
        const connection = await connect();
        // eslint-disable-next-line no-console
        console.log('connection status', connection);
        if (connection?.isConnected) {
          dispatch({
            type: 'CONNECTION_CHANGE',
            payload: {
              connected: true,
              device: connection.device,
            },
          });
          let deviceSettings: SettingsValueType | undefined = linkDeviceData?.linkDevice?.device?.settings?.value;
          if (!linkDeviceData) {
            try {
              const device = await linkDeviceMutation({
                variables: {
                  input: {
                    mac: connection.device.address,
                    deviceType: DeviceDeviceType.A_1,
                  },
                },
              });

              deviceSettings = device?.data?.linkDevice?.device?.settings?.value;
            } catch (e) {
              // App connected but it won't launch watch app
              return;
            }
          }

          const establishedDeviceSettings = establishDeviceSettings(deviceSettings);
          await runWatchApp(connection.device, onDeviceUnsubscribe, onBundleChange, establishedDeviceSettings);
          dispatch({
            type: 'APP_RUNNING',
            payload: {
              appRunning: true,
            },
          });
        } else {
          dispatch({
            type: 'CONNECTION_CHANGE',
            payload: {
              connected: false,
              device: undefined,
            },
          });
        }
      }
    }, 10000);
    // }, deviceConfig.reconnectAttemptPeriod);

    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line no-console
      console.log(`--- END INTERVAL---- ${new Date().getSeconds()}`);
      clearInterval(interval);
    };
  }, [
    linkDeviceMutation,
    deviceConfig,
    onBundleChange,
    onDeviceUnsubscribe,
    isUserAuthorizedAndPlatformNative,
    linkDeviceMutationCalled,
    linkDeviceData,
  ]);

  // Repeatedly send 'device' records to DB
  useEffect(() => {
    let timerID: NodeJS.Timeout;
    if (deviceConfig && isUserAuthorizedAndPlatformNative) {
      timerID = setInterval(() => {
        if (stateRef.current.connected && stateRef.current.appRunning && linkDeviceData?.linkDevice.device) {
          if (deviceRecordBundleRef.current.ppg.length === 0) {
            // Stop running watch app if no ppg
            // eslint-disable-next-line no-console
            console.log('No ppg -> stop running watch');

            onDeviceUnsubscribe();
          } else {
            pushDeviceRecord({
              variables: {
                input: {
                  device: linkDeviceData?.linkDevice.device.id,
                  value: JSON.stringify(deviceRecordBundleRef.current),
                  date: new Date(),
                },
              },
            });
          }

          deviceRecordBundleRef.current = defaultRecordBundleValue;
        }
      }, deviceConfig.sendDataPeriod);
    }
    return () => clearInterval(timerID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushDeviceRecord, deviceConfig, isUserAuthorizedAndPlatformNative, linkDeviceData?.linkDevice.device]);

  return [state, dispatch];
};

export { useWatchDevice };
