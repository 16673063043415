import { format, parseISO } from 'date-fns';

const convertToMinutes = (sec: number): number => {
  return Math.floor(sec / 60);
};

const convertToSeconds = (sec: number): number => {
  return sec % 60;
};

const UTCLocaleDateTimeConverter = (dateTime: string, toUTC?: boolean) => {
  return new Date(
    Date.parse(new Date(dateTime).toUTCString()) + new Date().getTimezoneOffset() * 60000 * (toUTC ? 1 : -1)
  );
};

const noDelaySetInterval = (cb: () => void, interval: number) => {
  cb();
  return setInterval(cb, interval);
};

const formatDisplayDate = (value: string) => {
  return format(parseISO(value), 'dd/MM/yyyy');
};

const formatDisplayDateTime = (value: string) => {
  return format(parseISO(value), `HH:mm dd/MM/yyyy`);
};

const formatDisplayTime = (value: string) => {
  return format(parseISO(value), 'HH:mm');
};

const formatDisplayMonth = (value: string) => {
  return format(parseISO(value), 'LLLL');
};

const averageNumber = (nums: number[]) => {
  return nums.length > 0 ? (nums.reduce((a, b) => a + b) / nums.length).toFixed(1) : 0;
};

export {
  convertToMinutes,
  convertToSeconds,
  UTCLocaleDateTimeConverter,
  noDelaySetInterval,
  formatDisplayDate,
  formatDisplayTime,
  formatDisplayDateTime,
  formatDisplayMonth,
  averageNumber,
};
