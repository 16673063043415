import React from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReviewType, useClinicReviewsGroupByCharacteristicQuery, useClinicReviewsQuery } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { useSearchParams } from '../../hooks/useSearchParams';
import { ClinicReviewsDetail } from '../../components/reviews/ClinicReviewsDetail';

const ReviewsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };
  const searchParams = useSearchParams();
  const { data, loading, error, refetch } = useClinicReviewsQuery({
    variables: {
      id,
      sort: searchParams.get('sort') || '',
    },
    fetchPolicy: 'no-cache',
  });

  const { data: characteristics, error: characteristicError } = useClinicReviewsGroupByCharacteristicQuery({
    variables: {
      clinicId: id,
    },
    fetchPolicy: 'no-cache',
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  if (error) {
    return <ErrorNetwork error={error} />;
  }
  if (characteristicError) {
    return <ErrorNetwork error={characteristicError} />;
  }

  const clinicReviews = data?.clinicReviews.edges.map((review) => review.node as ReviewType) || [];
  const starsByCharacteristics = characteristics?.clinicReviewsGroupByCharacteristic || [];

  return (
    <IonPage>
      <Title color="primary" translucent title={t('Reviews')} backButton />
      <IonContent>
        {loading && <IonLoading isOpen keyboardClose={false} message={t('Loading...')} />}
        <ClinicReviewsDetail clinicReviews={clinicReviews} starsByCharacteristics={starsByCharacteristics} />
      </IonContent>
    </IonPage>
  );
};

export { ReviewsPage };
