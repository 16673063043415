import React from 'react';
import { IonButton } from '@ionic/react';
import styles from './WideButton.module.scss';

interface Props {
  onClick?: (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => void;
  text?: string;
  type: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  children?: React.ReactNode;
}

const WideButton = ({ disabled, onClick, text, type, children }: Props) => {
  return (
    <IonButton color="primary" onClick={onClick} className={styles.wideButton} disabled={disabled} type={type}>
      {text}
      {children}
    </IonButton>
  );
};

WideButton.defaultProps = {
  text: undefined,
  onClick: undefined,
  children: undefined,
  disabled: false,
};

export { WideButton };
