import React, { useCallback, useEffect, useMemo } from 'react';
import { Preferences } from '@capacitor/preferences';
import { useAuth } from './auth-context';
import { usePhoneDevice } from './usePhoneDevice';

const getInitialStateFromLocalStorage = async (): Promise<boolean> => {
  const { value } = await Preferences.get({ key: 'disable_accelerometer_data_send' });
  return JSON.parse(value || 'false') as boolean;
};

interface ContextType {
  togglePhoneDeviceTracking: () => void;
  disablePhoneDeviceTracking: boolean;
}

const PhoneDeviceContext = React.createContext<ContextType>({
  disablePhoneDeviceTracking: false,
  togglePhoneDeviceTracking: () => {},
});

const PhoneDeviceProvider = (props: any) => {
  const authContext = useAuth();
  const { disablePhoneDeviceTracking, setDisablePhoneDeviceTracking } = usePhoneDevice(authContext);

  // Get State from LocalStorage
  useEffect(() => {
    getInitialStateFromLocalStorage().then((isDisabled) => {
      setDisablePhoneDeviceTracking(isDisabled);
    });
  }, [setDisablePhoneDeviceTracking]);

  const togglePhoneDeviceTracking = useCallback(() => {
    Preferences.set({
      key: 'disable_accelerometer_data_send',
      value: JSON.stringify(!disablePhoneDeviceTracking),
    });

    setDisablePhoneDeviceTracking((prevState) => !prevState);
  }, [disablePhoneDeviceTracking, setDisablePhoneDeviceTracking]);

  const value = useMemo(
    () => ({
      disablePhoneDeviceTracking,
      togglePhoneDeviceTracking,
    }),
    [disablePhoneDeviceTracking, togglePhoneDeviceTracking]
  );

  return <PhoneDeviceContext.Provider value={value} {...props} />;
};

const usePhoneDeviceContext = () => React.useContext(PhoneDeviceContext);

export { PhoneDeviceProvider, usePhoneDeviceContext };
