import React, { useState } from 'react';
import { IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { MedicinesItem } from './MedecineItem/MedicinesItem';
import { PatientMedicinesQuery, PatientMedicineType } from '../../generated/graphql';
import { NoElementFound } from '../empty-state/NoElementFound';

interface Props {
  sortDesc: boolean;
  data: PatientMedicinesQuery;
  removeMedicine: (id: string) => void;
}

const Medicines = ({ removeMedicine, data, sortDesc }: Props) => {
  const { t } = useTranslation();

  const [searchText] = useState('');

  let newData = data.patientMedicines.edges.filter(
    (x) => x.node.medicine.name.toLowerCase().indexOf(searchText.toLowerCase()) + 1
  );
  newData = sortDesc
    ? _.sortBy(newData, (x) => x.node.medicine.name)
    : _.sortBy(newData, (x) => x.node.medicine.name).reverse();

  if (newData.length === 0) {
    return <NoElementFound title={t('Medicines list is empty')} />;
  }
  return (
    <div>
      {/* TODO: reimplement search
        <IonInput
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          className={styles.searchbar}
          placeholder={t("Search")}
        />
*/}
      <IonList>
        {newData?.map(({ node }) => (
          <MedicinesItem key={node.id} removeMedicine={removeMedicine} patientMedicine={node as PatientMedicineType} />
        ))}
      </IonList>
    </div>
  );
};

Medicines.defaultProps = {
  sortDesc: false,
};

export { Medicines };
