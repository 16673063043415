import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonGrid, IonCard, IonPopover, IonCardContent, IonIcon, IonButton, IonRow } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { timeOutline, hourglassOutline, speedometerOutline, closeOutline } from 'ionicons/icons';
import { format } from 'date-fns';
import classNames from 'classnames/bind';
import { Seizure } from './Calendar';

import styles from './Calendar.module.scss';

const cx = classNames.bind(styles);

type TSeizureIntensity = {
  A_1: string;
  A_2: string;
  A_3: string;
  A_4: string;
};
const SeizureIntensity: TSeizureIntensity = {
  A_1: 'minor',
  A_2: 'medium',
  A_3: 'major',
  A_4: 'severe',
};

interface DayDetailPopoverProps {
  isOpen: boolean;
  seizures: Array<Seizure>;
  onDidDismiss: () => void;
}

interface PopoverRowProps {
  col1: string;
  col2: string;
  icon: string;
}

const PopoverRow = ({ icon, col1, col2 }: PopoverRowProps) => {
  return (
    <div className={styles.cardRow}>
      <div className={styles.col1}>
        <IonIcon className={styles.colIcon} icon={icon} />
        {col1}
      </div>
      <div className={styles.col2}>{col2}</div>
    </div>
  );
};

const DayDetailPopover = ({ onDidDismiss, seizures, isOpen }: DayDetailPopoverProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <IonPopover isOpen={isOpen} onDidDismiss={onDidDismiss} className={styles.popover}>
      {seizures && [
        <div key={seizures.length} className={styles.popoverTitle}>
          {format(new Date(seizures[0].date), 'd.MM.yyyy')}
          <IonButton className={cx(styles.popoverDismissButton, 'ion-no-margin ion-no-padding')} onClick={onDidDismiss}>
            <IonIcon className={styles.iconFilter} slot="icon-only" icon={closeOutline} />
          </IonButton>
        </div>,
        ...seizures.map((seizure) => {
          return (
            <IonCard key={seizure.id} className={cx(styles.card, 'ion-no-margin')}>
              <IonCardContent className={styles.cardContent}>
                <IonGrid>
                  <PopoverRow
                    icon={timeOutline}
                    col1={`${t('Time')}:`}
                    col2={format(new Date(seizure.date), 'HH:mm:ss')}
                  />
                  {seizure.duration && (
                    <PopoverRow icon={hourglassOutline} col1={`${t('Duration')}:`} col2={String(seizure.duration)} />
                  )}
                  {seizure.intensity && (
                    <PopoverRow
                      icon={speedometerOutline}
                      col1={`${t('Intensity')}:`}
                      col2={t(SeizureIntensity[seizure.intensity as keyof TSeizureIntensity])}
                    />
                  )}
                  <IonRow className="ion-justify-content-center">
                    <IonButton
                      onClick={() => {
                        onDidDismiss();
                        push(`/seizures/${seizure.id}`);
                      }}
                      className={styles.cardButton}
                    >
                      {t('Go to')}
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            </IonCard>
          );
        }),
      ]}
    </IonPopover>
  );
};

export { DayDetailPopover };
