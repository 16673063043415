import { IonContent, IonPage } from '@ionic/react';
import React, { useCallback } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// graphql
import { useHistory } from 'react-router-dom';
import { CreatePrescriptionInputType, useCreatePrescriptionMutation } from '../../generated/graphql';

// components
import { PrescriptionDetail } from '../../components/prescription/PrescriptionDetail';
import { Title } from '../../components/title/Title';

const NewPrescriptionPage: React.FC = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();

  const [createPrescription] = useCreatePrescriptionMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const handleCreatePrescription = useCallback(
    async (input: CreatePrescriptionInputType) => {
      await createPrescription({
        variables: {
          input: {
            description: input.description,
          },
        },
      });
    },
    [createPrescription]
  );

  return (
    <IonPage>
      <Title translucent backButton title={t('New prescription')} />
      <IonContent>
        <PrescriptionDetail mutation={handleCreatePrescription} />
      </IonContent>
    </IonPage>
  );
};

export { NewPrescriptionPage };
