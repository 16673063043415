import { IonDatetime, IonIcon, IonInput, IonItem, IonLabel, IonPopover } from '@ionic/react';
import React from 'react';
import { calendarOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames/bind';
import { MedicineType, PatientMedicineType } from '../../generated/graphql';
import styles from './MedicineDetail.module.scss';
import { WideButton } from '../UI/WideButton/WideButton';
import { ModalSearch } from '../UI/ModalSearch/ModalSearch';
import { formatDisplayDate } from '../../utils/utils';

export interface MedicineDetailOutput {
  quantity: number;
  purchaseDate: Date;
  medicineId?: string;
}

interface Props {
  patientMedicine?: PatientMedicineType;
  medicines?: MedicineType[];
  formSubmitHandler: (patientMedicine: MedicineDetailOutput) => void;
  showMedicineSuggestions?: boolean;
  submitButtonText: string;
}

const toISODateFormat = (value: string): string => {
  return new Date(value).toISOString().slice(0, 10);
};

const cx = classNames.bind(styles);

const MedicineDetail = ({
  patientMedicine,
  formSubmitHandler,
  medicines,
  showMedicineSuggestions,
  submitButtonText,
}: Props) => {
  const { control, handleSubmit, watch, errors } = useForm({
    defaultValues: { ...(patientMedicine ? { ...patientMedicine, medicineId: patientMedicine.medicine.id } : {}) },
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit(({ medicineId, quantity, purchaseDate }: MedicineDetailOutput) => {
    formSubmitHandler({
      medicineId,
      quantity,
      purchaseDate,
    });
  });

  const watchPurchaseDate = watch('purchaseDate');

  const { t } = useTranslation();

  return (
    <form onSubmit={onSubmit}>
      {showMedicineSuggestions && (
        <>
          <IonItem lines="none" className={cx(styles.inputContainer, { 'border-color-danger': errors.medicineId })}>
            <Controller
              render={({ onChange, onBlur }) => (
                <ModalSearch searchList={medicines} onBlur={onBlur} onChange={onChange} title="Medicine name" />
              )}
              control={control}
              defaultValue=""
              name="medicineId"
              rules={{ required: true }}
            />
          </IonItem>
          <IonItem
            lines="none"
            id="open-purchase-date-input"
            className={cx(styles.datetimeContainer, { 'border-color-danger': errors.purchaseDate })}
          >
            <IonInput placeholder={t('Purchase date')} className={styles.inputLabel}>
              {watchPurchaseDate && formatDisplayDate(watchPurchaseDate)}
            </IonInput>
            <Controller
              render={({ onChange, value, onBlur }) => (
                <IonPopover onDidDismiss={onBlur} trigger="open-purchase-date-input">
                  <IonDatetime
                    onIonChange={(e) => onChange(toISODateFormat(e.detail.value!))}
                    value={value}
                    presentation="date"
                    cancelText={t('Cancel')}
                    doneText={t('Done')}
                    className={styles.datetimeInput}
                    placeholder={t('Select date')}
                  />
                </IonPopover>
              )}
              control={control}
              defaultValue=""
              name="purchaseDate"
              rules={{ required: true }}
            />
            <IonIcon className={styles.iconInput} icon={calendarOutline} />
          </IonItem>
        </>
      )}
      <IonItem lines="none" className={cx(styles.inputContainer, { 'border-color-danger': errors.quantity })}>
        <IonLabel>{t('Remainder')}</IonLabel>
        <Controller
          render={({ onChange, value, onBlur }) => (
            <IonInput
              onIonBlur={onBlur}
              onIonChange={(e) => onChange(e.detail.value)}
              value={value}
              className={styles.inputNumber}
              type="number"
              min="1"
              placeholder={t('Enter a number')}
            />
          )}
          control={control}
          defaultValue=""
          name="quantity"
          rules={{ required: true }}
        />
      </IonItem>

      <WideButton type="submit" text={submitButtonText} />
    </form>
  );
};

MedicineDetail.defaultProps = {
  patientMedicine: null,
  medicines: null,
  showMedicineSuggestions: null,
};

export { MedicineDetail };
