import {
  IonButton,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  IonRow,
  IonTextarea,
} from '@ionic/react';
import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { calendarOutline, pencil, addCircleSharp } from 'ionicons/icons';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import styles from './PrescriptionDetail.module.scss';

import { PrescriptionType } from '../../generated/graphql';
import { formatDisplayDate } from '../../utils/utils';

const cx = classNames.bind(styles);

interface IPrescriptionDetail {
  prescription?: PrescriptionType;

  mutation(...args: any[]): Promise<void>;
}

const PrescriptionDetail: React.FC<IPrescriptionDetail> = ({ prescription, mutation }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { formState, control, handleSubmit, watch } = useForm<PrescriptionType>({
    defaultValues: {
      createdAt: prescription?.createdAt,
      description: prescription?.description || '',
    },
  });

  const watchCreatedAt = watch('createdAt');

  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        <IonList className={styles.list}>
          <IonListHeader className={cx(styles.listHeader, 'ion-no-padding')}>
            <IonLabel>{t('Date and description')}</IonLabel>
          </IonListHeader>

          <IonItem className={styles.prescriptionInfoItem} lines="none">
            <IonLabel className={styles.inputLabel}>
              {watchCreatedAt ? formatDisplayDate(watchCreatedAt) : t('Prescription date (*autofill)')}
            </IonLabel>
            <Controller
              render={({ onChange, value }) => (
                <IonPopover>
                  <IonDatetime
                    onIonChange={(e) => onChange(e.detail.value)}
                    className="ion-no-padding"
                    style={{ color: '#1C1C1C', opacity: 1 }}
                    value={value}
                    disabled
                  />
                </IonPopover>
              )}
              control={control}
              defaultValue=""
              name="createdAt"
            />
            <IonIcon className={cx(styles.icon, 'ion-no-margin')} icon={calendarOutline} slot="end" />
          </IonItem>

          <IonItem className={styles.prescriptionInfoItem} lines="none">
            <Controller
              render={({ onChange, value }) => (
                <IonTextarea
                  className={styles.prescriptionInfoTextArea}
                  onIonChange={(e) => onChange(e.detail.value)}
                  value={value}
                  inputmode="text"
                  rows={5}
                  placeholder={t('Enter description')}
                  maxlength={256}
                />
              )}
              control={control}
              name="description"
            />
          </IonItem>
        </IonList>

        {prescription && (
          <IonList className={styles.list}>
            <IonListHeader className={cx(styles.listHeader, 'ion-no-padding')}>
              <IonLabel>{t('List of medicines')}</IonLabel>
            </IonListHeader>

            {prescription?.prescriptionmedicineSet.edges.map(({ node }) => {
              return (
                <IonItem
                  key={node.id}
                  className={styles.drugsItems}
                  button
                  onClick={() => push(`/prescriptions/${prescription?.id}/medicines/${node.id}`)}
                  detail={false}
                >
                  <IonIcon className={styles.icon} slot="end" icon={pencil} />
                  {t(node.medicine.name)}
                </IonItem>
              );
            })}

            <IonItem
              className={cx(styles.drugsItems, styles.addDrugsItem)}
              button
              onClick={() => push(`/prescriptions/${prescription?.id}/medicines/new`)}
              detail={false}
              lines="none"
            >
              <IonIcon icon={addCircleSharp} className={styles.iconAdd} />
              {t('Add medicine')}
            </IonItem>
          </IonList>
        )}
      </IonRow>
      <IonRow>
        <IonButton
          className={styles.button}
          disabled={!formState.isValid || formState.isSubmitting || formState.isSubmitSuccessful}
          expand="block"
          onClick={handleSubmit(mutation)}
        >
          {t('Save')}
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};

PrescriptionDetail.defaultProps = {
  prescription: undefined,
};

export { PrescriptionDetail };
