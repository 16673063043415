import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { ItemSliding } from '../UI/ItemSliding/ItemSliding';
import { SeizureType } from '../../generated/graphql';

interface ISeizureItem {
  seizure: SeizureType;
  deleteSeizure: (id: string) => void;
}

const SeizureItem: React.FC<ISeizureItem> = ({ seizure, deleteSeizure }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const date = useMemo(() => format(new Date(seizure.date), 'dd.MM.yyyy'), [seizure.date]);

  return (
    <ItemSliding
      label={`${t('Record from ')} ${date}`}
      onItemClick={() => push(`/seizures/${seizure.id}`)}
      onItemRemove={() => deleteSeizure(seizure.id)}
    />
  );
};

export { SeizureItem };
