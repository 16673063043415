import React from 'react';
import classnames from 'classnames/bind';
import { useIonViewWillEnter } from '@ionic/react';
import moment from 'moment';
import styles from './MessageList.module.scss';
import { MessageType } from '../../../pages/chat/Chat';
import { useAuth } from '../../../contexts/auth-context';
import useChatMessageListProvider from '../../../providers/chat/useChatMessageListProvider';

const cx = classnames.bind(styles);

const MessageItem = ({ from_user_id, text, created_at }: MessageType) => {
  const authContext = useAuth();

  const loggedInUserMessage = from_user_id === Number(authContext.user?.id);
  return (
    <div
      className={cx(styles.message, {
        [styles.message__incoming]: !loggedInUserMessage,
      })}
    >
      <span className={cx(styles.message_text)}>{text}</span>
      <span className={styles.message_time}>{moment(new Date(created_at)).format('HH:mm')}</span>
    </div>
  );
};

interface IMessageListProps {
  messages: MessageType[] | undefined;
}

export interface DayType {
  id?: string;
  date?: string;
  type?: string;
}

const MessageList = ({ messages }: IMessageListProps) => {
  const [displayDay, messagesEndRef, scrollToBottom] = useChatMessageListProvider(messages);

  useIonViewWillEnter(scrollToBottom);

  return (
    <div className={cx(styles.chat_messageList)}>
      {messages?.map((message: MessageType | DayType) => {
        if ('type' in message && message?.type && message?.type === 'day') {
          return (
            <div key={message.id} className={cx(styles.day)}>
              {displayDay(message.date)}
            </div>
          );
        }
        return <MessageItem key={message.id} {...(message as MessageType)} />;
      })}
      <div ref={messagesEndRef} />
    </div>
  );
};

MessageList.defaultProps = {
  messages: [],
};

export { MessageList };
