import React from 'react';

// graphql
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PrescriptionType } from '../../../generated/graphql';

import { ItemSliding } from '../../UI/ItemSliding/ItemSliding';

interface IPrescriptionsItem {
  prescription: PrescriptionType;
  removePrescription: (id: string) => void;
}

const PrescriptionsItem: React.FC<IPrescriptionsItem> = ({ prescription, removePrescription }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  return (
    <ItemSliding
      label={`${t('Prescription')} ${prescription.id}`}
      onItemClick={() => push(`/prescriptions/${prescription.id}`)}
      onItemRemove={() => removePrescription(prescription.id)}
    />
  );
};

export { PrescriptionsItem };
