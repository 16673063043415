import React from 'react';
import classNames from 'classnames/bind';
import { Rating } from 'react-simple-star-rating';
import { useHistory } from 'react-router-dom';
import { IonCard, IonItem } from '@ionic/react';
import styles from './ClinicReview.module.scss';
import { ReviewType } from '../../../generated/graphql';
import { formatDisplayDateTime } from '../../../utils/utils';

const cx = classNames.bind(styles);

interface IReviewClinic {
  review: ReviewType;
}

const ClinicReview = ({ review }: IReviewClinic) => {
  const { push } = useHistory();

  return (
    <IonCard className={cx(styles.ionCard)} onClick={() => push(`../review/${review.id}`)} mode="ios">
      <IonItem className={cx(styles.item)}>{`Name : ${review.author?.firstName} ${review.author?.secondName}`}</IonItem>
      <IonItem className={cx(styles.item)}>{`Created : ${formatDisplayDateTime(review.createdAt)}`}</IonItem>
      <IonItem className={cx(styles.item)}>
        <Rating fillColor="var(--ion-color-primary)" allowFraction readonly initialValue={review.avgEntryStars} />
      </IonItem>
      <IonItem className={cx(styles.item)}>{`Comment : ${review.comment}`}</IonItem>
    </IonCard>
  );
};

export { ClinicReview };
