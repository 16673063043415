import React from 'react';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames/bind';
import { IonIcon } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import styles from './DisclosureDropdown.module.scss';

const cx = classNames.bind(styles);

interface IDisclosureDropdown {
  isOpen?: boolean;
  title: string;
  children: React.ReactNode;
}

const DisclosureDropdown = ({ isOpen, title, children }: IDisclosureDropdown) => {
  return (
    <Disclosure defaultOpen={isOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className={cx('disclosureButton')}>
            <div className={cx(styles.title)}>{title}</div>
            {open ? (
              <IonIcon icon={chevronUpOutline} />
            ) : (
              <IonIcon className={cx(styles.icon)} icon={chevronDownOutline} />
            )}
          </Disclosure.Button>
          <Disclosure.Panel>{children}</Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

DisclosureDropdown.defaultProps = {
  isOpen: false,
};

export { DisclosureDropdown };
