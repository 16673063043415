import { IonDatetime, IonIcon, IonItem, IonPopover, IonLabel } from '@ionic/react';
import React, { useEffect } from 'react';
import { calendarOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames/bind';
import { WomenPeriodType } from '../../generated/graphql';
import styles from './WomenPeriodDetail.module.scss';
import { WideButton } from '../UI/WideButton/WideButton';
import { formatDisplayDate, UTCLocaleDateTimeConverter } from '../../utils/utils';

interface Props {
  womenPeriod?: WomenPeriodType;
  formSubmitHandler: (womenPeriod: Omit<WomenPeriodType, 'id'>) => void;
}

const toISODateFormat = (value: string): string => {
  return UTCLocaleDateTimeConverter(value).toISOString().slice(0, 10);
};

const cx = classNames.bind(styles);

const WomenPeriodDetail: React.FC<Props> = ({ womenPeriod, formSubmitHandler }) => {
  const { control, handleSubmit, watch, errors, setValue } = useForm<Pick<WomenPeriodType, 'startDate' | 'endDate'>>({
    mode: 'onBlur',
    defaultValues: {
      endDate: womenPeriod?.endDate,
      startDate: womenPeriod?.startDate,
    },
  });
  const { t } = useTranslation();

  const onSubmit = handleSubmit((payload: Omit<WomenPeriodType, 'id'>) => {
    formSubmitHandler(payload);
  });

  const { startDate, endDate } = watch(['startDate', 'endDate']);

  // Prevents startDate > endDate
  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setValue('endDate', startDate);
    }
  }, [startDate, endDate, setValue]);

  return (
    <form onSubmit={onSubmit}>
      <IonItem
        lines="none"
        id="open-start-date-input"
        button
        detail={false}
        className={cx(styles.datetimeContainer, { 'border-color-danger': errors.startDate })}
      >
        <IonLabel className={styles.inputLabel}>
          <b>{t('Start date')}</b>: <i>{startDate ? formatDisplayDate(startDate) : <span>XX/XX/XXXX</span>}</i>
        </IonLabel>

        <Controller
          render={({ onChange, value, onBlur }) => (
            <IonPopover onDidDismiss={onBlur} trigger="open-start-date-input">
              <IonDatetime
                onIonChange={(e) => onChange(toISODateFormat(e.detail.value!))}
                presentation="date"
                cancelText={t('Cancel')}
                value={value}
                doneText={t('Done')}
                className={styles.datetimeInput}
                placeholder={t('Select date')}
              />
            </IonPopover>
          )}
          control={control}
          defaultValue=""
          name="startDate"
          rules={{ required: true }}
        />
        <IonIcon className={cx(styles.iconInput, 'ion-no-margin')} icon={calendarOutline} slot="end" />
      </IonItem>
      <IonItem
        lines="none"
        button
        detail={false}
        id="open-end-date-input"
        className={cx(styles.datetimeContainer, { 'border-color-danger': errors.endDate })}
      >
        <IonLabel className={styles.inputLabel}>
          <b>{t('End date')}</b>: <i>{endDate ? formatDisplayDate(endDate) : <span>XX/XX/XXXX</span>}</i>
        </IonLabel>
        <Controller
          render={({ onChange, value, onBlur }: any) => (
            <IonPopover onDidDismiss={onBlur} trigger="open-end-date-input">
              <IonDatetime
                onIonChange={(e) => {
                  onChange(toISODateFormat(e.detail.value!));
                }}
                presentation="date"
                cancelText={t('Cancel')}
                value={value}
                min={startDate || undefined}
                doneText={t('Done')}
                className={styles.datetimeInput}
              />
            </IonPopover>
          )}
          control={control}
          defaultValue=""
          name="endDate"
          rules={{ required: true }}
        />
        <IonIcon className={cx(styles.iconInput, 'ion-no-margin')} icon={calendarOutline} slot="end" />
      </IonItem>

      <WideButton type="submit" text={t('Save')} />
    </form>
  );
};

WomenPeriodDetail.defaultProps = {
  womenPeriod: undefined,
};

export { WomenPeriodDetail };
