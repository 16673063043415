import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonIcon, IonRippleEffect, IonRow, IonText } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './TopSearchField.module.scss';
import { backButtonCircleIcon } from '../../../assets/images/icons/icons';

const cx = classNames.bind(styles);

interface Props {
  onSearchFieldClick?: () => void;
  onGoBackClick?: () => void;
  value?: string;
}

const TopSearchField = ({ onSearchFieldClick, onGoBackClick, value }: Props) => {
  const { goBack } = useHistory();
  const { t } = useTranslation();

  const [isComponentVisible, setIsComponentVisible] = useState(false);

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => setIsComponentVisible(true), 100);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return (
    <div style={{ opacity: isComponentVisible ? '1' : '0' }} className={cx(styles.locationGridContainer)}>
      <IonRow className={cx('ion-align-items-center', 'ion-nowrap', 'locationGridContainer__searchbar')}>
        <IonCol size="1.3" offset="0.2">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            onClick={() => {
              if (onGoBackClick) {
                onGoBackClick();
              } else {
                goBack();
              }
            }}
            className={cx('toolbarIconButton', 'locationButton')}
          >
            <IonIcon slot="start" color="dark" className={cx('toolbar__back-button')} icon={backButtonCircleIcon} />
          </div>
        </IonCol>
        <IonCol size="10" offset="0.5" className={cx('searchColumn')}>
          {/* <IonInput className={cx("addressInput")} /> */}
          <IonButton color="secondary" onClick={onSearchFieldClick} className={cx('search-button', 'ion-activatable')}>
            <IonRow className={cx('search-button__container')}>
              <IonCol size="11" className={cx('search-button__text')}>
                <IonText color="dark">{value || t('Enter address')}</IonText>
              </IonCol>
              <IonCol size="1">
                <IonIcon color="primary" icon={chevronDown} />
              </IonCol>
            </IonRow>
            <IonRippleEffect type="unbounded" />
          </IonButton>
        </IonCol>
      </IonRow>
    </div>
  );
};

TopSearchField.defaultProps = {
  onSearchFieldClick: undefined,
  onGoBackClick: undefined,
  value: undefined,
};

export { TopSearchField };
