import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useWomenPeriodCreateMutation, WomenPeriodType } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { WomenPeriodDetail } from '../../components/women-period/WomenPeriodDetail';

const NewWomenPeriod = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const [createWomenPeriod, { loading: createWomenPeriodLoading, error }] = useWomenPeriodCreateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const handleFormSubmission = async ({ startDate, endDate }: Omit<WomenPeriodType, 'id'>) => {
    await createWomenPeriod({
      variables: {
        input: {
          startDate,
          endDate,
        },
      },
    });
  };

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title translucent title={t('New record')} backButton />
      <IonContent>
        {createWomenPeriodLoading && <IonLoading isOpen message={t('Loading...')} />}
        <WomenPeriodDetail formSubmitHandler={handleFormSubmission} />
      </IonContent>
    </IonPage>
  );
};

export { NewWomenPeriod };
