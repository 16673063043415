import React from 'react';
import { IonList } from '@ionic/react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { PatientFileType } from '../../generated/graphql';
import styles from './PatientFileList.module.scss';
import { NoElementFound } from '../empty-state/NoElementFound';
import { PatientFileItem } from './PatientFileItem';

const cx = classNames.bind(styles);

interface Props {
  patientFiles: PatientFileType[];
  removePatientFile: (id: string) => void;
}

const PatientFileList = ({ removePatientFile, patientFiles }: Props) => {
  const { t } = useTranslation();

  if (patientFiles.length === 0) {
    return <NoElementFound title={t('File list is empty')} />;
  }
  return (
    <IonList className={cx(styles.listContainer)}>
      {patientFiles.map((SOSContact) => (
        <PatientFileItem removePatientFile={removePatientFile} key={SOSContact.id} patientFile={SOSContact} />
      ))}
    </IonList>
  );
};

PatientFileList.defaultProps = {};

export { PatientFileList };
