import React from 'react';
import { IonButton, IonButtons, IonIcon, IonTitle } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Map, Placemark } from 'react-yandex-maps';
import classnames from 'classnames/bind';
import { arrowBackOutline } from 'ionicons/icons';
import styles from './ReviewMaps.module.scss';
import { ReviewClinicType } from '../../generated/graphql';

interface IReviewMaps {
  setShowReviewMap: React.Dispatch<React.SetStateAction<boolean>>;
  clinics: ReviewClinicType[];
}

const cx = classnames.bind(styles);

const ReviewMaps = ({ setShowReviewMap, clinics }: IReviewMaps) => {
  const { t } = useTranslation();

  return (
    <>
      <IonButtons className={cx('ionButtons')}>
        <IonButton
          onClick={() => setShowReviewMap((prevState) => !prevState)}
          className={cx('ionButtons__btn')}
          mode="ios"
        >
          <IonIcon className={cx('ionButtons__btn-icon')} icon={arrowBackOutline} />
        </IonButton>
        <IonTitle className={cx('ionButtons__btn-text')}>{t('Map')}</IonTitle>
      </IonButtons>
      <Map
        defaultState={{
          center: [43.317681629584854, 45.69400995310456],
          zoom: 14,
          controls: ['geolocationControl', 'zoomControl'],
        }}
        options={{
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: true,
          yandexMapDisablePoiInteractivity: true,
        }}
        style={{
          height: '100%',
          width: '100%',
          position: 'fixed',
          top: 50,
        }}
      >
        {clinics.length > 0 &&
          clinics.map((clinic) => {
            return (
              <Placemark
                key={clinic.id}
                defaultGeometry={clinic.location.coordinates}
                properties={{
                  balloonContentHeader: clinic.name,
                }}
                options={{
                  iconColor: 'green',
                }}
              />
            );
          })}
      </Map>
    </>
  );
};

export { ReviewMaps };
