import React from 'react';
import { IonText } from '@ionic/react';
import moment from 'moment';

// graphql
import { useTranslation } from 'react-i18next';
import { useUserRegisterMutation } from '../../generated/graphql';

// i18n

// hooks
import { useTimer } from '../../hooks/useTimer';

// context
import { useAuth } from '../../contexts/auth-context';

const ResendSMS = () => {
  const authContext = useAuth();
  const { t } = useTranslation();
  const [duration, setDuration] = useTimer(Number(process.env.REACT_APP_SMS_RESEND_TIMER));

  function formatTimerDuration(): string {
    const timerObject = moment.duration(duration, 'seconds');
    return `${timerObject.minutes()}:${
      timerObject.seconds() < 10 ? `0${timerObject.seconds()}` : timerObject.seconds()
    }`;
  }

  const [userRegistration] = useUserRegisterMutation({
    onCompleted: () => {
      setDuration(Number(process.env.REACT_APP_SMS_RESEND_TIMER));
    },
  });

  const handleRegistration = () => {
    userRegistration({
      variables: {
        phone: authContext.user!.phone!,
        email: authContext.user!.email!,
      },
    });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {duration > 0 ? (
        <IonText color="dark">{t(`Re-send SMS after ${formatTimerDuration()} seconds`)}</IonText>
      ) : (
        <IonText onClick={handleRegistration} color="warning">
          {t('Re-send SMS')}
        </IonText>
      )}
    </>
  );
};

export { ResendSMS };
