import { IonPage } from '@ionic/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSeizureCreateMutation } from '../../generated/graphql';
import { Title } from '../../components/title/Title';
import { SeizureFormWithMap } from '../../components/seizure/SeizureFormWithMap';
import { SeizureWithSeparateDateAndTimeInputType } from '../../components/seizure/SeizureForm';

const NewSeizurePage = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const [seizureCreate, { loading }] = useSeizureCreateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const onSubmit = useCallback(
    ({ time, ...payload }: SeizureWithSeparateDateAndTimeInputType) => {
      const [dateOnly] = payload.date.split('T');
      const [, timeOnly] = time.split('T');
      const updatedPayload = {
        ...payload,
        date: `${dateOnly}T${timeOnly}`,
      };
      seizureCreate({
        variables: {
          input: updatedPayload,
        },
      });
    },
    [seizureCreate]
  );

  return (
    <IonPage>
      <Title translucent title={t('Add new entry')} backButton />
      <SeizureFormWithMap onSubmit={onSubmit} submitting={loading} submitButtonText="Create" />
    </IonPage>
  );
};

export { NewSeizurePage };
