import React from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface IRatingBar {
  rating: number;
}

const RatingBar = ({ rating }: IRatingBar) => {
  return (
    <div className={cx('ratingBar-container')}>
      <div style={{ width: `calc(100%/5*${rating}` }} className={cx('ratingBar-filter')} />
    </div>
  );
};

export { RatingBar };
