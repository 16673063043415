import React from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReviewType, useReviewQuery } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { ReviewDetail } from '../../components/review/ReviewDetail';

const ReviewPage = () => {
  const { t } = useTranslation();
  const { id } = useParams() as { id: string };

  const { data, loading, error, refetch } = useReviewQuery({ variables: { id }, fetchPolicy: 'no-cache' });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  if (loading || !data?.review) {
    return <IonLoading isOpen={loading} message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title translucent title={t('Review')} backButton />
      <IonContent>{data && <ReviewDetail review={data.review as ReviewType} />}</IonContent>
    </IonPage>
  );
};

export { ReviewPage };
