import React from 'react';
import { IonLabel, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router';
import {
  HomeIcon,
  MedicineIcon,
  MessageIcon,
  ContactsIcon,
  CalendarIcon,
} from '../../assets/images/icons/components/icons';
import styles from './FooterTab.module.scss';

const cx = classNames.bind(styles);

interface Props {
  children: React.ReactNode;
}

const FooterTab = ({ children }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // TODO: fix this shitcode

  return (
    <IonTabs className={cx('FooterTab')}>
      {children}
      <IonTabBar className={cx('FooterTab__container')} slot="bottom" mode="ios">
        <IonTabButton
          className={cx('FooterTab__btn')}
          selected={pathname === '/sos-contacts'}
          tab="SOSContacts"
          href="/sos-contacts"
        >
          <ContactsIcon classname={pathname === '/sos-contacts' ? 'active' : ''} />
          <IonLabel className={cx('FooterTab__btn-label')}>{t('Contacts')}</IonLabel>
        </IonTabButton>
        <IonTabButton
          className={cx('FooterTab__btn')}
          selected={pathname === '/calendar'}
          tab="Calendar"
          href="/calendar"
        >
          <CalendarIcon classname={pathname === '/calendar' ? 'active' : ''} />
          <IonLabel className={cx('FooterTab__btn-label')}>{t('Calendar')}</IonLabel>
        </IonTabButton>
        <IonTabButton
          className={cx('FooterTab__btn', 'homeIcon')}
          selected={pathname === '/home'}
          tab="Home"
          href="/home"
        >
          <HomeIcon classname={pathname === '/home' ? 'active' : ''} />
          <IonLabel className={cx('FooterTab__btn-label')}>{t('Home')}</IonLabel>
        </IonTabButton>
        <IonTabButton className={cx('FooterTab__btn')} selected={pathname === '/chat'} tab="Chat" href="/chat">
          <MessageIcon classname={pathname === '/chat' ? 'active' : ''} />
          <IonLabel className={cx('FooterTab__btn-label')}>{t('Chat')}</IonLabel>
        </IonTabButton>
        <IonTabButton
          className={cx('FooterTab__btn')}
          selected={pathname === '/medicines-intake'}
          tab="MedicinesIntake"
          href="/medicines-intake"
        >
          <MedicineIcon classname={pathname === '/medicines-intake' ? 'active' : ''} />
          <IonLabel className={cx('FooterTab__btn-label')}>{t('Medicines')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export { FooterTab };
