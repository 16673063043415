import React from 'react';
import { IonList } from '@ionic/react';
import * as _ from 'lodash';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { SOSContactItem } from './SOSContactItem';
import { SosContactType } from '../../generated/graphql';
import styles from './SOSContactList.module.scss';
import { NoElementFound } from '../empty-state/NoElementFound';

const cx = classNames.bind(styles);
interface Props {
  sortDesc: boolean;
  soscontacts: SosContactType[];
  removeSOSContact: (id: string) => void;
}

const SOSContactList = ({ removeSOSContact, soscontacts, sortDesc }: Props) => {
  const { t } = useTranslation();

  const sortedSOSContacts = sortDesc
    ? _.sortBy(soscontacts, (x) => x.firstName)
    : _.sortBy(soscontacts, (x) => x.secondName).reverse();

  if (sortedSOSContacts.length === 0) {
    return <NoElementFound title={t('SOSContacts list is empty')} />;
  }
  return (
    <IonList className={cx(styles.listContainer)}>
      {sortedSOSContacts.map((SOSContact) => (
        <SOSContactItem removeSOSContact={removeSOSContact} key={SOSContact.id} soscontact={SOSContact} />
      ))}
    </IonList>
  );
};

SOSContactList.defaultProps = {
  sortDesc: false,
};

export { SOSContactList };
