import React from 'react';
import { Rating } from 'react-simple-star-rating';
import classNames from 'classnames/bind';
import { IonCard, IonItem } from '@ionic/react';
import { ReviewEntryType } from '../../../generated/graphql';
import styles from './ReviewEntry.module.scss';

const cx = classNames.bind(styles);

interface IReviewEntry {
  reviewEntry: ReviewEntryType;
}

const ReviewEntry = ({ reviewEntry }: IReviewEntry) => {
  return (
    <IonCard className={cx(styles.ionCard)} mode="ios">
      <IonItem className={cx(styles.item)}>{`Characteristic :${reviewEntry.characteristic.name}`}</IonItem>
      <IonItem className={cx(styles.item)}>
        <Rating fillColor="var(--ion-color-primary)" allowFraction readonly initialValue={+reviewEntry.stars} />
      </IonItem>
      <IonItem className={cx(styles.item)}>{`Comment :${reviewEntry.comment}`}</IonItem>
    </IonCard>
  );
};

export { ReviewEntry };
