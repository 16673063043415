import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// graphql
import {
  PrescriptionType,
  UpdatePrescriptionInputType,
  useRemovePrescriptionMutation,
  useUpdatePrescriptionMutation,
  usePrescriptionQuery,
} from '../../generated/graphql';

// components
import { PrescriptionDetail } from '../../components/prescription/PrescriptionDetail';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';

const PrescriptionPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const toast = useToast();

  const {
    data,
    loading: prescriptionQueryLoading,
    error,
    refetch,
  } = usePrescriptionQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const [updatePrescription, { loading: updatePrescriptionLoading }] = useUpdatePrescriptionMutation({
    onCompleted() {
      refetch();
    },
  });

  const [removePrescription, { loading: removePrescriptionLoading }] = useRemovePrescriptionMutation({
    onCompleted() {
      toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
      goBack();
    },
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const handleUpdatePrescription = useCallback(
    async (input: UpdatePrescriptionInputType) => {
      await updatePrescription({
        variables: {
          input: {
            id,
            description: input.description,
          },
        },
      });
    },
    [updatePrescription, id]
  );

  async function handleRemovePrescription() {
    await removePrescription({
      variables: {
        input: { id },
      },
    });
  }

  if (prescriptionQueryLoading || !data?.prescription) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        backButton
        delete={{
          deleteButton: true,
          callback: handleRemovePrescription,
        }}
        title={`${t('Prescription')} №${data?.prescription.id}`}
      />
      <IonContent>
        {(updatePrescriptionLoading || removePrescriptionLoading) && <IonLoading isOpen message={t('Loading...')} />}
        <PrescriptionDetail mutation={handleUpdatePrescription} prescription={data?.prescription as PrescriptionType} />
      </IonContent>
    </IonPage>
  );
};

export { PrescriptionPage };
