import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  SosContactType,
  SosContactUpdateInputType,
  useSosContactLazyQuery,
  useSosContactUpdateMutation,
  useRemoveSosContactMutation,
} from '../../generated/graphql';
import { SOSContactDetail } from '../../components/sos-contact/SOSContactDetail';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';

const SOSContactPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const toast = useToast();

  const [getSosContact, { data, loading, error, refetch }] = useSosContactLazyQuery({ variables: { id } });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  useEffect(() => {
    getSosContact();
  }, [getSosContact]);

  const [sosСontactUpdate, { loading: soscontactUpdateLoading }] = useSosContactUpdateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const [removeSosContact, { loading: removeSosContactLoading }] = useRemoveSosContactMutation({
    onCompleted() {
      toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
      goBack();
    },
  });

  const mutationSosContact = useCallback(
    async (soscontact: SosContactType) => {
      const updateSOSContact = soscontact as SosContactUpdateInputType;

      updateSOSContact.id = id;

      await sosСontactUpdate({
        variables: {
          input: updateSOSContact,
        },
      });
    },
    [sosСontactUpdate, id]
  );

  const handleRemoveSosContact = useCallback(async () => {
    await removeSosContact({
      variables: {
        input: { id },
      },
    });
  }, [removeSosContact, id]);

  if (loading || !data?.sosContact) {
    return <IonLoading isOpen={loading} message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        title={t('Edit')}
        backButton
        delete={{
          deleteButton: true,
          callback: handleRemoveSosContact,
        }}
      />
      <IonContent>
        {(soscontactUpdateLoading || removeSosContactLoading) && <IonLoading isOpen message={t('Loading...')} />}
        <SOSContactDetail
          formSubmitHandler={(value) => mutationSosContact(value as SosContactType)}
          soscontact={data?.sosContact as SosContactType}
          submitButtonText={t('Save')}
        />
      </IonContent>
    </IonPage>
  );
};

export { SOSContactPage };
