import { ActionPerformed, PushNotifications, Token } from '@capacitor/push-notifications';
import { useCallback, useState } from 'react';
import { GcmDeviceCloudMessageType, useCreateGcmDeviceMutation } from '../generated/graphql';

const usePushNotifications = (): [(userId: string) => void, boolean] => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [createGCMDevice] = useCreateGcmDeviceMutation({
    onCompleted: () => {
      // console.log('success: ', res);
    },
    onError: () => {
      // console.log('im sorry jon: ', res)
    },
  });

  const registerPush = useCallback(
    (userId: string) => {
      if (isActive) {
        return;
      }

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((permission) => {
        if (permission.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // No permission for push granted
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        if (userId) {
          createGCMDevice({
            variables: {
              input: {
                // Get id on authentication
                // user: authContext.user.id!,
                user: userId,
                registrationId: token.value,
                cloudMessageType: GcmDeviceCloudMessageType.Fcm,
                name: null as unknown as string,
                applicationId: null as unknown as string,
                deviceId: null as unknown as string,
              },
            },
          });
        }
        // console.log('My token: ' + token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', () => {
        // console.log('Error: ' + JSON.stringify(error))
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived', async () => {
        // console.log('Push received: ' + JSON.stringify(notification));
      });

      // When clicked on received notification
      PushNotifications.addListener('pushNotificationActionPerformed', async ({ notification }: ActionPerformed) => {
        const { data } = notification;
        // console.log('Action performed: ', + JSON.stringify(notification));
        if (data.detailsId) {
          // this.router.navigateByUrl
          // console.log(data.detailsId)
        }
      });
      setIsActive(true);
    },
    [createGCMDevice, setIsActive, isActive]
  );

  return [registerPush, isActive];
};

export { usePushNotifications };
