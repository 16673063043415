/* eslint-disable no-console */
import { BluetoothLE, CommonInfo } from '@ionic-native/bluetooth-le';
import { isPlatform } from '@ionic/react';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import { Record } from '../contexts/watch-device-context';
import { SettingsValueType } from '../generated/graphql';

const DEVICE_NAME = 'Bangle.js';
const NORDIC_UART_SERVICE_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';
const TX_CHARACTERISTIC_UUID = '6e400003-b5a3-f393-e0a9-e50e24dcca9e';
const RX_CHARACTERISTIC_UUID = '6e400002-b5a3-f393-e0a9-e50e24dcca9e';

export interface StoreActionPayloadType {
  connected: boolean;
  appRunning: boolean;
  bluetooth: boolean;
  device: CommonInfo;
  battery: number;
  temperature: Record;
  acceleration: Record;
  gyro: Record;
  ppg: Record;
}

export const goToSettings = async () => {
  if (isPlatform('ios')) {
    await NativeSettings.openIOS({ option: IOSSettings.App });
  } else if (isPlatform('android')) {
    await NativeSettings.openAndroid({ option: AndroidSettings.ApplicationDetails });
  }
};

export const isBluetoothEnabled = async () => {
  const { isEnabled } = await BluetoothLE.isEnabled();
  return isEnabled;
};

export const isLocationAlways = async () => {
  const enable = await Diagnostic.getLocationAuthorizationStatus();
  return enable === Diagnostic.permissionStatus.GRANTED;
};

export const isBluetoothConnect = async () => {
  // If android version >= 12, then permission won't be granted and should be requested
  // If android version < 12, then 'BLUETOOTH_CONNECT' permission granted automatically
  const enable = await Diagnostic.getPermissionAuthorizationStatus('BLUETOOTH_CONNECT');

  // 'GRANTEND' - for Android >=12
  // 'NOT_REQUESTED' - for Android < 12
  return enable === Diagnostic.permissionStatus.GRANTED || enable === Diagnostic.permissionStatus.NOT_REQUESTED;
};

const str2ab = (str: string) => {
  const array = new Uint8Array(new ArrayBuffer(str.length));
  for (let i = 0; i < str.length; i += 1) {
    array[i] = str.charCodeAt(i);
  }
  return array;
};

const ab2str = (array: Uint8Array) => {
  return array.reduce((result, byte) => result + String.fromCharCode(byte), '');
};

async function attemptDeviceConnection(foundDevices: CommonInfo[]) {
  const connection: { device: CommonInfo | null; connectionResult: string } = {
    device: null,
    connectionResult: '',
  };

  // TODO: resolve this eslint issues
  // eslint-disable-next-line no-restricted-syntax
  for (const foundDevice of foundDevices) {
    try {
      // eslint-disable-next-line no-await-in-loop
      connection.connectionResult = await new Promise<string>((resolve) => {
        BluetoothLE.connect({ ...foundDevice, autoConnect: true }).subscribe(
          (result) => {
            resolve(result.status);
          },
          (error) => {
            // reject(error); // ERRORs: device previously connected and isDisconnected
            resolve(error.error);
          }
        );
      });

      if (connection.connectionResult === 'connected') {
        // Established connection with the device
        connection.device = foundDevice;
        break;
      } else {
        // If we didn't establish connection with
        // the device we close that attempt
        // eslint-disable-next-line no-await-in-loop
        await BluetoothLE.close(foundDevice);
      }
    } catch (e) {
      // console.log(e);
    }
  }
  return connection;
}

export const runWatchApp = async (
  device: CommonInfo,
  onWatchDisconnect: () => void,
  onDataChange: (data: any) => void,
  config: Pick<SettingsValueType, 'sensorReadPeriod' | 'batteryReadPeriod'>
) => {
  const BANGLE_RESET_CODE = 'reset();\n';

  const BANGLE_CODE = `
        g.clear();
        g.setFont("6x8", 3);
        g.drawString("Epihelper", 10, 70);
        Bangle.setHRMPower(1, 'epihelper');

        setInterval(function() {
            if (process.env.HWVERSION == 1) {
                ppg = analogRead(D29);
                ppgStr = 'P,' + ppg;
                Bluetooth.println(ppgStr);
            }
            accel = Bangle.getAccel();
            accx = Math.round(accel.x*100);
            accy = Math.round(accel.y*100);
            accz = Math.round(accel.z*100);
            accelStr = 'A,' + accx + ',' + accy + ',' + accz;
            Bluetooth.println(accelStr);
        }, ${config.sensorReadPeriod});
        setInterval(function() {
            Bluetooth.println('B,' + E.getBattery());
        }, ${config.batteryReadPeriod});
        
        if (process.env.HWVERSION == 2) {
            Bangle.on('HRM-raw', function(hrm) {
                ppg = 'P,' + hrm.raw;
                Bluetooth.println(ppg);
            });
        }
    `;

  const FINAL_BANGLE_CODE = `\x03\x10if(1){${BANGLE_CODE}}\n`;
  setTimeout(() => {
    for (let i = 0; i <= FINAL_BANGLE_CODE.length; i += 20) {
      BluetoothLE.write({
        address: device!.address,
        service: NORDIC_UART_SERVICE_UUID,
        characteristic: RX_CHARACTERISTIC_UUID,
        type: 'noResponse',
        value: BluetoothLE.bytesToEncodedString(str2ab(FINAL_BANGLE_CODE.substr(i, 20))),
      }).catch(() => {
        console.log('=>(device.ts:145) Handled promise');
      });
    }
  }, 1500);

  let buffer: string;
  try {
    let bluetoothUnsubscribeTimerID: NodeJS.Timeout;
    const subscriptionParams = {
      address: device.address,
      service: NORDIC_UART_SERVICE_UUID,
      characteristic: TX_CHARACTERISTIC_UUID,
    };
    BluetoothLE.subscribe(subscriptionParams).subscribe(
      (result) => {
        // Unsubscribe observable if watch not running our 'Epihelper' app
        clearTimeout(bluetoothUnsubscribeTimerID);
        bluetoothUnsubscribeTimerID = setTimeout(() => {
          BluetoothLE.unsubscribe(subscriptionParams).catch(() => {
            console.log('Unsubcscribe error catch');
          });
          onWatchDisconnect();
        }, config.sensorReadPeriod + 5000);

        if (result.status === 'subscribedResult') {
          const array = BluetoothLE.encodedStringToBytes(result.value);
          buffer += ab2str(array);
          let values = buffer.split('\n');
          buffer = values[values.length - 1];
          values = values.slice(0, values.length - 1);

          values.forEach((value) => {
            // console.log('value', value)
            const valueList = value.split(',');

            const timestamp = Date.now();
            if (valueList.length === 4 && valueList[0] === 'A') {
              // accel
              const [accx, accy, accz] = valueList.slice(1).map(Number);
              onDataChange({ acceleration: { value: [accx, accy, accz], timestamp } });
            } else if (valueList.length === 2 && valueList[0] === 'P') {
              // ppg
              const ppg = +valueList[1];
              onDataChange({ ppg: { value: [ppg], timestamp } });
            } else if (valueList.length === 2 && valueList[0] === 'B') {
              // battery
              const battery = +valueList[1];
              onDataChange({ battery });
            }
          });
        }
      },
      (error) => {
        console.log('Subscription: Device not connected', error);
        clearTimeout(bluetoothUnsubscribeTimerID);
        BluetoothLE.unsubscribe(subscriptionParams).catch((e: any) => {
          console.log('Unsubscribing error handled', e);
          onWatchDisconnect();
        });
        onWatchDisconnect();
      }
    );
  } catch (e: any) {
    // eslint-disable-next-line no-console
    console.log('Error: ', e.message);
  }

  try {
    await BluetoothLE.write({
      address: device.address,
      service: NORDIC_UART_SERVICE_UUID,
      characteristic: RX_CHARACTERISTIC_UUID,
      type: 'noResponse',
      value: BluetoothLE.bytesToEncodedString(str2ab(BANGLE_RESET_CODE)),
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const connect = async () => {
  console.log(`--- START CONNECT---- ${new Date().getSeconds()}`);

  let status;
  try {
    status = await new Promise<string>((resolve) => {
      BluetoothLE.initialize().subscribe((result) => {
        resolve(result.status);
      });
    });
  } catch (e) {
    console.log('New catch : ', status, e);
  }

  // console.log(`ble status: ${status}`)
  if (status === 'disabled') return;

  const connectedDevices: CommonInfo[] = (
    (await BluetoothLE.retrieveConnected({ services: ['1800', '1801'] })) as any
  ).filter((el: CommonInfo) => el.name.includes(DEVICE_NAME));

  // eslint-disable-next-line no-console
  console.log('connectedDevices', connectedDevices);

  // Attempt to connect to watch device
  try {
    const { connectionResult, device } = await attemptDeviceConnection(connectedDevices);

    // If we didn't establish connection with
    // the device we close that attempt
    if (connectionResult === 'connect' && device) {
      const { status: bluetoothStatus } = await BluetoothLE.close(device);
      console.log('close status', bluetoothStatus);
      return;
    }

    console.log('--->', connectionResult, device);

    if (!device) {
      console.log('Not bonded to a bracelet');
      return;
    }

    const { isConnected } = await BluetoothLE.isConnected(device);
    if (!isConnected) {
      console.log('Device not connected');
      return;
    }

    // To initialize services
    const hello = await BluetoothLE.discover(device);
    console.log('service: ', hello);

    // await runWatchApp(device, onUnsubscribeCb, onBundleChangeCb, deviceConfig);

    // TODO: resolve eslint issue
    // eslint-disable-next-line consistent-return
    return { isConnected, device };
  } catch (e) {
    console.log('Device connection exception handled');
  }
};
