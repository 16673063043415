import { useState } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from '../../../../hooks/useSearchParams';

const ReviewSearchBar = () => {
  const [focused, setFocused] = useState(false);
  const searchParams = useSearchParams();
  const { t } = useTranslation();

  const onSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    searchParams.set('search', text);
  }, 500);

  return (
    <IonItem className={`flex search items-center pb2${focused ? ' focused' : ''}`} fill="outline">
      <IonInput
        color="white"
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onIonChange={(e: any) => {
          onSearchChange(e);
        }}
        defaultValue={searchParams.get('search') ?? ''}
        type="search"
        placeholder={t("Enter clinic's name...")}
      />
    </IonItem>
  );
};

export { ReviewSearchBar };
