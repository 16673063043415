import React from 'react';

import { IonCol } from '@ionic/react';

interface Props {
  day: number;
  divStyle: string;
  textDayStyle: string;
  cb?: () => void;
}

const Day = ({ cb, divStyle, day, textDayStyle }: Props) => {
  return (
    <IonCol size="1.71" onClick={cb} className="ion-text-center ion-padding-top">
      <div className={divStyle}>
        <span className={textDayStyle}>{day}</span>
      </div>
    </IonCol>
  );
};

Day.defaultProps = {
  cb: () => {},
};

export { Day };
