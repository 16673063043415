import React from 'react';

import { useHistory } from 'react-router-dom';
import { isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { WomenPeriodType } from '../../../generated/graphql';
import { ItemSliding } from '../../UI/ItemSliding/ItemSliding';
import { formatDisplayDate } from '../../../utils/utils';

interface Props {
  womenPeriod: WomenPeriodType;
  removeWomenPeriod: (id: string) => void;
}

const WomenCalendarListItem: React.FC<Props> = ({ womenPeriod, removeWomenPeriod }) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  const generateLabel = (startDateValue: string, endDateValue: string): string => {
    // Determines to show one or both months
    if (isSameDay(new Date(startDateValue), new Date(endDateValue))) {
      return t(formatDisplayDate(startDateValue));
    }
    return `${formatDisplayDate(startDateValue)} - ${formatDisplayDate(endDateValue)}`;
  };

  return (
    <ItemSliding
      label={generateLabel(womenPeriod.startDate, womenPeriod.endDate)}
      onItemClick={() => push(`/women-calendar/${womenPeriod.id}`)}
      onItemRemove={() => removeWomenPeriod(womenPeriod.id)}
    />
  );
};

export { WomenCalendarListItem };
