import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Medicines } from '../../components/medicines/Medicines';
import { usePatientMedicinesQuery, useRemovePatientMedicineMutation } from '../../generated/graphql';
import { Title } from '../../components/title/Title';
import { AddButton } from '../../components/UI/AddButton/AddButton';

const MedicinesPage: React.FC = () => {
  const { t } = useTranslation();

  const [sortList] = useState(false);

  const { data, loading, error, refetch } = usePatientMedicinesQuery({
    fetchPolicy: 'no-cache',
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const [removePatientMedicine, { loading: removePatientMedicineLoading }] = useRemovePatientMedicineMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleRemovePatientMedicine = useCallback(
    async (id: string) => {
      await removePatientMedicine({
        variables: {
          input: { id },
        },
      });
    },
    [removePatientMedicine]
  );
  //

  if (loading) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        title="Medicines"
        menuButton
        sort={{
          sortButton: true,
          callback: () => {},
        }}
      />
      <IonContent>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          data && (
            <>
              <Medicines removeMedicine={handleRemovePatientMedicine} data={data} sortDesc={sortList} />
              <AddButton routeLink="medicines/new" />
            </>
          )
        )}
      </IonContent>
      {!loading && removePatientMedicineLoading && <IonLoading isOpen message={t('Loading...')} />}
    </IonPage>
  );
};
export { MedicinesPage };
