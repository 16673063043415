import React, { useCallback, useEffect, useRef } from 'react';
import { IonButton, IonItem, IonList, IonRow, IonTextarea, useIonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import styles from './Review.module.scss';
import { CreateReviewInputType, useCreateReviewMutation } from '../../generated/graphql';
import { useToggleState } from '../../hooks/useToggleState';
import { Modal } from '../../components/UI/Modal/Modal';

interface Props {
  onDismiss: () => void;
}

const Review = ({ onDismiss }: Props) => {
  const { t } = useTranslation();
  const [successFlag, toggleSuccess] = useToggleState(false);
  const refTextArea = useRef<HTMLIonTextareaElement>(null);
  const [present, dismiss] = useIonAlert();
  const [createReview, { loading }] = useCreateReviewMutation();

  const { formState, control, handleSubmit } = useForm<CreateReviewInputType>({
    mode: 'onChange',
    defaultValues: { review: '' },
  });

  useEffect(() => {
    setTimeout(() => {
      refTextArea.current?.setFocus();
    }, 150);
  }, []);

  const handleReview = useCallback(
    (review: string) => {
      createReview({
        variables: {
          input: { review },
        },
      });
    },
    [createReview]
  );

  const alertSuccessfull = useCallback(
    () =>
      present({
        cssClass: styles.alert,
        header: t('Thanks for your feedback'),
        message: t('Feedback sent successfully'),
      }),
    [present, t]
  );

  // Show success dialog and dismiss component
  useEffect(() => {
    if (successFlag && !loading) {
      alertSuccessfull();
      onDismiss();
      setTimeout(() => dismiss(), 2500);
    }
  }, [alertSuccessfull, dismiss, loading, onDismiss, successFlag]);

  const onConfirm = useCallback(() => {
    toggleSuccess();
  }, [toggleSuccess]);

  return (
    <Modal title={t('Write a review')} closeModal={onDismiss}>
      <form onSubmit={handleSubmit((data) => handleReview(data.review))}>
        <IonList className={styles.list}>
          <IonItem className={styles.reviewInfoItem} lines="none">
            <Controller
              render={({ onChange, value }) => (
                <IonTextarea
                  className={styles.reviewInfoTextArea}
                  onIonChange={(e) => onChange(e.detail.value)}
                  value={value}
                  rows={5}
                  placeholder={t('Review')}
                  ref={refTextArea}
                />
              )}
              control={control}
              name="review"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          <IonRow>
            <IonButton className={styles.button} disabled={!formState.isValid} type="submit" onClick={onConfirm}>
              {t('Save')}
            </IonButton>
          </IonRow>
        </IonList>
      </form>
    </Modal>
  );
};
export { Review };
