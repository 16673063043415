import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonModal, IonSearchbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { arrowBackOutline, searchSharp } from 'ionicons/icons';
import styles from './ModalSearch.module.scss';
import { NoElementFound } from '../../empty-state/NoElementFound';

interface ISearchItem {
  id: string;
  name: string;
}

interface Props {
  searchList: ISearchItem[];
  title: string;
  onBlur: () => void;
  onChange: (value: string) => void;
  value?: string | undefined;
}

const ModalSearch = ({ title, searchList, onChange, value: valueProp, onBlur }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedItemName, setSelectedItemName] = useState<string>('');
  const refSearchBar = useRef<any>(null);
  const { t } = useTranslation();
  const noDuplicateSearchList: ISearchItem[] = useMemo(() => {
    const hashedSearchResults: any = {};

    searchList.forEach((element) => {
      if (!hashedSearchResults[element.name]) {
        hashedSearchResults[element.name] = element;
      }
    });

    return Object.values(hashedSearchResults);
  }, [searchList]);

  const filterItemsByEnteredText = useCallback(
    (item: ISearchItem): boolean => {
      return item.name.toLowerCase().includes(searchText.toLowerCase());
    },
    [searchText]
  );

  const toggleModal = useCallback(() => {
    setShowModal((prevState) => !prevState);
  }, [setShowModal]);

  const onSearchChange = useCallback(
    ({ detail }: any) => {
      setSearchText(detail.value);
    },
    [setSearchText]
  );

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        refSearchBar?.current?.setFocus();
      }, 800);
    }
  }, [showModal]);

  const filteredSearchList = noDuplicateSearchList.filter(filterItemsByEnteredText);

  return (
    <>
      <IonInput
        value={valueProp && !selectedItemName ? valueProp : selectedItemName}
        className={styles.inputString}
        placeholder={t(`${title}`)}
        type="text"
        // onIonBlur={onBlur}
        readonly
        onClick={toggleModal}
      />
      <IonModal
        onDidDismiss={() => {
          if (showModal) {
            toggleModal();
            onBlur(); // For field validation trigger (useForm mode: 'onBlur')
          }
        }}
        isOpen={showModal}
      >
        <IonContent>
          <div className={styles.searchBarContainer}>
            <IonSearchbar
              ref={refSearchBar}
              value={searchText}
              onIonChange={onSearchChange}
              className={styles.searchBar}
              placeholder={t('Searching')}
            >
              <IonIcon
                className={styles.searchBarIcon}
                icon={arrowBackOutline}
                onClick={() => {
                  toggleModal();
                  onBlur();
                }}
              />
            </IonSearchbar>
          </div>
          {searchList?.length === 0 ? (
            <NoElementFound title={t('Medicines list is empty')} />
          ) : (
            <IonList className={styles.medicineList}>
              {filteredSearchList.length > 0 ? (
                filteredSearchList.map((item) => {
                  return (
                    <IonItem
                      button
                      key={item.id}
                      onClick={() => {
                        setSelectedItemName(item.name);
                        onChange(item.id);
                        onBlur(); // For field validation trigger (useForm mode: 'onBlur')
                        setSearchText('');
                        toggleModal();
                      }}
                    >
                      <IonLabel>{item.name}</IonLabel>
                    </IonItem>
                  );
                })
              ) : (
                <IonItem className={styles.noResultFound} lines="none">
                  <IonLabel>{t('No results found')}</IonLabel>
                </IonItem>
              )}
            </IonList>
          )}
        </IonContent>
      </IonModal>
      <IonIcon className={styles.iconInput} icon={searchSharp} />
    </>
  );
};

ModalSearch.defaultProps = {
  searchList: [],
  title: '',
  value: '',
};

export { ModalSearch };
