import React from 'react';
import { IonButton } from '@ionic/react';
import { useHistory } from 'react-router';
import { ApolloError } from 'apollo-boost';
import { useTranslation } from 'react-i18next';
import styles from './ErrorNetwork.module.scss';

interface Props {
  error?: ApolloError;
}

const ErrorNetwork: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.noServerConnection} />
      <span className={styles.message}>
        {t('Sorry, but something went wrong on the server, please retry your request later.')}
      </span>
      <IonButton className={styles.button} onClick={() => goBack()}>
        {t('Go back')}
      </IonButton>
    </div>
  );
};

export { ErrorNetwork };
