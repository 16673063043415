import { IonButton, IonDatetime, IonIcon, IonItem, IonLabel, IonPopover } from '@ionic/react';

import React from 'react';

import classNames from 'classnames/bind';

import { useTranslation } from 'react-i18next';

import { Controller, useFieldArray } from 'react-hook-form';

import { addOutline, closeOutline } from 'ionicons/icons';

import styles from './PrescriptionMedicine.module.scss';

import { formatDisplayTime } from '../../utils/utils';

const cx = classNames.bind(styles);

interface Props {
  control: any;
  errors: any;
  watch: any;
}

const PrescriptionMedicineIntakeTime = ({ control, errors, watch }: Props) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'intakeTimes',
  });

  if (fields.length < 1) {
    append({});
  }

  const { intakeTimes: watchIntakeTime } = watch(['intakeTimes']);

  return (
    <>
      {fields.map((element, index) => (
        <IonItem
          lines="none"
          className={cx(styles.medicineInfoItem, {
            'border-color-danger': errors.intakeTimes,
          })}
          key={element.id}
        >
          <div className={styles.medicineInfoItemInput} id={`open-intake-time-input${element.id}`}>
            <IonLabel>
              {watchIntakeTime[index]?.intakeTime ? (
                <>
                  <span>{t('Intake time')}: </span>
                  <span style={{ color: '#000' }}>{`${formatDisplayTime(watchIntakeTime[index]?.intakeTime)}`}</span>
                </>
              ) : (
                `${t('Intake time')}:`
              )}
            </IonLabel>
            <Controller
              render={({ onChange, value, onBlur }) => (
                <IonPopover onDidDismiss={onBlur} trigger={`open-intake-time-input${element.id}`} showBackdrop={false}>
                  <IonDatetime
                    presentation="time"
                    className={styles.inputNumber}
                    cancelText={t('Cancel')}
                    doneText={t('Done')}
                    onIonChange={onChange}
                    value={value}
                  />
                </IonPopover>
              )}
              name={`intakeTimes[${index}].intakeTime`}
              control={control}
              rules={{
                required: true,
              }}
            />
          </div>
          <IonButton
            style={{ display: index < 1 && 'none' }}
            className={styles.medicineInfoItemInputDel}
            onClick={() => remove(index)}
          >
            <IonIcon icon={closeOutline} />
          </IonButton>
          <IonButton
            style={{ display: index > 0 && 'none' }}
            className={styles.medicineInfoItemInputDel}
            onClick={() => append({})}
          >
            <IonIcon icon={addOutline} />
          </IonButton>
        </IonItem>
      ))}
    </>
  );
};
export { PrescriptionMedicineIntakeTime };
