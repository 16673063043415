import { IonContent, IonPage } from '@ionic/react';
import React from 'react';

import { Dashboard } from '../../components/dashboard/Dashboard';
import { Title } from '../../components/title/Title';
import styles from './Home.module.scss';

const Home: React.FC = () => {
  return (
    <IonPage>
      <Title color="primary" translucent title="Home" menuButton />
      <IonContent className={styles.pinqBg}>
        <Dashboard />
      </IonContent>
    </IonPage>
  );
};

export { Home };
