import {
  IonButton,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import classNames from 'classnames/bind';

import { Controller, useForm } from 'react-hook-form';

import { calendarOutline } from 'ionicons/icons';

import {
  PrescriptionMedicineType,
  PrescriptionMedicineIntakeState,
  CreatePrescriptionMedicineInputType,
  UpdatePrescriptionMedicineInputType,
  MedicineTypeConnection,
} from '../../generated/graphql';
import { ModalSearch } from '../UI/ModalSearch/ModalSearch';

import { formatDisplayDate } from '../../utils/utils';

import styles from './PrescriptionMedicine.module.scss';
import { PrescriptionMedicineIntakeTime } from './PrescriptionMedicineIntakeTime';

const cx = classNames.bind(styles);

interface IPrescriptionMedicine {
  medicine?: PrescriptionMedicineType;
  medicines: MedicineTypeConnection;

  mutation(...args: any[]): Promise<void>;
}

const defaultValues: PrescriptionMedicineType | any = {
  id: null,
  duration: null,
  frequencyDay: null,
  medicineId: null,
  intakeQuantity: null,
  intakeState: null,
  intervalDay: null,
  prescriptionId: null,
  instructions: null,
  startDate: null,
  medicineintaketimeSet: null,
  intakeTimes: [],
};

class IntakeTime {
  intakeTime: string;

  constructor(time: string) {
    // eslint-disable-next-line react/no-this-in-sfc
    this.intakeTime = time;
  }
}

const PrescriptionMedicine: React.FC<IPrescriptionMedicine> = ({ medicine, mutation, medicines }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, watch, errors } = useForm<
    CreatePrescriptionMedicineInputType | UpdatePrescriptionMedicineInputType
  >({
    mode: 'onBlur',
    defaultValues: medicine
      ? {
          ...medicine,
          ...(medicine?.medicineintaketimeSet
            ? {
                intakeTimes: medicine.medicineintaketimeSet.edges.map((edge) => new IntakeTime(edge.node.intakeTime)),
              }
            : {}),
          medicineId: medicine?.medicine.id,
        }
      : defaultValues,
  });

  const { startDate: watchStartDate } = watch(['startDate']);
  const suggestions = useMemo(() => medicines?.edges.map((item) => item.node), [medicines]);

  return (
    <IonGrid className={cx(styles.contentWrapper, 'ion-no-padding')}>
      <IonRow className={styles.medicineInfo}>
        <IonList className={styles.list}>
          <IonListHeader className={cx(styles.listHeader, 'ion-no-padding')}>
            <IonLabel>{t('Medication plan')}</IonLabel>
          </IonListHeader>
          <IonItem lines="none" className={cx(styles.medicineInfoItem, { 'border-color-danger': errors.medicineId })}>
            <Controller
              render={({ onChange, onBlur }) => (
                <ModalSearch
                  onBlur={onBlur}
                  onChange={onChange}
                  searchList={suggestions}
                  value={medicine?.medicine?.name}
                  title="Medicine search"
                />
              )}
              control={control}
              name="medicineId"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          <IonItem
            className={cx(styles.medicineInfoInput, { 'border-color-danger': errors.startDate })}
            lines="none"
            id="open-start-date-input"
          >
            <IonLabel>
              {watchStartDate ? (
                formatDisplayDate(watchStartDate)
              ) : (
                <span style={{ color: '#bdbdbd' }}>{`${t('Start date')}:`}</span>
              )}
            </IonLabel>
            <Controller
              render={({ onChange, value, onBlur }) => (
                <IonPopover onDidDismiss={onBlur} trigger="open-start-date-input">
                  <IonDatetime
                    className={styles.inputNumber}
                    cancelText={t('Cancel')}
                    presentation="date"
                    doneText={t('Done')}
                    onIonChange={onChange}
                    value={value}
                  />
                </IonPopover>
              )}
              control={control}
              name="startDate"
              rules={{
                required: true,
              }}
            />
            <IonIcon className={styles.icon} icon={calendarOutline} slot="end" />
          </IonItem>
          <IonItem className={cx(styles.medicineInfoItem, { 'border-color-danger': errors.duration })} lines="none">
            <IonLabel>{`${t('Days amount')}:`}</IonLabel>
            <Controller
              render={({ onChange, value, onBlur }) => (
                <IonInput
                  onIonBlur={onBlur}
                  className={styles.inputNumber}
                  onIonChange={(e) => onChange(e.detail.value)}
                  type="number"
                  inputMode="numeric"
                  value={value}
                />
              )}
              control={control}
              name="duration"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          <IonItem
            className={cx(styles.medicineInfoItem, { 'border-color-danger': errors.intakeQuantity })}
            lines="none"
          >
            <IonLabel>{`${t('Intake quantity')}:`}</IonLabel>
            <Controller
              render={({ onChange, onBlur, value }) => (
                <IonInput
                  className={styles.inputNumber}
                  onIonBlur={onBlur}
                  onIonChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  type="number"
                  inputMode="numeric"
                  value={value}
                />
              )}
              control={control}
              name="intakeQuantity"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          <IonItem className={cx(styles.medicineInfoItem, { 'border-color-danger': errors.frequencyDay })} lines="none">
            <IonLabel>{`${t('Day frequency')}:`}</IonLabel>
            <Controller
              render={({ onChange, onBlur, value }) => (
                <IonInput
                  className={styles.inputNumber}
                  onIonChange={(e) => onChange(e.detail.value)}
                  onIonBlur={onBlur}
                  type="number"
                  inputMode="numeric"
                  value={value}
                />
              )}
              control={control}
              name="frequencyDay"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          <PrescriptionMedicineIntakeTime control={control} errors={errors} watch={watch} />
          <IonItem className={cx(styles.medicineInfoItem, { 'border-color-danger': errors.intervalDay })} lines="none">
            <IonLabel>{`${t('Days interval')}:`}</IonLabel>
            <Controller
              render={({ onChange, value, onBlur }) => (
                <IonInput
                  className={styles.inputNumber}
                  onIonBlur={onBlur}
                  onIonChange={(e) => onChange(e.detail.value)}
                  type="number"
                  inputMode="numeric"
                  value={value}
                />
              )}
              control={control}
              name="intervalDay"
              rules={{
                required: true,
              }}
            />
          </IonItem>
          <Controller
            render={({ onChange, value, onBlur }) => {
              return (
                <IonSelect
                  interface="action-sheet"
                  onIonCancel={onBlur}
                  onIonChange={(e) => {
                    if (e.detail.value != null) {
                      onChange(e.detail.value);
                    }
                    onBlur();
                  }}
                  className={cx(styles.medicineSelect, { 'border-color-danger': errors.intakeState })}
                  cancel-text={t('Cancel')}
                  placeholder={t('Intake state')}
                  value={value}
                >
                  <IonSelectOption value={PrescriptionMedicineIntakeState.A_0}>{t('Anytime')}</IonSelectOption>
                  <IonSelectOption value={PrescriptionMedicineIntakeState.A_1}>{t('Hungry')}</IonSelectOption>
                  <IonSelectOption value={PrescriptionMedicineIntakeState.A_2}>{t('Eating')}</IonSelectOption>
                  <IonSelectOption value={PrescriptionMedicineIntakeState.A_3}>{t('Full')}</IonSelectOption>
                  <IonSelectOption value={PrescriptionMedicineIntakeState.A_4}>{t('Before sleep')}</IonSelectOption>
                  <IonSelectOption value={PrescriptionMedicineIntakeState.A_5}>{t('Awoke')}</IonSelectOption>
                </IonSelect>
              );
            }}
            control={control}
            name="intakeState"
            rules={{
              required: true,
            }}
          />
        </IonList>
      </IonRow>

      <IonRow>
        <IonButton
          // When adding a new field, add +1 to numberOfFields
          // TODO: find better solution
          className={styles.button}
          expand="block"
          onClick={handleSubmit(mutation)}
        >
          {t('Save')}
        </IonButton>
      </IonRow>
    </IonGrid>
  );
};

PrescriptionMedicine.defaultProps = {
  medicine: undefined,
};

export { PrescriptionMedicine };
