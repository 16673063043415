import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonLoading,
  IonPage,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// context

// i18n
import { useTranslation } from 'react-i18next';

// styles
import classNames from 'classnames/bind';
import { useAuth } from '../../contexts/auth-context';
import styles from './Profile.module.scss';

// components
import { Profile } from '../../components/profile/Profile';
import { Title } from '../../components/title/Title';
import { Image } from '../../components/UI/Image/Image';

// assets
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { useMeQuery } from '../../generated/graphql';

const cx = classNames.bind(styles);

const ProfilePage: React.FC = () => {
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const { t } = useTranslation();
  const authContext = useAuth();
  const { push } = useHistory();
  const { data, loading, error, refetch } = useMeQuery({
    fetchPolicy: 'no-cache',
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  if (loading) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title translucent backButton title="Profile" color="primary" />
      <IonContent className={cx(styles.purpleBackground)}>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          <>
            <>
              <IonToolbar className={cx(styles.toolbar, 'ion-no-padding')}>
                <IonButtons slot="start">
                  <IonButton
                    className={cx(styles.menuButton, 'ion-no-padding ion-no-margin')}
                    onClick={() => push('/profile-settings')}
                  >
                    {t('Change')}
                  </IonButton>
                </IonButtons>
                <IonButtons slot="end">
                  <IonButton className={styles.menuButton} onClick={() => setShowLogoutAlert(true)}>
                    {t('Logout')}
                  </IonButton>
                </IonButtons>
              </IonToolbar>
              <div className={styles.profilePhotoContainer}>
                <Image url={data?.me?.avatar?.url} className={styles.profilePhoto} />
              </div>
            </>
            <IonAlert
              isOpen={showLogoutAlert}
              onDidDismiss={() => setShowLogoutAlert(false)}
              header={t('Logout')}
              message={t('Confirm logout')}
              buttons={[
                {
                  text: t('Cancel'),
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: t('Logout'),
                  handler: () => {
                    authContext.logout();
                  },
                },
              ]}
            />
            {data && <Profile data={data} />}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export { ProfilePage };
