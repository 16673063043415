import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PatientFileInputType, usePatientFileCreateMutation } from '../../generated/graphql';
import { Title } from '../../components/title/Title';
import { PatientFileDetail } from '../../components/patient-file/PatientFileDetail';

const NewPatientFilePage = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const [patientFileCreateMutation, { loading }] = usePatientFileCreateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  function onPatientFileSubmit(payload: PatientFileInputType) {
    patientFileCreateMutation({
      variables: {
        input: payload,
      },
    });
  }

  return (
    <IonPage>
      <Title translucent title={t('Add patient file')} backButton />
      <IonContent>
        {loading && <IonLoading isOpen message={t('Loading...')} />}
        <PatientFileDetail formSubmitHandler={(value) => onPatientFileSubmit(value)} submitButtonText={t('Create')} />
      </IonContent>
    </IonPage>
  );
};

export { NewPatientFilePage };
