import React, { useState } from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ReviewClinicType, useReviewsClinicsQuery } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { ReviewClinicsList } from '../../components/review-clinics/ReviewClinicsList';
import { ReviewClinicsHeader } from '../../components/review-clinics/review-header/ReviewClinicsHeader';
import { ReviewMaps } from '../../components/review-maps/ReviewMaps';
import { useSearchParams } from '../../hooks/useSearchParams';

const ReviewClinicsPage = () => {
  const searchParams = useSearchParams();
  const [showReviewMap, setShowReviewMap] = useState(false);
  const { data, loading, error, refetch } = useReviewsClinicsQuery({
    variables: {
      sort: searchParams.get('sort') || '',
      search: searchParams.get('search') || '',
      filter: JSON.parse(searchParams.get('filter') || '{"id": []}'),
    },
    fetchPolicy: 'no-cache',
  });
  // I convey the value from params

  const clinics = data?.reviewsClinics.edges.map((edge) => edge.node as ReviewClinicType) || [];

  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title color="primary" translucent title={t('Clinics')} menuButton searchButton />
      <IonContent style={{ display: showReviewMap ? 'none' : 'block' }}>
        {loading && <IonLoading isOpen keyboardClose={false} message={t('Loading...')} />}
        <ReviewClinicsHeader clinicsLength={clinics?.length} setShowReviewMap={setShowReviewMap} />
        <ReviewClinicsList clinics={clinics} />
      </IonContent>
      {showReviewMap && <ReviewMaps clinics={clinics} setShowReviewMap={setShowReviewMap} />}
    </IonPage>
  );
};

export { ReviewClinicsPage };
