/* eslint-disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `BigInt` scalar type represents non-fractional whole numeric values.
   * `BigInt` is not constrained to 32-bit like the `Int` type and thus is a less
   * compatible type.
   */
  BigInt: any;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   *
   * `Geometry` scalar may be represented in a few ways:
   * - Well-known text (WKT)
   * - Hexadecimal (HEX)
   * - GeoJSON
   *
   */
  Geometry: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  geography: any;
  geometry: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
};


export type AddressSuggestionResultType = {
  __typename?: 'AddressSuggestionResultType';
  fullAddress: Maybe<Scalars['String']>;
  location: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type AddressType = {
  __typename?: 'AddressType';
  block: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  district: Maybe<Scalars['String']>;
  flat: Maybe<Scalars['String']>;
  fullAddress: Maybe<Scalars['String']>;
  house: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  location: Maybe<GeometryObjectType>;
  street: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq: Maybe<Scalars['Boolean']>;
  _gt: Maybe<Scalars['Boolean']>;
  _gte: Maybe<Scalars['Boolean']>;
  _in: Maybe<Array<Scalars['Boolean']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['Boolean']>;
  _lte: Maybe<Scalars['Boolean']>;
  _neq: Maybe<Scalars['Boolean']>;
  _nin: Maybe<Array<Scalars['Boolean']>>;
};

export type ClinicFilterInput = {
  avgStars: Maybe<Scalars['Float']>;
  id: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type ClinicReviewsGroupByCharacteristicType = {
  __typename?: 'ClinicReviewsGroupByCharacteristicType';
  averageStars: Maybe<Scalars['Float']>;
  characteristic: Maybe<Scalars['String']>;
};

export type CreateMedicine = {
  __typename?: 'CreateMedicine';
  medicine: Maybe<MedicineType>;
};

export type CreateMedicineInputType = {
  brand: Scalars['String'];
  cas: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  dosage: Scalars['Float'];
  medicineType: MedicineMedicineType;
  name: Scalars['String'];
  pharmacodynamics: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type CreateMedicineIntake = {
  __typename?: 'CreateMedicineIntake';
  medicineIntake: Maybe<MedicineIntakeType>;
  patientMedicine: Maybe<PatientMedicineType>;
};

export type CreateMedicineIntakeInputType = {
  intakeDate: Scalars['Date'];
  medicineId: Scalars['ID'];
  prescriptionMedicineId: Scalars['ID'];
};

export type CreateMedicineIntakeTimeInputType = {
  intakeTime: Scalars['String'];
};

export type CreatePatientMedicine = {
  __typename?: 'CreatePatientMedicine';
  patientMedicine: Maybe<PatientMedicineType>;
};

export type CreatePatientMedicineInputType = {
  medicineId: Scalars['ID'];
  purchaseDate: Scalars['Date'];
  quantity: Scalars['Int'];
};

export type CreatePrescription = {
  __typename?: 'CreatePrescription';
  prescription: Maybe<PrescriptionType>;
};

export type CreatePrescriptionInputType = {
  description: Maybe<Scalars['String']>;
};

export type CreatePrescriptionMedicine = {
  __typename?: 'CreatePrescriptionMedicine';
  medicineIntakeTimes: Maybe<Array<Maybe<MedicineIntakeTimeType>>>;
  prescriptionMedicine: Maybe<PrescriptionMedicineType>;
};

export type CreatePrescriptionMedicineInputType = {
  duration: Scalars['Int'];
  frequencyDay: Scalars['Int'];
  instructions: Maybe<Scalars['String']>;
  intakeQuantity: Scalars['Int'];
  intakeState: PrescriptionMedicineIntakeState;
  intakeTimes: Array<Maybe<CreateMedicineIntakeTimeInputType>>;
  intervalDay: Scalars['Int'];
  medicineId: Scalars['ID'];
  prescriptionId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type CreateReview = {
  __typename?: 'CreateReview';
  review: Maybe<ReviewAppType>;
};

export type CreateReviewInputType = {
  review: Maybe<Scalars['String']>;
};

/**
 * Mutation that receives 'phone' and 'security_code'
 * and returns a jwt token
 */
export type CreateToken = {
  __typename?: 'CreateToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  registered: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
  user: Maybe<UserType>;
};




export type DeviceCreate = {
  __typename?: 'DeviceCreate';
  device: Maybe<DeviceType>;
};

/** An enumeration. */
export enum DeviceDeviceType {
  /** bracelet */
  A_1 = 'A_1',
  /** phone */
  A_2 = 'A_2',
  /** other */
  A_3 = 'A_3'
}

export type DeviceInputType = {
  deviceType: DeviceDeviceType;
  mac: Scalars['String'];
};

export type DeviceRecordInputType = {
  date: Scalars['DateTime'];
  device: Scalars['ID'];
  value: Scalars['JSONString'];
};

export type DeviceType = {
  __typename?: 'DeviceType';
  deviceType: DeviceDeviceType;
  id: Scalars['ID'];
  mac: Scalars['String'];
  patient: PatientType;
  recordSet: RecordTypeConnection;
  settings: Maybe<SettingsType>;
};


export type DeviceTypeRecordSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type DeviceTypeConnection = {
  __typename?: 'DeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DeviceType` and its cursor. */
export type DeviceTypeEdge = {
  __typename?: 'DeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DeviceType>;
};

export type DoctorClinicType = {
  __typename?: 'DoctorClinicType';
  clinic: ReviewClinicType;
  createdAt: Scalars['DateTime'];
  doctor: ReviewDoctorType;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type DoctorClinicTypeConnection = {
  __typename?: 'DoctorClinicTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DoctorClinicTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DoctorClinicType` and its cursor. */
export type DoctorClinicTypeEdge = {
  __typename?: 'DoctorClinicTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DoctorClinicType>;
};

export type DoctorCreatePrescription = {
  __typename?: 'DoctorCreatePrescription';
  prescription: Maybe<PrescriptionType>;
};

export type DoctorCreatePrescriptionInputType = {
  description: Maybe<Scalars['String']>;
  medicines: Maybe<Array<Maybe<DoctorCreatePrescriptionMedicineInputType>>>;
  patientId: Maybe<Scalars['ID']>;
};

export type DoctorCreatePrescriptionMedicineInputType = {
  duration: Scalars['Int'];
  frequencyDay: Scalars['Int'];
  id: Maybe<Scalars['ID']>;
  instructions: Maybe<Scalars['String']>;
  intakeQuantity: Scalars['Int'];
  intakeState: PrescriptionMedicineIntakeState;
  intakeTimes: Array<Maybe<CreateMedicineIntakeTimeInputType>>;
  intervalDay: Scalars['Int'];
  medicineId: Scalars['ID'];
  prescriptionId: Maybe<Scalars['ID']>;
  startDate: Scalars['String'];
};

export type DoctorInfoUpdate = {
  __typename?: 'DoctorInfoUpdate';
  doctor: Maybe<DoctorType>;
};

export type DoctorInfoUpdateInputType = {
  details: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  secondName: Maybe<Scalars['String']>;
  specialization: Maybe<Scalars['String']>;
};

export type DoctorPatientFileCreate = {
  __typename?: 'DoctorPatientFileCreate';
  doctorPatientFile: Maybe<PatientFileType>;
};

export type DoctorPatientFileDelete = {
  __typename?: 'DoctorPatientFileDelete';
  success: Maybe<Scalars['Boolean']>;
};

export type DoctorPatientFileInputType = {
  file: Scalars['String'];
  patientId: Scalars['String'];
  title: Scalars['String'];
};

export type DoctorPatientFileRemoveInputType = {
  id: Scalars['ID'];
};

export type DoctorPatientFileUpdate = {
  __typename?: 'DoctorPatientFileUpdate';
  doctorPatientFile: Maybe<PatientFileType>;
};

export type DoctorPatientFileUpdateInputType = {
  id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
};

export type DoctorPatientType = {
  __typename?: 'DoctorPatientType';
  createdAt: Scalars['DateTime'];
  doctor: DoctorType;
  id: Scalars['ID'];
  patient: PatientType;
  updatedAt: Scalars['DateTime'];
};

export type DoctorPatientTypeConnection = {
  __typename?: 'DoctorPatientTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DoctorPatientTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DoctorPatientType` and its cursor. */
export type DoctorPatientTypeEdge = {
  __typename?: 'DoctorPatientTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DoctorPatientType>;
};

export type DoctorPushNotificationsType = {
  __typename?: 'DoctorPushNotificationsType';
  author: UserType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  pushNotification: PushNotificationsType;
  updatedAt: Scalars['DateTime'];
};

export type DoctorPushNotificationsTypeConnection = {
  __typename?: 'DoctorPushNotificationsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DoctorPushNotificationsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `DoctorPushNotificationsType` and its cursor. */
export type DoctorPushNotificationsTypeEdge = {
  __typename?: 'DoctorPushNotificationsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DoctorPushNotificationsType>;
};

/** Allows registration or authorization for Doctors */
export type DoctorRegister = {
  __typename?: 'DoctorRegister';
  user: Maybe<UserType>;
};

export type DoctorRegisterInputType = {
  email: Scalars['String'];
  phone: Scalars['String'];
  singIn: Maybe<Scalars['Boolean']>;
};

export type DoctorRemovePrescriptionMedicine = {
  __typename?: 'DoctorRemovePrescriptionMedicine';
  success: Maybe<Scalars['Boolean']>;
};

export type DoctorRemovePrescriptionMedicineInputType = {
  id: Scalars['ID'];
};

export type DoctorType = {
  __typename?: 'DoctorType';
  createdAt: Scalars['DateTime'];
  details: Maybe<Scalars['String']>;
  doctorpatientSet: DoctorPatientTypeConnection;
  id: Scalars['ID'];
  specialization: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
};


export type DoctorTypeDoctorpatientSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type DoctorTypeConnection = {
  __typename?: 'DoctorTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DoctorTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DoctorType` and its cursor. */
export type DoctorTypeEdge = {
  __typename?: 'DoctorTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<DoctorType>;
};

export type DoctorUpdatePrescription = {
  __typename?: 'DoctorUpdatePrescription';
  prescription: Maybe<PrescriptionType>;
};

export type DoctorUpdatePrescriptionInputType = {
  description: Maybe<Scalars['String']>;
  prescriptionId: Maybe<Scalars['ID']>;
};

export type DoctorUpdatePrescriptionMedicine = {
  __typename?: 'DoctorUpdatePrescriptionMedicine';
  medicineIntakeTimes: Maybe<Array<Maybe<MedicineIntakeTimeType>>>;
  prescriptionMedicine: Maybe<PrescriptionMedicineType>;
};

export type DoctorUpdatePrescriptionMedicineInputType = {
  duration: Scalars['Int'];
  frequencyDay: Scalars['Int'];
  id: Scalars['ID'];
  instructions: Maybe<Scalars['String']>;
  intakeQuantity: Scalars['Int'];
  intakeState: PrescriptionMedicineIntakeState;
  intakeTimes: Array<Maybe<UpdateMedicineIntakeTimeInputType>>;
  intervalDay: Scalars['Int'];
  medicineId: Scalars['ID'];
  prescriptionId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type DoctorUserDataInputType = {
  details: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  secondName: Scalars['String'];
  specialization: Maybe<Scalars['String']>;
  thirdName: Scalars['String'];
};

/** An enumeration. */
export enum EmergencyContactContactType {
  /** relative */
  A_1 = 'A_1',
  /** colleague */
  A_2 = 'A_2',
  /** friend */
  A_3 = 'A_3'
}

export type EmergencyContactType = {
  __typename?: 'EmergencyContactType';
  contact: Maybe<Scalars['String']>;
  contactType: EmergencyContactContactType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  notify: Scalars['Boolean'];
  patient: PatientType;
  updatedAt: Scalars['DateTime'];
};

export type EmergencyContactTypeConnection = {
  __typename?: 'EmergencyContactTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<EmergencyContactTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `EmergencyContactType` and its cursor. */
export type EmergencyContactTypeEdge = {
  __typename?: 'EmergencyContactTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<EmergencyContactType>;
};

export type FileType = {
  __typename?: 'FileType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  patientfileSet: PatientFileTypeConnection;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};


export type FileTypePatientfileSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum GcmDeviceCloudMessageType {
  /** Firebase Cloud Message */
  Fcm = 'FCM',
  /** Google Cloud Message */
  Gcm = 'GCM'
}

export type GcmDeviceCreate = {
  __typename?: 'GCMDeviceCreate';
  gcmDevice: Maybe<GcmDeviceType>;
};

export type GcmDeviceInputType = {
  applicationId: Maybe<Scalars['String']>;
  cloudMessageType: GcmDeviceCloudMessageType;
  deviceId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  registrationId: Scalars['String'];
  user: Scalars['ID'];
};

export type GcmDeviceType = {
  __typename?: 'GCMDeviceType';
  /** Inactive devices will not be sent notifications */
  active: Scalars['Boolean'];
  /** Opaque application identity, should be filled in for multiple key/certificate access */
  applicationId: Maybe<Scalars['String']>;
  /** You should choose FCM or GCM */
  cloudMessageType: GcmDeviceCloudMessageType;
  dateCreated: Maybe<Scalars['DateTime']>;
  /** ANDROID_ID / TelephonyManager.getDeviceId() (always as hex) */
  deviceId: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  registrationId: Scalars['String'];
  user: Maybe<UserType>;
};

export type GcmDeviceTypeConnection = {
  __typename?: 'GCMDeviceTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GcmDeviceTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `GCMDeviceType` and its cursor. */
export type GcmDeviceTypeEdge = {
  __typename?: 'GCMDeviceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<GcmDeviceType>;
};



/**
 * `GeometryObjectType` represents a pair of values:
 * - Geometry `type`
 * - Geometry `coordinates`
 */
export type GeometryObjectType = {
  __typename?: 'GeometryObjectType';
  coordinates: Maybe<Scalars['GenericScalar']>;
  type: Maybe<Scalars['String']>;
};

export type GraphMedicineIntakeType = {
  __typename?: 'GraphMedicineIntakeType';
  medicines: Maybe<Array<Maybe<GraphPrescriptionMedicineWithMedicineIntakeType>>>;
  patientMedicines: Maybe<Array<Maybe<PatientMedicineType>>>;
};

export type GraphPrescriptionMedicineWithMedicineIntakeType = {
  __typename?: 'GraphPrescriptionMedicineWithMedicineIntakeType';
  medicineIntakes: Maybe<Array<Maybe<MedicineIntakeType>>>;
  prescriptionMedicine: Maybe<PrescriptionMedicineType>;
};

export type GraphRecordEntryType = {
  __typename?: 'GraphRecordEntryType';
  magnitude: Maybe<Scalars['Float']>;
  timestamp: Maybe<Scalars['BigInt']>;
  value: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type GraphRecordType = {
  __typename?: 'GraphRecordType';
  entries: Maybe<Array<Maybe<GraphRecordEntryType>>>;
  key: Maybe<Scalars['String']>;
};

export type ImageType = {
  __typename?: 'ImageType';
  clinicSet: ReviewClinicTypeConnection;
  createdAt: Scalars['DateTime'];
  doctorSet: ReviewDoctorTypeConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Maybe<Scalars['String']>;
  userSet: UserTypeConnection;
};


export type ImageTypeClinicSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type ImageTypeDoctorSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type ImageTypeUserSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq: Maybe<Scalars['Int']>;
  _gt: Maybe<Scalars['Int']>;
  _gte: Maybe<Scalars['Int']>;
  _in: Maybe<Array<Scalars['Int']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['Int']>;
  _lte: Maybe<Scalars['Int']>;
  _neq: Maybe<Scalars['Int']>;
  _nin: Maybe<Array<Scalars['Int']>>;
};


export type LinkDevice = {
  __typename?: 'LinkDevice';
  device: Maybe<DeviceType>;
};

export type MedicineIntakeTimeType = {
  __typename?: 'MedicineIntakeTimeType';
  id: Scalars['ID'];
  intakeTime: Scalars['String'];
  prescriptionMedicine: PrescriptionMedicineType;
};

export type MedicineIntakeTimeTypeConnection = {
  __typename?: 'MedicineIntakeTimeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MedicineIntakeTimeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MedicineIntakeTimeType` and its cursor. */
export type MedicineIntakeTimeTypeEdge = {
  __typename?: 'MedicineIntakeTimeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MedicineIntakeTimeType>;
};

export type MedicineIntakeType = {
  __typename?: 'MedicineIntakeType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  intakeDate: Scalars['Date'];
  prescriptionMedicine: PrescriptionMedicineType;
  updatedAt: Scalars['DateTime'];
};

export type MedicineIntakeTypeConnection = {
  __typename?: 'MedicineIntakeTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MedicineIntakeTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MedicineIntakeType` and its cursor. */
export type MedicineIntakeTypeEdge = {
  __typename?: 'MedicineIntakeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MedicineIntakeType>;
};

/** An enumeration. */
export enum MedicineMedicineType {
  /** mg */
  A_1 = 'A_1',
  /** tablets */
  A_2 = 'A_2',
  /** ml */
  A_3 = 'A_3',
  /** capsules */
  A_4 = 'A_4'
}

export type MedicineType = {
  __typename?: 'MedicineType';
  brand: Scalars['String'];
  cas: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  dosage: Maybe<Scalars['Decimal']>;
  id: Scalars['ID'];
  medicineType: MedicineMedicineType;
  name: Scalars['String'];
  patientmedicineSet: PatientMedicineTypeConnection;
  pharmacodynamics: Maybe<Scalars['String']>;
  prescriptionmedicineSet: PrescriptionMedicineTypeConnection;
  quantity: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};


export type MedicineTypePatientmedicineSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type MedicineTypePrescriptionmedicineSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type MedicineTypeConnection = {
  __typename?: 'MedicineTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MedicineTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MedicineType` and its cursor. */
export type MedicineTypeEdge = {
  __typename?: 'MedicineTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MedicineType>;
};

export type MessageType = {
  __typename?: 'MessageType';
  author: UserType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MessageTypeConnection = {
  __typename?: 'MessageTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MessageTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `MessageType` and its cursor. */
export type MessageTypeEdge = {
  __typename?: 'MessageTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<MessageType>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum PatientEpilepsyType {
  /** arbitrary */
  A_1 = 'A_1',
  /** post_traumatic */
  A_2 = 'A_2',
  /** stroke */
  A_3 = 'A_3',
  /** cerebral_paralysis */
  A_4 = 'A_4'
}

export type PatientFileCreate = {
  __typename?: 'PatientFileCreate';
  patientFile: Maybe<PatientFileType>;
};

export type PatientFileDelete = {
  __typename?: 'PatientFileDelete';
  success: Maybe<Scalars['Boolean']>;
};

export type PatientFileInputType = {
  file: Scalars['String'];
  title: Scalars['String'];
};

export type PatientFileRemoveInputType = {
  id: Scalars['ID'];
};

export type PatientFileType = {
  __typename?: 'PatientFileType';
  createdAt: Scalars['DateTime'];
  file: Maybe<FileType>;
  id: Scalars['ID'];
  patient: PatientType;
  updatedAt: Scalars['DateTime'];
};

export type PatientFileTypeConnection = {
  __typename?: 'PatientFileTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PatientFileTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PatientFileType` and its cursor. */
export type PatientFileTypeEdge = {
  __typename?: 'PatientFileTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PatientFileType>;
};

export type PatientFileUpdate = {
  __typename?: 'PatientFileUpdate';
  patientFile: Maybe<PatientFileType>;
};

export type PatientFileUpdateInputType = {
  file: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
};

export type PatientLocationInputType = {
  location: Scalars['Geometry'];
};

export type PatientLocationType = {
  __typename?: 'PatientLocationType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  location: Maybe<GeometryObjectType>;
  patient: PatientType;
  updatedAt: Scalars['DateTime'];
};

export type PatientLocationTypeConnection = {
  __typename?: 'PatientLocationTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PatientLocationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PatientLocationType` and its cursor. */
export type PatientLocationTypeEdge = {
  __typename?: 'PatientLocationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PatientLocationType>;
};

export type PatientMedicineType = {
  __typename?: 'PatientMedicineType';
  id: Scalars['ID'];
  medicine: MedicineType;
  patient: PatientType;
  purchaseDate: Scalars['Date'];
  quantity: Scalars['Int'];
};

export type PatientMedicineTypeConnection = {
  __typename?: 'PatientMedicineTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PatientMedicineTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PatientMedicineType` and its cursor. */
export type PatientMedicineTypeEdge = {
  __typename?: 'PatientMedicineTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PatientMedicineType>;
};

export type PatientType = {
  __typename?: 'PatientType';
  createdAt: Scalars['DateTime'];
  deviceSet: DeviceTypeConnection;
  diagnosis: Maybe<Scalars['String']>;
  doctorpatientSet: DoctorPatientTypeConnection;
  emergencycontactSet: EmergencyContactTypeConnection;
  epilepsyType: PatientEpilepsyType;
  id: Scalars['ID'];
  menstrualCorrelation: Scalars['Boolean'];
  patientfileSet: PatientFileTypeConnection;
  patientlocationSet: PatientLocationTypeConnection;
  patientmedicineSet: PatientMedicineTypeConnection;
  prescriptionSet: PrescriptionTypeConnection;
  seizureSet: SeizureTypeConnection;
  soscontactSet: SosContactTypeConnection;
  updatedAt: Scalars['DateTime'];
  user: UserType;
  womenperiodSet: WomenPeriodTypeConnection;
};


export type PatientTypeDeviceSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypeDoctorpatientSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypeEmergencycontactSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypePatientfileSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypePatientlocationSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypePatientmedicineSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypePrescriptionSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypeSeizureSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypeSoscontactSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PatientTypeWomenperiodSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type PatientTypeConnection = {
  __typename?: 'PatientTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PatientTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PatientType` and its cursor. */
export type PatientTypeEdge = {
  __typename?: 'PatientTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PatientType>;
};

/** An enumeration. */
export enum PrescriptionMedicineIntakeState {
  /** anytime */
  A_0 = 'A_0',
  /** hungry */
  A_1 = 'A_1',
  /** eating */
  A_2 = 'A_2',
  /** full */
  A_3 = 'A_3',
  /** before_sleep */
  A_4 = 'A_4',
  /** awoke */
  A_5 = 'A_5'
}

export type PrescriptionMedicineType = {
  __typename?: 'PrescriptionMedicineType';
  createdAt: Scalars['DateTime'];
  duration: Scalars['Int'];
  frequencyDay: Scalars['Int'];
  id: Scalars['ID'];
  instructions: Maybe<Scalars['String']>;
  intakeQuantity: Scalars['Int'];
  intakeState: PrescriptionMedicineIntakeState;
  intervalDay: Scalars['Int'];
  medicine: MedicineType;
  medicineintakeSet: MedicineIntakeTypeConnection;
  medicineintaketimeSet: MedicineIntakeTimeTypeConnection;
  prescription: PrescriptionType;
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type PrescriptionMedicineTypeMedicineintakeSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type PrescriptionMedicineTypeMedicineintaketimeSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type PrescriptionMedicineTypeConnection = {
  __typename?: 'PrescriptionMedicineTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrescriptionMedicineTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PrescriptionMedicineType` and its cursor. */
export type PrescriptionMedicineTypeEdge = {
  __typename?: 'PrescriptionMedicineTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PrescriptionMedicineType>;
};

export type PrescriptionType = {
  __typename?: 'PrescriptionType';
  author: UserType;
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  patient: PatientType;
  prescriptionmedicineSet: PrescriptionMedicineTypeConnection;
  updatedAt: Scalars['DateTime'];
};


export type PrescriptionTypePrescriptionmedicineSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type PrescriptionTypeConnection = {
  __typename?: 'PrescriptionTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PrescriptionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `PrescriptionType` and its cursor. */
export type PrescriptionTypeEdge = {
  __typename?: 'PrescriptionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PrescriptionType>;
};

export type PushDeviceRecordMutation = {
  __typename?: 'PushDeviceRecordMutation';
  record: Maybe<RecordType>;
};

/** An enumeration. */
export enum PushNotificationsNotifyType {
  /** anytype */
  A_0 = 'A_0',
  /** medication_reminder_after_leaving_home */
  A_1 = 'A_1',
  /** notify_for_doctor */
  A_2 = 'A_2'
}

export type PushNotificationsType = {
  __typename?: 'PushNotificationsType';
  createdAt: Scalars['DateTime'];
  doctorpushnotificationSet: DoctorPushNotificationsTypeConnection;
  id: Scalars['ID'];
  notifyType: PushNotificationsNotifyType;
  recipient: UserType;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type PushNotificationsTypeDoctorpushnotificationSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type PushNotificationsTypeConnection = {
  __typename?: 'PushNotificationsTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PushNotificationsTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PushNotificationsType` and its cursor. */
export type PushNotificationsTypeEdge = {
  __typename?: 'PushNotificationsTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<PushNotificationsType>;
};

export type RecordType = {
  __typename?: 'RecordType';
  date: Scalars['DateTime'];
  device: DeviceType;
  id: Scalars['ID'];
  value: Maybe<Scalars['String']>;
};

export type RecordTypeConnection = {
  __typename?: 'RecordTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RecordTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `RecordType` and its cursor. */
export type RecordTypeEdge = {
  __typename?: 'RecordTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<RecordType>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RemoveDoctorPushNotification = {
  __typename?: 'RemoveDoctorPushNotification';
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveDoctorPushNotificationType = {
  doctorNotifyId: Scalars['ID'];
};

export type RemoveMedicine = {
  __typename?: 'RemoveMedicine';
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveMedicineInputType = {
  id: Scalars['ID'];
};

export type RemoveMedicineIntake = {
  __typename?: 'RemoveMedicineIntake';
  patientMedicine: Maybe<PatientMedicineType>;
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveMedicineIntakeInputType = {
  id: Scalars['ID'];
  intakeDate: Scalars['Date'];
  medicineId: Scalars['ID'];
  prescriptionMedicineId: Scalars['ID'];
};

export type RemovePatientMedicine = {
  __typename?: 'RemovePatientMedicine';
  success: Maybe<Scalars['Boolean']>;
};

export type RemovePatientMedicineInputType = {
  id: Scalars['ID'];
};

export type RemovePrescription = {
  __typename?: 'RemovePrescription';
  success: Maybe<Scalars['Boolean']>;
};

export type RemovePrescriptionInputType = {
  id: Scalars['ID'];
};

export type RemovePrescriptionMedicine = {
  __typename?: 'RemovePrescriptionMedicine';
  success: Maybe<Scalars['Boolean']>;
};

export type RemovePrescriptionMedicineInputType = {
  id: Scalars['ID'];
};

export type RemoveUser = {
  __typename?: 'RemoveUser';
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveUserAvatar = {
  __typename?: 'RemoveUserAvatar';
  success: Maybe<Scalars['Boolean']>;
};

export type RemoveUserAvatarType = {
  avatarId: Scalars['ID'];
};

export type RemoveUserType = {
  userId: Scalars['ID'];
};

export type ReviewAppType = {
  __typename?: 'ReviewAppType';
  author: UserType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  review: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ReviewCharacteristicType = {
  __typename?: 'ReviewCharacteristicType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  reviewentrySet: ReviewEntryTypeConnection;
  updatedAt: Scalars['DateTime'];
};


export type ReviewCharacteristicTypeReviewentrySetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type ReviewClinicType = {
  __typename?: 'ReviewClinicType';
  address: Scalars['String'];
  avatar: Maybe<ImageType>;
  averageStars: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  discription: Scalars['String'];
  doctorclinicSet: DoctorClinicTypeConnection;
  id: Scalars['ID'];
  location: Maybe<GeometryObjectType>;
  name: Scalars['String'];
  phone: Scalars['String'];
  reviews: Maybe<Array<Maybe<ReviewType>>>;
  updatedAt: Scalars['DateTime'];
  workTime: Scalars['String'];
};


export type ReviewClinicTypeDoctorclinicSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type ReviewClinicTypeConnection = {
  __typename?: 'ReviewClinicTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewClinicTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReviewClinicType` and its cursor. */
export type ReviewClinicTypeEdge = {
  __typename?: 'ReviewClinicTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReviewClinicType>;
};

export type ReviewDoctorType = {
  __typename?: 'ReviewDoctorType';
  address: Scalars['String'];
  avatar: Maybe<ImageType>;
  averageStars: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  doctorclinicSet: DoctorClinicTypeConnection;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  location: Maybe<GeometryObjectType>;
  phone: Scalars['String'];
  reviews: Maybe<Array<Maybe<ReviewType>>>;
  secondName: Scalars['String'];
  specialization: Scalars['String'];
  thirdName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  workTime: Scalars['String'];
};


export type ReviewDoctorTypeDoctorclinicSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type ReviewDoctorTypeConnection = {
  __typename?: 'ReviewDoctorTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewDoctorTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReviewDoctorType` and its cursor. */
export type ReviewDoctorTypeEdge = {
  __typename?: 'ReviewDoctorTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReviewDoctorType>;
};

export type ReviewEntryType = {
  __typename?: 'ReviewEntryType';
  characteristic: ReviewCharacteristicType;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  review: ReviewType;
  stars: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ReviewEntryTypeConnection = {
  __typename?: 'ReviewEntryTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewEntryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReviewEntryType` and its cursor. */
export type ReviewEntryTypeEdge = {
  __typename?: 'ReviewEntryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReviewEntryType>;
};

export type ReviewType = {
  __typename?: 'ReviewType';
  author: UserType;
  averageEntryStars: Maybe<Scalars['Float']>;
  avgEntryStars: Maybe<Scalars['Float']>;
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  objectId: Scalars['Int'];
  reviewentrySet: ReviewEntryTypeConnection;
  stars: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};


export type ReviewTypeReviewentrySetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type ReviewTypeConnection = {
  __typename?: 'ReviewTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ReviewTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ReviewType` and its cursor. */
export type ReviewTypeEdge = {
  __typename?: 'ReviewTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<ReviewType>;
};

export type SosContactCreate = {
  __typename?: 'SOSContactCreate';
  soscontact: Maybe<SosContactType>;
};

export type SosContactDelete = {
  __typename?: 'SOSContactDelete';
  success: Maybe<Scalars['Boolean']>;
};

export type SosContactInputType = {
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  phone: Scalars['String'];
  secondName: Maybe<Scalars['String']>;
};

export type SosContactRemoveInputType = {
  id: Scalars['ID'];
};

export type SosContactType = {
  __typename?: 'SOSContactType';
  createdAt: Scalars['DateTime'];
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  patient: PatientType;
  phone: Scalars['String'];
  secondName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SosContactTypeConnection = {
  __typename?: 'SOSContactTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SosContactTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SOSContactType` and its cursor. */
export type SosContactTypeEdge = {
  __typename?: 'SOSContactTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SosContactType>;
};

export type SosContactUpdate = {
  __typename?: 'SOSContactUpdate';
  soscontact: Maybe<SosContactType>;
};

export type SosContactUpdateInputType = {
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  phone: Scalars['String'];
  secondName: Maybe<Scalars['String']>;
};

export type SeizureCreate = {
  __typename?: 'SeizureCreate';
  seizure: Maybe<SeizureType>;
};

export type SeizureDelete = {
  __typename?: 'SeizureDelete';
  success: Maybe<Scalars['Boolean']>;
};

export type SeizureInputType = {
  date: Scalars['DateTime'];
  duration: Maybe<Scalars['Int']>;
  intensity: Maybe<SeizureIntensity>;
  location: Scalars['Geometry'];
  status: Maybe<SeizureStatus>;
  trigger: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum SeizureIntensity {
  /** minor */
  A_1 = 'A_1',
  /** medium */
  A_2 = 'A_2',
  /** major */
  A_3 = 'A_3',
  /** severe */
  A_4 = 'A_4'
}

export type SeizureRemoveInputType = {
  id: Scalars['ID'];
};

/** An enumeration. */
export enum SeizureStatus {
  /** confirmed */
  A_1 = 'A_1',
  /** pending */
  A_2 = 'A_2',
  /** rejected */
  A_3 = 'A_3'
}

export type SeizureType = {
  __typename?: 'SeizureType';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  duration: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  intensity: Maybe<SeizureIntensity>;
  location: GeometryObjectType;
  patient: PatientType;
  status: Maybe<SeizureStatus>;
  trigger: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SeizureTypeConnection = {
  __typename?: 'SeizureTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SeizureTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** A Relay edge containing a `SeizureType` and its cursor. */
export type SeizureTypeEdge = {
  __typename?: 'SeizureTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<SeizureType>;
};

export type SeizureUpdate = {
  __typename?: 'SeizureUpdate';
  seizure: Maybe<SeizureType>;
};

export type SeizureUpdateInputType = {
  date: Scalars['DateTime'];
  duration: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  intensity: Maybe<SeizureIntensity>;
  location: Scalars['Geometry'];
  status: Maybe<SeizureStatus>;
  trigger: Maybe<Scalars['String']>;
};

export type SetPatientLocation = {
  __typename?: 'SetPatientLocation';
  patientLocation: Maybe<PatientLocationType>;
};

export type SettingsType = {
  __typename?: 'SettingsType';
  deviceSet: DeviceTypeConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Maybe<SettingsValueType>;
};


export type SettingsTypeDeviceSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type SettingsValueType = {
  __typename?: 'SettingsValueType';
  batteryReadPeriod: Maybe<Scalars['Int']>;
  historyBufferSize: Maybe<Scalars['Int']>;
  reconnectAttemptPeriod: Maybe<Scalars['Int']>;
  sendDataPeriod: Maybe<Scalars['Int']>;
  sensorReadPeriod: Maybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq: Maybe<Scalars['String']>;
  _gt: Maybe<Scalars['String']>;
  _gte: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike: Maybe<Scalars['String']>;
  _in: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex: Maybe<Scalars['String']>;
  _is_null: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like: Maybe<Scalars['String']>;
  _lt: Maybe<Scalars['String']>;
  _lte: Maybe<Scalars['String']>;
  _neq: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike: Maybe<Scalars['String']>;
  _nin: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar: Maybe<Scalars['String']>;
};

export type UpdateMedicine = {
  __typename?: 'UpdateMedicine';
  medicine: Maybe<MedicineType>;
};

export type UpdateMedicineInputType = {
  brand: Scalars['String'];
  cas: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  dosage: Scalars['Float'];
  id: Scalars['ID'];
  medicineType: MedicineMedicineType;
  name: Scalars['String'];
  pharmacodynamics: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
};

export type UpdateMedicineIntakeTimeInputType = {
  intakeTime: Scalars['String'];
};

export type UpdatePatientMedicine = {
  __typename?: 'UpdatePatientMedicine';
  patientMedicine: Maybe<PatientMedicineType>;
};

export type UpdatePatientMedicineInputType = {
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type UpdatePrescription = {
  __typename?: 'UpdatePrescription';
  prescription: Maybe<PrescriptionType>;
};

export type UpdatePrescriptionInputType = {
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type UpdatePrescriptionMedicine = {
  __typename?: 'UpdatePrescriptionMedicine';
  medicineIntakeTimes: Maybe<Array<Maybe<MedicineIntakeTimeType>>>;
  prescriptionMedicine: Maybe<PrescriptionMedicineType>;
};

export type UpdatePrescriptionMedicineInputType = {
  duration: Scalars['Int'];
  frequencyDay: Scalars['Int'];
  id: Scalars['ID'];
  instructions: Maybe<Scalars['String']>;
  intakeQuantity: Scalars['Int'];
  intakeState: PrescriptionMedicineIntakeState;
  intakeTimes: Array<Maybe<UpdateMedicineIntakeTimeInputType>>;
  intervalDay: Scalars['Int'];
  medicineId: Scalars['ID'];
  startDate: Scalars['String'];
};

export type UploadUserAvatar = {
  __typename?: 'UploadUserAvatar';
  image: Maybe<ImageType>;
};

export type UploadUserAvatarType = {
  file: Scalars['String'];
  userId: Scalars['ID'];
};

export type UserData = {
  __typename?: 'UserData';
  doctor: Maybe<DoctorType>;
  patient: Maybe<PatientType>;
  user: Maybe<UserType>;
};

export type UserDataInputType = {
  address: Scalars['String'];
  dateOfBirth: Scalars['DateTime'];
  diagnosis: Scalars['String'];
  epilepsyType: PatientEpilepsyType;
  firstName: Scalars['String'];
  location: Scalars['Geometry'];
  menstrualCorrelation: Scalars['Boolean'];
  secondName: Scalars['String'];
  sex: UserSex;
  thirdName: Scalars['String'];
};

/**
 * Mutation that receives 'phone', 'email' and possibly 'role', creates a user (if doesn't exist)
 * with provided data and send security code through sms to the provided phone number
 */
export type UserRegister = {
  __typename?: 'UserRegister';
  user: Maybe<UserType>;
};

export type UserRegisterInputType = {
  email: Scalars['String'];
  phone: Scalars['String'];
};

/** An enumeration. */
export enum UserRole {
  /** admin */
  A_1 = 'A_1',
  /** doctor */
  A_2 = 'A_2',
  /** client */
  A_3 = 'A_3'
}

/** An enumeration. */
export enum UserSex {
  /** male */
  A_1 = 'A_1',
  /** female */
  A_2 = 'A_2'
}

export type UserType = {
  __typename?: 'UserType';
  address: Scalars['String'];
  avatar: Maybe<ImageType>;
  createdAt: Scalars['DateTime'];
  dateJoined: Scalars['DateTime'];
  dateOfBirth: Maybe<Scalars['DateTime']>;
  doctorSet: DoctorTypeConnection;
  doctorpushnotificationSet: DoctorPushNotificationsTypeConnection;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gcmdeviceSet: GcmDeviceTypeConnection;
  id: Scalars['ID'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  lastLogin: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  lastSeen: Maybe<Scalars['DateTime']>;
  location: Maybe<GeometryObjectType>;
  messageSet: MessageTypeConnection;
  notifyEmail: Scalars['Boolean'];
  notifyPush: Scalars['Boolean'];
  patientSet: PatientTypeConnection;
  phone: Scalars['String'];
  prescriptionSet: PrescriptionTypeConnection;
  pushnotificationsSet: PushNotificationsTypeConnection;
  reviewSet: ReviewTypeConnection;
  role: UserRole;
  roleName: Maybe<Scalars['String']>;
  secondName: Scalars['String'];
  sex: UserSex;
  thirdName: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type UserTypeDoctorSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypeDoctorpushnotificationSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypeGcmdeviceSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypeMessageSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypePatientSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypePrescriptionSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypePushnotificationsSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type UserTypeReviewSetArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};

export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserType` and its cursor. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<UserType>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type VerifySecurityCodeInputType = {
  phone: Scalars['String'];
  role: UserRole;
  securityCode: Scalars['String'];
};

export type VersionType = {
  __typename?: 'VersionType';
  versionAndroid: Maybe<Scalars['String']>;
  versionIos: Maybe<Scalars['String']>;
};

export type WomenPeriodCreate = {
  __typename?: 'WomenPeriodCreate';
  womenPeriod: Maybe<WomenPeriodType>;
};

export type WomenPeriodDelete = {
  __typename?: 'WomenPeriodDelete';
  success: Maybe<Scalars['Boolean']>;
};

export type WomenPeriodInputType = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type WomenPeriodRemoveInputType = {
  id: Scalars['ID'];
};

export type WomenPeriodType = {
  __typename?: 'WomenPeriodType';
  createdAt: Scalars['DateTime'];
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  patient: PatientType;
  startDate: Scalars['Date'];
  updatedAt: Scalars['DateTime'];
};

export type WomenPeriodTypeConnection = {
  __typename?: 'WomenPeriodTypeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WomenPeriodTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `WomenPeriodType` and its cursor. */
export type WomenPeriodTypeEdge = {
  __typename?: 'WomenPeriodTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node: Maybe<WomenPeriodType>;
};

export type WomenPeriodUpdate = {
  __typename?: 'WomenPeriodUpdate';
  womenPeriod: Maybe<WomenPeriodType>;
};

export type WomenPeriodUpdateInputType = {
  endDate: Scalars['Date'];
  id: Scalars['ID'];
  startDate: Scalars['Date'];
};

/** columns and relationships of "chat_message" */
export type Chat_Message = {
  __typename?: 'chat_message';
  created_at: Scalars['timestamptz'];
  from_user_id: Scalars['Int'];
  id: Scalars['Int'];
  text: Scalars['String'];
  to_user_id: Scalars['Int'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "chat_message" */
export type Chat_Message_Aggregate = {
  __typename?: 'chat_message_aggregate';
  aggregate: Maybe<Chat_Message_Aggregate_Fields>;
  nodes: Array<Chat_Message>;
};

/** aggregate fields of "chat_message" */
export type Chat_Message_Aggregate_Fields = {
  __typename?: 'chat_message_aggregate_fields';
  avg: Maybe<Chat_Message_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Chat_Message_Max_Fields>;
  min: Maybe<Chat_Message_Min_Fields>;
  stddev: Maybe<Chat_Message_Stddev_Fields>;
  stddev_pop: Maybe<Chat_Message_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Chat_Message_Stddev_Samp_Fields>;
  sum: Maybe<Chat_Message_Sum_Fields>;
  var_pop: Maybe<Chat_Message_Var_Pop_Fields>;
  var_samp: Maybe<Chat_Message_Var_Samp_Fields>;
  variance: Maybe<Chat_Message_Variance_Fields>;
};


/** aggregate fields of "chat_message" */
export type Chat_Message_Aggregate_FieldsCountArgs = {
  columns: Maybe<Array<Chat_Message_Select_Column>>;
  distinct: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Chat_Message_Avg_Fields = {
  __typename?: 'chat_message_avg_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "chat_message". All fields are combined with a logical 'AND'. */
export type Chat_Message_Bool_Exp = {
  _and: Maybe<Array<Chat_Message_Bool_Exp>>;
  _not: Maybe<Chat_Message_Bool_Exp>;
  _or: Maybe<Array<Chat_Message_Bool_Exp>>;
  created_at: Maybe<Timestamptz_Comparison_Exp>;
  from_user_id: Maybe<Int_Comparison_Exp>;
  id: Maybe<Int_Comparison_Exp>;
  text: Maybe<String_Comparison_Exp>;
  to_user_id: Maybe<Int_Comparison_Exp>;
  updated_at: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "chat_message" */
export enum Chat_Message_Constraint {
  /** unique or primary key constraint on columns "id" */
  ChatMessagePkey = 'chat_message_pkey'
}

/** input type for incrementing numeric columns in table "chat_message" */
export type Chat_Message_Inc_Input = {
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  to_user_id: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "chat_message" */
export type Chat_Message_Insert_Input = {
  created_at: Maybe<Scalars['timestamptz']>;
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
  to_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Chat_Message_Max_Fields = {
  __typename?: 'chat_message_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
  to_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Chat_Message_Min_Fields = {
  __typename?: 'chat_message_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
  to_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "chat_message" */
export type Chat_Message_Mutation_Response = {
  __typename?: 'chat_message_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Chat_Message>;
};

/** on_conflict condition type for table "chat_message" */
export type Chat_Message_On_Conflict = {
  constraint: Chat_Message_Constraint;
  update_columns: Array<Chat_Message_Update_Column>;
  where: Maybe<Chat_Message_Bool_Exp>;
};

/** Ordering options when selecting data from "chat_message". */
export type Chat_Message_Order_By = {
  created_at: Maybe<Order_By>;
  from_user_id: Maybe<Order_By>;
  id: Maybe<Order_By>;
  text: Maybe<Order_By>;
  to_user_id: Maybe<Order_By>;
  updated_at: Maybe<Order_By>;
};

/** primary key columns input for table: chat_message */
export type Chat_Message_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "chat_message" */
export enum Chat_Message_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "chat_message" */
export type Chat_Message_Set_Input = {
  created_at: Maybe<Scalars['timestamptz']>;
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
  to_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Chat_Message_Stddev_Fields = {
  __typename?: 'chat_message_stddev_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Chat_Message_Stddev_Pop_Fields = {
  __typename?: 'chat_message_stddev_pop_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Chat_Message_Stddev_Samp_Fields = {
  __typename?: 'chat_message_stddev_samp_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "chat_message" */
export type Chat_Message_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chat_Message_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Chat_Message_Stream_Cursor_Value_Input = {
  created_at: Maybe<Scalars['timestamptz']>;
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  text: Maybe<Scalars['String']>;
  to_user_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Chat_Message_Sum_Fields = {
  __typename?: 'chat_message_sum_fields';
  from_user_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  to_user_id: Maybe<Scalars['Int']>;
};

/** update columns of table "chat_message" */
export enum Chat_Message_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Text = 'text',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Chat_Message_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: Maybe<Chat_Message_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: Maybe<Chat_Message_Set_Input>;
  where: Chat_Message_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Chat_Message_Var_Pop_Fields = {
  __typename?: 'chat_message_var_pop_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Chat_Message_Var_Samp_Fields = {
  __typename?: 'chat_message_var_samp_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Chat_Message_Variance_Fields = {
  __typename?: 'chat_message_variance_fields';
  from_user_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  to_user_id: Maybe<Scalars['Float']>;
};

/** columns and relationships of "core_user" */
export type Core_User = {
  __typename?: 'core_user';
  address: Scalars['String'];
  avatar_id: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamptz'];
  date_joined: Scalars['timestamptz'];
  date_of_birth: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  is_staff: Scalars['Boolean'];
  is_superuser: Scalars['Boolean'];
  last_login: Maybe<Scalars['timestamptz']>;
  last_name: Scalars['String'];
  last_seen: Maybe<Scalars['timestamptz']>;
  location: Maybe<Scalars['geometry']>;
  notify_email: Scalars['Boolean'];
  notify_push: Scalars['Boolean'];
  password: Scalars['String'];
  phone: Scalars['String'];
  role: Scalars['smallint'];
  second_name: Scalars['String'];
  sex: Scalars['smallint'];
  third_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "core_user" */
export type Core_User_Aggregate = {
  __typename?: 'core_user_aggregate';
  aggregate: Maybe<Core_User_Aggregate_Fields>;
  nodes: Array<Core_User>;
};

/** aggregate fields of "core_user" */
export type Core_User_Aggregate_Fields = {
  __typename?: 'core_user_aggregate_fields';
  avg: Maybe<Core_User_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Core_User_Max_Fields>;
  min: Maybe<Core_User_Min_Fields>;
  stddev: Maybe<Core_User_Stddev_Fields>;
  stddev_pop: Maybe<Core_User_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Core_User_Stddev_Samp_Fields>;
  sum: Maybe<Core_User_Sum_Fields>;
  var_pop: Maybe<Core_User_Var_Pop_Fields>;
  var_samp: Maybe<Core_User_Var_Samp_Fields>;
  variance: Maybe<Core_User_Variance_Fields>;
};


/** aggregate fields of "core_user" */
export type Core_User_Aggregate_FieldsCountArgs = {
  columns: Maybe<Array<Core_User_Select_Column>>;
  distinct: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Core_User_Avg_Fields = {
  __typename?: 'core_user_avg_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "core_user". All fields are combined with a logical 'AND'. */
export type Core_User_Bool_Exp = {
  _and: Maybe<Array<Core_User_Bool_Exp>>;
  _not: Maybe<Core_User_Bool_Exp>;
  _or: Maybe<Array<Core_User_Bool_Exp>>;
  address: Maybe<String_Comparison_Exp>;
  avatar_id: Maybe<Int_Comparison_Exp>;
  created_at: Maybe<Timestamptz_Comparison_Exp>;
  date_joined: Maybe<Timestamptz_Comparison_Exp>;
  date_of_birth: Maybe<Timestamptz_Comparison_Exp>;
  email: Maybe<String_Comparison_Exp>;
  first_name: Maybe<String_Comparison_Exp>;
  id: Maybe<Int_Comparison_Exp>;
  is_active: Maybe<Boolean_Comparison_Exp>;
  is_staff: Maybe<Boolean_Comparison_Exp>;
  is_superuser: Maybe<Boolean_Comparison_Exp>;
  last_login: Maybe<Timestamptz_Comparison_Exp>;
  last_name: Maybe<String_Comparison_Exp>;
  last_seen: Maybe<Timestamptz_Comparison_Exp>;
  location: Maybe<Geometry_Comparison_Exp>;
  notify_email: Maybe<Boolean_Comparison_Exp>;
  notify_push: Maybe<Boolean_Comparison_Exp>;
  password: Maybe<String_Comparison_Exp>;
  phone: Maybe<String_Comparison_Exp>;
  role: Maybe<Smallint_Comparison_Exp>;
  second_name: Maybe<String_Comparison_Exp>;
  sex: Maybe<Smallint_Comparison_Exp>;
  third_name: Maybe<String_Comparison_Exp>;
  updated_at: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "core_user" */
export enum Core_User_Constraint {
  /** unique or primary key constraint on columns "email" */
  CoreUserEmailKey = 'core_user_email_key',
  /** unique or primary key constraint on columns "phone" */
  CoreUserPhoneKey = 'core_user_phone_key',
  /** unique or primary key constraint on columns "id" */
  CoreUserPkey = 'core_user_pkey'
}

/** input type for incrementing numeric columns in table "core_user" */
export type Core_User_Inc_Input = {
  avatar_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  role: Maybe<Scalars['smallint']>;
  sex: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "core_user" */
export type Core_User_Insert_Input = {
  address: Maybe<Scalars['String']>;
  avatar_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  date_joined: Maybe<Scalars['timestamptz']>;
  date_of_birth: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  is_active: Maybe<Scalars['Boolean']>;
  is_staff: Maybe<Scalars['Boolean']>;
  is_superuser: Maybe<Scalars['Boolean']>;
  last_login: Maybe<Scalars['timestamptz']>;
  last_name: Maybe<Scalars['String']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  location: Maybe<Scalars['geometry']>;
  notify_email: Maybe<Scalars['Boolean']>;
  notify_push: Maybe<Scalars['Boolean']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['smallint']>;
  second_name: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['smallint']>;
  third_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Core_User_Max_Fields = {
  __typename?: 'core_user_max_fields';
  address: Maybe<Scalars['String']>;
  avatar_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  date_joined: Maybe<Scalars['timestamptz']>;
  date_of_birth: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_login: Maybe<Scalars['timestamptz']>;
  last_name: Maybe<Scalars['String']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['smallint']>;
  second_name: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['smallint']>;
  third_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Core_User_Min_Fields = {
  __typename?: 'core_user_min_fields';
  address: Maybe<Scalars['String']>;
  avatar_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  date_joined: Maybe<Scalars['timestamptz']>;
  date_of_birth: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  last_login: Maybe<Scalars['timestamptz']>;
  last_name: Maybe<Scalars['String']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['smallint']>;
  second_name: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['smallint']>;
  third_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "core_user" */
export type Core_User_Mutation_Response = {
  __typename?: 'core_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Core_User>;
};

/** input type for inserting object relation for remote table "core_user" */
export type Core_User_Obj_Rel_Insert_Input = {
  data: Core_User_Insert_Input;
  /** upsert condition */
  on_conflict: Maybe<Core_User_On_Conflict>;
};

/** on_conflict condition type for table "core_user" */
export type Core_User_On_Conflict = {
  constraint: Core_User_Constraint;
  update_columns: Array<Core_User_Update_Column>;
  where: Maybe<Core_User_Bool_Exp>;
};

/** Ordering options when selecting data from "core_user". */
export type Core_User_Order_By = {
  address: Maybe<Order_By>;
  avatar_id: Maybe<Order_By>;
  created_at: Maybe<Order_By>;
  date_joined: Maybe<Order_By>;
  date_of_birth: Maybe<Order_By>;
  email: Maybe<Order_By>;
  first_name: Maybe<Order_By>;
  id: Maybe<Order_By>;
  is_active: Maybe<Order_By>;
  is_staff: Maybe<Order_By>;
  is_superuser: Maybe<Order_By>;
  last_login: Maybe<Order_By>;
  last_name: Maybe<Order_By>;
  last_seen: Maybe<Order_By>;
  location: Maybe<Order_By>;
  notify_email: Maybe<Order_By>;
  notify_push: Maybe<Order_By>;
  password: Maybe<Order_By>;
  phone: Maybe<Order_By>;
  role: Maybe<Order_By>;
  second_name: Maybe<Order_By>;
  sex: Maybe<Order_By>;
  third_name: Maybe<Order_By>;
  updated_at: Maybe<Order_By>;
};

/** primary key columns input for table: core_user */
export type Core_User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "core_user" */
export enum Core_User_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateJoined = 'date_joined',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Location = 'location',
  /** column name */
  NotifyEmail = 'notify_email',
  /** column name */
  NotifyPush = 'notify_push',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  ThirdName = 'third_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "core_user" */
export type Core_User_Set_Input = {
  address: Maybe<Scalars['String']>;
  avatar_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  date_joined: Maybe<Scalars['timestamptz']>;
  date_of_birth: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  is_active: Maybe<Scalars['Boolean']>;
  is_staff: Maybe<Scalars['Boolean']>;
  is_superuser: Maybe<Scalars['Boolean']>;
  last_login: Maybe<Scalars['timestamptz']>;
  last_name: Maybe<Scalars['String']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  location: Maybe<Scalars['geometry']>;
  notify_email: Maybe<Scalars['Boolean']>;
  notify_push: Maybe<Scalars['Boolean']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['smallint']>;
  second_name: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['smallint']>;
  third_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Core_User_Stddev_Fields = {
  __typename?: 'core_user_stddev_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Core_User_Stddev_Pop_Fields = {
  __typename?: 'core_user_stddev_pop_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Core_User_Stddev_Samp_Fields = {
  __typename?: 'core_user_stddev_samp_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "core_user" */
export type Core_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Core_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Core_User_Stream_Cursor_Value_Input = {
  address: Maybe<Scalars['String']>;
  avatar_id: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  date_joined: Maybe<Scalars['timestamptz']>;
  date_of_birth: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  first_name: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  is_active: Maybe<Scalars['Boolean']>;
  is_staff: Maybe<Scalars['Boolean']>;
  is_superuser: Maybe<Scalars['Boolean']>;
  last_login: Maybe<Scalars['timestamptz']>;
  last_name: Maybe<Scalars['String']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  location: Maybe<Scalars['geometry']>;
  notify_email: Maybe<Scalars['Boolean']>;
  notify_push: Maybe<Scalars['Boolean']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  role: Maybe<Scalars['smallint']>;
  second_name: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['smallint']>;
  third_name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Core_User_Sum_Fields = {
  __typename?: 'core_user_sum_fields';
  avatar_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  role: Maybe<Scalars['smallint']>;
  sex: Maybe<Scalars['smallint']>;
};

/** update columns of table "core_user" */
export enum Core_User_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AvatarId = 'avatar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateJoined = 'date_joined',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsStaff = 'is_staff',
  /** column name */
  IsSuperuser = 'is_superuser',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Location = 'location',
  /** column name */
  NotifyEmail = 'notify_email',
  /** column name */
  NotifyPush = 'notify_push',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  Role = 'role',
  /** column name */
  SecondName = 'second_name',
  /** column name */
  Sex = 'sex',
  /** column name */
  ThirdName = 'third_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Core_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: Maybe<Core_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: Maybe<Core_User_Set_Input>;
  where: Core_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Core_User_Var_Pop_Fields = {
  __typename?: 'core_user_var_pop_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Core_User_Var_Samp_Fields = {
  __typename?: 'core_user_var_samp_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Core_User_Variance_Fields = {
  __typename?: 'core_user_variance_fields';
  avatar_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  role: Maybe<Scalars['Float']>;
  sex: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}


export type Geography_Cast_Exp = {
  geometry: Maybe<Geometry_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast: Maybe<Geography_Cast_Exp>;
  _eq: Maybe<Scalars['geography']>;
  _gt: Maybe<Scalars['geography']>;
  _gte: Maybe<Scalars['geography']>;
  _in: Maybe<Array<Scalars['geography']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['geography']>;
  _lte: Maybe<Scalars['geography']>;
  _neq: Maybe<Scalars['geography']>;
  _nin: Maybe<Array<Scalars['geography']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within: Maybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects: Maybe<Scalars['geography']>;
};


export type Geometry_Cast_Exp = {
  geography: Maybe<Geography_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast: Maybe<Geometry_Cast_Exp>;
  _eq: Maybe<Scalars['geometry']>;
  _gt: Maybe<Scalars['geometry']>;
  _gte: Maybe<Scalars['geometry']>;
  _in: Maybe<Array<Scalars['geometry']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['geometry']>;
  _lte: Maybe<Scalars['geometry']>;
  _neq: Maybe<Scalars['geometry']>;
  _nin: Maybe<Array<Scalars['geometry']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within: Maybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects: Maybe<Scalars['geometry']>;
  /** does the column contain the given geometry value */
  _st_contains: Maybe<Scalars['geometry']>;
  /** does the column cross the given geometry value */
  _st_crosses: Maybe<Scalars['geometry']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within: Maybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within: Maybe<Scalars['geometry']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  createMedicine: Maybe<CreateMedicine>;
  createMedicineIntake: Maybe<CreateMedicineIntake>;
  createPatientMedicine: Maybe<CreatePatientMedicine>;
  createPrescription: Maybe<CreatePrescription>;
  createPrescriptionMedicine: Maybe<CreatePrescriptionMedicine>;
  createReview: Maybe<CreateReview>;
  /** delete data from the table: "chat_message" */
  delete_chat_message: Maybe<Chat_Message_Mutation_Response>;
  /** delete single row from the table: "chat_message" */
  delete_chat_message_by_pk: Maybe<Chat_Message>;
  /** delete data from the table: "core_user" */
  delete_core_user: Maybe<Core_User_Mutation_Response>;
  /** delete single row from the table: "core_user" */
  delete_core_user_by_pk: Maybe<Core_User>;
  /** delete data from the table: "user_online" */
  delete_user_online: Maybe<User_Online_Mutation_Response>;
  deviceCreate: Maybe<DeviceCreate>;
  doctorCreatePrescription: Maybe<DoctorCreatePrescription>;
  doctorInfoUpdate: Maybe<DoctorInfoUpdate>;
  doctorPatientFileCreate: Maybe<DoctorPatientFileCreate>;
  doctorPatientFileDelete: Maybe<DoctorPatientFileDelete>;
  doctorPatientFileUpdate: Maybe<DoctorPatientFileUpdate>;
  /** Allows registration or authorization for Doctors */
  doctorRegister: Maybe<DoctorRegister>;
  doctorRemovePrescriptionMedicine: Maybe<DoctorRemovePrescriptionMedicine>;
  doctorUpdatePrescription: Maybe<DoctorUpdatePrescription>;
  doctorUpdatePrescriptionMedicine: Maybe<DoctorUpdatePrescriptionMedicine>;
  gcmDeviceCreate: Maybe<GcmDeviceCreate>;
  /** insert data into the table: "chat_message" */
  insert_chat_message: Maybe<Chat_Message_Mutation_Response>;
  /** insert a single row into the table: "chat_message" */
  insert_chat_message_one: Maybe<Chat_Message>;
  /** insert data into the table: "core_user" */
  insert_core_user: Maybe<Core_User_Mutation_Response>;
  /** insert a single row into the table: "core_user" */
  insert_core_user_one: Maybe<Core_User>;
  /** insert data into the table: "user_online" */
  insert_user_online: Maybe<User_Online_Mutation_Response>;
  /** insert a single row into the table: "user_online" */
  insert_user_online_one: Maybe<User_Online>;
  linkDevice: Maybe<LinkDevice>;
  patientFileCreate: Maybe<PatientFileCreate>;
  patientFileDelete: Maybe<PatientFileDelete>;
  patientFileUpdate: Maybe<PatientFileUpdate>;
  pushDeviceRecord: Maybe<PushDeviceRecordMutation>;
  refreshToken: Maybe<Refresh>;
  removeDoctorPushNotification: Maybe<RemoveDoctorPushNotification>;
  removeMedicine: Maybe<RemoveMedicine>;
  removeMedicineIntake: Maybe<RemoveMedicineIntake>;
  removePatientMedicine: Maybe<RemovePatientMedicine>;
  removePrescription: Maybe<RemovePrescription>;
  removePrescriptionMedicine: Maybe<RemovePrescriptionMedicine>;
  removeUser: Maybe<RemoveUser>;
  removeUserAvatar: Maybe<RemoveUserAvatar>;
  seizureCreate: Maybe<SeizureCreate>;
  seizureDelete: Maybe<SeizureDelete>;
  seizureUpdate: Maybe<SeizureUpdate>;
  setPatientLocation: Maybe<SetPatientLocation>;
  soscontactCreate: Maybe<SosContactCreate>;
  soscontactDelete: Maybe<SosContactDelete>;
  soscontactUpdate: Maybe<SosContactUpdate>;
  /**
   * Mutation that receives 'phone' and 'security_code'
   * and returns a jwt token
   */
  tokenAuth: Maybe<CreateToken>;
  updateMedicine: Maybe<UpdateMedicine>;
  updatePatientMedicine: Maybe<UpdatePatientMedicine>;
  updatePrescription: Maybe<UpdatePrescription>;
  updatePrescriptionMedicine: Maybe<UpdatePrescriptionMedicine>;
  /** update data of the table: "chat_message" */
  update_chat_message: Maybe<Chat_Message_Mutation_Response>;
  /** update single row of the table: "chat_message" */
  update_chat_message_by_pk: Maybe<Chat_Message>;
  /** update multiples rows of table: "chat_message" */
  update_chat_message_many: Maybe<Array<Maybe<Chat_Message_Mutation_Response>>>;
  /** update data of the table: "core_user" */
  update_core_user: Maybe<Core_User_Mutation_Response>;
  /** update single row of the table: "core_user" */
  update_core_user_by_pk: Maybe<Core_User>;
  /** update multiples rows of table: "core_user" */
  update_core_user_many: Maybe<Array<Maybe<Core_User_Mutation_Response>>>;
  /** update data of the table: "user_online" */
  update_user_online: Maybe<User_Online_Mutation_Response>;
  /** update multiples rows of table: "user_online" */
  update_user_online_many: Maybe<Array<Maybe<User_Online_Mutation_Response>>>;
  uploadUserAvatar: Maybe<UploadUserAvatar>;
  userDataSet: Maybe<UserData>;
  /**
   * Mutation that receives 'phone', 'email' and possibly 'role', creates a user (if doesn't exist)
   * with provided data and send security code through sms to the provided phone number
   */
  userRegister: Maybe<UserRegister>;
  verifyToken: Maybe<Verify>;
  womenPeriodCreate: Maybe<WomenPeriodCreate>;
  womenPeriodDelete: Maybe<WomenPeriodDelete>;
  womenPeriodUpdate: Maybe<WomenPeriodUpdate>;
};


/** mutation root */
export type Mutation_RootCreateMedicineArgs = {
  input: CreateMedicineInputType;
};


/** mutation root */
export type Mutation_RootCreateMedicineIntakeArgs = {
  input: CreateMedicineIntakeInputType;
};


/** mutation root */
export type Mutation_RootCreatePatientMedicineArgs = {
  input: CreatePatientMedicineInputType;
};


/** mutation root */
export type Mutation_RootCreatePrescriptionArgs = {
  input: CreatePrescriptionInputType;
};


/** mutation root */
export type Mutation_RootCreatePrescriptionMedicineArgs = {
  input: CreatePrescriptionMedicineInputType;
};


/** mutation root */
export type Mutation_RootCreateReviewArgs = {
  input: CreateReviewInputType;
};


/** mutation root */
export type Mutation_RootDelete_Chat_MessageArgs = {
  where: Chat_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Chat_Message_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Core_UserArgs = {
  where: Core_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Core_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_OnlineArgs = {
  where: User_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDeviceCreateArgs = {
  input: DeviceInputType;
};


/** mutation root */
export type Mutation_RootDoctorCreatePrescriptionArgs = {
  input: DoctorCreatePrescriptionInputType;
};


/** mutation root */
export type Mutation_RootDoctorInfoUpdateArgs = {
  input: DoctorInfoUpdateInputType;
};


/** mutation root */
export type Mutation_RootDoctorPatientFileCreateArgs = {
  input: DoctorPatientFileInputType;
};


/** mutation root */
export type Mutation_RootDoctorPatientFileDeleteArgs = {
  input: DoctorPatientFileRemoveInputType;
};


/** mutation root */
export type Mutation_RootDoctorPatientFileUpdateArgs = {
  input: Maybe<DoctorPatientFileUpdateInputType>;
};


/** mutation root */
export type Mutation_RootDoctorRegisterArgs = {
  input: DoctorRegisterInputType;
};


/** mutation root */
export type Mutation_RootDoctorRemovePrescriptionMedicineArgs = {
  input: DoctorRemovePrescriptionMedicineInputType;
};


/** mutation root */
export type Mutation_RootDoctorUpdatePrescriptionArgs = {
  input: DoctorUpdatePrescriptionInputType;
};


/** mutation root */
export type Mutation_RootDoctorUpdatePrescriptionMedicineArgs = {
  input: DoctorUpdatePrescriptionMedicineInputType;
};


/** mutation root */
export type Mutation_RootGcmDeviceCreateArgs = {
  input: GcmDeviceInputType;
};


/** mutation root */
export type Mutation_RootInsert_Chat_MessageArgs = {
  objects: Array<Chat_Message_Insert_Input>;
  on_conflict: Maybe<Chat_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Chat_Message_OneArgs = {
  object: Chat_Message_Insert_Input;
  on_conflict: Maybe<Chat_Message_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_UserArgs = {
  objects: Array<Core_User_Insert_Input>;
  on_conflict: Maybe<Core_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Core_User_OneArgs = {
  object: Core_User_Insert_Input;
  on_conflict: Maybe<Core_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OnlineArgs = {
  objects: Array<User_Online_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_User_Online_OneArgs = {
  object: User_Online_Insert_Input;
};


/** mutation root */
export type Mutation_RootLinkDeviceArgs = {
  input: DeviceInputType;
};


/** mutation root */
export type Mutation_RootPatientFileCreateArgs = {
  input: PatientFileInputType;
};


/** mutation root */
export type Mutation_RootPatientFileDeleteArgs = {
  input: PatientFileRemoveInputType;
};


/** mutation root */
export type Mutation_RootPatientFileUpdateArgs = {
  input: Maybe<PatientFileUpdateInputType>;
};


/** mutation root */
export type Mutation_RootPushDeviceRecordArgs = {
  input: DeviceRecordInputType;
};


/** mutation root */
export type Mutation_RootRefreshTokenArgs = {
  refreshToken: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootRemoveDoctorPushNotificationArgs = {
  input: RemoveDoctorPushNotificationType;
};


/** mutation root */
export type Mutation_RootRemoveMedicineArgs = {
  input: RemoveMedicineInputType;
};


/** mutation root */
export type Mutation_RootRemoveMedicineIntakeArgs = {
  input: RemoveMedicineIntakeInputType;
};


/** mutation root */
export type Mutation_RootRemovePatientMedicineArgs = {
  input: RemovePatientMedicineInputType;
};


/** mutation root */
export type Mutation_RootRemovePrescriptionArgs = {
  input: RemovePrescriptionInputType;
};


/** mutation root */
export type Mutation_RootRemovePrescriptionMedicineArgs = {
  input: RemovePrescriptionMedicineInputType;
};


/** mutation root */
export type Mutation_RootRemoveUserArgs = {
  input: RemoveUserType;
};


/** mutation root */
export type Mutation_RootRemoveUserAvatarArgs = {
  input: RemoveUserAvatarType;
};


/** mutation root */
export type Mutation_RootSeizureCreateArgs = {
  input: SeizureInputType;
};


/** mutation root */
export type Mutation_RootSeizureDeleteArgs = {
  input: SeizureRemoveInputType;
};


/** mutation root */
export type Mutation_RootSeizureUpdateArgs = {
  input: SeizureUpdateInputType;
};


/** mutation root */
export type Mutation_RootSetPatientLocationArgs = {
  input: PatientLocationInputType;
};


/** mutation root */
export type Mutation_RootSoscontactCreateArgs = {
  input: SosContactInputType;
};


/** mutation root */
export type Mutation_RootSoscontactDeleteArgs = {
  input: SosContactRemoveInputType;
};


/** mutation root */
export type Mutation_RootSoscontactUpdateArgs = {
  input: SosContactUpdateInputType;
};


/** mutation root */
export type Mutation_RootTokenAuthArgs = {
  input: VerifySecurityCodeInputType;
};


/** mutation root */
export type Mutation_RootUpdateMedicineArgs = {
  input: UpdateMedicineInputType;
};


/** mutation root */
export type Mutation_RootUpdatePatientMedicineArgs = {
  input: UpdatePatientMedicineInputType;
};


/** mutation root */
export type Mutation_RootUpdatePrescriptionArgs = {
  input: UpdatePrescriptionInputType;
};


/** mutation root */
export type Mutation_RootUpdatePrescriptionMedicineArgs = {
  input: UpdatePrescriptionMedicineInputType;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_MessageArgs = {
  _inc: Maybe<Chat_Message_Inc_Input>;
  _set: Maybe<Chat_Message_Set_Input>;
  where: Chat_Message_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_By_PkArgs = {
  _inc: Maybe<Chat_Message_Inc_Input>;
  _set: Maybe<Chat_Message_Set_Input>;
  pk_columns: Chat_Message_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Chat_Message_ManyArgs = {
  updates: Array<Chat_Message_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Core_UserArgs = {
  _inc: Maybe<Core_User_Inc_Input>;
  _set: Maybe<Core_User_Set_Input>;
  where: Core_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_By_PkArgs = {
  _inc: Maybe<Core_User_Inc_Input>;
  _set: Maybe<Core_User_Set_Input>;
  pk_columns: Core_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Core_User_ManyArgs = {
  updates: Array<Core_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_OnlineArgs = {
  _inc: Maybe<User_Online_Inc_Input>;
  _set: Maybe<User_Online_Set_Input>;
  where: User_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Online_ManyArgs = {
  updates: Array<User_Online_Updates>;
};


/** mutation root */
export type Mutation_RootUploadUserAvatarArgs = {
  input: UploadUserAvatarType;
};


/** mutation root */
export type Mutation_RootUserDataSetArgs = {
  doctorInput: Maybe<DoctorUserDataInputType>;
  input: Maybe<UserDataInputType>;
};


/** mutation root */
export type Mutation_RootUserRegisterArgs = {
  input: UserRegisterInputType;
};


/** mutation root */
export type Mutation_RootVerifyTokenArgs = {
  token: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootWomenPeriodCreateArgs = {
  input: WomenPeriodInputType;
};


/** mutation root */
export type Mutation_RootWomenPeriodDeleteArgs = {
  input: WomenPeriodRemoveInputType;
};


/** mutation root */
export type Mutation_RootWomenPeriodUpdateArgs = {
  input: WomenPeriodUpdateInputType;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  addressSuggestions: Maybe<Array<Maybe<AddressSuggestionResultType>>>;
  /** fetch data from the table: "chat_message" */
  chat_message: Array<Chat_Message>;
  /** fetch aggregated fields from the table: "chat_message" */
  chat_message_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "chat_message" using primary key columns */
  chat_message_by_pk: Maybe<Chat_Message>;
  clinicDoctors: Maybe<DoctorClinicTypeConnection>;
  clinicReviews: Maybe<ReviewTypeConnection>;
  clinicReviewsGroupByCharacteristic: Maybe<Array<Maybe<ClinicReviewsGroupByCharacteristicType>>>;
  /** fetch data from the table: "core_user" */
  core_user: Array<Core_User>;
  /** fetch aggregated fields from the table: "core_user" */
  core_user_aggregate: Core_User_Aggregate;
  /** fetch data from the table: "core_user" using primary key columns */
  core_user_by_pk: Maybe<Core_User>;
  device: Maybe<DeviceType>;
  deviceRecords: Maybe<GraphRecordType>;
  deviceSettings: Maybe<SettingsType>;
  devices: Maybe<DeviceTypeConnection>;
  doctor: Maybe<DoctorType>;
  doctorClinics: Maybe<DoctorClinicTypeConnection>;
  doctorPatient: Maybe<DoctorPatientType>;
  doctorPatientPrescription: Maybe<PrescriptionType>;
  doctorPatients: Maybe<DoctorPatientTypeConnection>;
  doctorPrescriptions: Maybe<PrescriptionTypeConnection>;
  doctorPushNotifications: Maybe<DoctorPushNotificationsTypeConnection>;
  doctorSeizures: Maybe<SeizureTypeConnection>;
  doctors: Maybe<DoctorTypeConnection>;
  geocode: Maybe<AddressType>;
  getPresignedUrl: Maybe<Scalars['String']>;
  me: Maybe<UserType>;
  medicine: Maybe<MedicineType>;
  medicineIntake: Maybe<MedicineIntakeType>;
  medicines: Maybe<MedicineTypeConnection>;
  medicinesIntakes: Maybe<MedicineIntakeTypeConnection>;
  patient: Maybe<PatientType>;
  patientDevices: Maybe<DeviceTypeConnection>;
  patientDoctorsByPatientId: Maybe<Array<Maybe<DoctorType>>>;
  patientFile: Maybe<PatientFileType>;
  patientFiles: Maybe<PatientFileTypeConnection>;
  patientLocations: Maybe<PatientLocationTypeConnection>;
  patientLocationsAll: Maybe<PatientLocationTypeConnection>;
  patientMedicine: Maybe<PatientMedicineType>;
  patientMedicines: Maybe<PatientMedicineTypeConnection>;
  patientMedicinesAll: Maybe<PatientMedicineTypeConnection>;
  patientMedicinesByPatientPk: Maybe<Array<Maybe<PatientMedicineType>>>;
  patientMedicinesIntakes: Maybe<GraphMedicineIntakeType>;
  patientPrescriptions: Maybe<PrescriptionTypeConnection>;
  patientSeizures: Maybe<SeizureTypeConnection>;
  patients: Maybe<PatientTypeConnection>;
  prescription: Maybe<PrescriptionType>;
  prescriptionMedicine: Maybe<PrescriptionMedicineType>;
  prescriptions: Maybe<PrescriptionTypeConnection>;
  review: Maybe<ReviewType>;
  reviews: Maybe<ReviewTypeConnection>;
  reviewsClinic: Maybe<ReviewClinicType>;
  reviewsClinics: Maybe<ReviewClinicTypeConnection>;
  reviewsDoctor: Maybe<ReviewDoctorType>;
  reviewsDoctors: Maybe<ReviewDoctorTypeConnection>;
  seizure: Maybe<SeizureType>;
  seizures: Maybe<SeizureTypeConnection>;
  sosContact: Maybe<SosContactType>;
  sosContacts: Maybe<SosContactTypeConnection>;
  user: Maybe<UserType>;
  /** fetch data from the table: "user_online" */
  user_online: Array<User_Online>;
  /** fetch aggregated fields from the table: "user_online" */
  user_online_aggregate: User_Online_Aggregate;
  users: Maybe<UserTypeConnection>;
  version: Maybe<VersionType>;
  womenPeriod: Maybe<WomenPeriodType>;
  womenPeriods: Maybe<WomenPeriodTypeConnection>;
};


export type Query_RootAddressSuggestionsArgs = {
  addressStr: Scalars['String'];
};


export type Query_RootChat_MessageArgs = {
  distinct_on: Maybe<Array<Chat_Message_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Chat_Message_Order_By>>;
  where: Maybe<Chat_Message_Bool_Exp>;
};


export type Query_RootChat_Message_AggregateArgs = {
  distinct_on: Maybe<Array<Chat_Message_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Chat_Message_Order_By>>;
  where: Maybe<Chat_Message_Bool_Exp>;
};


export type Query_RootChat_Message_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClinicDoctorsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootClinicReviewsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  sort: Maybe<Scalars['String']>;
};


export type Query_RootClinicReviewsGroupByCharacteristicArgs = {
  clinicId: Scalars['ID'];
};


export type Query_RootCore_UserArgs = {
  distinct_on: Maybe<Array<Core_User_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Core_User_Order_By>>;
  where: Maybe<Core_User_Bool_Exp>;
};


export type Query_RootCore_User_AggregateArgs = {
  distinct_on: Maybe<Array<Core_User_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Core_User_Order_By>>;
  where: Maybe<Core_User_Bool_Exp>;
};


export type Query_RootCore_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootDeviceArgs = {
  id: Scalars['ID'];
};


export type Query_RootDeviceRecordsArgs = {
  deviceId: Scalars['ID'];
  key: Scalars['String'];
};


export type Query_RootDeviceSettingsArgs = {
  id: Scalars['ID'];
};


export type Query_RootDevicesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootDoctorArgs = {
  id: Scalars['ID'];
};


export type Query_RootDoctorClinicsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootDoctorPatientArgs = {
  id: Scalars['ID'];
};


export type Query_RootDoctorPatientPrescriptionArgs = {
  id: Scalars['ID'];
};


export type Query_RootDoctorPatientsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootDoctorPrescriptionsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootDoctorPushNotificationsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootDoctorSeizuresArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['ID']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootDoctorsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootGeocodeArgs = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
};


export type Query_RootGetPresignedUrlArgs = {
  filename: Scalars['String'];
};


export type Query_RootMedicineArgs = {
  id: Scalars['ID'];
};


export type Query_RootMedicineIntakeArgs = {
  id: Scalars['ID'];
};


export type Query_RootMedicinesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootMedicinesIntakesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientArgs = {
  id: Scalars['ID'];
};


export type Query_RootPatientDevicesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientDoctorsByPatientIdArgs = {
  id: Scalars['ID'];
};


export type Query_RootPatientFileArgs = {
  id: Scalars['ID'];
};


export type Query_RootPatientFilesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientLocationsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientLocationsAllArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientMedicineArgs = {
  id: Scalars['ID'];
};


export type Query_RootPatientMedicinesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientMedicinesAllArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientMedicinesByPatientPkArgs = {
  patientId: Scalars['ID'];
};


export type Query_RootPatientMedicinesIntakesArgs = {
  date: Scalars['Date'];
};


export type Query_RootPatientPrescriptionsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientSeizuresArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPatientsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootPrescriptionArgs = {
  id: Scalars['ID'];
};


export type Query_RootPrescriptionMedicineArgs = {
  id: Scalars['ID'];
};


export type Query_RootPrescriptionsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootReviewArgs = {
  id: Scalars['ID'];
};


export type Query_RootReviewsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootReviewsClinicArgs = {
  id: Scalars['ID'];
};


export type Query_RootReviewsClinicsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  filter: Maybe<ClinicFilterInput>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  search: Maybe<Scalars['String']>;
  sort: Maybe<Scalars['String']>;
};


export type Query_RootReviewsDoctorArgs = {
  id: Scalars['ID'];
};


export type Query_RootReviewsDoctorsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootSeizureArgs = {
  id: Scalars['ID'];
};


export type Query_RootSeizuresArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootSosContactArgs = {
  id: Scalars['ID'];
};


export type Query_RootSosContactsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootUserArgs = {
  email: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  phone: Maybe<Scalars['String']>;
};


export type Query_RootUser_OnlineArgs = {
  distinct_on: Maybe<Array<User_Online_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<User_Online_Order_By>>;
  where: Maybe<User_Online_Bool_Exp>;
};


export type Query_RootUser_Online_AggregateArgs = {
  distinct_on: Maybe<Array<User_Online_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<User_Online_Order_By>>;
  where: Maybe<User_Online_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


export type Query_RootWomenPeriodArgs = {
  id: Scalars['ID'];
};


export type Query_RootWomenPeriodsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
};


/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq: Maybe<Scalars['smallint']>;
  _gt: Maybe<Scalars['smallint']>;
  _gte: Maybe<Scalars['smallint']>;
  _in: Maybe<Array<Scalars['smallint']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['smallint']>;
  _lte: Maybe<Scalars['smallint']>;
  _neq: Maybe<Scalars['smallint']>;
  _nin: Maybe<Array<Scalars['smallint']>>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid: Maybe<Scalars['Boolean']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "chat_message" */
  chat_message: Array<Chat_Message>;
  /** fetch aggregated fields from the table: "chat_message" */
  chat_message_aggregate: Chat_Message_Aggregate;
  /** fetch data from the table: "chat_message" using primary key columns */
  chat_message_by_pk: Maybe<Chat_Message>;
  /** fetch data from the table in a streaming manner : "chat_message" */
  chat_message_stream: Array<Chat_Message>;
  /** fetch data from the table: "core_user" */
  core_user: Array<Core_User>;
  /** fetch aggregated fields from the table: "core_user" */
  core_user_aggregate: Core_User_Aggregate;
  /** fetch data from the table: "core_user" using primary key columns */
  core_user_by_pk: Maybe<Core_User>;
  /** fetch data from the table in a streaming manner : "core_user" */
  core_user_stream: Array<Core_User>;
  /** fetch data from the table: "user_online" */
  user_online: Array<User_Online>;
  /** fetch aggregated fields from the table: "user_online" */
  user_online_aggregate: User_Online_Aggregate;
  /** fetch data from the table in a streaming manner : "user_online" */
  user_online_stream: Array<User_Online>;
};


export type Subscription_RootChat_MessageArgs = {
  distinct_on: Maybe<Array<Chat_Message_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Chat_Message_Order_By>>;
  where: Maybe<Chat_Message_Bool_Exp>;
};


export type Subscription_RootChat_Message_AggregateArgs = {
  distinct_on: Maybe<Array<Chat_Message_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Chat_Message_Order_By>>;
  where: Maybe<Chat_Message_Bool_Exp>;
};


export type Subscription_RootChat_Message_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootChat_Message_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Chat_Message_Stream_Cursor_Input>>;
  where: Maybe<Chat_Message_Bool_Exp>;
};


export type Subscription_RootCore_UserArgs = {
  distinct_on: Maybe<Array<Core_User_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Core_User_Order_By>>;
  where: Maybe<Core_User_Bool_Exp>;
};


export type Subscription_RootCore_User_AggregateArgs = {
  distinct_on: Maybe<Array<Core_User_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<Core_User_Order_By>>;
  where: Maybe<Core_User_Bool_Exp>;
};


export type Subscription_RootCore_User_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCore_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Core_User_Stream_Cursor_Input>>;
  where: Maybe<Core_User_Bool_Exp>;
};


export type Subscription_RootUser_OnlineArgs = {
  distinct_on: Maybe<Array<User_Online_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<User_Online_Order_By>>;
  where: Maybe<User_Online_Bool_Exp>;
};


export type Subscription_RootUser_Online_AggregateArgs = {
  distinct_on: Maybe<Array<User_Online_Select_Column>>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
  order_by: Maybe<Array<User_Online_Order_By>>;
  where: Maybe<User_Online_Bool_Exp>;
};


export type Subscription_RootUser_Online_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<User_Online_Stream_Cursor_Input>>;
  where: Maybe<User_Online_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq: Maybe<Scalars['timestamp']>;
  _gt: Maybe<Scalars['timestamp']>;
  _gte: Maybe<Scalars['timestamp']>;
  _in: Maybe<Array<Scalars['timestamp']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['timestamp']>;
  _lte: Maybe<Scalars['timestamp']>;
  _neq: Maybe<Scalars['timestamp']>;
  _nin: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq: Maybe<Scalars['timestamptz']>;
  _gt: Maybe<Scalars['timestamptz']>;
  _gte: Maybe<Scalars['timestamptz']>;
  _in: Maybe<Array<Scalars['timestamptz']>>;
  _is_null: Maybe<Scalars['Boolean']>;
  _lt: Maybe<Scalars['timestamptz']>;
  _lte: Maybe<Scalars['timestamptz']>;
  _neq: Maybe<Scalars['timestamptz']>;
  _nin: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "user_online" */
export type User_Online = {
  __typename?: 'user_online';
  id: Maybe<Scalars['Int']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  userOnline: Maybe<Core_User>;
};

/** aggregated selection of "user_online" */
export type User_Online_Aggregate = {
  __typename?: 'user_online_aggregate';
  aggregate: Maybe<User_Online_Aggregate_Fields>;
  nodes: Array<User_Online>;
};

/** aggregate fields of "user_online" */
export type User_Online_Aggregate_Fields = {
  __typename?: 'user_online_aggregate_fields';
  avg: Maybe<User_Online_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<User_Online_Max_Fields>;
  min: Maybe<User_Online_Min_Fields>;
  stddev: Maybe<User_Online_Stddev_Fields>;
  stddev_pop: Maybe<User_Online_Stddev_Pop_Fields>;
  stddev_samp: Maybe<User_Online_Stddev_Samp_Fields>;
  sum: Maybe<User_Online_Sum_Fields>;
  var_pop: Maybe<User_Online_Var_Pop_Fields>;
  var_samp: Maybe<User_Online_Var_Samp_Fields>;
  variance: Maybe<User_Online_Variance_Fields>;
};


/** aggregate fields of "user_online" */
export type User_Online_Aggregate_FieldsCountArgs = {
  columns: Maybe<Array<User_Online_Select_Column>>;
  distinct: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Online_Avg_Fields = {
  __typename?: 'user_online_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_online". All fields are combined with a logical 'AND'. */
export type User_Online_Bool_Exp = {
  _and: Maybe<Array<User_Online_Bool_Exp>>;
  _not: Maybe<User_Online_Bool_Exp>;
  _or: Maybe<Array<User_Online_Bool_Exp>>;
  id: Maybe<Int_Comparison_Exp>;
  last_seen: Maybe<Timestamptz_Comparison_Exp>;
  userOnline: Maybe<Core_User_Bool_Exp>;
};

/** input type for incrementing numeric columns in table "user_online" */
export type User_Online_Inc_Input = {
  id: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_online" */
export type User_Online_Insert_Input = {
  id: Maybe<Scalars['Int']>;
  last_seen: Maybe<Scalars['timestamptz']>;
  userOnline: Maybe<Core_User_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Online_Max_Fields = {
  __typename?: 'user_online_max_fields';
  id: Maybe<Scalars['Int']>;
  last_seen: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Online_Min_Fields = {
  __typename?: 'user_online_min_fields';
  id: Maybe<Scalars['Int']>;
  last_seen: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user_online" */
export type User_Online_Mutation_Response = {
  __typename?: 'user_online_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Online>;
};

/** Ordering options when selecting data from "user_online". */
export type User_Online_Order_By = {
  id: Maybe<Order_By>;
  last_seen: Maybe<Order_By>;
  userOnline: Maybe<Core_User_Order_By>;
};

/** select columns of table "user_online" */
export enum User_Online_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen'
}

/** input type for updating data in table "user_online" */
export type User_Online_Set_Input = {
  id: Maybe<Scalars['Int']>;
  last_seen: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Online_Stddev_Fields = {
  __typename?: 'user_online_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Online_Stddev_Pop_Fields = {
  __typename?: 'user_online_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Online_Stddev_Samp_Fields = {
  __typename?: 'user_online_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_online" */
export type User_Online_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Online_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Online_Stream_Cursor_Value_Input = {
  id: Maybe<Scalars['Int']>;
  last_seen: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type User_Online_Sum_Fields = {
  __typename?: 'user_online_sum_fields';
  id: Maybe<Scalars['Int']>;
};

export type User_Online_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc: Maybe<User_Online_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set: Maybe<User_Online_Set_Input>;
  where: User_Online_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Online_Var_Pop_Fields = {
  __typename?: 'user_online_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Online_Var_Samp_Fields = {
  __typename?: 'user_online_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Online_Variance_Fields = {
  __typename?: 'user_online_variance_fields';
  id: Maybe<Scalars['Float']>;
};

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'query_root' }
  & { users: Maybe<(
    { __typename?: 'UserTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'UserTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'id' | 'email' | 'phone' | 'createdAt'>
      )> }
    )>> }
  )> }
);

export type VersionQueryVariables = Exact<{ [key: string]: never; }>;


export type VersionQuery = (
  { __typename?: 'query_root' }
  & { version: Maybe<(
    { __typename?: 'VersionType' }
    & Pick<VersionType, 'versionAndroid' | 'versionIos'>
  )> }
);

export type GeneratePresignedUrlQueryVariables = Exact<{
  filename: Scalars['String'];
}>;


export type GeneratePresignedUrlQuery = (
  { __typename?: 'query_root' }
  & Pick<Query_Root, 'getPresignedUrl'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'query_root' }
  & { me: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'firstName' | 'secondName' | 'thirdName' | 'dateOfBirth' | 'sex' | 'email' | 'phone' | 'address'>
    & { avatar: Maybe<(
      { __typename?: 'ImageType' }
      & Pick<ImageType, 'id' | 'name' | 'url'>
    )>, location: Maybe<(
      { __typename?: 'GeometryObjectType' }
      & Pick<GeometryObjectType, 'type' | 'coordinates'>
    )>, patientSet: (
      { __typename?: 'PatientTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'PatientTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'PatientType' }
          & Pick<PatientType, 'id' | 'diagnosis' | 'epilepsyType' | 'menstrualCorrelation'>
          & { deviceSet: (
            { __typename?: 'DeviceTypeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'DeviceTypeEdge' }
              & { node: Maybe<(
                { __typename?: 'DeviceType' }
                & Pick<DeviceType, 'id' | 'mac' | 'deviceType'>
                & { settings: Maybe<(
                  { __typename?: 'SettingsType' }
                  & Pick<SettingsType, 'id' | 'name'>
                  & { value: Maybe<(
                    { __typename?: 'SettingsValueType' }
                    & Pick<SettingsValueType, 'batteryReadPeriod' | 'historyBufferSize' | 'reconnectAttemptPeriod' | 'sendDataPeriod' | 'sensorReadPeriod'>
                  )> }
                )> }
              )> }
            )>> }
          ) }
        )> }
      )>> }
    ) }
  )> }
);

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenMutation = (
  { __typename?: 'mutation_root' }
  & { refreshToken: Maybe<(
    { __typename?: 'Refresh' }
    & Pick<Refresh, 'refreshToken' | 'token'>
  )> }
);

export type TokenAuthMutationVariables = Exact<{
  phone: Scalars['String'];
  securityCode: Scalars['String'];
  role: UserRole;
}>;


export type TokenAuthMutation = (
  { __typename?: 'mutation_root' }
  & { tokenAuth: Maybe<(
    { __typename?: 'CreateToken' }
    & Pick<CreateToken, 'token' | 'refreshToken' | 'registered'>
    & { user: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email' | 'phone' | 'firstName' | 'secondName' | 'thirdName' | 'lastName' | 'sex'>
      & { avatar: Maybe<(
        { __typename?: 'ImageType' }
        & Pick<ImageType, 'id' | 'name' | 'url'>
      )>, patientSet: (
        { __typename?: 'PatientTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'PatientTypeEdge' }
          & { node: Maybe<(
            { __typename?: 'PatientType' }
            & Pick<PatientType, 'id'>
            & { deviceSet: (
              { __typename?: 'DeviceTypeConnection' }
              & { edges: Array<Maybe<(
                { __typename?: 'DeviceTypeEdge' }
                & { node: Maybe<(
                  { __typename?: 'DeviceType' }
                  & Pick<DeviceType, 'id' | 'mac' | 'deviceType'>
                  & { settings: Maybe<(
                    { __typename?: 'SettingsType' }
                    & Pick<SettingsType, 'id' | 'name'>
                    & { value: Maybe<(
                      { __typename?: 'SettingsValueType' }
                      & Pick<SettingsValueType, 'batteryReadPeriod' | 'historyBufferSize' | 'reconnectAttemptPeriod' | 'sendDataPeriod' | 'sensorReadPeriod'>
                    )> }
                  )> }
                )> }
              )>> }
            ) }
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type UserRegisterMutationVariables = Exact<{
  email: Scalars['String'];
  phone: Scalars['String'];
}>;


export type UserRegisterMutation = (
  { __typename?: 'mutation_root' }
  & { userRegister: Maybe<(
    { __typename?: 'UserRegister' }
    & { user: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'phone' | 'email' | 'role'>
    )> }
  )> }
);

export type UserDataSetMutationVariables = Exact<{
  input: UserDataInputType;
}>;


export type UserDataSetMutation = (
  { __typename?: 'mutation_root' }
  & { userDataSet: Maybe<(
    { __typename?: 'UserData' }
    & { user: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email' | 'phone' | 'firstName' | 'secondName' | 'thirdName' | 'lastName' | 'sex'>
      & { avatar: Maybe<(
        { __typename?: 'ImageType' }
        & Pick<ImageType, 'id' | 'name' | 'url'>
      )>, location: Maybe<(
        { __typename?: 'GeometryObjectType' }
        & Pick<GeometryObjectType, 'type' | 'coordinates'>
      )>, patientSet: (
        { __typename?: 'PatientTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'PatientTypeEdge' }
          & { node: Maybe<(
            { __typename?: 'PatientType' }
            & Pick<PatientType, 'id'>
          )> }
        )>> }
      ) }
    )>, patient: Maybe<(
      { __typename?: 'PatientType' }
      & Pick<PatientType, 'id'>
    )> }
  )> }
);

export type UploadUserAvatarMutationVariables = Exact<{
  input: UploadUserAvatarType;
}>;


export type UploadUserAvatarMutation = (
  { __typename?: 'mutation_root' }
  & { uploadUserAvatar: Maybe<(
    { __typename?: 'UploadUserAvatar' }
    & { image: Maybe<(
      { __typename?: 'ImageType' }
      & Pick<ImageType, 'id'>
    )> }
  )> }
);

export type RemoveUserAvatarMutationVariables = Exact<{
  input: RemoveUserAvatarType;
}>;


export type RemoveUserAvatarMutation = (
  { __typename?: 'mutation_root' }
  & { removeUserAvatar: Maybe<(
    { __typename?: 'RemoveUserAvatar' }
    & Pick<RemoveUserAvatar, 'success'>
  )> }
);

export type CreateReviewMutationVariables = Exact<{
  input: CreateReviewInputType;
}>;


export type CreateReviewMutation = (
  { __typename?: 'mutation_root' }
  & { createReview: Maybe<(
    { __typename?: 'CreateReview' }
    & { review: Maybe<(
      { __typename?: 'ReviewAppType' }
      & Pick<ReviewAppType, 'id' | 'review' | 'createdAt'>
    )> }
  )> }
);

export type PatientDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientDevicesQuery = (
  { __typename?: 'query_root' }
  & { patientDevices: Maybe<(
    { __typename?: 'DeviceTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'DeviceTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'DeviceType' }
        & Pick<DeviceType, 'id'>
        & { patient: (
          { __typename?: 'PatientType' }
          & Pick<PatientType, 'id'>
        ) }
      )> }
    )>> }
  )> }
);

export type DeviceQueryVariables = Exact<{
  device_id: Scalars['ID'];
}>;


export type DeviceQuery = (
  { __typename?: 'query_root' }
  & { device: Maybe<(
    { __typename?: 'DeviceType' }
    & Pick<DeviceType, 'id' | 'mac'>
    & { settings: Maybe<(
      { __typename?: 'SettingsType' }
      & Pick<SettingsType, 'id' | 'name'>
      & { value: Maybe<(
        { __typename?: 'SettingsValueType' }
        & Pick<SettingsValueType, 'sensorReadPeriod'>
      )> }
    )> }
  )> }
);

export type CreateDeviceMutationVariables = Exact<{
  input: DeviceInputType;
}>;


export type CreateDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { deviceCreate: Maybe<(
    { __typename?: 'DeviceCreate' }
    & { device: Maybe<(
      { __typename?: 'DeviceType' }
      & Pick<DeviceType, 'id' | 'mac' | 'deviceType'>
      & { settings: Maybe<(
        { __typename?: 'SettingsType' }
        & Pick<SettingsType, 'id'>
        & { value: Maybe<(
          { __typename?: 'SettingsValueType' }
          & Pick<SettingsValueType, 'batteryReadPeriod' | 'historyBufferSize' | 'reconnectAttemptPeriod' | 'sendDataPeriod' | 'sensorReadPeriod'>
        )> }
      )> }
    )> }
  )> }
);

export type LinkDeviceMutationVariables = Exact<{
  input: DeviceInputType;
}>;


export type LinkDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { linkDevice: Maybe<(
    { __typename?: 'LinkDevice' }
    & { device: Maybe<(
      { __typename?: 'DeviceType' }
      & Pick<DeviceType, 'id' | 'mac' | 'deviceType'>
      & { settings: Maybe<(
        { __typename?: 'SettingsType' }
        & Pick<SettingsType, 'id'>
        & { value: Maybe<(
          { __typename?: 'SettingsValueType' }
          & Pick<SettingsValueType, 'batteryReadPeriod' | 'historyBufferSize' | 'reconnectAttemptPeriod' | 'sendDataPeriod' | 'sensorReadPeriod'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateDeviceRecordsMutationVariables = Exact<{
  input: DeviceRecordInputType;
}>;


export type CreateDeviceRecordsMutation = (
  { __typename?: 'mutation_root' }
  & { pushDeviceRecord: Maybe<(
    { __typename?: 'PushDeviceRecordMutation' }
    & { record: Maybe<(
      { __typename?: 'RecordType' }
      & Pick<RecordType, 'id'>
    )> }
  )> }
);

export type CreateGcmDeviceMutationVariables = Exact<{
  input: GcmDeviceInputType;
}>;


export type CreateGcmDeviceMutation = (
  { __typename?: 'mutation_root' }
  & { gcmDeviceCreate: Maybe<(
    { __typename?: 'GCMDeviceCreate' }
    & { gcmDevice: Maybe<(
      { __typename?: 'GCMDeviceType' }
      & Pick<GcmDeviceType, 'registrationId' | 'cloudMessageType'>
      & { user: Maybe<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'id'>
      )> }
    )> }
  )> }
);

export type PrescriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PrescriptionsQuery = (
  { __typename?: 'query_root' }
  & { prescriptions: Maybe<(
    { __typename?: 'PrescriptionTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PrescriptionTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'PrescriptionType' }
        & Pick<PrescriptionType, 'id' | 'description'>
        & { author: (
          { __typename?: 'UserType' }
          & Pick<UserType, 'email'>
        ) }
      )> }
    )>> }
  )> }
);

export type PatientPrescriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientPrescriptionsQuery = (
  { __typename?: 'query_root' }
  & { patientPrescriptions: Maybe<(
    { __typename?: 'PrescriptionTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PrescriptionTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'PrescriptionType' }
        & Pick<PrescriptionType, 'id' | 'description'>
        & { author: (
          { __typename?: 'UserType' }
          & Pick<UserType, 'email'>
        ) }
      )> }
    )>> }
  )> }
);

export type PatientDoctorsQueryByPatientIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientDoctorsQueryByPatientIdQuery = (
  { __typename?: 'query_root' }
  & { patientDoctorsByPatientId: Maybe<Array<Maybe<(
    { __typename?: 'DoctorType' }
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    ) }
  )>>> }
);

export type PrescriptionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PrescriptionQuery = (
  { __typename?: 'query_root' }
  & { prescription: Maybe<(
    { __typename?: 'PrescriptionType' }
    & Pick<PrescriptionType, 'id' | 'description' | 'createdAt'>
    & { author: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'email'>
    ), prescriptionmedicineSet: (
      { __typename?: 'PrescriptionMedicineTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'PrescriptionMedicineTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'PrescriptionMedicineType' }
          & Pick<PrescriptionMedicineType, 'id' | 'instructions' | 'startDate' | 'duration' | 'frequencyDay' | 'intervalDay' | 'intakeQuantity' | 'intakeState' | 'createdAt'>
          & { medicine: (
            { __typename?: 'MedicineType' }
            & Pick<MedicineType, 'id' | 'name'>
          ), medicineintaketimeSet: (
            { __typename?: 'MedicineIntakeTimeTypeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'MedicineIntakeTimeTypeEdge' }
              & { node: Maybe<(
                { __typename?: 'MedicineIntakeTimeType' }
                & Pick<MedicineIntakeTimeType, 'intakeTime'>
              )> }
            )>> }
          ) }
        )> }
      )>> }
    ) }
  )> }
);

export type PrescriptionMedicineQueryVariables = Exact<{
  prescription_medicine_id: Scalars['ID'];
}>;


export type PrescriptionMedicineQuery = (
  { __typename?: 'query_root' }
  & { prescriptionMedicine: Maybe<(
    { __typename?: 'PrescriptionMedicineType' }
    & Pick<PrescriptionMedicineType, 'id' | 'instructions' | 'startDate' | 'duration' | 'frequencyDay' | 'intervalDay' | 'intakeQuantity' | 'intakeState' | 'createdAt'>
    & { medicine: (
      { __typename?: 'MedicineType' }
      & Pick<MedicineType, 'id' | 'name'>
    ), medicineintaketimeSet: (
      { __typename?: 'MedicineIntakeTimeTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'MedicineIntakeTimeTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'MedicineIntakeTimeType' }
          & Pick<MedicineIntakeTimeType, 'intakeTime'>
        )> }
      )>> }
    ) }
  )> }
);

export type PrescriptionWithMedicinesQueryVariables = Exact<{
  prescription_id: Scalars['ID'];
}>;


export type PrescriptionWithMedicinesQuery = (
  { __typename?: 'query_root' }
  & { prescription: Maybe<(
    { __typename?: 'PrescriptionType' }
    & Pick<PrescriptionType, 'id' | 'description' | 'createdAt'>
    & { author: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'email'>
    ), prescriptionmedicineSet: (
      { __typename?: 'PrescriptionMedicineTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'PrescriptionMedicineTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'PrescriptionMedicineType' }
          & Pick<PrescriptionMedicineType, 'id' | 'instructions' | 'startDate' | 'duration' | 'frequencyDay' | 'intervalDay' | 'intakeQuantity' | 'intakeState' | 'createdAt'>
          & { medicine: (
            { __typename?: 'MedicineType' }
            & Pick<MedicineType, 'id' | 'name'>
          ), medicineintaketimeSet: (
            { __typename?: 'MedicineIntakeTimeTypeConnection' }
            & { edges: Array<Maybe<(
              { __typename?: 'MedicineIntakeTimeTypeEdge' }
              & { node: Maybe<(
                { __typename?: 'MedicineIntakeTimeType' }
                & Pick<MedicineIntakeTimeType, 'intakeTime'>
              )> }
            )>> }
          ) }
        )> }
      )>> }
    ) }
  )>, medicines: Maybe<(
    { __typename?: 'MedicineTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'MedicineTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name'>
      )> }
    )>> }
  )> }
);

export type MedicinesQueryVariables = Exact<{ [key: string]: never; }>;


export type MedicinesQuery = (
  { __typename?: 'query_root' }
  & { medicines: Maybe<(
    { __typename?: 'MedicineTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'MedicineTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name' | 'brand' | 'description' | 'cas'>
      )> }
    )>> }
  )> }
);

export type MedicinesWithPatientMedicinesQueryVariables = Exact<{ [key: string]: never; }>;


export type MedicinesWithPatientMedicinesQuery = (
  { __typename?: 'query_root' }
  & { medicines: Maybe<(
    { __typename?: 'MedicineTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'MedicineTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name' | 'brand' | 'description' | 'cas'>
      )> }
    )>> }
  )>, patientMedicines: Maybe<(
    { __typename?: 'PatientMedicineTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PatientMedicineTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'PatientMedicineType' }
        & Pick<PatientMedicineType, 'id'>
        & { medicine: (
          { __typename?: 'MedicineType' }
          & Pick<MedicineType, 'id'>
        ) }
      )> }
    )>> }
  )> }
);

export type MedicineQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MedicineQuery = (
  { __typename?: 'query_root' }
  & { medicine: Maybe<(
    { __typename?: 'MedicineType' }
    & Pick<MedicineType, 'id' | 'name' | 'brand' | 'description' | 'cas'>
  )> }
);

export type PatientMedicinesQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientMedicinesQuery = (
  { __typename?: 'query_root' }
  & { patientMedicines: Maybe<(
    { __typename?: 'PatientMedicineTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PatientMedicineTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'PatientMedicineType' }
        & Pick<PatientMedicineType, 'id' | 'quantity' | 'purchaseDate'>
        & { medicine: (
          { __typename?: 'MedicineType' }
          & Pick<MedicineType, 'id' | 'name'>
        ) }
      )> }
    )>> }
  )> }
);

export type PatientMedicineQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientMedicineQuery = (
  { __typename?: 'query_root' }
  & { patientMedicine: Maybe<(
    { __typename?: 'PatientMedicineType' }
    & Pick<PatientMedicineType, 'id' | 'quantity' | 'purchaseDate'>
    & { medicine: (
      { __typename?: 'MedicineType' }
      & Pick<MedicineType, 'id' | 'name'>
    ) }
  )> }
);

export type PatientMedicineWithMedicinesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientMedicineWithMedicinesQuery = (
  { __typename?: 'query_root' }
  & { medicines: Maybe<(
    { __typename?: 'MedicineTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'MedicineTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name' | 'brand' | 'description' | 'cas'>
      )> }
    )>> }
  )>, patientMedicine: Maybe<(
    { __typename?: 'PatientMedicineType' }
    & Pick<PatientMedicineType, 'id' | 'quantity' | 'purchaseDate'>
    & { medicine: (
      { __typename?: 'MedicineType' }
      & Pick<MedicineType, 'id' | 'name'>
    ) }
  )> }
);

export type CreatePatientMedicineMutationVariables = Exact<{
  input: CreatePatientMedicineInputType;
}>;


export type CreatePatientMedicineMutation = (
  { __typename?: 'mutation_root' }
  & { createPatientMedicine: Maybe<(
    { __typename?: 'CreatePatientMedicine' }
    & { patientMedicine: Maybe<(
      { __typename?: 'PatientMedicineType' }
      & Pick<PatientMedicineType, 'id' | 'quantity' | 'purchaseDate'>
      & { medicine: (
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id'>
      ) }
    )> }
  )> }
);

export type UpdatePatientMedicineMutationVariables = Exact<{
  input: UpdatePatientMedicineInputType;
}>;


export type UpdatePatientMedicineMutation = (
  { __typename?: 'mutation_root' }
  & { updatePatientMedicine: Maybe<(
    { __typename?: 'UpdatePatientMedicine' }
    & { patientMedicine: Maybe<(
      { __typename?: 'PatientMedicineType' }
      & Pick<PatientMedicineType, 'id' | 'quantity' | 'purchaseDate'>
      & { medicine: (
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id'>
      ) }
    )> }
  )> }
);

export type RemovePatientMedicineMutationVariables = Exact<{
  input: RemovePatientMedicineInputType;
}>;


export type RemovePatientMedicineMutation = (
  { __typename?: 'mutation_root' }
  & { removePatientMedicine: Maybe<(
    { __typename?: 'RemovePatientMedicine' }
    & Pick<RemovePatientMedicine, 'success'>
  )> }
);

export type CreatePrescriptionMutationVariables = Exact<{
  input: CreatePrescriptionInputType;
}>;


export type CreatePrescriptionMutation = (
  { __typename?: 'mutation_root' }
  & { createPrescription: Maybe<(
    { __typename?: 'CreatePrescription' }
    & { prescription: Maybe<(
      { __typename?: 'PrescriptionType' }
      & Pick<PrescriptionType, 'id' | 'description' | 'createdAt'>
    )> }
  )> }
);

export type UpdatePrescriptionMutationVariables = Exact<{
  input: UpdatePrescriptionInputType;
}>;


export type UpdatePrescriptionMutation = (
  { __typename?: 'mutation_root' }
  & { updatePrescription: Maybe<(
    { __typename?: 'UpdatePrescription' }
    & { prescription: Maybe<(
      { __typename?: 'PrescriptionType' }
      & Pick<PrescriptionType, 'id' | 'description' | 'createdAt'>
    )> }
  )> }
);

export type RemovePrescriptionMutationVariables = Exact<{
  input: RemovePrescriptionInputType;
}>;


export type RemovePrescriptionMutation = (
  { __typename?: 'mutation_root' }
  & { removePrescription: Maybe<(
    { __typename?: 'RemovePrescription' }
    & Pick<RemovePrescription, 'success'>
  )> }
);

export type CreatePrescriptionMedicineMutationVariables = Exact<{
  input: CreatePrescriptionMedicineInputType;
}>;


export type CreatePrescriptionMedicineMutation = (
  { __typename?: 'mutation_root' }
  & { createPrescriptionMedicine: Maybe<(
    { __typename?: 'CreatePrescriptionMedicine' }
    & { prescriptionMedicine: Maybe<(
      { __typename?: 'PrescriptionMedicineType' }
      & Pick<PrescriptionMedicineType, 'id' | 'instructions' | 'startDate' | 'duration' | 'frequencyDay' | 'intervalDay' | 'intakeQuantity' | 'intakeState'>
      & { prescription: (
        { __typename?: 'PrescriptionType' }
        & Pick<PrescriptionType, 'id' | 'description'>
      ), medicine: (
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name'>
      ), medicineintaketimeSet: (
        { __typename?: 'MedicineIntakeTimeTypeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'MedicineIntakeTimeTypeEdge' }
          & { node: Maybe<(
            { __typename?: 'MedicineIntakeTimeType' }
            & Pick<MedicineIntakeTimeType, 'id' | 'intakeTime'>
            & { prescriptionMedicine: (
              { __typename?: 'PrescriptionMedicineType' }
              & Pick<PrescriptionMedicineType, 'id'>
            ) }
          )> }
        )>> }
      ) }
    )> }
  )> }
);

export type UpdatePrescriptionMedicineMutationVariables = Exact<{
  input: UpdatePrescriptionMedicineInputType;
}>;


export type UpdatePrescriptionMedicineMutation = (
  { __typename?: 'mutation_root' }
  & { updatePrescriptionMedicine: Maybe<(
    { __typename?: 'UpdatePrescriptionMedicine' }
    & { prescriptionMedicine: Maybe<(
      { __typename?: 'PrescriptionMedicineType' }
      & Pick<PrescriptionMedicineType, 'id' | 'instructions' | 'startDate' | 'duration' | 'frequencyDay' | 'intervalDay' | 'intakeQuantity' | 'intakeState'>
      & { prescription: (
        { __typename?: 'PrescriptionType' }
        & Pick<PrescriptionType, 'id' | 'description'>
      ), medicine: (
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name'>
      ) }
    )>, medicineIntakeTimes: Maybe<Array<Maybe<(
      { __typename?: 'MedicineIntakeTimeType' }
      & Pick<MedicineIntakeTimeType, 'id' | 'intakeTime'>
      & { prescriptionMedicine: (
        { __typename?: 'PrescriptionMedicineType' }
        & Pick<PrescriptionMedicineType, 'id'>
      ) }
    )>>> }
  )> }
);

export type RemovePrescriptionMedicineMutationVariables = Exact<{
  input: RemovePrescriptionMedicineInputType;
}>;


export type RemovePrescriptionMedicineMutation = (
  { __typename?: 'mutation_root' }
  & { removePrescriptionMedicine: Maybe<(
    { __typename?: 'RemovePrescriptionMedicine' }
    & Pick<RemovePrescriptionMedicine, 'success'>
  )> }
);

export type PatientMedicinesIntakesQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type PatientMedicinesIntakesQuery = (
  { __typename?: 'query_root' }
  & { patientMedicinesIntakes: Maybe<(
    { __typename?: 'GraphMedicineIntakeType' }
    & { medicines: Maybe<Array<Maybe<(
      { __typename?: 'GraphPrescriptionMedicineWithMedicineIntakeType' }
      & { prescriptionMedicine: Maybe<(
        { __typename?: 'PrescriptionMedicineType' }
        & Pick<PrescriptionMedicineType, 'id' | 'intakeState' | 'intakeQuantity'>
        & { medicine: (
          { __typename?: 'MedicineType' }
          & Pick<MedicineType, 'id' | 'name'>
        ), medicineintakeSet: (
          { __typename?: 'MedicineIntakeTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'MedicineIntakeTypeEdge' }
            & { node: Maybe<(
              { __typename?: 'MedicineIntakeType' }
              & Pick<MedicineIntakeType, 'id' | 'intakeDate'>
            )> }
          )>> }
        ) }
      )>, medicineIntakes: Maybe<Array<Maybe<(
        { __typename?: 'MedicineIntakeType' }
        & Pick<MedicineIntakeType, 'id'>
      )>>> }
    )>>>, patientMedicines: Maybe<Array<Maybe<(
      { __typename?: 'PatientMedicineType' }
      & Pick<PatientMedicineType, 'id' | 'quantity'>
      & { medicine: (
        { __typename?: 'MedicineType' }
        & Pick<MedicineType, 'id' | 'name'>
      ) }
    )>>> }
  )> }
);

export type CreateMedicineIntakeMutationVariables = Exact<{
  input: CreateMedicineIntakeInputType;
}>;


export type CreateMedicineIntakeMutation = (
  { __typename?: 'mutation_root' }
  & { createMedicineIntake: Maybe<(
    { __typename?: 'CreateMedicineIntake' }
    & { medicineIntake: Maybe<(
      { __typename?: 'MedicineIntakeType' }
      & Pick<MedicineIntakeType, 'id'>
    )> }
  )> }
);

export type RemoveMedicineIntakeMutationVariables = Exact<{
  input: RemoveMedicineIntakeInputType;
}>;


export type RemoveMedicineIntakeMutation = (
  { __typename?: 'mutation_root' }
  & { removeMedicineIntake: Maybe<(
    { __typename?: 'RemoveMedicineIntake' }
    & Pick<RemoveMedicineIntake, 'success'>
  )> }
);

export type PatientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientQuery = (
  { __typename?: 'query_root' }
  & { patient: Maybe<(
    { __typename?: 'PatientType' }
    & Pick<PatientType, 'id' | 'diagnosis'>
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'id'>
    ), doctorpatientSet: (
      { __typename?: 'DoctorPatientTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DoctorPatientTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'DoctorPatientType' }
          & Pick<DoctorPatientType, 'id'>
          & { doctor: (
            { __typename?: 'DoctorType' }
            & Pick<DoctorType, 'id'>
            & { user: (
              { __typename?: 'UserType' }
              & Pick<UserType, 'id' | 'firstName' | 'lastName'>
            ) }
          ) }
        )> }
      )>> }
    ) }
  )> }
);

export type PatientsQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientsQuery = (
  { __typename?: 'query_root' }
  & { patients: Maybe<(
    { __typename?: 'PatientTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PatientTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'PatientType' }
        & Pick<PatientType, 'id'>
        & { user: (
          { __typename?: 'UserType' }
          & Pick<UserType, 'lastName' | 'firstName'>
        ) }
      )> }
    )>> }
  )> }
);

export type PatientFileQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PatientFileQuery = (
  { __typename?: 'query_root' }
  & { patientFile: Maybe<(
    { __typename?: 'PatientFileType' }
    & Pick<PatientFileType, 'id'>
    & { file: Maybe<(
      { __typename?: 'FileType' }
      & Pick<FileType, 'url' | 'title'>
    )> }
  )> }
);

export type PatientFilesQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientFilesQuery = (
  { __typename?: 'query_root' }
  & { patientFiles: Maybe<(
    { __typename?: 'PatientFileTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PatientFileTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'PatientFileType' }
        & Pick<PatientFileType, 'id'>
        & { file: Maybe<(
          { __typename?: 'FileType' }
          & Pick<FileType, 'url' | 'title'>
        )> }
      )> }
    )>> }
  )> }
);

export type PatientFileCreateMutationVariables = Exact<{
  input: PatientFileInputType;
}>;


export type PatientFileCreateMutation = (
  { __typename?: 'mutation_root' }
  & { patientFileCreate: Maybe<(
    { __typename?: 'PatientFileCreate' }
    & { patientFile: Maybe<(
      { __typename?: 'PatientFileType' }
      & Pick<PatientFileType, 'id'>
      & { file: Maybe<(
        { __typename?: 'FileType' }
        & Pick<FileType, 'url' | 'title'>
      )> }
    )> }
  )> }
);

export type PatientFileUpdateMutationVariables = Exact<{
  input: PatientFileUpdateInputType;
}>;


export type PatientFileUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { patientFileUpdate: Maybe<(
    { __typename?: 'PatientFileUpdate' }
    & { patientFile: Maybe<(
      { __typename?: 'PatientFileType' }
      & Pick<PatientFileType, 'id'>
      & { file: Maybe<(
        { __typename?: 'FileType' }
        & Pick<FileType, 'url' | 'title'>
      )> }
    )> }
  )> }
);

export type RemovePatientFileMutationVariables = Exact<{
  input: PatientFileRemoveInputType;
}>;


export type RemovePatientFileMutation = (
  { __typename?: 'mutation_root' }
  & { patientFileDelete: Maybe<(
    { __typename?: 'PatientFileDelete' }
    & Pick<PatientFileDelete, 'success'>
  )> }
);

export type SetPatientLocationMutationVariables = Exact<{
  input: PatientLocationInputType;
}>;


export type SetPatientLocationMutation = (
  { __typename?: 'mutation_root' }
  & { setPatientLocation: Maybe<(
    { __typename?: 'SetPatientLocation' }
    & { patientLocation: Maybe<(
      { __typename?: 'PatientLocationType' }
      & Pick<PatientLocationType, 'id'>
      & { location: Maybe<(
        { __typename?: 'GeometryObjectType' }
        & Pick<GeometryObjectType, 'type' | 'coordinates'>
      )>, patient: (
        { __typename?: 'PatientType' }
        & Pick<PatientType, 'id'>
      ) }
    )> }
  )> }
);

export type WomenPeriodCreateMutationVariables = Exact<{
  input: WomenPeriodInputType;
}>;


export type WomenPeriodCreateMutation = (
  { __typename?: 'mutation_root' }
  & { womenPeriodCreate: Maybe<(
    { __typename?: 'WomenPeriodCreate' }
    & { womenPeriod: Maybe<(
      { __typename?: 'WomenPeriodType' }
      & Pick<WomenPeriodType, 'id' | 'startDate' | 'endDate'>
      & { patient: (
        { __typename?: 'PatientType' }
        & Pick<PatientType, 'id'>
      ) }
    )> }
  )> }
);

export type WomenPeriodUpdateMutationVariables = Exact<{
  input: WomenPeriodUpdateInputType;
}>;


export type WomenPeriodUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { womenPeriodUpdate: Maybe<(
    { __typename?: 'WomenPeriodUpdate' }
    & { womenPeriod: Maybe<(
      { __typename?: 'WomenPeriodType' }
      & Pick<WomenPeriodType, 'id' | 'startDate' | 'endDate'>
      & { patient: (
        { __typename?: 'PatientType' }
        & Pick<PatientType, 'id'>
      ) }
    )> }
  )> }
);

export type RemoveWomenPeriodMutationVariables = Exact<{
  input: WomenPeriodRemoveInputType;
}>;


export type RemoveWomenPeriodMutation = (
  { __typename?: 'mutation_root' }
  & { womenPeriodDelete: Maybe<(
    { __typename?: 'WomenPeriodDelete' }
    & Pick<WomenPeriodDelete, 'success'>
  )> }
);

export type WomenPeriodsQueryVariables = Exact<{ [key: string]: never; }>;


export type WomenPeriodsQuery = (
  { __typename?: 'query_root' }
  & { womenPeriods: Maybe<(
    { __typename?: 'WomenPeriodTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'WomenPeriodTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'WomenPeriodType' }
        & Pick<WomenPeriodType, 'id' | 'startDate' | 'endDate'>
        & { patient: (
          { __typename?: 'PatientType' }
          & Pick<PatientType, 'id'>
        ) }
      )> }
    )>> }
  )> }
);

export type WomenPeriodQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WomenPeriodQuery = (
  { __typename?: 'query_root' }
  & { womenPeriod: Maybe<(
    { __typename?: 'WomenPeriodType' }
    & Pick<WomenPeriodType, 'id' | 'startDate' | 'endDate'>
    & { patient: (
      { __typename?: 'PatientType' }
      & Pick<PatientType, 'id'>
    ) }
  )> }
);

export type ReviewsClinicsQueryVariables = Exact<{
  sort: Maybe<Scalars['String']>;
  search: Maybe<Scalars['String']>;
  filter: Maybe<ClinicFilterInput>;
}>;


export type ReviewsClinicsQuery = (
  { __typename?: 'query_root' }
  & { reviewsClinics: Maybe<(
    { __typename?: 'ReviewClinicTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ReviewClinicTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'ReviewClinicType' }
        & Pick<ReviewClinicType, 'id' | 'name' | 'discription' | 'workTime' | 'phone' | 'address' | 'averageStars'>
        & { avatar: Maybe<(
          { __typename?: 'ImageType' }
          & Pick<ImageType, 'name' | 'url'>
        )>, location: Maybe<(
          { __typename?: 'GeometryObjectType' }
          & Pick<GeometryObjectType, 'coordinates' | 'type'>
        )>, reviews: Maybe<Array<Maybe<(
          { __typename?: 'ReviewType' }
          & Pick<ReviewType, 'id' | 'averageEntryStars' | 'comment' | 'objectId' | 'createdAt'>
          & { author: (
            { __typename?: 'UserType' }
            & Pick<UserType, 'firstName' | 'secondName'>
          ) }
        )>>> }
      )> }
    )>> }
  )> }
);

export type ReviewClinicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewClinicQuery = (
  { __typename?: 'query_root' }
  & { reviewsClinic: Maybe<(
    { __typename?: 'ReviewClinicType' }
    & Pick<ReviewClinicType, 'id' | 'name' | 'discription' | 'workTime' | 'phone' | 'address' | 'averageStars'>
    & { avatar: Maybe<(
      { __typename?: 'ImageType' }
      & Pick<ImageType, 'name' | 'url'>
    )>, location: Maybe<(
      { __typename?: 'GeometryObjectType' }
      & Pick<GeometryObjectType, 'coordinates' | 'type'>
    )>, doctorclinicSet: (
      { __typename?: 'DoctorClinicTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DoctorClinicTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'DoctorClinicType' }
          & { doctor: (
            { __typename?: 'ReviewDoctorType' }
            & Pick<ReviewDoctorType, 'id' | 'firstName' | 'secondName' | 'specialization' | 'workTime' | 'averageStars'>
            & { avatar: Maybe<(
              { __typename?: 'ImageType' }
              & Pick<ImageType, 'name' | 'url'>
            )>, reviews: Maybe<Array<Maybe<(
              { __typename?: 'ReviewType' }
              & Pick<ReviewType, 'stars'>
            )>>> }
          ) }
        )> }
      )>> }
    ), reviews: Maybe<Array<Maybe<(
      { __typename?: 'ReviewType' }
      & Pick<ReviewType, 'id' | 'averageEntryStars' | 'comment' | 'objectId' | 'createdAt'>
      & { author: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'secondName'>
      ) }
    )>>> }
  )> }
);

export type ReviewDoctorQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewDoctorQuery = (
  { __typename?: 'query_root' }
  & { reviewsDoctor: Maybe<(
    { __typename?: 'ReviewDoctorType' }
    & Pick<ReviewDoctorType, 'id' | 'firstName' | 'secondName' | 'thirdName' | 'specialization' | 'workTime' | 'phone' | 'address' | 'averageStars'>
    & { avatar: Maybe<(
      { __typename?: 'ImageType' }
      & Pick<ImageType, 'name' | 'url'>
    )>, location: Maybe<(
      { __typename?: 'GeometryObjectType' }
      & Pick<GeometryObjectType, 'coordinates' | 'type'>
    )>, doctorclinicSet: (
      { __typename?: 'DoctorClinicTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'DoctorClinicTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'DoctorClinicType' }
          & { clinic: (
            { __typename?: 'ReviewClinicType' }
            & Pick<ReviewClinicType, 'id' | 'name' | 'discription' | 'workTime' | 'phone' | 'address'>
            & { avatar: Maybe<(
              { __typename?: 'ImageType' }
              & Pick<ImageType, 'name' | 'url'>
            )>, reviews: Maybe<Array<Maybe<(
              { __typename?: 'ReviewType' }
              & Pick<ReviewType, 'stars'>
            )>>>, location: Maybe<(
              { __typename?: 'GeometryObjectType' }
              & Pick<GeometryObjectType, 'coordinates' | 'type'>
            )> }
          ) }
        )> }
      )>> }
    ), reviews: Maybe<Array<Maybe<(
      { __typename?: 'ReviewType' }
      & Pick<ReviewType, 'id' | 'averageEntryStars' | 'comment' | 'objectId' | 'createdAt'>
      & { author: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'secondName'>
      ) }
    )>>> }
  )> }
);

export type ReviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReviewQuery = (
  { __typename?: 'query_root' }
  & { review: Maybe<(
    { __typename?: 'ReviewType' }
    & Pick<ReviewType, 'id' | 'averageEntryStars' | 'comment'>
    & { author: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'firstName' | 'secondName'>
    ), reviewentrySet: (
      { __typename?: 'ReviewEntryTypeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'ReviewEntryTypeEdge' }
        & { node: Maybe<(
          { __typename?: 'ReviewEntryType' }
          & Pick<ReviewEntryType, 'id' | 'stars' | 'comment'>
          & { characteristic: (
            { __typename?: 'ReviewCharacteristicType' }
            & Pick<ReviewCharacteristicType, 'name'>
          ) }
        )> }
      )>> }
    ) }
  )> }
);

export type ClinicReviewsGroupByCharacteristicQueryVariables = Exact<{
  clinicId: Scalars['ID'];
}>;


export type ClinicReviewsGroupByCharacteristicQuery = (
  { __typename?: 'query_root' }
  & { clinicReviewsGroupByCharacteristic: Maybe<Array<Maybe<(
    { __typename?: 'ClinicReviewsGroupByCharacteristicType' }
    & Pick<ClinicReviewsGroupByCharacteristicType, 'characteristic' | 'averageStars'>
  )>>> }
);

export type ClinicReviewsQueryVariables = Exact<{
  id: Scalars['ID'];
  sort: Maybe<Scalars['String']>;
}>;


export type ClinicReviewsQuery = (
  { __typename?: 'query_root' }
  & { clinicReviews: Maybe<(
    { __typename?: 'ReviewTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'ReviewTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'ReviewType' }
        & Pick<ReviewType, 'id' | 'stars' | 'comment' | 'createdAt' | 'avgEntryStars'>
        & { author: (
          { __typename?: 'UserType' }
          & Pick<UserType, 'firstName' | 'secondName'>
        ), reviewentrySet: (
          { __typename?: 'ReviewEntryTypeConnection' }
          & { edges: Array<Maybe<(
            { __typename?: 'ReviewEntryTypeEdge' }
            & { node: Maybe<(
              { __typename?: 'ReviewEntryType' }
              & Pick<ReviewEntryType, 'id' | 'stars' | 'comment'>
              & { characteristic: (
                { __typename?: 'ReviewCharacteristicType' }
                & Pick<ReviewCharacteristicType, 'name'>
              ) }
            )> }
          )>> }
        ) }
      )> }
    )>> }
  )> }
);

export type SeizuresQueryVariables = Exact<{ [key: string]: never; }>;


export type SeizuresQuery = (
  { __typename?: 'query_root' }
  & { seizures: Maybe<(
    { __typename?: 'SeizureTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SeizureTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'SeizureType' }
        & Pick<SeizureType, 'id' | 'date' | 'duration' | 'intensity' | 'trigger' | 'status' | 'createdAt' | 'updatedAt'>
        & { patient: (
          { __typename?: 'PatientType' }
          & Pick<PatientType, 'id'>
        ) }
      )> }
    )>> }
  )> }
);

export type SeizureQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SeizureQuery = (
  { __typename?: 'query_root' }
  & { seizure: Maybe<(
    { __typename?: 'SeizureType' }
    & Pick<SeizureType, 'id' | 'date' | 'duration' | 'intensity' | 'trigger' | 'status'>
    & { location: (
      { __typename?: 'GeometryObjectType' }
      & Pick<GeometryObjectType, 'type' | 'coordinates'>
    ) }
  )> }
);

export type SeizureCreateMutationVariables = Exact<{
  input: SeizureInputType;
}>;


export type SeizureCreateMutation = (
  { __typename?: 'mutation_root' }
  & { seizureCreate: Maybe<(
    { __typename?: 'SeizureCreate' }
    & { seizure: Maybe<(
      { __typename?: 'SeizureType' }
      & Pick<SeizureType, 'id' | 'trigger' | 'status' | 'intensity' | 'date' | 'duration'>
      & { location: (
        { __typename?: 'GeometryObjectType' }
        & Pick<GeometryObjectType, 'type' | 'coordinates'>
      ), patient: (
        { __typename?: 'PatientType' }
        & Pick<PatientType, 'id'>
      ) }
    )> }
  )> }
);

export type SeizureUpdateMutationVariables = Exact<{
  input: SeizureUpdateInputType;
}>;


export type SeizureUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { seizureUpdate: Maybe<(
    { __typename?: 'SeizureUpdate' }
    & { seizure: Maybe<(
      { __typename?: 'SeizureType' }
      & Pick<SeizureType, 'id' | 'trigger' | 'status' | 'intensity' | 'date' | 'duration'>
      & { location: (
        { __typename?: 'GeometryObjectType' }
        & Pick<GeometryObjectType, 'type' | 'coordinates'>
      ), patient: (
        { __typename?: 'PatientType' }
        & Pick<PatientType, 'id'>
      ) }
    )> }
  )> }
);

export type RemoveSeizureMutationVariables = Exact<{
  input: SeizureRemoveInputType;
}>;


export type RemoveSeizureMutation = (
  { __typename?: 'mutation_root' }
  & { seizureDelete: Maybe<(
    { __typename?: 'SeizureDelete' }
    & Pick<SeizureDelete, 'success'>
  )> }
);

export type PatientSeizuresQueryVariables = Exact<{ [key: string]: never; }>;


export type PatientSeizuresQuery = (
  { __typename?: 'query_root' }
  & { patientSeizures: Maybe<(
    { __typename?: 'SeizureTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SeizureTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'SeizureType' }
        & Pick<SeizureType, 'id' | 'date' | 'duration' | 'intensity' | 'trigger'>
      )> }
    )>> }
  )> }
);

export type SosContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type SosContactsQuery = (
  { __typename?: 'query_root' }
  & { sosContacts: Maybe<(
    { __typename?: 'SOSContactTypeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'SOSContactTypeEdge' }
      & { node: Maybe<(
        { __typename?: 'SOSContactType' }
        & Pick<SosContactType, 'id' | 'firstName' | 'secondName' | 'email' | 'phone'>
      )> }
    )>> }
  )> }
);

export type SosContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SosContactQuery = (
  { __typename?: 'query_root' }
  & { sosContact: Maybe<(
    { __typename?: 'SOSContactType' }
    & Pick<SosContactType, 'id' | 'firstName' | 'secondName' | 'email' | 'phone'>
  )> }
);

export type SosContactCreateMutationVariables = Exact<{
  input: SosContactInputType;
}>;


export type SosContactCreateMutation = (
  { __typename?: 'mutation_root' }
  & { soscontactCreate: Maybe<(
    { __typename?: 'SOSContactCreate' }
    & { soscontact: Maybe<(
      { __typename?: 'SOSContactType' }
      & Pick<SosContactType, 'id' | 'firstName' | 'secondName' | 'email' | 'phone'>
    )> }
  )> }
);

export type SosContactUpdateMutationVariables = Exact<{
  input: SosContactUpdateInputType;
}>;


export type SosContactUpdateMutation = (
  { __typename?: 'mutation_root' }
  & { soscontactUpdate: Maybe<(
    { __typename?: 'SOSContactUpdate' }
    & { soscontact: Maybe<(
      { __typename?: 'SOSContactType' }
      & Pick<SosContactType, 'id' | 'firstName' | 'secondName' | 'email' | 'phone'>
    )> }
  )> }
);

export type RemoveSosContactMutationVariables = Exact<{
  input: SosContactRemoveInputType;
}>;


export type RemoveSosContactMutation = (
  { __typename?: 'mutation_root' }
  & { soscontactDelete: Maybe<(
    { __typename?: 'SOSContactDelete' }
    & Pick<SosContactDelete, 'success'>
  )> }
);


export const UsersDocument = gql`
    query Users {
  users {
    edges {
      node {
        id
        email
        phone
        createdAt
      }
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export const VersionDocument = gql`
    query Version {
  version {
    versionAndroid
    versionIos
  }
}
    `;

/**
 * __useVersionQuery__
 *
 * To run a query within a React component, call `useVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VersionQuery, VersionQueryVariables>) {
        return ApolloReactHooks.useQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
      }
export function useVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VersionQuery, VersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VersionQuery, VersionQueryVariables>(VersionDocument, baseOptions);
        }
export type VersionQueryHookResult = ReturnType<typeof useVersionQuery>;
export type VersionLazyQueryHookResult = ReturnType<typeof useVersionLazyQuery>;
export const GeneratePresignedUrlDocument = gql`
    query GeneratePresignedUrl($filename: String!) {
  getPresignedUrl(filename: $filename)
}
    `;

/**
 * __useGeneratePresignedUrlQuery__
 *
 * To run a query within a React component, call `useGeneratePresignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeneratePresignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeneratePresignedUrlQuery({
 *   variables: {
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGeneratePresignedUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GeneratePresignedUrlQuery, GeneratePresignedUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<GeneratePresignedUrlQuery, GeneratePresignedUrlQueryVariables>(GeneratePresignedUrlDocument, baseOptions);
      }
export function useGeneratePresignedUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GeneratePresignedUrlQuery, GeneratePresignedUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GeneratePresignedUrlQuery, GeneratePresignedUrlQueryVariables>(GeneratePresignedUrlDocument, baseOptions);
        }
export type GeneratePresignedUrlQueryHookResult = ReturnType<typeof useGeneratePresignedUrlQuery>;
export type GeneratePresignedUrlLazyQueryHookResult = ReturnType<typeof useGeneratePresignedUrlLazyQuery>;
export const MeDocument = gql`
    query Me {
  me {
    id
    firstName
    secondName
    thirdName
    dateOfBirth
    sex
    email
    phone
    address
    avatar {
      id
      name
      url
    }
    location {
      type
      coordinates
    }
    patientSet {
      edges {
        node {
          id
          diagnosis
          epilepsyType
          menstrualCorrelation
          deviceSet {
            edges {
              node {
                id
                mac
                deviceType
                settings {
                  id
                  name
                  value {
                    batteryReadPeriod
                    historyBufferSize
                    reconnectAttemptPeriod
                    sendDataPeriod
                    sensorReadPeriod
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return ApolloReactHooks.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    refreshToken
    token
  }
}
    `;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, baseOptions);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const TokenAuthDocument = gql`
    mutation TokenAuth($phone: String!, $securityCode: String!, $role: UserRole!) {
  tokenAuth(input: {phone: $phone, securityCode: $securityCode, role: $role}) {
    token
    refreshToken
    user {
      id
      email
      phone
      firstName
      secondName
      thirdName
      lastName
      avatar {
        id
        name
        url
      }
      sex
      patientSet {
        edges {
          node {
            id
            deviceSet {
              edges {
                node {
                  id
                  mac
                  deviceType
                  settings {
                    id
                    name
                    value {
                      batteryReadPeriod
                      historyBufferSize
                      reconnectAttemptPeriod
                      sendDataPeriod
                      sensorReadPeriod
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    registered
  }
}
    `;

/**
 * __useTokenAuthMutation__
 *
 * To run a mutation, you first call `useTokenAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTokenAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tokenAuthMutation, { data, loading, error }] = useTokenAuthMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      securityCode: // value for 'securityCode'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useTokenAuthMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TokenAuthMutation, TokenAuthMutationVariables>) {
        return ApolloReactHooks.useMutation<TokenAuthMutation, TokenAuthMutationVariables>(TokenAuthDocument, baseOptions);
      }
export type TokenAuthMutationHookResult = ReturnType<typeof useTokenAuthMutation>;
export type TokenAuthMutationOptions = ApolloReactCommon.BaseMutationOptions<TokenAuthMutation, TokenAuthMutationVariables>;
export const UserRegisterDocument = gql`
    mutation UserRegister($email: String!, $phone: String!) {
  userRegister(input: {email: $email, phone: $phone}) {
    user {
      phone
      email
      role
    }
  }
}
    `;

/**
 * __useUserRegisterMutation__
 *
 * To run a mutation, you first call `useUserRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRegisterMutation, { data, loading, error }] = useUserRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUserRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserRegisterMutation, UserRegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<UserRegisterMutation, UserRegisterMutationVariables>(UserRegisterDocument, baseOptions);
      }
export type UserRegisterMutationHookResult = ReturnType<typeof useUserRegisterMutation>;
export type UserRegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<UserRegisterMutation, UserRegisterMutationVariables>;
export const UserDataSetDocument = gql`
    mutation UserDataSet($input: UserDataInputType!) {
  userDataSet(input: $input) {
    user {
      id
      email
      phone
      firstName
      secondName
      thirdName
      lastName
      sex
      avatar {
        id
        name
        url
      }
      location {
        type
        coordinates
      }
      patientSet {
        edges {
          node {
            id
          }
        }
      }
    }
    patient {
      id
    }
  }
}
    `;

/**
 * __useUserDataSetMutation__
 *
 * To run a mutation, you first call `useUserDataSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDataSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDataSetMutation, { data, loading, error }] = useUserDataSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserDataSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserDataSetMutation, UserDataSetMutationVariables>) {
        return ApolloReactHooks.useMutation<UserDataSetMutation, UserDataSetMutationVariables>(UserDataSetDocument, baseOptions);
      }
export type UserDataSetMutationHookResult = ReturnType<typeof useUserDataSetMutation>;
export type UserDataSetMutationOptions = ApolloReactCommon.BaseMutationOptions<UserDataSetMutation, UserDataSetMutationVariables>;
export const UploadUserAvatarDocument = gql`
    mutation UploadUserAvatar($input: UploadUserAvatarType!) {
  uploadUserAvatar(input: $input) {
    image {
      id
    }
  }
}
    `;

/**
 * __useUploadUserAvatarMutation__
 *
 * To run a mutation, you first call `useUploadUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserAvatarMutation, { data, loading, error }] = useUploadUserAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadUserAvatarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>(UploadUserAvatarDocument, baseOptions);
      }
export type UploadUserAvatarMutationHookResult = ReturnType<typeof useUploadUserAvatarMutation>;
export type UploadUserAvatarMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>;
export const RemoveUserAvatarDocument = gql`
    mutation RemoveUserAvatar($input: RemoveUserAvatarType!) {
  removeUserAvatar(input: $input) {
    success
  }
}
    `;

/**
 * __useRemoveUserAvatarMutation__
 *
 * To run a mutation, you first call `useRemoveUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserAvatarMutation, { data, loading, error }] = useRemoveUserAvatarMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserAvatarMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserAvatarMutation, RemoveUserAvatarMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserAvatarMutation, RemoveUserAvatarMutationVariables>(RemoveUserAvatarDocument, baseOptions);
      }
export type RemoveUserAvatarMutationHookResult = ReturnType<typeof useRemoveUserAvatarMutation>;
export type RemoveUserAvatarMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserAvatarMutation, RemoveUserAvatarMutationVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($input: CreateReviewInputType!) {
  createReview(input: $input) {
    review {
      id
      review
      createdAt
    }
  }
}
    `;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, baseOptions);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export const PatientDevicesDocument = gql`
    query PatientDevices {
  patientDevices {
    edges {
      node {
        id
        patient {
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePatientDevicesQuery__
 *
 * To run a query within a React component, call `usePatientDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientDevicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientDevicesQuery, PatientDevicesQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientDevicesQuery, PatientDevicesQueryVariables>(PatientDevicesDocument, baseOptions);
      }
export function usePatientDevicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientDevicesQuery, PatientDevicesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientDevicesQuery, PatientDevicesQueryVariables>(PatientDevicesDocument, baseOptions);
        }
export type PatientDevicesQueryHookResult = ReturnType<typeof usePatientDevicesQuery>;
export type PatientDevicesLazyQueryHookResult = ReturnType<typeof usePatientDevicesLazyQuery>;
export const DeviceDocument = gql`
    query Device($device_id: ID!) {
  device(id: $device_id) {
    id
    mac
    settings {
      id
      name
      value {
        sensorReadPeriod
      }
    }
  }
}
    `;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      device_id: // value for 'device_id'
 *   },
 * });
 */
export function useDeviceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
        return ApolloReactHooks.useQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, baseOptions);
      }
export function useDeviceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, baseOptions);
        }
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export const CreateDeviceDocument = gql`
    mutation CreateDevice($input: DeviceInputType!) {
  deviceCreate(input: $input) {
    device {
      id
      mac
      deviceType
      settings {
        id
        value {
          batteryReadPeriod
          historyBufferSize
          reconnectAttemptPeriod
          sendDataPeriod
          sensorReadPeriod
        }
      }
    }
  }
}
    `;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeviceMutation, CreateDeviceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeviceMutation, CreateDeviceMutationVariables>(CreateDeviceDocument, baseOptions);
      }
export type CreateDeviceMutationHookResult = ReturnType<typeof useCreateDeviceMutation>;
export type CreateDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeviceMutation, CreateDeviceMutationVariables>;
export const LinkDeviceDocument = gql`
    mutation LinkDevice($input: DeviceInputType!) {
  linkDevice(input: $input) {
    device {
      id
      mac
      deviceType
      settings {
        id
        value {
          batteryReadPeriod
          historyBufferSize
          reconnectAttemptPeriod
          sendDataPeriod
          sensorReadPeriod
        }
      }
    }
  }
}
    `;

/**
 * __useLinkDeviceMutation__
 *
 * To run a mutation, you first call `useLinkDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkDeviceMutation, { data, loading, error }] = useLinkDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkDeviceMutation, LinkDeviceMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkDeviceMutation, LinkDeviceMutationVariables>(LinkDeviceDocument, baseOptions);
      }
export type LinkDeviceMutationHookResult = ReturnType<typeof useLinkDeviceMutation>;
export type LinkDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkDeviceMutation, LinkDeviceMutationVariables>;
export const CreateDeviceRecordsDocument = gql`
    mutation CreateDeviceRecords($input: DeviceRecordInputType!) {
  pushDeviceRecord(input: $input) {
    record {
      id
    }
  }
}
    `;

/**
 * __useCreateDeviceRecordsMutation__
 *
 * To run a mutation, you first call `useCreateDeviceRecordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceRecordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceRecordsMutation, { data, loading, error }] = useCreateDeviceRecordsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeviceRecordsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeviceRecordsMutation, CreateDeviceRecordsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeviceRecordsMutation, CreateDeviceRecordsMutationVariables>(CreateDeviceRecordsDocument, baseOptions);
      }
export type CreateDeviceRecordsMutationHookResult = ReturnType<typeof useCreateDeviceRecordsMutation>;
export type CreateDeviceRecordsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeviceRecordsMutation, CreateDeviceRecordsMutationVariables>;
export const CreateGcmDeviceDocument = gql`
    mutation CreateGCMDevice($input: GCMDeviceInputType!) {
  gcmDeviceCreate(input: $input) {
    gcmDevice {
      user {
        id
      }
      registrationId
      cloudMessageType
    }
  }
}
    `;

/**
 * __useCreateGcmDeviceMutation__
 *
 * To run a mutation, you first call `useCreateGcmDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGcmDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGcmDeviceMutation, { data, loading, error }] = useCreateGcmDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGcmDeviceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGcmDeviceMutation, CreateGcmDeviceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGcmDeviceMutation, CreateGcmDeviceMutationVariables>(CreateGcmDeviceDocument, baseOptions);
      }
export type CreateGcmDeviceMutationHookResult = ReturnType<typeof useCreateGcmDeviceMutation>;
export type CreateGcmDeviceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGcmDeviceMutation, CreateGcmDeviceMutationVariables>;
export const PrescriptionsDocument = gql`
    query Prescriptions {
  prescriptions {
    edges {
      node {
        id
        author {
          email
        }
        description
      }
    }
  }
}
    `;

/**
 * __usePrescriptionsQuery__
 *
 * To run a query within a React component, call `usePrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrescriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrescriptionsQuery, PrescriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PrescriptionsQuery, PrescriptionsQueryVariables>(PrescriptionsDocument, baseOptions);
      }
export function usePrescriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrescriptionsQuery, PrescriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrescriptionsQuery, PrescriptionsQueryVariables>(PrescriptionsDocument, baseOptions);
        }
export type PrescriptionsQueryHookResult = ReturnType<typeof usePrescriptionsQuery>;
export type PrescriptionsLazyQueryHookResult = ReturnType<typeof usePrescriptionsLazyQuery>;
export const PatientPrescriptionsDocument = gql`
    query PatientPrescriptions {
  patientPrescriptions {
    edges {
      node {
        id
        author {
          email
        }
        description
      }
    }
  }
}
    `;

/**
 * __usePatientPrescriptionsQuery__
 *
 * To run a query within a React component, call `usePatientPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientPrescriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientPrescriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientPrescriptionsQuery, PatientPrescriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientPrescriptionsQuery, PatientPrescriptionsQueryVariables>(PatientPrescriptionsDocument, baseOptions);
      }
export function usePatientPrescriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientPrescriptionsQuery, PatientPrescriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientPrescriptionsQuery, PatientPrescriptionsQueryVariables>(PatientPrescriptionsDocument, baseOptions);
        }
export type PatientPrescriptionsQueryHookResult = ReturnType<typeof usePatientPrescriptionsQuery>;
export type PatientPrescriptionsLazyQueryHookResult = ReturnType<typeof usePatientPrescriptionsLazyQuery>;
export const PatientDoctorsQueryByPatientIdDocument = gql`
    query PatientDoctorsQueryByPatientId($id: ID!) {
  patientDoctorsByPatientId(id: $id) {
    user {
      id
    }
  }
}
    `;

/**
 * __usePatientDoctorsQueryByPatientIdQuery__
 *
 * To run a query within a React component, call `usePatientDoctorsQueryByPatientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientDoctorsQueryByPatientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientDoctorsQueryByPatientIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientDoctorsQueryByPatientIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientDoctorsQueryByPatientIdQuery, PatientDoctorsQueryByPatientIdQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientDoctorsQueryByPatientIdQuery, PatientDoctorsQueryByPatientIdQueryVariables>(PatientDoctorsQueryByPatientIdDocument, baseOptions);
      }
export function usePatientDoctorsQueryByPatientIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientDoctorsQueryByPatientIdQuery, PatientDoctorsQueryByPatientIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientDoctorsQueryByPatientIdQuery, PatientDoctorsQueryByPatientIdQueryVariables>(PatientDoctorsQueryByPatientIdDocument, baseOptions);
        }
export type PatientDoctorsQueryByPatientIdQueryHookResult = ReturnType<typeof usePatientDoctorsQueryByPatientIdQuery>;
export type PatientDoctorsQueryByPatientIdLazyQueryHookResult = ReturnType<typeof usePatientDoctorsQueryByPatientIdLazyQuery>;
export const PrescriptionDocument = gql`
    query Prescription($id: ID!) {
  prescription(id: $id) {
    id
    description
    author {
      email
    }
    createdAt
    prescriptionmedicineSet {
      edges {
        node {
          id
          medicine {
            id
            name
          }
          instructions
          startDate
          duration
          frequencyDay
          intervalDay
          intakeQuantity
          intakeState
          medicineintaketimeSet {
            edges {
              node {
                intakeTime
              }
            }
          }
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __usePrescriptionQuery__
 *
 * To run a query within a React component, call `usePrescriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrescriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrescriptionQuery, PrescriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<PrescriptionQuery, PrescriptionQueryVariables>(PrescriptionDocument, baseOptions);
      }
export function usePrescriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrescriptionQuery, PrescriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrescriptionQuery, PrescriptionQueryVariables>(PrescriptionDocument, baseOptions);
        }
export type PrescriptionQueryHookResult = ReturnType<typeof usePrescriptionQuery>;
export type PrescriptionLazyQueryHookResult = ReturnType<typeof usePrescriptionLazyQuery>;
export const PrescriptionMedicineDocument = gql`
    query PrescriptionMedicine($prescription_medicine_id: ID!) {
  prescriptionMedicine(id: $prescription_medicine_id) {
    id
    medicine {
      id
      name
    }
    instructions
    startDate
    duration
    frequencyDay
    intervalDay
    intakeQuantity
    intakeState
    createdAt
    medicineintaketimeSet {
      edges {
        node {
          intakeTime
        }
      }
    }
  }
}
    `;

/**
 * __usePrescriptionMedicineQuery__
 *
 * To run a query within a React component, call `usePrescriptionMedicineQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionMedicineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionMedicineQuery({
 *   variables: {
 *      prescription_medicine_id: // value for 'prescription_medicine_id'
 *   },
 * });
 */
export function usePrescriptionMedicineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrescriptionMedicineQuery, PrescriptionMedicineQueryVariables>) {
        return ApolloReactHooks.useQuery<PrescriptionMedicineQuery, PrescriptionMedicineQueryVariables>(PrescriptionMedicineDocument, baseOptions);
      }
export function usePrescriptionMedicineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrescriptionMedicineQuery, PrescriptionMedicineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrescriptionMedicineQuery, PrescriptionMedicineQueryVariables>(PrescriptionMedicineDocument, baseOptions);
        }
export type PrescriptionMedicineQueryHookResult = ReturnType<typeof usePrescriptionMedicineQuery>;
export type PrescriptionMedicineLazyQueryHookResult = ReturnType<typeof usePrescriptionMedicineLazyQuery>;
export const PrescriptionWithMedicinesDocument = gql`
    query PrescriptionWithMedicines($prescription_id: ID!) {
  prescription(id: $prescription_id) {
    id
    description
    author {
      email
    }
    createdAt
    prescriptionmedicineSet {
      edges {
        node {
          id
          medicine {
            id
            name
          }
          instructions
          startDate
          duration
          frequencyDay
          intervalDay
          intakeQuantity
          intakeState
          createdAt
          medicineintaketimeSet {
            edges {
              node {
                intakeTime
              }
            }
          }
        }
      }
    }
  }
  medicines {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePrescriptionWithMedicinesQuery__
 *
 * To run a query within a React component, call `usePrescriptionWithMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionWithMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionWithMedicinesQuery({
 *   variables: {
 *      prescription_id: // value for 'prescription_id'
 *   },
 * });
 */
export function usePrescriptionWithMedicinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrescriptionWithMedicinesQuery, PrescriptionWithMedicinesQueryVariables>) {
        return ApolloReactHooks.useQuery<PrescriptionWithMedicinesQuery, PrescriptionWithMedicinesQueryVariables>(PrescriptionWithMedicinesDocument, baseOptions);
      }
export function usePrescriptionWithMedicinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrescriptionWithMedicinesQuery, PrescriptionWithMedicinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrescriptionWithMedicinesQuery, PrescriptionWithMedicinesQueryVariables>(PrescriptionWithMedicinesDocument, baseOptions);
        }
export type PrescriptionWithMedicinesQueryHookResult = ReturnType<typeof usePrescriptionWithMedicinesQuery>;
export type PrescriptionWithMedicinesLazyQueryHookResult = ReturnType<typeof usePrescriptionWithMedicinesLazyQuery>;
export const MedicinesDocument = gql`
    query Medicines {
  medicines {
    edges {
      node {
        id
        name
        brand
        description
        cas
      }
    }
  }
}
    `;

/**
 * __useMedicinesQuery__
 *
 * To run a query within a React component, call `useMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMedicinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MedicinesQuery, MedicinesQueryVariables>) {
        return ApolloReactHooks.useQuery<MedicinesQuery, MedicinesQueryVariables>(MedicinesDocument, baseOptions);
      }
export function useMedicinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MedicinesQuery, MedicinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MedicinesQuery, MedicinesQueryVariables>(MedicinesDocument, baseOptions);
        }
export type MedicinesQueryHookResult = ReturnType<typeof useMedicinesQuery>;
export type MedicinesLazyQueryHookResult = ReturnType<typeof useMedicinesLazyQuery>;
export const MedicinesWithPatientMedicinesDocument = gql`
    query MedicinesWithPatientMedicines {
  medicines {
    edges {
      node {
        id
        name
        brand
        description
        cas
      }
    }
  }
  patientMedicines {
    edges {
      node {
        id
        medicine {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useMedicinesWithPatientMedicinesQuery__
 *
 * To run a query within a React component, call `useMedicinesWithPatientMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicinesWithPatientMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicinesWithPatientMedicinesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMedicinesWithPatientMedicinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MedicinesWithPatientMedicinesQuery, MedicinesWithPatientMedicinesQueryVariables>) {
        return ApolloReactHooks.useQuery<MedicinesWithPatientMedicinesQuery, MedicinesWithPatientMedicinesQueryVariables>(MedicinesWithPatientMedicinesDocument, baseOptions);
      }
export function useMedicinesWithPatientMedicinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MedicinesWithPatientMedicinesQuery, MedicinesWithPatientMedicinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MedicinesWithPatientMedicinesQuery, MedicinesWithPatientMedicinesQueryVariables>(MedicinesWithPatientMedicinesDocument, baseOptions);
        }
export type MedicinesWithPatientMedicinesQueryHookResult = ReturnType<typeof useMedicinesWithPatientMedicinesQuery>;
export type MedicinesWithPatientMedicinesLazyQueryHookResult = ReturnType<typeof useMedicinesWithPatientMedicinesLazyQuery>;
export const MedicineDocument = gql`
    query Medicine($id: ID!) {
  medicine(id: $id) {
    id
    name
    brand
    description
    cas
  }
}
    `;

/**
 * __useMedicineQuery__
 *
 * To run a query within a React component, call `useMedicineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MedicineQuery, MedicineQueryVariables>) {
        return ApolloReactHooks.useQuery<MedicineQuery, MedicineQueryVariables>(MedicineDocument, baseOptions);
      }
export function useMedicineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MedicineQuery, MedicineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MedicineQuery, MedicineQueryVariables>(MedicineDocument, baseOptions);
        }
export type MedicineQueryHookResult = ReturnType<typeof useMedicineQuery>;
export type MedicineLazyQueryHookResult = ReturnType<typeof useMedicineLazyQuery>;
export const PatientMedicinesDocument = gql`
    query PatientMedicines {
  patientMedicines {
    edges {
      node {
        id
        quantity
        purchaseDate
        medicine {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePatientMedicinesQuery__
 *
 * To run a query within a React component, call `usePatientMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicinesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientMedicinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientMedicinesQuery, PatientMedicinesQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientMedicinesQuery, PatientMedicinesQueryVariables>(PatientMedicinesDocument, baseOptions);
      }
export function usePatientMedicinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientMedicinesQuery, PatientMedicinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientMedicinesQuery, PatientMedicinesQueryVariables>(PatientMedicinesDocument, baseOptions);
        }
export type PatientMedicinesQueryHookResult = ReturnType<typeof usePatientMedicinesQuery>;
export type PatientMedicinesLazyQueryHookResult = ReturnType<typeof usePatientMedicinesLazyQuery>;
export const PatientMedicineDocument = gql`
    query PatientMedicine($id: ID!) {
  patientMedicine(id: $id) {
    id
    quantity
    purchaseDate
    medicine {
      id
      name
    }
  }
}
    `;

/**
 * __usePatientMedicineQuery__
 *
 * To run a query within a React component, call `usePatientMedicineQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientMedicineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientMedicineQuery, PatientMedicineQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientMedicineQuery, PatientMedicineQueryVariables>(PatientMedicineDocument, baseOptions);
      }
export function usePatientMedicineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientMedicineQuery, PatientMedicineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientMedicineQuery, PatientMedicineQueryVariables>(PatientMedicineDocument, baseOptions);
        }
export type PatientMedicineQueryHookResult = ReturnType<typeof usePatientMedicineQuery>;
export type PatientMedicineLazyQueryHookResult = ReturnType<typeof usePatientMedicineLazyQuery>;
export const PatientMedicineWithMedicinesDocument = gql`
    query PatientMedicineWithMedicines($id: ID!) {
  medicines {
    edges {
      node {
        id
        name
        brand
        description
        cas
      }
    }
  }
  patientMedicine(id: $id) {
    id
    quantity
    purchaseDate
    medicine {
      id
      name
    }
  }
}
    `;

/**
 * __usePatientMedicineWithMedicinesQuery__
 *
 * To run a query within a React component, call `usePatientMedicineWithMedicinesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicineWithMedicinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicineWithMedicinesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientMedicineWithMedicinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientMedicineWithMedicinesQuery, PatientMedicineWithMedicinesQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientMedicineWithMedicinesQuery, PatientMedicineWithMedicinesQueryVariables>(PatientMedicineWithMedicinesDocument, baseOptions);
      }
export function usePatientMedicineWithMedicinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientMedicineWithMedicinesQuery, PatientMedicineWithMedicinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientMedicineWithMedicinesQuery, PatientMedicineWithMedicinesQueryVariables>(PatientMedicineWithMedicinesDocument, baseOptions);
        }
export type PatientMedicineWithMedicinesQueryHookResult = ReturnType<typeof usePatientMedicineWithMedicinesQuery>;
export type PatientMedicineWithMedicinesLazyQueryHookResult = ReturnType<typeof usePatientMedicineWithMedicinesLazyQuery>;
export const CreatePatientMedicineDocument = gql`
    mutation CreatePatientMedicine($input: CreatePatientMedicineInputType!) {
  createPatientMedicine(input: $input) {
    patientMedicine {
      id
      medicine {
        id
      }
      quantity
      purchaseDate
    }
  }
}
    `;

/**
 * __useCreatePatientMedicineMutation__
 *
 * To run a mutation, you first call `useCreatePatientMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMedicineMutation, { data, loading, error }] = useCreatePatientMedicineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientMedicineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePatientMedicineMutation, CreatePatientMedicineMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePatientMedicineMutation, CreatePatientMedicineMutationVariables>(CreatePatientMedicineDocument, baseOptions);
      }
export type CreatePatientMedicineMutationHookResult = ReturnType<typeof useCreatePatientMedicineMutation>;
export type CreatePatientMedicineMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientMedicineMutation, CreatePatientMedicineMutationVariables>;
export const UpdatePatientMedicineDocument = gql`
    mutation UpdatePatientMedicine($input: UpdatePatientMedicineInputType!) {
  updatePatientMedicine(input: $input) {
    patientMedicine {
      id
      medicine {
        id
      }
      quantity
      purchaseDate
    }
  }
}
    `;

/**
 * __useUpdatePatientMedicineMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMedicineMutation, { data, loading, error }] = useUpdatePatientMedicineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientMedicineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePatientMedicineMutation, UpdatePatientMedicineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePatientMedicineMutation, UpdatePatientMedicineMutationVariables>(UpdatePatientMedicineDocument, baseOptions);
      }
export type UpdatePatientMedicineMutationHookResult = ReturnType<typeof useUpdatePatientMedicineMutation>;
export type UpdatePatientMedicineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientMedicineMutation, UpdatePatientMedicineMutationVariables>;
export const RemovePatientMedicineDocument = gql`
    mutation RemovePatientMedicine($input: RemovePatientMedicineInputType!) {
  removePatientMedicine(input: $input) {
    success
  }
}
    `;

/**
 * __useRemovePatientMedicineMutation__
 *
 * To run a mutation, you first call `useRemovePatientMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientMedicineMutation, { data, loading, error }] = useRemovePatientMedicineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePatientMedicineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePatientMedicineMutation, RemovePatientMedicineMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePatientMedicineMutation, RemovePatientMedicineMutationVariables>(RemovePatientMedicineDocument, baseOptions);
      }
export type RemovePatientMedicineMutationHookResult = ReturnType<typeof useRemovePatientMedicineMutation>;
export type RemovePatientMedicineMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePatientMedicineMutation, RemovePatientMedicineMutationVariables>;
export const CreatePrescriptionDocument = gql`
    mutation CreatePrescription($input: CreatePrescriptionInputType!) {
  createPrescription(input: $input) {
    prescription {
      id
      description
      createdAt
    }
  }
}
    `;

/**
 * __useCreatePrescriptionMutation__
 *
 * To run a mutation, you first call `useCreatePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrescriptionMutation, { data, loading, error }] = useCreatePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePrescriptionMutation, CreatePrescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePrescriptionMutation, CreatePrescriptionMutationVariables>(CreatePrescriptionDocument, baseOptions);
      }
export type CreatePrescriptionMutationHookResult = ReturnType<typeof useCreatePrescriptionMutation>;
export type CreatePrescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePrescriptionMutation, CreatePrescriptionMutationVariables>;
export const UpdatePrescriptionDocument = gql`
    mutation UpdatePrescription($input: UpdatePrescriptionInputType!) {
  updatePrescription(input: $input) {
    prescription {
      id
      description
      createdAt
    }
  }
}
    `;

/**
 * __useUpdatePrescriptionMutation__
 *
 * To run a mutation, you first call `useUpdatePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrescriptionMutation, { data, loading, error }] = useUpdatePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePrescriptionMutation, UpdatePrescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePrescriptionMutation, UpdatePrescriptionMutationVariables>(UpdatePrescriptionDocument, baseOptions);
      }
export type UpdatePrescriptionMutationHookResult = ReturnType<typeof useUpdatePrescriptionMutation>;
export type UpdatePrescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePrescriptionMutation, UpdatePrescriptionMutationVariables>;
export const RemovePrescriptionDocument = gql`
    mutation RemovePrescription($input: RemovePrescriptionInputType!) {
  removePrescription(input: $input) {
    success
  }
}
    `;

/**
 * __useRemovePrescriptionMutation__
 *
 * To run a mutation, you first call `useRemovePrescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePrescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePrescriptionMutation, { data, loading, error }] = useRemovePrescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePrescriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePrescriptionMutation, RemovePrescriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePrescriptionMutation, RemovePrescriptionMutationVariables>(RemovePrescriptionDocument, baseOptions);
      }
export type RemovePrescriptionMutationHookResult = ReturnType<typeof useRemovePrescriptionMutation>;
export type RemovePrescriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePrescriptionMutation, RemovePrescriptionMutationVariables>;
export const CreatePrescriptionMedicineDocument = gql`
    mutation CreatePrescriptionMedicine($input: CreatePrescriptionMedicineInputType!) {
  createPrescriptionMedicine(input: $input) {
    prescriptionMedicine {
      id
      prescription {
        id
        description
      }
      medicine {
        id
        name
      }
      instructions
      startDate
      duration
      frequencyDay
      intervalDay
      intakeQuantity
      intakeState
      medicineintaketimeSet {
        edges {
          node {
            id
            intakeTime
            prescriptionMedicine {
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCreatePrescriptionMedicineMutation__
 *
 * To run a mutation, you first call `useCreatePrescriptionMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrescriptionMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrescriptionMedicineMutation, { data, loading, error }] = useCreatePrescriptionMedicineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePrescriptionMedicineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePrescriptionMedicineMutation, CreatePrescriptionMedicineMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePrescriptionMedicineMutation, CreatePrescriptionMedicineMutationVariables>(CreatePrescriptionMedicineDocument, baseOptions);
      }
export type CreatePrescriptionMedicineMutationHookResult = ReturnType<typeof useCreatePrescriptionMedicineMutation>;
export type CreatePrescriptionMedicineMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePrescriptionMedicineMutation, CreatePrescriptionMedicineMutationVariables>;
export const UpdatePrescriptionMedicineDocument = gql`
    mutation UpdatePrescriptionMedicine($input: UpdatePrescriptionMedicineInputType!) {
  updatePrescriptionMedicine(input: $input) {
    prescriptionMedicine {
      id
      prescription {
        id
        description
      }
      medicine {
        id
        name
      }
      instructions
      startDate
      duration
      frequencyDay
      intervalDay
      intakeQuantity
      intakeState
    }
    medicineIntakeTimes {
      id
      intakeTime
      prescriptionMedicine {
        id
      }
    }
  }
}
    `;

/**
 * __useUpdatePrescriptionMedicineMutation__
 *
 * To run a mutation, you first call `useUpdatePrescriptionMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrescriptionMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrescriptionMedicineMutation, { data, loading, error }] = useUpdatePrescriptionMedicineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePrescriptionMedicineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePrescriptionMedicineMutation, UpdatePrescriptionMedicineMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePrescriptionMedicineMutation, UpdatePrescriptionMedicineMutationVariables>(UpdatePrescriptionMedicineDocument, baseOptions);
      }
export type UpdatePrescriptionMedicineMutationHookResult = ReturnType<typeof useUpdatePrescriptionMedicineMutation>;
export type UpdatePrescriptionMedicineMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePrescriptionMedicineMutation, UpdatePrescriptionMedicineMutationVariables>;
export const RemovePrescriptionMedicineDocument = gql`
    mutation RemovePrescriptionMedicine($input: RemovePrescriptionMedicineInputType!) {
  removePrescriptionMedicine(input: $input) {
    success
  }
}
    `;

/**
 * __useRemovePrescriptionMedicineMutation__
 *
 * To run a mutation, you first call `useRemovePrescriptionMedicineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePrescriptionMedicineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePrescriptionMedicineMutation, { data, loading, error }] = useRemovePrescriptionMedicineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePrescriptionMedicineMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePrescriptionMedicineMutation, RemovePrescriptionMedicineMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePrescriptionMedicineMutation, RemovePrescriptionMedicineMutationVariables>(RemovePrescriptionMedicineDocument, baseOptions);
      }
export type RemovePrescriptionMedicineMutationHookResult = ReturnType<typeof useRemovePrescriptionMedicineMutation>;
export type RemovePrescriptionMedicineMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePrescriptionMedicineMutation, RemovePrescriptionMedicineMutationVariables>;
export const PatientMedicinesIntakesDocument = gql`
    query PatientMedicinesIntakes($date: Date!) {
  patientMedicinesIntakes(date: $date) {
    medicines {
      prescriptionMedicine {
        id
        medicine {
          id
          name
        }
        intakeState
        intakeQuantity
        medicineintakeSet {
          edges {
            node {
              id
              intakeDate
            }
          }
        }
      }
      medicineIntakes {
        id
      }
    }
    patientMedicines {
      id
      quantity
      medicine {
        id
        name
      }
    }
  }
}
    `;

/**
 * __usePatientMedicinesIntakesQuery__
 *
 * To run a query within a React component, call `usePatientMedicinesIntakesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientMedicinesIntakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientMedicinesIntakesQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePatientMedicinesIntakesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientMedicinesIntakesQuery, PatientMedicinesIntakesQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientMedicinesIntakesQuery, PatientMedicinesIntakesQueryVariables>(PatientMedicinesIntakesDocument, baseOptions);
      }
export function usePatientMedicinesIntakesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientMedicinesIntakesQuery, PatientMedicinesIntakesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientMedicinesIntakesQuery, PatientMedicinesIntakesQueryVariables>(PatientMedicinesIntakesDocument, baseOptions);
        }
export type PatientMedicinesIntakesQueryHookResult = ReturnType<typeof usePatientMedicinesIntakesQuery>;
export type PatientMedicinesIntakesLazyQueryHookResult = ReturnType<typeof usePatientMedicinesIntakesLazyQuery>;
export const CreateMedicineIntakeDocument = gql`
    mutation CreateMedicineIntake($input: CreateMedicineIntakeInputType!) {
  createMedicineIntake(input: $input) {
    medicineIntake {
      id
    }
  }
}
    `;

/**
 * __useCreateMedicineIntakeMutation__
 *
 * To run a mutation, you first call `useCreateMedicineIntakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicineIntakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicineIntakeMutation, { data, loading, error }] = useCreateMedicineIntakeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMedicineIntakeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMedicineIntakeMutation, CreateMedicineIntakeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMedicineIntakeMutation, CreateMedicineIntakeMutationVariables>(CreateMedicineIntakeDocument, baseOptions);
      }
export type CreateMedicineIntakeMutationHookResult = ReturnType<typeof useCreateMedicineIntakeMutation>;
export type CreateMedicineIntakeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMedicineIntakeMutation, CreateMedicineIntakeMutationVariables>;
export const RemoveMedicineIntakeDocument = gql`
    mutation RemoveMedicineIntake($input: RemoveMedicineIntakeInputType!) {
  removeMedicineIntake(input: $input) {
    success
  }
}
    `;

/**
 * __useRemoveMedicineIntakeMutation__
 *
 * To run a mutation, you first call `useRemoveMedicineIntakeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMedicineIntakeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMedicineIntakeMutation, { data, loading, error }] = useRemoveMedicineIntakeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMedicineIntakeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMedicineIntakeMutation, RemoveMedicineIntakeMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMedicineIntakeMutation, RemoveMedicineIntakeMutationVariables>(RemoveMedicineIntakeDocument, baseOptions);
      }
export type RemoveMedicineIntakeMutationHookResult = ReturnType<typeof useRemoveMedicineIntakeMutation>;
export type RemoveMedicineIntakeMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMedicineIntakeMutation, RemoveMedicineIntakeMutationVariables>;
export const PatientDocument = gql`
    query Patient($id: ID!) {
  patient(id: $id) {
    id
    diagnosis
    user {
      id
    }
    doctorpatientSet {
      edges {
        node {
          id
          doctor {
            id
            user {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePatientQuery__
 *
 * To run a query within a React component, call `usePatientQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientQuery, PatientQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientQuery, PatientQueryVariables>(PatientDocument, baseOptions);
      }
export function usePatientLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientQuery, PatientQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientQuery, PatientQueryVariables>(PatientDocument, baseOptions);
        }
export type PatientQueryHookResult = ReturnType<typeof usePatientQuery>;
export type PatientLazyQueryHookResult = ReturnType<typeof usePatientLazyQuery>;
export const PatientsDocument = gql`
    query Patients {
  patients {
    edges {
      node {
        id
        user {
          lastName
          firstName
        }
      }
    }
  }
}
    `;

/**
 * __usePatientsQuery__
 *
 * To run a query within a React component, call `usePatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientsQuery, PatientsQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientsQuery, PatientsQueryVariables>(PatientsDocument, baseOptions);
      }
export function usePatientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientsQuery, PatientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientsQuery, PatientsQueryVariables>(PatientsDocument, baseOptions);
        }
export type PatientsQueryHookResult = ReturnType<typeof usePatientsQuery>;
export type PatientsLazyQueryHookResult = ReturnType<typeof usePatientsLazyQuery>;
export const PatientFileDocument = gql`
    query PatientFile($id: ID!) {
  patientFile(id: $id) {
    id
    file {
      url
      title
    }
  }
}
    `;

/**
 * __usePatientFileQuery__
 *
 * To run a query within a React component, call `usePatientFileQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePatientFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientFileQuery, PatientFileQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientFileQuery, PatientFileQueryVariables>(PatientFileDocument, baseOptions);
      }
export function usePatientFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientFileQuery, PatientFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientFileQuery, PatientFileQueryVariables>(PatientFileDocument, baseOptions);
        }
export type PatientFileQueryHookResult = ReturnType<typeof usePatientFileQuery>;
export type PatientFileLazyQueryHookResult = ReturnType<typeof usePatientFileLazyQuery>;
export const PatientFilesDocument = gql`
    query PatientFiles {
  patientFiles {
    edges {
      node {
        id
        file {
          url
          title
        }
      }
    }
  }
}
    `;

/**
 * __usePatientFilesQuery__
 *
 * To run a query within a React component, call `usePatientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientFilesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientFilesQuery, PatientFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientFilesQuery, PatientFilesQueryVariables>(PatientFilesDocument, baseOptions);
      }
export function usePatientFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientFilesQuery, PatientFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientFilesQuery, PatientFilesQueryVariables>(PatientFilesDocument, baseOptions);
        }
export type PatientFilesQueryHookResult = ReturnType<typeof usePatientFilesQuery>;
export type PatientFilesLazyQueryHookResult = ReturnType<typeof usePatientFilesLazyQuery>;
export const PatientFileCreateDocument = gql`
    mutation PatientFileCreate($input: PatientFileInputType!) {
  patientFileCreate(input: $input) {
    patientFile {
      id
      file {
        url
        title
      }
    }
  }
}
    `;

/**
 * __usePatientFileCreateMutation__
 *
 * To run a mutation, you first call `usePatientFileCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientFileCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientFileCreateMutation, { data, loading, error }] = usePatientFileCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientFileCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PatientFileCreateMutation, PatientFileCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<PatientFileCreateMutation, PatientFileCreateMutationVariables>(PatientFileCreateDocument, baseOptions);
      }
export type PatientFileCreateMutationHookResult = ReturnType<typeof usePatientFileCreateMutation>;
export type PatientFileCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<PatientFileCreateMutation, PatientFileCreateMutationVariables>;
export const PatientFileUpdateDocument = gql`
    mutation PatientFileUpdate($input: PatientFileUpdateInputType!) {
  patientFileUpdate(input: $input) {
    patientFile {
      id
      file {
        url
        title
      }
    }
  }
}
    `;

/**
 * __usePatientFileUpdateMutation__
 *
 * To run a mutation, you first call `usePatientFileUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientFileUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientFileUpdateMutation, { data, loading, error }] = usePatientFileUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientFileUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PatientFileUpdateMutation, PatientFileUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PatientFileUpdateMutation, PatientFileUpdateMutationVariables>(PatientFileUpdateDocument, baseOptions);
      }
export type PatientFileUpdateMutationHookResult = ReturnType<typeof usePatientFileUpdateMutation>;
export type PatientFileUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PatientFileUpdateMutation, PatientFileUpdateMutationVariables>;
export const RemovePatientFileDocument = gql`
    mutation RemovePatientFile($input: PatientFileRemoveInputType!) {
  patientFileDelete(input: $input) {
    success
  }
}
    `;

/**
 * __useRemovePatientFileMutation__
 *
 * To run a mutation, you first call `useRemovePatientFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePatientFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePatientFileMutation, { data, loading, error }] = useRemovePatientFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePatientFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePatientFileMutation, RemovePatientFileMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePatientFileMutation, RemovePatientFileMutationVariables>(RemovePatientFileDocument, baseOptions);
      }
export type RemovePatientFileMutationHookResult = ReturnType<typeof useRemovePatientFileMutation>;
export type RemovePatientFileMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePatientFileMutation, RemovePatientFileMutationVariables>;
export const SetPatientLocationDocument = gql`
    mutation SetPatientLocation($input: PatientLocationInputType!) {
  setPatientLocation(input: $input) {
    patientLocation {
      id
      location {
        type
        coordinates
      }
      patient {
        id
      }
    }
  }
}
    `;

/**
 * __useSetPatientLocationMutation__
 *
 * To run a mutation, you first call `useSetPatientLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPatientLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPatientLocationMutation, { data, loading, error }] = useSetPatientLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetPatientLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetPatientLocationMutation, SetPatientLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<SetPatientLocationMutation, SetPatientLocationMutationVariables>(SetPatientLocationDocument, baseOptions);
      }
export type SetPatientLocationMutationHookResult = ReturnType<typeof useSetPatientLocationMutation>;
export type SetPatientLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<SetPatientLocationMutation, SetPatientLocationMutationVariables>;
export const WomenPeriodCreateDocument = gql`
    mutation WomenPeriodCreate($input: WomenPeriodInputType!) {
  womenPeriodCreate(input: $input) {
    womenPeriod {
      id
      startDate
      endDate
      patient {
        id
      }
    }
  }
}
    `;

/**
 * __useWomenPeriodCreateMutation__
 *
 * To run a mutation, you first call `useWomenPeriodCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWomenPeriodCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [womenPeriodCreateMutation, { data, loading, error }] = useWomenPeriodCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWomenPeriodCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WomenPeriodCreateMutation, WomenPeriodCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<WomenPeriodCreateMutation, WomenPeriodCreateMutationVariables>(WomenPeriodCreateDocument, baseOptions);
      }
export type WomenPeriodCreateMutationHookResult = ReturnType<typeof useWomenPeriodCreateMutation>;
export type WomenPeriodCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<WomenPeriodCreateMutation, WomenPeriodCreateMutationVariables>;
export const WomenPeriodUpdateDocument = gql`
    mutation WomenPeriodUpdate($input: WomenPeriodUpdateInputType!) {
  womenPeriodUpdate(input: $input) {
    womenPeriod {
      id
      startDate
      endDate
      patient {
        id
      }
    }
  }
}
    `;

/**
 * __useWomenPeriodUpdateMutation__
 *
 * To run a mutation, you first call `useWomenPeriodUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWomenPeriodUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [womenPeriodUpdateMutation, { data, loading, error }] = useWomenPeriodUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWomenPeriodUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<WomenPeriodUpdateMutation, WomenPeriodUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<WomenPeriodUpdateMutation, WomenPeriodUpdateMutationVariables>(WomenPeriodUpdateDocument, baseOptions);
      }
export type WomenPeriodUpdateMutationHookResult = ReturnType<typeof useWomenPeriodUpdateMutation>;
export type WomenPeriodUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<WomenPeriodUpdateMutation, WomenPeriodUpdateMutationVariables>;
export const RemoveWomenPeriodDocument = gql`
    mutation RemoveWomenPeriod($input: WomenPeriodRemoveInputType!) {
  womenPeriodDelete(input: $input) {
    success
  }
}
    `;

/**
 * __useRemoveWomenPeriodMutation__
 *
 * To run a mutation, you first call `useRemoveWomenPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWomenPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWomenPeriodMutation, { data, loading, error }] = useRemoveWomenPeriodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveWomenPeriodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveWomenPeriodMutation, RemoveWomenPeriodMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveWomenPeriodMutation, RemoveWomenPeriodMutationVariables>(RemoveWomenPeriodDocument, baseOptions);
      }
export type RemoveWomenPeriodMutationHookResult = ReturnType<typeof useRemoveWomenPeriodMutation>;
export type RemoveWomenPeriodMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveWomenPeriodMutation, RemoveWomenPeriodMutationVariables>;
export const WomenPeriodsDocument = gql`
    query WomenPeriods {
  womenPeriods {
    edges {
      node {
        id
        startDate
        endDate
        patient {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useWomenPeriodsQuery__
 *
 * To run a query within a React component, call `useWomenPeriodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWomenPeriodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWomenPeriodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWomenPeriodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WomenPeriodsQuery, WomenPeriodsQueryVariables>) {
        return ApolloReactHooks.useQuery<WomenPeriodsQuery, WomenPeriodsQueryVariables>(WomenPeriodsDocument, baseOptions);
      }
export function useWomenPeriodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WomenPeriodsQuery, WomenPeriodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WomenPeriodsQuery, WomenPeriodsQueryVariables>(WomenPeriodsDocument, baseOptions);
        }
export type WomenPeriodsQueryHookResult = ReturnType<typeof useWomenPeriodsQuery>;
export type WomenPeriodsLazyQueryHookResult = ReturnType<typeof useWomenPeriodsLazyQuery>;
export const WomenPeriodDocument = gql`
    query WomenPeriod($id: ID!) {
  womenPeriod(id: $id) {
    id
    startDate
    endDate
    patient {
      id
    }
  }
}
    `;

/**
 * __useWomenPeriodQuery__
 *
 * To run a query within a React component, call `useWomenPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useWomenPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWomenPeriodQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWomenPeriodQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WomenPeriodQuery, WomenPeriodQueryVariables>) {
        return ApolloReactHooks.useQuery<WomenPeriodQuery, WomenPeriodQueryVariables>(WomenPeriodDocument, baseOptions);
      }
export function useWomenPeriodLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WomenPeriodQuery, WomenPeriodQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WomenPeriodQuery, WomenPeriodQueryVariables>(WomenPeriodDocument, baseOptions);
        }
export type WomenPeriodQueryHookResult = ReturnType<typeof useWomenPeriodQuery>;
export type WomenPeriodLazyQueryHookResult = ReturnType<typeof useWomenPeriodLazyQuery>;
export const ReviewsClinicsDocument = gql`
    query ReviewsClinics($sort: String, $search: String, $filter: ClinicFilterInput) {
  reviewsClinics(search: $search, filter: $filter, sort: $sort) {
    edges {
      node {
        id
        name
        avatar {
          name
          url
        }
        discription
        workTime
        phone
        address
        location {
          coordinates
          type
        }
        averageStars
        reviews {
          id
          author {
            firstName
            secondName
          }
          averageEntryStars
          comment
          objectId
          createdAt
        }
      }
    }
  }
}
    `;

/**
 * __useReviewsClinicsQuery__
 *
 * To run a query within a React component, call `useReviewsClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsClinicsQuery({
 *   variables: {
 *      sort: // value for 'sort'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useReviewsClinicsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewsClinicsQuery, ReviewsClinicsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewsClinicsQuery, ReviewsClinicsQueryVariables>(ReviewsClinicsDocument, baseOptions);
      }
export function useReviewsClinicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewsClinicsQuery, ReviewsClinicsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewsClinicsQuery, ReviewsClinicsQueryVariables>(ReviewsClinicsDocument, baseOptions);
        }
export type ReviewsClinicsQueryHookResult = ReturnType<typeof useReviewsClinicsQuery>;
export type ReviewsClinicsLazyQueryHookResult = ReturnType<typeof useReviewsClinicsLazyQuery>;
export const ReviewClinicDocument = gql`
    query ReviewClinic($id: ID!) {
  reviewsClinic(id: $id) {
    id
    name
    avatar {
      name
      url
    }
    discription
    workTime
    phone
    address
    location {
      coordinates
      type
    }
    doctorclinicSet {
      edges {
        node {
          doctor {
            id
            avatar {
              name
              url
            }
            firstName
            secondName
            specialization
            workTime
            averageStars
            reviews {
              stars
            }
          }
        }
      }
    }
    averageStars
    reviews {
      id
      author {
        firstName
        secondName
      }
      averageEntryStars
      comment
      objectId
      createdAt
    }
  }
}
    `;

/**
 * __useReviewClinicQuery__
 *
 * To run a query within a React component, call `useReviewClinicQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewClinicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewClinicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewClinicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewClinicQuery, ReviewClinicQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewClinicQuery, ReviewClinicQueryVariables>(ReviewClinicDocument, baseOptions);
      }
export function useReviewClinicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewClinicQuery, ReviewClinicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewClinicQuery, ReviewClinicQueryVariables>(ReviewClinicDocument, baseOptions);
        }
export type ReviewClinicQueryHookResult = ReturnType<typeof useReviewClinicQuery>;
export type ReviewClinicLazyQueryHookResult = ReturnType<typeof useReviewClinicLazyQuery>;
export const ReviewDoctorDocument = gql`
    query ReviewDoctor($id: ID!) {
  reviewsDoctor(id: $id) {
    id
    firstName
    secondName
    thirdName
    specialization
    avatar {
      name
      url
    }
    workTime
    phone
    address
    location {
      coordinates
      type
    }
    doctorclinicSet {
      edges {
        node {
          clinic {
            id
            name
            avatar {
              name
              url
            }
            reviews {
              stars
            }
            discription
            workTime
            phone
            address
            location {
              coordinates
              type
            }
          }
        }
      }
    }
    averageStars
    reviews {
      id
      author {
        firstName
        secondName
      }
      averageEntryStars
      comment
      objectId
      createdAt
    }
  }
}
    `;

/**
 * __useReviewDoctorQuery__
 *
 * To run a query within a React component, call `useReviewDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewDoctorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewDoctorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewDoctorQuery, ReviewDoctorQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewDoctorQuery, ReviewDoctorQueryVariables>(ReviewDoctorDocument, baseOptions);
      }
export function useReviewDoctorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewDoctorQuery, ReviewDoctorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewDoctorQuery, ReviewDoctorQueryVariables>(ReviewDoctorDocument, baseOptions);
        }
export type ReviewDoctorQueryHookResult = ReturnType<typeof useReviewDoctorQuery>;
export type ReviewDoctorLazyQueryHookResult = ReturnType<typeof useReviewDoctorLazyQuery>;
export const ReviewDocument = gql`
    query Review($id: ID!) {
  review(id: $id) {
    id
    author {
      firstName
      secondName
    }
    averageEntryStars
    comment
    reviewentrySet {
      edges {
        node {
          id
          characteristic {
            name
          }
          stars
          comment
        }
      }
    }
  }
}
    `;

/**
 * __useReviewQuery__
 *
 * To run a query within a React component, call `useReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReviewQuery, ReviewQueryVariables>) {
        return ApolloReactHooks.useQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, baseOptions);
      }
export function useReviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReviewQuery, ReviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReviewQuery, ReviewQueryVariables>(ReviewDocument, baseOptions);
        }
export type ReviewQueryHookResult = ReturnType<typeof useReviewQuery>;
export type ReviewLazyQueryHookResult = ReturnType<typeof useReviewLazyQuery>;
export const ClinicReviewsGroupByCharacteristicDocument = gql`
    query ClinicReviewsGroupByCharacteristic($clinicId: ID!) {
  clinicReviewsGroupByCharacteristic(clinicId: $clinicId) {
    characteristic
    averageStars
  }
}
    `;

/**
 * __useClinicReviewsGroupByCharacteristicQuery__
 *
 * To run a query within a React component, call `useClinicReviewsGroupByCharacteristicQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicReviewsGroupByCharacteristicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicReviewsGroupByCharacteristicQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useClinicReviewsGroupByCharacteristicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClinicReviewsGroupByCharacteristicQuery, ClinicReviewsGroupByCharacteristicQueryVariables>) {
        return ApolloReactHooks.useQuery<ClinicReviewsGroupByCharacteristicQuery, ClinicReviewsGroupByCharacteristicQueryVariables>(ClinicReviewsGroupByCharacteristicDocument, baseOptions);
      }
export function useClinicReviewsGroupByCharacteristicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClinicReviewsGroupByCharacteristicQuery, ClinicReviewsGroupByCharacteristicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClinicReviewsGroupByCharacteristicQuery, ClinicReviewsGroupByCharacteristicQueryVariables>(ClinicReviewsGroupByCharacteristicDocument, baseOptions);
        }
export type ClinicReviewsGroupByCharacteristicQueryHookResult = ReturnType<typeof useClinicReviewsGroupByCharacteristicQuery>;
export type ClinicReviewsGroupByCharacteristicLazyQueryHookResult = ReturnType<typeof useClinicReviewsGroupByCharacteristicLazyQuery>;
export const ClinicReviewsDocument = gql`
    query ClinicReviews($id: ID!, $sort: String) {
  clinicReviews(id: $id, sort: $sort) {
    edges {
      node {
        id
        author {
          firstName
          secondName
        }
        stars
        comment
        createdAt
        avgEntryStars
        reviewentrySet {
          edges {
            node {
              id
              stars
              characteristic {
                name
              }
              comment
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useClinicReviewsQuery__
 *
 * To run a query within a React component, call `useClinicReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClinicReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClinicReviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useClinicReviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClinicReviewsQuery, ClinicReviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<ClinicReviewsQuery, ClinicReviewsQueryVariables>(ClinicReviewsDocument, baseOptions);
      }
export function useClinicReviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClinicReviewsQuery, ClinicReviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClinicReviewsQuery, ClinicReviewsQueryVariables>(ClinicReviewsDocument, baseOptions);
        }
export type ClinicReviewsQueryHookResult = ReturnType<typeof useClinicReviewsQuery>;
export type ClinicReviewsLazyQueryHookResult = ReturnType<typeof useClinicReviewsLazyQuery>;
export const SeizuresDocument = gql`
    query Seizures {
  seizures {
    edges {
      node {
        id
        date
        duration
        intensity
        trigger
        status
        createdAt
        updatedAt
        patient {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useSeizuresQuery__
 *
 * To run a query within a React component, call `useSeizuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeizuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeizuresQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeizuresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeizuresQuery, SeizuresQueryVariables>) {
        return ApolloReactHooks.useQuery<SeizuresQuery, SeizuresQueryVariables>(SeizuresDocument, baseOptions);
      }
export function useSeizuresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeizuresQuery, SeizuresQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeizuresQuery, SeizuresQueryVariables>(SeizuresDocument, baseOptions);
        }
export type SeizuresQueryHookResult = ReturnType<typeof useSeizuresQuery>;
export type SeizuresLazyQueryHookResult = ReturnType<typeof useSeizuresLazyQuery>;
export const SeizureDocument = gql`
    query Seizure($id: ID!) {
  seizure(id: $id) {
    id
    date
    duration
    intensity
    trigger
    location {
      type
      coordinates
    }
    status
  }
}
    `;

/**
 * __useSeizureQuery__
 *
 * To run a query within a React component, call `useSeizureQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeizureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeizureQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeizureQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SeizureQuery, SeizureQueryVariables>) {
        return ApolloReactHooks.useQuery<SeizureQuery, SeizureQueryVariables>(SeizureDocument, baseOptions);
      }
export function useSeizureLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SeizureQuery, SeizureQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SeizureQuery, SeizureQueryVariables>(SeizureDocument, baseOptions);
        }
export type SeizureQueryHookResult = ReturnType<typeof useSeizureQuery>;
export type SeizureLazyQueryHookResult = ReturnType<typeof useSeizureLazyQuery>;
export const SeizureCreateDocument = gql`
    mutation SeizureCreate($input: SeizureInputType!) {
  seizureCreate(input: $input) {
    seizure {
      id
      trigger
      status
      intensity
      date
      duration
      location {
        type
        coordinates
      }
      patient {
        id
      }
    }
  }
}
    `;

/**
 * __useSeizureCreateMutation__
 *
 * To run a mutation, you first call `useSeizureCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeizureCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seizureCreateMutation, { data, loading, error }] = useSeizureCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeizureCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SeizureCreateMutation, SeizureCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SeizureCreateMutation, SeizureCreateMutationVariables>(SeizureCreateDocument, baseOptions);
      }
export type SeizureCreateMutationHookResult = ReturnType<typeof useSeizureCreateMutation>;
export type SeizureCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<SeizureCreateMutation, SeizureCreateMutationVariables>;
export const SeizureUpdateDocument = gql`
    mutation SeizureUpdate($input: SeizureUpdateInputType!) {
  seizureUpdate(input: $input) {
    seizure {
      id
      trigger
      status
      intensity
      location {
        type
        coordinates
      }
      date
      duration
      patient {
        id
      }
    }
  }
}
    `;

/**
 * __useSeizureUpdateMutation__
 *
 * To run a mutation, you first call `useSeizureUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeizureUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seizureUpdateMutation, { data, loading, error }] = useSeizureUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSeizureUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SeizureUpdateMutation, SeizureUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SeizureUpdateMutation, SeizureUpdateMutationVariables>(SeizureUpdateDocument, baseOptions);
      }
export type SeizureUpdateMutationHookResult = ReturnType<typeof useSeizureUpdateMutation>;
export type SeizureUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SeizureUpdateMutation, SeizureUpdateMutationVariables>;
export const RemoveSeizureDocument = gql`
    mutation RemoveSeizure($input: SeizureRemoveInputType!) {
  seizureDelete(input: $input) {
    success
  }
}
    `;

/**
 * __useRemoveSeizureMutation__
 *
 * To run a mutation, you first call `useRemoveSeizureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSeizureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSeizureMutation, { data, loading, error }] = useRemoveSeizureMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSeizureMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSeizureMutation, RemoveSeizureMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSeizureMutation, RemoveSeizureMutationVariables>(RemoveSeizureDocument, baseOptions);
      }
export type RemoveSeizureMutationHookResult = ReturnType<typeof useRemoveSeizureMutation>;
export type RemoveSeizureMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSeizureMutation, RemoveSeizureMutationVariables>;
export const PatientSeizuresDocument = gql`
    query PatientSeizures {
  patientSeizures {
    edges {
      node {
        id
        date
        duration
        intensity
        trigger
      }
    }
  }
}
    `;

/**
 * __usePatientSeizuresQuery__
 *
 * To run a query within a React component, call `usePatientSeizuresQuery` and pass it any options that fit your needs.
 * When your component renders, `usePatientSeizuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePatientSeizuresQuery({
 *   variables: {
 *   },
 * });
 */
export function usePatientSeizuresQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PatientSeizuresQuery, PatientSeizuresQueryVariables>) {
        return ApolloReactHooks.useQuery<PatientSeizuresQuery, PatientSeizuresQueryVariables>(PatientSeizuresDocument, baseOptions);
      }
export function usePatientSeizuresLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PatientSeizuresQuery, PatientSeizuresQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PatientSeizuresQuery, PatientSeizuresQueryVariables>(PatientSeizuresDocument, baseOptions);
        }
export type PatientSeizuresQueryHookResult = ReturnType<typeof usePatientSeizuresQuery>;
export type PatientSeizuresLazyQueryHookResult = ReturnType<typeof usePatientSeizuresLazyQuery>;
export const SosContactsDocument = gql`
    query SOSContacts {
  sosContacts {
    edges {
      node {
        id
        firstName
        secondName
        email
        phone
      }
    }
  }
}
    `;

/**
 * __useSosContactsQuery__
 *
 * To run a query within a React component, call `useSosContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSosContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSosContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSosContactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SosContactsQuery, SosContactsQueryVariables>) {
        return ApolloReactHooks.useQuery<SosContactsQuery, SosContactsQueryVariables>(SosContactsDocument, baseOptions);
      }
export function useSosContactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SosContactsQuery, SosContactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SosContactsQuery, SosContactsQueryVariables>(SosContactsDocument, baseOptions);
        }
export type SosContactsQueryHookResult = ReturnType<typeof useSosContactsQuery>;
export type SosContactsLazyQueryHookResult = ReturnType<typeof useSosContactsLazyQuery>;
export const SosContactDocument = gql`
    query SOSContact($id: ID!) {
  sosContact(id: $id) {
    id
    firstName
    secondName
    email
    phone
  }
}
    `;

/**
 * __useSosContactQuery__
 *
 * To run a query within a React component, call `useSosContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useSosContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSosContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSosContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SosContactQuery, SosContactQueryVariables>) {
        return ApolloReactHooks.useQuery<SosContactQuery, SosContactQueryVariables>(SosContactDocument, baseOptions);
      }
export function useSosContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SosContactQuery, SosContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SosContactQuery, SosContactQueryVariables>(SosContactDocument, baseOptions);
        }
export type SosContactQueryHookResult = ReturnType<typeof useSosContactQuery>;
export type SosContactLazyQueryHookResult = ReturnType<typeof useSosContactLazyQuery>;
export const SosContactCreateDocument = gql`
    mutation SOSContactCreate($input: SOSContactInputType!) {
  soscontactCreate(input: $input) {
    soscontact {
      id
      firstName
      secondName
      email
      phone
    }
  }
}
    `;

/**
 * __useSosContactCreateMutation__
 *
 * To run a mutation, you first call `useSosContactCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosContactCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosContactCreateMutation, { data, loading, error }] = useSosContactCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSosContactCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SosContactCreateMutation, SosContactCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<SosContactCreateMutation, SosContactCreateMutationVariables>(SosContactCreateDocument, baseOptions);
      }
export type SosContactCreateMutationHookResult = ReturnType<typeof useSosContactCreateMutation>;
export type SosContactCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<SosContactCreateMutation, SosContactCreateMutationVariables>;
export const SosContactUpdateDocument = gql`
    mutation SOSContactUpdate($input: SOSContactUpdateInputType!) {
  soscontactUpdate(input: $input) {
    soscontact {
      id
      firstName
      secondName
      email
      phone
    }
  }
}
    `;

/**
 * __useSosContactUpdateMutation__
 *
 * To run a mutation, you first call `useSosContactUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSosContactUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sosContactUpdateMutation, { data, loading, error }] = useSosContactUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSosContactUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SosContactUpdateMutation, SosContactUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<SosContactUpdateMutation, SosContactUpdateMutationVariables>(SosContactUpdateDocument, baseOptions);
      }
export type SosContactUpdateMutationHookResult = ReturnType<typeof useSosContactUpdateMutation>;
export type SosContactUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<SosContactUpdateMutation, SosContactUpdateMutationVariables>;
export const RemoveSosContactDocument = gql`
    mutation RemoveSOSContact($input: SOSContactRemoveInputType!) {
  soscontactDelete(input: $input) {
    success
  }
}
    `;

/**
 * __useRemoveSosContactMutation__
 *
 * To run a mutation, you first call `useRemoveSosContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSosContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSosContactMutation, { data, loading, error }] = useRemoveSosContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveSosContactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSosContactMutation, RemoveSosContactMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveSosContactMutation, RemoveSosContactMutationVariables>(RemoveSosContactDocument, baseOptions);
      }
export type RemoveSosContactMutationHookResult = ReturnType<typeof useRemoveSosContactMutation>;
export type RemoveSosContactMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSosContactMutation, RemoveSosContactMutationVariables>;