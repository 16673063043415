import { useMemo } from 'react';
import { useHistory } from 'react-router';

const useSearchParams = () => {
  const history = useHistory();

  const searchParams = useMemo(() => {
    return new URLSearchParams(history.location.search);
  }, [history.location.search]);

  return {
    searchParams,
    set: (key: string, value: string) => {
      searchParams.set(key, value);
      history.replace({ search: searchParams.toString() });
    },
    get: (key: string) => {
      return searchParams?.get(key);
    },
  };
};
export { useSearchParams };
