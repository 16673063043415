import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import { Geolocation, Position } from '@capacitor/geolocation';
import { useSetPatientLocationMutation } from '../generated/graphql';
import { useAuth } from './auth-context';
import { isLocationAlways } from '../utils/device';

const REQUEST_PERIOD = Number(process.env.REACT_APP_REQUEST_LOCATION_PERIOD) || 3600000;

interface ActionType {
  type: 'SET_LOCATION' | 'CHECK_PERMISSION' | 'REQUEST_PERMISSION' | 'SET_IS_ALWAYS';
  payload?: StateType;
}

type PermissionType = 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied';

interface StateType {
  location?: Position | null;
  permission?: PermissionType | null;
  isAlways?: boolean | null;
}

interface ContextType extends StateType {
  setLocation: () => Promise<Position> | void;
  checkPermission: () => Promise<boolean> | void;
  requestPermission: () => Promise<PermissionType> | void;
  setIsAlways: () => void;
}

const initialState: StateType = {
  location: null,
  permission: null,
  isAlways: null,
};

const LocationContext = React.createContext<ContextType>({
  location: null,
  permission: null,
  isAlways: null,
  setLocation: () => {},
  checkPermission: () => {},
  requestPermission: () => {},
  setIsAlways: () => {},
});

const locationReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'SET_LOCATION': {
      const { location } = action.payload!;
      return { ...state, location };
    }
    case 'CHECK_PERMISSION': {
      const { permission } = action.payload!;
      return { ...state, permission };
    }
    case 'REQUEST_PERMISSION': {
      const { permission } = action.payload!;
      return { ...state, permission };
    }
    case 'SET_IS_ALWAYS': {
      const { isAlways } = action.payload!;
      return { ...state, isAlways };
    }
    default:
      return state;
  }
};

const LocationProvider = (props: any) => {
  const [state, dispatch] = useReducer(locationReducer, initialState);
  const [setPatientLocationMutation] = useSetPatientLocationMutation();
  const authContext = useAuth();

  const checkPermission = useCallback(async () => {
    try {
      const { location: permission } = await Geolocation.checkPermissions();

      dispatch({
        type: 'CHECK_PERMISSION',
        payload: { permission },
      });
      return permission;
    } catch (error: any) {
      throw new Error(error);
    }
  }, []);

  const setIsAlways = useCallback(async () => {
    const isAlways = await isLocationAlways();
    dispatch({
      type: 'SET_IS_ALWAYS',
      payload: { isAlways },
    });
  }, []);

  const setLocation = useCallback(async (): Promise<Position | undefined> => {
    let position: Position | undefined;
    try {
      const permission = await checkPermission();
      if (permission !== 'denied' && authContext.user?.id && authContext.registered) {
        position = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
        dispatch({
          type: 'SET_LOCATION',
          payload: { location: position },
        });
        await setPatientLocationMutation({
          variables: {
            input: {
              location: {
                type: 'POINT',
                coordinates: [position.coords.latitude, position.coords.longitude],
              },
            },
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return position;
  }, [authContext.user?.id, checkPermission, setPatientLocationMutation, authContext.registered]);

  const requestPermission = useCallback(async () => {
    try {
      const { location } = await Geolocation.requestPermissions();
      const permission = location;

      dispatch({
        type: 'REQUEST_PERMISSION',
        payload: { permission },
      });
      return permission;
    } catch (error: any) {
      throw new Error(error);
    }
  }, []);

  // Initialize component's permission and location state
  useEffect(() => {
    checkPermission().then((permission) => {
      if (permission !== 'denied') {
        setLocation();
      }
    });
  }, [setLocation, checkPermission]);

  useEffect(() => {
    if (state.permission !== 'denied' || state.permission != null) {
      setLocation();
    }
    const interval = setInterval(setLocation, REQUEST_PERIOD);
    return () => clearInterval(interval);
  }, [checkPermission, setLocation, state.permission]);

  useEffect(() => {
    // TODO: SigninGeolocation component will flash for a moment, some workaround is needed
    setIsAlways();
  }, [setIsAlways]);

  const value = useMemo(
    () => ({
      isAlways: state.isAlways,
      location: state.location,
      permission: state.permission,
      setLocation,
      checkPermission,
      requestPermission,
      setIsAlways,
    }),
    [state, setLocation, checkPermission, requestPermission, setIsAlways]
  );

  return <LocationContext.Provider value={value} {...props} />;
};

const useLocation = () => React.useContext(LocationContext);

export { LocationProvider, useLocation };
