import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SosContactInputType, SosContactType, useSosContactCreateMutation } from '../../generated/graphql';
import { SOSContactDetail } from '../../components/sos-contact/SOSContactDetail';
import { Title } from '../../components/title/Title';

const NewSOSContacts = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const [soscontactCreate, { loading }] = useSosContactCreateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  function mutationSosContact(soscontact: SosContactType) {
    const newSOSContact = soscontact as SosContactInputType;
    soscontactCreate({
      variables: {
        input: newSOSContact,
      },
    });
  }

  return (
    <IonPage>
      <Title translucent title={t('Add new contact')} backButton />
      <IonContent>
        {loading && <IonLoading isOpen message={t('Loading...')} />}
        <SOSContactDetail
          formSubmitHandler={(value) => mutationSosContact(value as SosContactType)}
          submitButtonText={t('Create')}
        />
      </IonContent>
    </IonPage>
  );
};

export { NewSOSContacts };
