import { IonContent, IonLoading, IonPage } from '@ionic/react';
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';

// graphql
import { useTranslation } from 'react-i18next';
import {
  CreatePrescriptionMedicineInputType,
  useCreatePrescriptionMedicineMutation,
  useMedicinesQuery,
  MedicineTypeConnection,
} from '../../generated/graphql';

// i18n

// components
import { PrescriptionMedicine } from '../../components/prescription-medicine/PrescriptionMedicine';
import { Title } from '../../components/title/Title';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';

const NewPrescriptionMedicinePage = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { prescription_id } = useParams() as { prescription_id: string };

  const { data, loading, error } = useMedicinesQuery({ fetchPolicy: 'no-cache' });

  const [createPrescriptionMedicine, { loading: createPrescriptionMedicineLoading }] =
    useCreatePrescriptionMedicineMutation({
      onCompleted() {
        goBack();
      },
    });

  const handleCreatePrescriptionMedicine = async (input: CreatePrescriptionMedicineInputType) => {
    await createPrescriptionMedicine({
      variables: {
        input: {
          ...input,
          prescriptionId: prescription_id,
        },
      },
    });
  };

  if (loading || !data) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title translucent backButton title="New medicine" />
      <IonContent>
        {createPrescriptionMedicineLoading && <IonLoading isOpen message={t('Loading...')} />}
        <PrescriptionMedicine
          medicines={data.medicines as MedicineTypeConnection}
          mutation={handleCreatePrescriptionMedicine}
        />
      </IonContent>
    </IonPage>
  );
};

export { NewPrescriptionMedicinePage };
