import React from 'react';

import { useHistory } from 'react-router-dom';
import { PatientFileType } from '../../generated/graphql';
import { ItemSliding } from '../UI/ItemSliding/ItemSliding';

interface IPatientFileItem {
  patientFile: PatientFileType;
  removePatientFile: (id: string) => void;
}

const PatientFileItem: React.FC<IPatientFileItem> = ({ patientFile, removePatientFile }) => {
  const { push } = useHistory();

  return (
    <ItemSliding
      label={`${patientFile.file.title}`}
      onItemClick={() => push(`/patient-files/${patientFile.id}`)}
      onItemRemove={() => removePatientFile(patientFile.id)}
    />
  );
};

export { PatientFileItem };
