import React from 'react';
import classNames from 'classnames/bind';
import { Rating } from 'react-simple-star-rating';
import { useHistory } from 'react-router-dom';
import { IonCard, IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { ReviewDoctorType } from '../../../generated/graphql';
import styles from './ClinicDoctor.module.scss';
import { Image } from '../../UI/Image/Image';

const cx = classNames.bind(styles);

interface IClinicDoctor {
  doctor: ReviewDoctorType;
}

const ClinicDoctor = ({ doctor }: IClinicDoctor) => {
  const { push } = useHistory();

  return (
    <IonCard onClick={() => push(`../review-doctors/${doctor.id}`)} className={cx(styles.ionCard)} mode="ios">
      <IonGrid>
        <IonRow>
          <IonCol>
            <Image url={doctor.avatar?.url} className={cx(styles.ionAvatar)} />
          </IonCol>
          <IonCol>
            <IonItem className={cx(styles.item)}>{`Name : ${doctor.firstName} ${doctor.secondName}`}</IonItem>
            <IonItem className={cx(styles.item)}>{`Specialization : ${doctor.specialization}`}</IonItem>
            <IonItem className={cx(styles.item)}>{`Work time : ${doctor.workTime}`}</IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonItem className={cx(styles.item)}>
            <Rating fillColor="var(--ion-color-primary)" allowFraction readonly initialValue={doctor.averageStars} />
          </IonItem>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export { ClinicDoctor };
