import React, { useCallback, useEffect, useState } from 'react';
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonAlert,
} from '@ionic/react';

// graphql
import { useHistory } from 'react-router-dom';

// form
import { Controller, DeepPartial, useForm } from 'react-hook-form';

// i18n
import { useTranslation } from 'react-i18next';

// icons
import { calendarOutline } from 'ionicons/icons';

// styles
import classNames from 'classnames/bind';
import styles from './UserDataForm.module.scss';
import {
  UserSex,
  PatientEpilepsyType,
  UserType,
  UserDataInputType,
  useUserDataSetMutation,
  useUploadUserAvatarMutation,
  useRemoveUserAvatarMutation,
  MeDocument,
  useGeneratePresignedUrlLazyQuery,
} from '../../generated/graphql';
import { useAuth } from '../../contexts/auth-context';
import { PhotoLoader } from '../photo-loader/PhotoLoader';
import { SearchView, IChosenLocation } from '../search-view/SearchView';
import { TopSearchField } from '../UI/TopSearchField/TopSearchField';
import { YandexMap } from '../map/YandexMap';
import { useLocation } from '../../contexts/location-context';
import { useSearchView } from '../search-view/useSearchView';
import { formatDisplayDate } from '../../utils/utils';

const cx = classNames.bind(styles);

interface Props {
  me?: UserType;
  greetingText?: string;
  isRegistration: boolean;
}

const defaultValuesConstructor = (config: { map: boolean; haveAvatar: boolean }, me?: UserType) => ({
  avatar: me?.avatar,
  firstName: me?.firstName || '',
  secondName: me?.secondName || '',
  thirdName: me?.thirdName || '',
  dateOfBirth: me?.dateOfBirth || ('' as unknown as DeepPartial<unknown>[]),
  sex: me?.sex || ('' as unknown as UserSex),
  menstrualCorrelation: me?.patientSet.edges[0].node.menstrualCorrelation || false,
  diagnosis: me?.patientSet.edges[0].node.diagnosis || '',
  epilepsyType: me?.patientSet.edges[0].node.epilepsyType || ('' as unknown as PatientEpilepsyType),
  address: me?.address || '',
  password: '',
  map: config.map,
  haveAvatar: config.haveAvatar,
});

const UserDataForm = ({ me, greetingText, isRegistration }: Props) => {
  const { t } = useTranslation();
  const { location } = useLocation();
  const authContext = useAuth();
  const { goBack } = useHistory();
  const [present] = useIonAlert();

  const [isYandexMapOpen, setIsYandexMapOpen] = useState(false);
  const [chosenLocation, setChosenLocation] = useState<IChosenLocation | null>();
  const [showSearchView, toggleSearchView] = useSearchView();
  const [changedAvatar, setChangedAvatar] = useState<File>();
  const { handleSubmit, control, setValue, watch, errors } = useForm<UserDataInputType>({
    mode: 'onBlur',
    defaultValues: defaultValuesConstructor(
      {
        haveAvatar: !!me?.avatar,
        map: !!(me?.location?.coordinates || location?.coords),
      },
      me
    ),
  });

  const { sex: watchUserSex, dateOfBirth: watchDateOfBirth } = watch(['sex', 'dateOfBirth']);
  const [uploadUserAvatarMutation] = useUploadUserAvatarMutation();
  const [getGeneratePresignedUrl] = useGeneratePresignedUrlLazyQuery();
  const [removeUserAvatarMutation] = useRemoveUserAvatarMutation({
    refetchQueries: [MeDocument],
    onCompleted: (data) => {
      if (data.removeUserAvatar.success) {
        authContext.setUser({ ...authContext.user, avatar: undefined });
      }
    },
  });

  // Passing current user location to map point
  useEffect(() => {
    if (me?.location?.coordinates) {
      setChosenLocation({
        coordinates: me.location.coordinates,
        displayName: me.address,
      });
    } else if (location?.coords) {
      setChosenLocation({
        coordinates: [location?.coords.latitude, location?.coords.longitude],
        displayName: '',
      });
    }
  }, [location, setChosenLocation, me?.location.coordinates, me?.address]);

  const toggleYandexMap = useCallback(() => {
    setIsYandexMapOpen((prevState) => !prevState);
  }, [setIsYandexMapOpen]);

  const [userDataSet, { error, called }] = useUserDataSetMutation({
    onCompleted: (data) => {
      authContext.setUser(data.userDataSet.user as UserType);
      if (!isRegistration) goBack();
    },
  });

  const handleRegistration = useCallback(
    (payload: UserDataInputType) => {
      if (chosenLocation) {
        const updatedPayload: UserDataInputType = {
          ...payload,
          location: { coordinates: chosenLocation.coordinates, type: 'POINT' },
        };

        userDataSet({
          variables: {
            input: updatedPayload,
          },
        });
      }
    },
    [chosenLocation, userDataSet]
  );

  const setNewAvatar = useCallback(async () => {
    if (changedAvatar && authContext.user?.id) {
      const result = await getGeneratePresignedUrl({ variables: { filename: changedAvatar?.name } });
      if (result.data?.getPresignedUrl) {
        await fetch(result.data?.getPresignedUrl, {
          method: 'PUT',
          body: changedAvatar,
        });
        await uploadUserAvatarMutation({
          variables: {
            input: {
              userId: authContext.user.id,
              file: result.data?.getPresignedUrl,
            },
          },
          refetchQueries: [MeDocument],
        });
      }
      setValue('haveAvatar', true, { shouldDirty: true, shouldValidate: true });
    }
  }, [authContext.user, changedAvatar, getGeneratePresignedUrl, setValue, uploadUserAvatarMutation]);

  const removeAvatar = useCallback(async () => {
    setChangedAvatar(undefined);
    if (me?.avatar) {
      await removeUserAvatarMutation({
        variables: {
          input: {
            avatarId: me.avatar.id,
          },
        },
        refetchQueries: [MeDocument],
      });
    }
  }, [me?.avatar, removeUserAvatarMutation]);

  const onRemoveConfirmation = useCallback(
    () =>
      present({
        header: `${t('Avatar will be removed. Are you sure?')}`,
        buttons: [`${t('Cancel')}`, { text: `${t('Delete')}`, handler: () => removeAvatar() }],
      }),
    [present, removeAvatar, t]
  );

  const saveUserData = useCallback(
    async ({ map, haveAvatar, ...data }: UserDataInputType & { map: boolean; haveAvatar: boolean }) => {
      await setNewAvatar();
      await handleRegistration(data);
    },
    [handleRegistration, setNewAvatar]
  );

  const onAddressConfirm = useCallback(
    (coordinates: [number, number], displayName: string) => {
      setChosenLocation({
        coordinates,
        displayName,
      });
      toggleYandexMap();
      setValue('map', true, { shouldDirty: true, shouldValidate: true });
    },
    [setValue, toggleYandexMap]
  );

  return (
    <>
      <IonContent style={{ display: isYandexMapOpen ? 'none' : 'block' }}>
        {called && !error && <IonLoading isOpen />}
        <IonGrid className={cx(styles.ionGrid, 'ion-no-padding')}>
          <IonRow className={cx(styles.inputFieldsContainer, 'ion-padding-horizontal')}>
            {greetingText && (
              <IonItem className={cx(styles.greetingItem, 'ion-no-padding')} lines="none">
                <div>{t(greetingText)}</div>
              </IonItem>
            )}
            <PhotoLoader
              onPhotoRemove={onRemoveConfirmation}
              photo={authContext.user?.avatar}
              onPhotoChange={(image: File) => {
                setChangedAvatar(image);
                setValue('haveAvatar', true, { shouldDirty: true, shouldValidate: true });
              }}
            />
            <IonItem className={cx(styles.inputItem, { 'border-color-danger': errors.secondName })} lines="none">
              <Controller
                render={({ onChange, value, onBlur }) => (
                  <IonInput
                    className="ion-padding"
                    onIonBlur={onBlur}
                    placeholder={t('Surname')}
                    inputMode="text"
                    type="text"
                    onIonChange={(e) => onChange(e.detail.value)}
                    value={value}
                  />
                )}
                control={control}
                name="secondName"
                rules={{
                  required: true,
                }}
              />
            </IonItem>

            <IonItem className={styles.inputItem} lines="none">
              <Controller
                render={({ onChange, value }) => (
                  <IonInput
                    className="ion-padding"
                    placeholder={t('Name')}
                    inputMode="text"
                    type="text"
                    onIonChange={(e) => onChange(e.detail.value)}
                    value={value}
                  />
                )}
                control={control}
                name="firstName"
                rules={{
                  required: true,
                }}
              />
            </IonItem>

            <IonItem className={cx(styles.inputItem, { 'border-color-danger': errors.thirdName })} lines="none">
              <Controller
                render={({ onChange, value, onBlur }) => (
                  <IonInput
                    onIonBlur={onBlur}
                    className="ion-padding"
                    placeholder={t('Third name')}
                    inputMode="text"
                    type="text"
                    onIonChange={(e) => onChange(e.detail.value)}
                    value={value}
                  />
                )}
                control={control}
                name="thirdName"
                rules={{
                  required: true,
                }}
              />
            </IonItem>

            <IonItem
              id="open-birth-input"
              className={cx(styles.inputItem, { 'border-color-danger': errors.dateOfBirth })}
              lines="none"
            >
              <IonLabel>{watchDateOfBirth ? formatDisplayDate(watchDateOfBirth) : `${t('Date of birth')}:`}</IonLabel>
              <Controller
                render={({ onChange, value, onBlur }) => {
                  return (
                    <IonPopover trigger="open-birth-input" onDidDismiss={onBlur}>
                      <IonDatetime
                        className={cx(styles.datetime, 'ion-no-padding')}
                        presentation="date"
                        onIonChange={(e) => {
                          onChange(e.detail.value);
                          onBlur();
                        }}
                        cancelText={t('Cancel')}
                        doneText={t('Done')}
                        value={value}
                      />
                    </IonPopover>
                  );
                }}
                control={control}
                name="dateOfBirth"
                rules={{
                  required: true,
                }}
              />
              <IonIcon className={cx(styles.icon, 'ion-no-margin')} icon={calendarOutline} slot="end" />
            </IonItem>
            <Controller
              render={({ onChange, value, onBlur }) => {
                return (
                  <IonSelect
                    className={cx(styles.inputSelect, { 'border-color-danger': errors.sex })}
                    placeholder={t('Sex')}
                    onIonBlur={onBlur}
                    interface="action-sheet"
                    cancel-text={t('Cancel')}
                    onIonChange={(e) => {
                      if (e.detail.value === UserSex.A_1) {
                        setValue('menstrualCorrelation', false);
                      }
                      onChange(e.detail.value);
                    }}
                    value={value}
                  >
                    <IonSelectOption value={UserSex.A_1}>{t('Male')}</IonSelectOption>
                    <IonSelectOption value={UserSex.A_2}>{t('Female')}</IonSelectOption>
                  </IonSelect>
                );
              }}
              control={control}
              name="sex"
              rules={{
                required: true,
              }}
            />
            <IonItem className={cx(styles.inputItem, styles.checkboxItem, 'ion-no-padding')} lines="none">
              <Controller
                render={({ onChange, value }) => (
                  <>
                    <IonLabel>{t('Menstrual correlation')}</IonLabel>
                    <IonCheckbox
                      disabled={watchUserSex !== UserSex.A_2}
                      className="ion-no-padding"
                      onIonChange={(e) => onChange(e.detail.checked)}
                      slot="end"
                      checked={value}
                    />
                  </>
                )}
                control={control}
                name="menstrualCorrelation"
              />
            </IonItem>

            <IonItem className={cx(styles.inputItem, { 'border-color-danger': errors.diagnosis })} lines="none">
              <Controller
                render={({ onChange, value, onBlur }) => (
                  <IonInput
                    className="ion-padding"
                    placeholder={t('Your epileptic diagnosis in registration')}
                    inputMode="text"
                    type="text"
                    onIonBlur={onBlur}
                    onIonChange={(e) => onChange(e.detail.value)}
                    value={value}
                  />
                )}
                control={control}
                rules={{
                  required: true,
                }}
                name="diagnosis"
              />
            </IonItem>

            <Controller
              render={({ onChange, onBlur, value }) => (
                <IonSelect
                  className={cx(styles.inputSelect, { 'border-color-danger': errors.epilepsyType })}
                  placeholder={t('Epilepsy type')}
                  onIonBlur={onBlur}
                  onIonChange={(e) => {
                    onChange(e.detail.value);
                  }}
                  value={value}
                  cancel-text={t('Cancel')}
                  interface="action-sheet"
                >
                  <IonSelectOption value={PatientEpilepsyType.A_1}>{t('Arbitrary')}</IonSelectOption>
                  <IonSelectOption value={PatientEpilepsyType.A_2}>{t('Post traumatic')}</IonSelectOption>
                  <IonSelectOption value={PatientEpilepsyType.A_3}>{t('Stroke')}</IonSelectOption>
                  <IonSelectOption value={PatientEpilepsyType.A_4}>{t('Cerebral paralysis')}</IonSelectOption>
                </IonSelect>
              )}
              control={control}
              name="epilepsyType"
              rules={{
                required: true,
              }}
            />

            <IonItem className={cx(styles.inputItem, { 'border-color-danger': errors.address })} lines="none">
              <Controller
                render={({ onChange, onBlur }) => (
                  <IonInput
                    className="ion-padding"
                    placeholder={t('Address')}
                    readonly
                    inputMode="text"
                    type="text"
                    onIonChange={(e) => {
                      onChange(e.detail.value);
                      onBlur();
                    }}
                    value={chosenLocation?.displayName}
                  />
                )}
                control={control}
                name="address"
                rules={{
                  required: !!isRegistration,
                }}
              />
              <IonButton className={styles.switchHomeLocationButton} onClick={toggleYandexMap}>
                {t('Change')}
              </IonButton>
            </IonItem>

            <IonItem className={cx('ion-hide')} lines="none">
              <Controller
                render={({ onChange, value }) => (
                  <IonCheckbox
                    className="ion-no-padding"
                    onIonChange={(e) => onChange(e.detail.checked)}
                    slot="end"
                    checked={value}
                  />
                )}
                control={control}
                name="map"
                rules={{
                  required: true,
                }}
              />
            </IonItem>
            <IonItem className={cx('ion-hide')} lines="none">
              <Controller
                render={({ onChange, value }) => (
                  <IonCheckbox
                    className="ion-no-padding"
                    onIonChange={(e) => onChange(e.detail.checked)}
                    slot="end"
                    checked={value}
                  />
                )}
                control={control}
                name="haveAvatar"
              />
            </IonItem>
          </IonRow>
          <IonRow className={styles.buttonRow}>
            <IonCol>
              <IonButton
                className={cx(styles.button, 'ion-no-margin')}
                onClick={handleSubmit(saveUserData)}
                // disabled={!(formState.isValid && formState.isDirty) || (called && !error)}
                expand="block"
              >
                {t('Save')}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {isYandexMapOpen && (
        <>
          <SearchView
            goBack={toggleSearchView}
            showView={showSearchView}
            onLocationSelect={(loc: IChosenLocation) => {
              toggleSearchView();
              setChosenLocation(loc);
            }}
          />
          {!showSearchView && (
            <TopSearchField
              onSearchFieldClick={toggleSearchView}
              value={chosenLocation?.displayName}
              onGoBackClick={() => {
                toggleYandexMap();
              }}
            />
          )}
          <YandexMap
            mapState={
              chosenLocation?.coordinates
                ? {
                    center: chosenLocation?.coordinates,
                    zoom: 19,
                    controls: [],
                  }
                : undefined
            }
            onAddressConfirm={onAddressConfirm}
          />
        </>
      )}
    </>
  );
};

UserDataForm.defaultProps = {
  me: undefined,
  greetingText: undefined,
  isRegistration: false,
};

export { UserDataForm };
