import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import classNames from 'classnames/bind';
import { IonItem } from '@ionic/react';
import { ReviewEntryType, ReviewType } from '../../generated/graphql';
import { ReviewEntry } from './review-entry/ReviewEntry';
import styles from './ReviewDetail.module.scss';
import { DisclosureDropdown } from '../UI/DisclosureDropdown/DisclosureDropdown';

const cx = classNames.bind(styles);

interface IReviewDetail {
  review: ReviewType;
}

const ReviewDetail = ({ review }: IReviewDetail) => {
  const { t } = useTranslation();

  return (
    <>
      <IonItem className={cx(styles.item)}>{`Name : ${review.author.firstName} ${review.author.secondName}`}</IonItem>
      <IonItem className={cx(styles.item)}>
        <Rating fillColor="var(--ion-color-primary)" allowFraction readonly initialValue={review.averageEntryStars} />
      </IonItem>
      <IonItem className={cx(styles.item)}>{`Comment : ${review.comment}`}</IonItem>
      <DisclosureDropdown title={t('Review entries')}>
        {review.reviewentrySet.edges.length > 0 ? (
          review.reviewentrySet.edges.map((edge) => (
            <ReviewEntry key={edge.node.id} reviewEntry={edge.node as ReviewEntryType} />
          ))
        ) : (
          <IonItem>{t('No review entries')}</IonItem>
        )}
      </DisclosureDropdown>
    </>
  );
};

export { ReviewDetail };
