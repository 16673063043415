import React, { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { IonIcon, IonLabel, IonList, IonItem, IonAlert, IonRow, IonCol, IonInput, IonBackdrop } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { locationOutline } from 'ionicons/icons';
import { withYMaps } from 'react-yandex-maps';
import styles from './SearchView.module.scss';

import { useLocation } from '../../contexts/location-context';
import { backButtonCircleIcon } from '../../assets/images/icons/icons';

export interface ISuggestion {
  displayName: string;
  hl: [number, number][];
  type: string;
  value: string;
  geoCoordinates: [number, number];
}

export interface IChosenLocation {
  displayName: string;
  coordinates: [number, number];
}

interface IProps {
  goBack: () => void;
  ymaps?: any;
  showView: boolean;
  onLocationSelect?: (location: IChosenLocation) => void;
}

const cx = classnames.bind(styles);

const SearchViewComponent = ({ goBack, ymaps, showView, onLocationSelect }: IProps) => {
  const { t } = useTranslation();
  const { requestPermission } = useLocation();
  const [suggestions, setSuggestions] = useState<ISuggestion[]>([]);
  const [showGeolocationAlert, setShowGeolocationAlert] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (showView) {
      timer = setTimeout(() => setIsVisible(true), 100);
    } else {
      setIsVisible(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showView]);

  const getSuggestions = useCallback(
    async (searchText: string) => {
      const fetchedSuggestions = await ymaps.suggest(searchText, { results: 5 });
      setSuggestions(fetchedSuggestions);
    },
    [setSuggestions, ymaps]
  );

  const handleSuggestionClick = useCallback(
    async (suggestion: any) => {
      // setSelectedSuggestion(suggestion);
      const res = await ymaps.geocode(suggestion.value);
      const geoCoordinates: [number, number] = res.geoObjects.get(0).geometry.getCoordinates();
      if (onLocationSelect) {
        onLocationSelect({ displayName: suggestion.displayName, coordinates: geoCoordinates });
      }
      setSuggestions([]);
    },
    [ymaps, onLocationSelect]
  );

  return (
    <div className={cx('ion-align-items-center')}>
      {showView && (
        <IonRow className={cx('search-two-field-form', { 'search-two-field-form--visible': isVisible })}>
          <IonCol size="1.3" offset="0.2">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={goBack} className={cx('toolbarIconButton', 'locationButton')}>
              <IonIcon slot="start" color="dark" className={cx('toolbar__back-button')} icon={backButtonCircleIcon} />
            </div>
          </IonCol>
          <IonCol offset="0.5" size="10" className={cx('searchColumn')}>
            {/* <IonInput className={cx("addressInput")} /> */}
            <IonItem className={cx('searchColumnItem')} mode="md">
              <IonInput
                onIonChange={({ detail: { value } }) => {
                  if (value) {
                    getSuggestions(value);
                  } else {
                    setSuggestions([]);
                  }
                }}
                className={cx('ion-no-padding', 'searchColumn__input')}
                autofocus
                debounce={600}
                placeholder={t('Enter address')}
              />
            </IonItem>
          </IonCol>
          <IonCol size="12">
            {/* eslint-disable react/no-array-index-key */}
            <IonList className={cx('listContainer')}>
              {!!suggestions.length &&
                suggestions.map((data: { displayName: string; value: string }, index, array) => (
                  <IonItem
                    detail={false}
                    button
                    onClick={() => handleSuggestionClick(data)}
                    key={index}
                    lines={array.length - 1 === index ? 'none' : 'inset'}
                    className={cx('list-item')}
                  >
                    <IonIcon className={cx('search-result-icon')} size="small" icon={locationOutline} slot="start" />
                    <IonLabel className="ion-text-wrap">
                      <h2>{t(data.displayName)}</h2>
                    </IonLabel>
                  </IonItem>
                ))}
            </IonList>
          </IonCol>
        </IonRow>
      )}
      <IonAlert
        isOpen={showGeolocationAlert}
        header={t('Geolocation')}
        message={t(`Allow application to track your location?\n
                    You can turn it off at any time in your phone settings`)}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
            handler: () => setShowGeolocationAlert(false),
          },
          {
            text: t('Allow'),
            handler: async () => {
              const locationPermissionGranted = await requestPermission();
              if (!locationPermissionGranted) {
                setShowGeolocationAlert(false);
              }
            },
          },
        ]}
      />

      {showView && (
        <IonBackdrop className={cx('backdrop', { 'backdrop--visible': isVisible })} onIonBackdropTap={goBack} />
      )}
    </div>
  );
};

SearchViewComponent.defaultProps = {
  ymaps: undefined,
  onLocationSelect: undefined,
};

const SearchView = withYMaps(SearchViewComponent, true);

export { SearchView };
