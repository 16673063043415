import React from 'react';
import styles from './Image.module.scss';
import personImage from '../../../assets/images/person.png';

export interface IImageProps {
  url: string;
  className?: string;
}

const Image = ({ url, className }: IImageProps) => {
  return <img className={`${styles.image} ${className}`} src={url} alt="" />;
};

Image.defaultProps = {
  url: personImage,
  className: '',
};

export { Image };
