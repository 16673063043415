import { useCallback, useState } from 'react';

const useSearchView = (): [boolean, () => void] => {
  const [showSearchView, setShowSearchView] = useState(false);

  const toggleSearchView = useCallback(() => {
    setShowSearchView((prevState) => !prevState);
  }, []);

  return [showSearchView, toggleSearchView];
};

export { useSearchView };
