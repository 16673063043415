import { useEffect } from 'react';
import { isPlatform, useIonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { AppLauncher } from '@capacitor/app-launcher';
import { useVersionQuery } from '../generated/graphql';

const useVersionCheck = () => {
  const { t } = useTranslation();
  const androidVersion = 26;
  const iosVersion = 10;

  const [alert] = useIonAlert();
  const version = useVersionQuery({
    fetchPolicy: 'no-cache',
  });

  const minIosVersion = Number(version.data?.version.versionIos);
  const minAndroidVersion = Number(version.data?.version.versionAndroid);

  useEffect(() => {
    if (androidVersion < minAndroidVersion && isPlatform('android')) {
      alert({
        cssClass: 'my-css',
        header: t('Please update your app'),
        message: t('A new version has been released'),
        backdropDismiss: false,
        keyboardClose: false,
        buttons: [
          {
            text: t('Update'),
            handler: () => {
              AppLauncher.openUrl({ url: `market://details?id=dev.staq.epihelper` });
              return false;
            },
          },
        ],
      });
    } else if (iosVersion < minIosVersion && isPlatform('ios')) {
      alert({
        cssClass: 'my-css',
        header: t('Please update your app'),
        message: t('A new version has been released'),
        backdropDismiss: false,
        keyboardClose: false,
        buttons: [
          {
            text: t('Update'),
            handler: () => {
              AppLauncher.openUrl({ url: `itms-apps://itunes.apple.com/app/id1578085874` });
              return false;
            },
          },
        ],
      });
    }
  }, [t, alert, minAndroidVersion, minIosVersion]);
};

export { useVersionCheck };
