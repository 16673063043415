import React from 'react';

import { useHistory } from 'react-router-dom';
import { SosContactType } from '../../generated/graphql';
import { ItemSliding } from '../UI/ItemSliding/ItemSliding';

interface ISOSContactItem {
  soscontact: SosContactType;
  removeSOSContact: (id: string) => void;
}

const SOSContactItem: React.FC<ISOSContactItem> = ({ soscontact, removeSOSContact }) => {
  const { push } = useHistory();

  return (
    <ItemSliding
      label={`${soscontact.firstName}: ${soscontact.phone}`}
      onItemClick={() => push(`/sos-contacts/${soscontact.id}`)}
      onItemRemove={() => removeSOSContact(soscontact.id)}
    />
  );
};

export { SOSContactItem };
