import React from 'react';
import { IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { WomenPeriodsQuery, WomenPeriodType } from '../../generated/graphql';
import { NoElementFound } from '../empty-state/NoElementFound';
import { WomenCalendarListItem } from './WomenCalendarListItem/WomenCalendarListItem';

interface Props {
  data: WomenPeriodsQuery;
  removeWomenPeriod: (id: string) => void;
}

const WomenCalendarList = ({ removeWomenPeriod, ...props }: Props) => {
  const { t } = useTranslation();

  const data = props.data.womenPeriods.edges;

  if (data.length === 0) {
    return <NoElementFound title={t('No records')} />;
  }
  return (
    <div>
      {/* TODO: reimplement search
        <IonInput
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          className={styles.searchbar}
          placeholder={t("Search")}
        />
*/}
      <IonList>
        {data?.map(({ node }) => (
          <WomenCalendarListItem
            key={node.id}
            removeWomenPeriod={removeWomenPeriod}
            womenPeriod={node as WomenPeriodType}
          />
        ))}
      </IonList>
    </div>
  );
};

export { WomenCalendarList };
