import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PatientFileType, usePatientFilesQuery, useRemovePatientFileMutation } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { AddButton } from '../../components/UI/AddButton/AddButton';
import { Title } from '../../components/title/Title';
import { PatientFileList } from '../../components/patient-files/PatientFileList';

const PatientFilesPage: React.FC = () => {
  const { data, loading, error, refetch } = usePatientFilesQuery({
    fetchPolicy: 'no-cache',
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const { t } = useTranslation();

  const [removePatientFileMutation, { loading: removePatientFileLoading }] = useRemovePatientFileMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const handleRemovePatientFile = async (id: string) => {
    await removePatientFileMutation({
      variables: {
        input: { id },
      },
    });
  };

  if (loading) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        title={t('Documents upload')}
        menuButton
        sort={{
          sortButton: true,
          callback: () => {},
        }}
      />
      <IonContent>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          <>
            <PatientFileList
              removePatientFile={handleRemovePatientFile}
              patientFiles={data?.patientFiles.edges.map((edge) => edge.node as PatientFileType) || []}
            />
            <AddButton routeLink="/patient-files/new" />
          </>
        )}
      </IonContent>
      {!loading && removePatientFileLoading && <IonLoading isOpen message={t('Loading...')} />}
    </IonPage>
  );
};

export { PatientFilesPage };
