import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonContent, IonIcon, IonLoading, IonPage, IonTextarea } from '@ionic/react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';
import { gql, useMutation, useSubscription } from '@apollo/client';
import { paperPlane } from 'ionicons/icons';
import styles from './Chat.module.scss';
import { Title } from '../../components/title/Title';
import { useAuth } from '../../contexts/auth-context';
import { usePatientDoctorsQueryByPatientIdQuery } from '../../generated/graphql';
import { DayType, MessageList } from '../../components/chat/message-list/MessageList';
import { generateItems } from '../../components/chat/message-list/utilsChat';
import { useDoctorOnlineStatusSubscription } from '../../hooks/useDoctorOnlineStatusSubscription';

export type MessageType = {
  id: number;
  text: string;
  from_user_id: number;
  to_user_id: number;
  created_at: string;
  __typename: 'chat_messages';
};

const MESSAGES_SUBSCRIPTION = gql`
  subscription messagesSubscription($user1: Int!, $user2: Int!) {
    chat_message(
      where: {
        _or: [
          { _and: { from_user_id: { _eq: $user1 }, to_user_id: { _eq: $user2 } } }
          { _and: { from_user_id: { _eq: $user2 }, to_user_id: { _eq: $user1 } } }
        ]
      }
    ) {
      from_user_id
      id
      text
      to_user_id
      created_at
    }
  }
`;

const SUBMIT_MESSAGES = gql`
  mutation InsertMessages($text: String!, $fromUserId: Int!, $toUserId: Int!) {
    insert_chat_message(objects: { text: $text, from_user_id: $fromUserId, to_user_id: $toUserId }) {
      returning {
        id
        text
        from_user_id
        to_user_id
      }
    }
  }
`;

const cx = classnames.bind(styles);

const Chat = () => {
  const authContext = useAuth();
  const { t } = useTranslation();
  const [messagesToShow, setMessagesToShow] = useState<(MessageType | DayType)[]>([]);

  const { register, handleSubmit, setValue } = useForm();

  const [sendMessageMutation] = useMutation(SUBMIT_MESSAGES, {
    context: {
      clientName: 'chat',
    },
  });

  const { loading: loadingDoctorIDs, data: { patientDoctorsByPatientId: fetchedPatientDoctors } = {} } =
    usePatientDoctorsQueryByPatientIdQuery({
      variables: {
        id: authContext.user!.patientSet!.edges[0].node.id,
      },
      fetchPolicy: 'no-cache',
    });

  let doctorId: string | undefined;
  if (fetchedPatientDoctors && fetchedPatientDoctors.length > 0) {
    // TODO: start changing here if you want to add messaging with several doctors (hard-coded first doctor)
    doctorId = fetchedPatientDoctors[0].user.id;
  }
  const showOnline = useDoctorOnlineStatusSubscription(doctorId);

  const { loading: loadingSubscription, data: { chat_message: fetchedMessages } = {} } = useSubscription<{
    chat_message: MessageType[];
  }>(MESSAGES_SUBSCRIPTION, {
    skip: loadingDoctorIDs || !doctorId,
    variables: {
      user1: doctorId,
      user2: authContext.user!.id,
    },
  });

  useEffect(() => {
    if (fetchedMessages) {
      const messages = generateItems(fetchedMessages);
      setMessagesToShow(messages);
    }
  }, [fetchedMessages]);

  const onMessageSubmit = (data: { message: string }) => {
    const message = data.message.trim();
    if (message.length > 0) {
      sendMessageMutation({
        variables: {
          text: message,
          fromUserId: authContext.user!.id,
          toUserId: doctorId,
        },
      });
    }
    setValue('message', '');
  };

  if (loadingSubscription || loadingDoctorIDs) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title translucent title={t('Chat with doctor')} backButton />
      {doctorId && (showOnline ? <div className={styles.greenSpan} /> : <div className={styles.redSpan} />)}
      <IonContent scroll-y="false">
        {doctorId ? (
          <div className={cx(styles.chatContainer)}>
            <div className={cx(styles.chat)}>
              {messagesToShow && messagesToShow?.length > 0 ? (
                <MessageList messages={messagesToShow as MessageType[]} />
              ) : (
                <div className={cx(styles.container)}>
                  <div className={cx(styles.styleImg)} />
                  <div className={cx(styles.styleTitle)}>{t('There are no messages in your chat')}</div>
                </div>
              )}
            </div>
            <div className={cx(styles.newMessage)}>
              <IonTextarea
                inputMode="text"
                className={cx(styles.newMessage_inputField)}
                autoGrow
                rows={1}
                placeholder={t('Message')}
                name="message"
                required
                ref={register({ required: true })}
              />
              <IonIcon
                className={styles.newMessage_sendButton}
                icon={paperPlane}
                onClick={handleSubmit(onMessageSubmit)}
              />
            </div>
          </div>
        ) : (
          <div className={cx(styles.container)}>
            <div className={cx(styles.styleImgDoctor)} />
            <div className={cx(styles.styleTitle)}>{t('You are not assigned to any doctor yet.')}</div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export { Chat };
