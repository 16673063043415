import React from 'react';
import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { mapOutline, optionsOutline, swapVerticalOutline } from 'ionicons/icons';
import classNames from 'classnames/bind';
import styles from './ReviewClinicsHeader.module.scss';
import { ReviewFilter } from './review-filter/ReviewFilter';
import { ReviewSort } from './review-sort/ReviewSort';

const cx = classNames.bind(styles);

interface IReviewClinicsHeader {
  setShowReviewMap: React.Dispatch<React.SetStateAction<boolean>>;
  clinicsLength: number;
}

const ReviewClinicsHeader = ({ setShowReviewMap, clinicsLength }: IReviewClinicsHeader) => {
  const { t } = useTranslation();

  const sortValues = [
    {
      id: '1',
      title: 'Name A-Z',
      name: 'name',
    },
    {
      id: '2',
      title: 'Name Z-A',
      name: '-name',
    },
    {
      id: '3',
      title: 'Rating increase',
      name: 'avg_stars',
    },
    {
      id: '4',
      title: 'Descending rating',
      name: '-avg_stars',
    },
  ];

  return (
    <IonButtons className={cx('ionButtons')}>
      <IonButton id="open-review-header-sort-button" className={cx('ionButtons__btn')} mode="ios">
        <IonIcon color="tertiary" className={cx('ionButtons__btn-icon')} icon={swapVerticalOutline} />
        <span className={cx('ionButtons__btn-text')}>{t('Sort')}</span>
      </IonButton>
      <ReviewSort sortValues={sortValues} trigger="open-review-header-sort-button" />
      <IonButton id="open-review-header-filter-button" className={cx('ionButtons__btn')} mode="ios">
        <IonIcon color="tertiary" className={cx('ionButtons__btn-icon')} icon={optionsOutline} />
        <span className={cx('ionButtons__btn-text')}>{t('Filter')}</span>
      </IonButton>
      <ReviewFilter trigger="open-review-header-filter-button" clinicsLength={clinicsLength} />
      <IonButton onClick={() => setShowReviewMap(true)} className={cx('ionButtons__btn')} mode="ios">
        <IonIcon color="tertiary" className={cx('ionButtons__btn-icon')} icon={mapOutline} />
        <span className={cx('ionButtons__btn-text')}>{t('Map')}</span>
      </IonButton>
    </IonButtons>
  );
};

export { ReviewClinicsHeader };
