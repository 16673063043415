import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isSameMonth } from 'date-fns';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import {
  useRemoveWomenPeriodMutation,
  useWomenPeriodQuery,
  useWomenPeriodUpdateMutation,
  WomenPeriodType,
} from '../../generated/graphql';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';
import { WomenPeriodDetail } from '../../components/women-period/WomenPeriodDetail';
import { formatDisplayMonth } from '../../utils/utils';

const WomenCalendarPage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const toast = useToast();
  const { data, loading, error, refetch } = useWomenPeriodQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  });
  const { goBack } = useHistory();
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const [updateWomenPeriod, { loading: updateWomenPeriodLoading }] = useWomenPeriodUpdateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const [removeWomenPeriod, { loading: removeWomenPeriodLoading }] = useRemoveWomenPeriodMutation({
    onCompleted: () => {
      toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
      goBack();
    },
  });

  const handleFormSubmission = useCallback(
    async (param: any) => {
      await updateWomenPeriod({
        variables: {
          input: { ...param, id },
        },
      });
    },
    [updateWomenPeriod, id]
  );

  const handleRemoveWomenPeriod = useCallback(async () => {
    await removeWomenPeriod({
      variables: {
        input: { id },
      },
    });
  }, [removeWomenPeriod, id]);

  if (loading || !data?.womenPeriod) {
    return <IonLoading isOpen={loading} message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  const { womenPeriod } = data;

  const generateTitle = (startDateValue: string, endDateValue: string): string => {
    // Determines to show one or both months
    if (isSameMonth(new Date(startDateValue), new Date(endDateValue))) {
      return t(formatDisplayMonth(startDateValue));
    }
    return `${t(formatDisplayMonth(startDateValue))} - ${t(formatDisplayMonth(endDateValue))}`;
  };

  return (
    <IonPage>
      <Title
        translucent
        title={generateTitle(womenPeriod.startDate, womenPeriod.endDate)}
        backButton
        delete={{
          deleteButton: true,
          callback: handleRemoveWomenPeriod,
        }}
      />
      <IonContent>
        {(updateWomenPeriodLoading || removeWomenPeriodLoading) && <IonLoading isOpen message={t('Loading...')} />}
        <WomenPeriodDetail womenPeriod={womenPeriod as WomenPeriodType} formSubmitHandler={handleFormSubmission} />
      </IonContent>
    </IonPage>
  );
};

export { WomenCalendarPage };
