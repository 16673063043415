import { PatientSeizuresQuery } from '../../generated/graphql';

export type TDate = {
  year: number;
  month: number;
};

export function getArraysWithDaysOfMonth(year: number, month: number) {
  const currentMonthForJSDate = month - 1;
  const previousMonthForJSDate = month - 2;

  const currnetDate = new Date(year, currentMonthForJSDate, 1);
  const firstDay: number = currnetDate.getDay();
  const currentMonthTotalDays: number = new Date(year, currentMonthForJSDate + 1, 0).getDate();
  const previousMonthTotalDays: number = new Date(year, previousMonthForJSDate + 1, 0).getDate();

  const nextMonthFirstDay = new Date(year, month, 1).getDay();

  const nextMonthDays = Array.from(
    Array(
      (() => {
        if (nextMonthFirstDay === 1) return 0;
        if (nextMonthFirstDay === 0) return 1;
        return 8 - nextMonthFirstDay;
      })()
    ),
    (_, i) => i + 1
  );
  const currentMonthDays = Array.from(Array(currentMonthTotalDays), (_, i) => i + 1);
  const previousMonthDays = Array.from(
    Array(firstDay ? firstDay - 1 : 6),
    (_, i) => previousMonthTotalDays - i
  ).reverse();
  return [previousMonthDays, currentMonthDays, nextMonthDays];
}

export function getMonthSeizures(data: PatientSeizuresQuery, date: TDate) {
  return data.patientSeizures.edges
    .filter((item) => {
      const itemDate = new Date(item.node.date);
      return itemDate.getFullYear() === date.year && itemDate.getMonth() + 1 === date.month;
    })
    .map((item) => {
      return { day: new Date(item.node.date).getDate(), dataItem: item };
    });
}

export function nextMonth(date: TDate) {
  const today = new Date();
  if (date.year >= today.getFullYear() && date.month >= today.getMonth() + 1) return date;

  if (date.month === 12) {
    return {
      year: date.year + 1,
      month: 1,
    };
  }
  return {
    year: date.year,
    month: date.month + 1,
  };
}

export function previousMonth(date: TDate) {
  if (date.month === 1) {
    return {
      year: date.year - 1,
      month: 12,
    };
  }
  return {
    year: date.year,
    month: date.month - 1,
  };
}
