import React, { useMemo } from 'react';
import { IonGrid, IonList } from '@ionic/react';

import { useTranslation } from 'react-i18next';
import { PatientPrescriptionsQuery, PrescriptionType } from '../../generated/graphql';

// components
import { PrescriptionsItem } from './PrescriptionsItem/PrescriptionsItem';
import { NoElementFound } from '../empty-state/NoElementFound';

interface IPrescriptions {
  searchValue?: string;
  data: PatientPrescriptionsQuery;
  removeMedicine: (id: string) => void;
}

const Prescriptions: React.FC<IPrescriptions> = ({ searchValue, data, removeMedicine }) => {
  const { t } = useTranslation();

  return useMemo(() => {
    const prescriptionsEdges = data.patientPrescriptions.edges;
    if (prescriptionsEdges?.length) {
      return (
        <IonGrid>
          <IonList>
            {prescriptionsEdges
              .filter(({ node }) => !searchValue || node.description.toLowerCase().includes(searchValue.toLowerCase()))
              .map(({ node }) => (
                <PrescriptionsItem
                  key={node.id}
                  removePrescription={removeMedicine}
                  prescription={node as PrescriptionType}
                />
              ))}
          </IonList>
        </IonGrid>
      );
    }

    return <NoElementFound title={t('Prescriptions list is empty')} />;
  }, [data.patientPrescriptions.edges, removeMedicine, searchValue, t]);
};

export { Prescriptions };
