import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener';

class S3BucketService {
  blobToBase64(blob: Blob) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  downloadFileFromUrl = async (url: string, filename = 'EpihelperFile'): Promise<'SUCCESS'> => {
    const response = await fetch(url);
    const blob = await response.blob();
    const base64: any = await this.blobToBase64(blob);
    const currentDate = new Date().toLocaleString().replace(/[,:\s\\/]/g, '-');
    const fileName = `${filename}-${currentDate}`;
    const file = await Filesystem.writeFile({
      path: fileName,
      recursive: true,
      directory: Directory.External,
      data: base64,
    });
    await FileOpener.open(file.uri, blob.type);

    return 'SUCCESS';
  };
}

export const s3BucketService = new S3BucketService();
