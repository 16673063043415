import { useMemo } from 'react';
import { AuthContextType } from '../contexts/auth-context';
import { UserSex } from '../generated/graphql';

const useDetermineWomenCalendarNavItem = <T extends { title: string }>(
  navItems: T[],
  authContext: AuthContextType
): { menuItems: T[] } => {
  const menuItems = useMemo(() => {
    if (authContext.user?.sex === UserSex.A_2) {
      return navItems;
    }
    const result = navItems.filter((page) => !page.title.toLowerCase().includes("women's calendar"));
    return result;
  }, [navItems, authContext.user?.sex]);
  return { menuItems };
};

export { useDetermineWomenCalendarNavItem };
