import { IonInput, IonItem, IonSpinner } from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames/bind';
import styles from './PatientFileDetail.module.scss';
import { WideButton } from '../UI/WideButton/WideButton';
import { FileField } from '../file-field/FileField';
import { useGeneratePresignedUrlLazyQuery } from '../../generated/graphql';
import { s3BucketService } from '../../services/S3Bucket.service';

type PatientFileFormType = { title: string; file: File | undefined | null };
export type PatientFileSubmitType = { title: string; file: string };

interface IPatientFileProps {
  formSubmitHandler: (data: PatientFileSubmitType) => void;
  submitButtonText: string;
  defaultValue?: { title: string; fileUrl: string };
}

const cx = classNames.bind(styles);

const PatientFileDetail = ({ formSubmitHandler, submitButtonText, defaultValue }: IPatientFileProps) => {
  const { title = '', fileUrl = '' } = defaultValue || {};
  const { t } = useTranslation();
  const [showDownloadSpinner, setShowDownloadSpinner] = useState(false);
  const [getGeneratePresignedUrl] = useGeneratePresignedUrlLazyQuery();
  const { control, handleSubmit, errors, setValue } = useForm<PatientFileFormType>({
    mode: 'onBlur',
    defaultValues: {
      title,
      file: fileUrl,
    },
  });

  const handleFileSelect = useCallback(
    (fileParam: File | null) => {
      setValue('file', fileParam);
    },
    [setValue]
  );

  const generatePresignedUrl = async (titleParam: string, file: File) => {
    const result = await getGeneratePresignedUrl({ variables: { filename: file?.name } });
    if (result.data?.getPresignedUrl) {
      await fetch(result.data?.getPresignedUrl, {
        method: 'PUT',
        body: file,
      });
      return { title: titleParam, file: result.data?.getPresignedUrl };
    }
    throw new Error("Can't get presigned url");
  };

  return defaultValue ? (
    <>
      <IonItem className={styles.titleContainer} lines="none">
        <IonInput value={defaultValue.title} className="ion-padding" readonly />
      </IonItem>
      <WideButton
        type="button"
        disabled={showDownloadSpinner}
        onClick={async () => {
          setShowDownloadSpinner(true);
          await s3BucketService.downloadFileFromUrl(defaultValue!.fileUrl, defaultValue.title);
          setShowDownloadSpinner(false);
        }}
      >
        {showDownloadSpinner ? (
          <>
            {t('Downloading')}
            <IonSpinner className={styles.downloadButtonSpinner} color="secondary" name="crescent" />
          </>
        ) : (
          <div>{t('Download')}</div>
        )}
      </WideButton>
    </>
  ) : (
    <form
      onSubmit={handleSubmit(async ({ title: titleParam, file }) => {
        if (file) {
          setShowDownloadSpinner(true);
          try {
            const payload = await generatePresignedUrl(titleParam, file);
            formSubmitHandler(payload);
          } catch (e) {
            // TODO: handle displaying error if generatingPresignedUrl goes wrong
            console.error(`Can't generate presigned URL for file upload`);
          }
          setShowDownloadSpinner(false);
        }
      })}
    >
      <IonItem className={cx(styles.titleContainer, { 'border-color-danger': errors.title })} lines="none">
        <Controller
          render={({ onChange, value, onBlur }) => (
            <IonInput
              onIonChange={(e) => onChange(e)}
              onIonBlur={onBlur}
              value={value}
              className="ion-padding"
              placeholder={t('Title')}
              inputMode="text"
              type="text"
              autocapitalize="words"
            />
          )}
          control={control}
          defaultValue=""
          name="title"
          rules={{
            required: true,
          }}
        />
      </IonItem>
      <IonItem className={cx(styles.fileContainer)} lines="none">
        <Controller
          render={() => <FileField onFileSelect={handleFileSelect} error={errors.file} />}
          control={control}
          name="file"
          rules={{
            required: true,
          }}
        />
      </IonItem>

      <WideButton disabled={showDownloadSpinner} type="submit">
        {showDownloadSpinner ? (
          <>
            {t('Creating')}
            <IonSpinner className={styles.downloadButtonSpinner} color="secondary" name="crescent" />
          </>
        ) : (
          <div>{submitButtonText}</div>
        )}
      </WideButton>
    </form>
  );
};

PatientFileDetail.defaultProps = {
  defaultValue: undefined,
};

export { PatientFileDetail };
