import React from 'react';
import { IonButton, IonIcon, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Rating } from 'react-simple-star-rating';
import { swapVerticalOutline } from 'ionicons/icons';
import { ClinicReviewsGroupByCharacteristicType, ReviewType } from '../../generated/graphql';
import { ClinicReview } from '../review-clinic/clinic-review/ClinicReview';
import { DisclosureDropdown } from '../UI/DisclosureDropdown/DisclosureDropdown';
import { averageNumber } from '../../utils/utils';
import { ReviewSort } from '../review-clinics/review-header/review-sort/ReviewSort';

interface IClinicReviewsDetailProps {
  clinicReviews: ReviewType[];
  starsByCharacteristics: ClinicReviewsGroupByCharacteristicType[];
}

const ClinicReviewsDetail = ({ clinicReviews, starsByCharacteristics }: IClinicReviewsDetailProps) => {
  const { t } = useTranslation();

  const sortValues = [
    {
      id: '1',
      title: 'New reviews',
      name: '-created_at',
    },
    {
      id: '2',
      title: 'Old reviews',
      name: 'created_at',
    },
    {
      id: '3',
      title: 'Rating increase',
      name: 'avg_entry_stars',
    },
    {
      id: '4',
      title: 'Descending rating',
      name: '-avg_entry_stars',
    },
  ];

  const AverageStarsByCharacteristics =
    averageNumber(starsByCharacteristics?.map((element) => element.averageStars)) || [];

  return (
    <>
      <DisclosureDropdown isOpen title={t(`${t('Stars by characteristics')} ${AverageStarsByCharacteristics}`)}>
        {starsByCharacteristics &&
          starsByCharacteristics.map((element) => (
            <IonItem key={element.characteristic} mode="ios">
              <IonItem>{element.characteristic}</IonItem>
              <Rating fillColor="var(--ion-color-primary)" allowFraction readonly initialValue={element.averageStars} />
            </IonItem>
          ))}
      </DisclosureDropdown>
      <IonButton style={{ width: '100%' }} id="open-reviews-sort-button">
        {t('Sort')}
        <IonIcon color="tertiary" icon={swapVerticalOutline} />
      </IonButton>
      <ReviewSort sortValues={sortValues} trigger="open-reviews-sort-button" />
      {clinicReviews.length > 0 ? (
        clinicReviews.map((review) => <ClinicReview key={review.id} review={review} />)
      ) : (
        <IonItem>{t('No reviews')}</IonItem>
      )}
    </>
  );
};

export { ClinicReviewsDetail };
