import { IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  useSeizureLazyQuery,
  useSeizureUpdateMutation,
  useRemoveSeizureMutation,
  SeizureStatus,
} from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';
import { SeizureFormWithMap } from '../../components/seizure/SeizureFormWithMap';
import { SeizureWithSeparateDateAndTimeInputType } from '../../components/seizure/SeizureForm';

const SeizurePage: React.FC = () => {
  const { id: seizureId } = useParams() as { id: string };
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const toast = useToast();

  const [getSeizure, { data, loading, error, refetch }] = useSeizureLazyQuery({ variables: { id: seizureId } });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  useEffect(() => {
    getSeizure();
  }, [getSeizure]);

  const [seizureUpdate, { loading: seizureUpdateLoading }] = useSeizureUpdateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const [removeSeizure, { loading: removeSeizureLoading }] = useRemoveSeizureMutation({
    onCompleted() {
      toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
      // history.push('/seizures');
      goBack();
    },
  });

  const mutationSeizure = useCallback(
    async ({ time, ...seizure }: SeizureWithSeparateDateAndTimeInputType) => {
      const [dateOnly] = seizure.date.split('T');
      const [, timeOnly] = time.split('T');
      const updateSeizure: any = {
        ...seizure,
        date: `${dateOnly}T${timeOnly}`,
        status: SeizureStatus.A_1,
        id: seizureId,
      };

      await seizureUpdate({
        variables: {
          input: updateSeizure,
        },
      });
    },
    [seizureUpdate, seizureId]
  );

  const handleRemoveSeizure = useCallback(async () => {
    await removeSeizure({
      variables: {
        input: { id: seizureId },
      },
    });
  }, [removeSeizure, seizureId]);

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  // TODO: maybe take time from 'data.seizure.date' and get rid off date
  const seizureWithSeparateDateAndTime = data ? { ...data.seizure, time: data.seizure.date } : undefined;

  return (
    <IonPage>
      <Title
        translucent
        title={t('Edit')}
        backButton
        delete={{
          deleteButton: true,
          callback: handleRemoveSeizure,
        }}
      />
      <SeizureFormWithMap
        onSubmit={(value) => mutationSeizure(value)}
        submitting={loading}
        seizure={seizureWithSeparateDateAndTime}
        submitButtonText={t('Save')}
      />
      {((!loading && (seizureUpdateLoading || removeSeizureLoading)) || !data?.seizure.id) && (
        <IonLoading isOpen message={t('Loading...')} />
      )}
    </IonPage>
  );
};

export { SeizurePage };
