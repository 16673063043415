import React, { useEffect, useState } from 'react';
import { IonRouterOutlet, isPlatform } from '@ionic/react';
import { Route, Redirect } from 'react-router-dom';
import { Network } from '@capacitor/network';
import { Preferences } from '@capacitor/preferences';

import { Home } from '../pages/home/Home';
import { ProfilePage } from '../pages/profile/Profile';
import { ProfileSettingsPage } from '../pages/profile-settings/ProfileSettings';
import { CalendarPage } from '../pages/calendar/Calendar';
import { SigninPage } from '../pages/signin/Signin';
import { PrescriptionsPage } from '../pages/prescriptions/Prescriptions';
import { PrescriptionPage } from '../pages/prescription/Prescription';
import { NewPrescriptionPage } from '../pages/new-prescription/NewPrescription';
import { PrescriptionMedicinePage } from '../pages/prescription-medicine/PrescriptionMedicine';
import { NewPrescriptionMedicinePage } from '../pages/new-prescription-medicine/NewPrescriptionMedicine';
import { MedicinesPage } from '../pages/medicines/Medicines';
import { MedicinePage } from '../pages/medicine/Medicine';
import { NewMedicine } from '../pages/new-medicine/NewMedicine';
import { SeizuresPage } from '../pages/seizures/Seizures';
import { SeizurePage } from '../pages/seizure/Seizure';
import { NewSeizurePage } from '../pages/new-seizure/NewSeizure';
import { SOSContactsPage } from '../pages/sos-contacts/SOSContacts';
import { SOSContactPage } from '../pages/sos-contact/SOSContact';
import { NewSOSContacts } from '../pages/new-sos-contacts/NewSOSContacts';
import { useAuth } from '../contexts/auth-context';
import { DeviceRecordsPage } from '../pages/device-records-chart/DeviceRecordsChartPage';
import { MedicinesIntakePage } from '../pages/medicines-intake/MedicinesIntake';
import { NoConnection } from '../pages/no-connection/NoConnection';
import { SigninGeolocation } from '../components/signin/SigninGeolocation';
import { useLocation } from '../contexts/location-context';
import { WomenCalendarListPage } from '../pages/women-calendar-list/WomenCalendarList';
import { WomenCalendarPage } from '../pages/women-calendar/WomenCalendar';
import { NewWomenPeriod } from '../pages/new-women-period/NewWomenPeriod';
import { Chat } from '../pages/chat/Chat';
import { PatientFilesPage } from '../pages/patient-files/PatientFiles';
import { PatientFilePage } from '../pages/patient-file/PatientFile';
import { ReviewClinicsPage } from '../pages/review-clinics/ReviewClinics';
import { NewPatientFilePage } from '../pages/new-patient-file/NewPatientFilePage';
import { FooterTab } from '../components/footer-tabs/FooterTab';
import { ReviewClinicPage } from '../pages/review-clinic/ReviewClinic';
import { ReviewPage } from '../pages/review/Review';
import { ReviewsPage } from '../pages/reviews/Reviews';
import { ReviewDoctorPage } from '../pages/review-doctor/ReviewDoctor';

export interface AppPageLink {
  id: number;
  url: string;
  title: string;
}

export interface AppPage extends AppPageLink {
  icon: string;
  iconStyle: string;
  description: string;
}

export interface AppRoute {
  title: string;
  url: string;
  page: React.FC;
}

const routes: AppRoute[] = [
  {
    title: 'Home',
    url: '/home',
    page: Home,
  },
  {
    title: 'Profile',
    url: '/profile',
    page: ProfilePage,
  },
  {
    title: 'Profile Settings',
    url: '/profile-settings',
    page: ProfileSettingsPage,
  },
  {
    title: 'Prescriptions',
    url: '/prescriptions',
    page: PrescriptionsPage,
  },
  {
    title: 'Prescription',
    url: '/prescriptions/:id(\\d+)',
    page: PrescriptionPage,
  },
  {
    title: 'New Prescription',
    url: '/prescriptions/new',
    page: NewPrescriptionPage,
  },
  {
    title: 'Prescription Medicine',
    url: '/prescriptions/:prescription_id(\\d+)/medicines/:medicine_id(\\d+)',
    page: PrescriptionMedicinePage,
  },
  {
    title: 'New Prescription Medicine',
    url: '/prescriptions/:prescription_id(\\d+)/medicines/new',
    page: NewPrescriptionMedicinePage,
  },
  {
    title: 'Medicines',
    url: '/medicines',
    page: MedicinesPage,
  },
  {
    title: 'Medicine',
    url: '/medicines/:id(\\d+)',
    page: MedicinePage,
  },
  {
    title: 'New medicine',
    url: '/medicines/new',
    page: NewMedicine,
  },
  {
    title: 'Seizures',
    url: '/seizures/',
    page: SeizuresPage,
  },
  {
    title: 'Seizure',
    url: '/seizures/:id(\\d+)',
    page: SeizurePage,
  },
  {
    title: 'New Seizures',
    url: '/seizures/new',
    page: NewSeizurePage,
  },
  {
    title: 'SOS Contacts',
    url: '/sos-contacts',
    page: SOSContactsPage,
  },
  {
    title: 'SOS Contact',
    url: '/sos-contacts/:id(\\d+)',
    page: SOSContactPage,
  },
  {
    title: 'New Contact',
    url: '/sos-contacts/new',
    page: NewSOSContacts,
  },
  {
    title: 'Patient Files',
    url: '/patient-files',
    page: PatientFilesPage,
  },
  {
    title: 'Patient File',
    url: '/patient-files/:id(\\d+)',
    page: PatientFilePage,
  },
  {
    title: 'Review Clinics',
    url: '/review-clinics',
    page: ReviewClinicsPage,
  },
  {
    title: 'Review Clinic',
    url: '/review-clinics/:id(\\d+)',
    page: ReviewClinicPage,
  },
  {
    title: 'Review Doctor',
    url: '/review-doctors/:id(\\d+)',
    page: ReviewDoctorPage,
  },
  {
    title: 'Review',
    url: '/review/:id(\\d+)',
    page: ReviewPage,
  },
  {
    title: 'Reviews',
    url: '/reviews/:id(\\d+)',
    page: ReviewsPage,
  },
  {
    title: 'New Patient File',
    url: '/patient-files/new',
    page: NewPatientFilePage,
  },
  {
    title: 'Calendar',
    url: '/calendar',
    page: CalendarPage,
  },
  {
    title: "Women's Calendar",
    url: '/women-calendar',
    page: WomenCalendarListPage,
  },
  {
    title: "Women's Calendar",
    url: '/women-calendar/:id(\\d+)',
    page: WomenCalendarPage,
  },
  {
    title: 'New Record',
    url: '/women-calendar/new',
    page: NewWomenPeriod,
  },
  {
    title: 'Device Charts',
    url: '/device-charts',
    page: DeviceRecordsPage,
  },
  {
    title: 'Device Charts',
    url: '/device-charts',
    page: DeviceRecordsPage,
  },
  {
    title: 'Medicines Intake',
    url: '/medicines-intake',
    page: MedicinesIntakePage,
  },
  {
    title: 'Chat',
    url: '/chat',
    page: Chat,
  },
];

const Router: React.FC = () => {
  const authContext = useAuth();
  const [internetConnectionStatus, setInternetConnectionStatus] = useState<boolean>(true);
  const [toggleSkip, setToggleSkip] = useState<boolean>(false);
  const { isAlways } = useLocation();

  useEffect(() => {
    const checkName = async () => {
      const { value } = await Preferences.get({ key: 'LocationSkip' });
      if (value != null) setToggleSkip(!!JSON.parse(value));
    };
    checkName();
  }, []);

  useEffect(() => {
    Preferences.set({
      key: 'LocationSkip',
      value: JSON.stringify(toggleSkip),
    });
  }, [toggleSkip]);

  useEffect(() => {
    Network.addListener('networkStatusChange', (status: any) => {
      setInternetConnectionStatus(status.connected);
    });

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  if (!internetConnectionStatus) {
    return <NoConnection internetConnectionStatus />;
  }

  const isAndroidPlatform = isPlatform('android') && !isPlatform('mobileweb');
  if (isAndroidPlatform && !isAlways) {
    return <SigninGeolocation />;
  }

  const isIOSPlatform = isPlatform('ios') && !isPlatform('mobileweb');
  if (isIOSPlatform && !toggleSkip && !isAlways) {
    return <SigninGeolocation setToggleSkip={setToggleSkip} />;
  }

  // if (authContext.isFetchingFromStorage) {
  //   return (<IonLoading isOpen={true} message={t('Loading...')} />);
  // }

  if (!authContext.registered) {
    return <SigninPage />;
  }

  return (
    <FooterTab>
      <IonRouterOutlet id="main">
        {routes.map((route) => (
          <Route key={route.url} exact path={route.url} component={route.page} />
        ))}
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
      </IonRouterOutlet>
    </FooterTab>
  );
};

export { Router };
