import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

// styles
import './Signin.css';

// components
import { ScreensSwitcher } from '../../screensSwitcher/ScreensSwitcher';
import { SigninCodeInput } from '../../components/signin/SigninCodeInput';
import { UserDataForm } from '../../components/user-data-form/UserDataForm';
import { SigninNumberInput } from '../../components/signin/SigninNumberInput';
// import SigninGeolocation from '../../components/signin/SigninGeolocation';

const SigninPage: React.FC = () => {
  // TODO: implement graphql mutations for signing up and query for token
  // and then pass these mutations/callbacks to the appropriate component below

  return (
    <IonPage>
      <IonContent>
        <ScreensSwitcher>
          <SigninNumberInput />
          <SigninCodeInput />
          {/* TODO: uncomment this to enable geolocation toggling */}
          {/* <SigninGeolocation /> */}
          <UserDataForm isRegistration />
        </ScreensSwitcher>
      </IonContent>
    </IonPage>
  );
};

export { SigninPage };
