import React from 'react';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useHistory } from 'react-router-dom';
import styles from './AddButton.module.scss';

interface Props {
  routeLink?: string;
  onClick?: (e: React.MouseEvent<HTMLIonFabButtonElement, MouseEvent>) => void;
}

const AddButton = ({ onClick, routeLink }: Props) => {
  const { push } = useHistory();
  return (
    <IonFab vertical="bottom" horizontal="end" slot="fixed">
      <IonFabButton
        className={styles.AddButton}
        onClick={(e) => {
          onClick?.(e);
          push(routeLink ?? '#');
        }}
      >
        <IonIcon color="bright" icon={addOutline} />
      </IonFabButton>
    </IonFab>
  );
};

AddButton.defaultProps = {
  routeLink: '',
  onClick: () => {},
};

export { AddButton };
