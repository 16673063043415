import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import React, { useEffect } from 'react';
import { IonApp, IonLoading, IonPage, IonSplitPane, IonToast, isPlatform, setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

/* Translation */
import './i18n/i18n';

import { App as CapacitorApp } from '@capacitor/app';
import { AppMinimize } from '@ionic-native/app-minimize';
import { BackgroundMode } from '@ionic-native/background-mode';
import { SplashScreen } from '@capacitor/splash-screen';
import { useTranslation } from 'react-i18next';
import { useLocation } from './contexts/location-context';
import { useAuth } from './contexts/auth-context';
import { useToast } from './contexts/toast-context';
import { usePushNotifications } from './hooks/usePushNotifications';

import { Router } from './routes/Router';
import { Menu } from './components/menu/Menu';
import { useVersionCheck } from './hooks/useVersionCheck';
import { usePatientOnlineMutation } from './hooks/usePatientOnlineMutation';

setupIonicReact();

const App: React.FC = () => {
  const { t } = useTranslation();
  const toastContext = useToast();
  const authContext = useAuth();
  const [registerPush, isActive] = usePushNotifications();
  const { checkPermission, setIsAlways } = useLocation();
  const { requestPermission } = useLocation();

  useVersionCheck();
  usePatientOnlineMutation(authContext);

  useEffect(() => {
    SplashScreen.show({
      showDuration: 3000,
      autoHide: true,
    });
  }, []);

  // Request Geolocation permission
  useEffect(() => {
    if (isPlatform('capacitor')) {
      requestPermission();
    }
  }, [requestPermission]);

  useEffect(() => {
    if (isPlatform('capacitor')) {
      BackgroundMode.setDefaults({
        title: t('Epihelper is running'),
        text: t('Tap for more information or stop the app.'),
      });
      BackgroundMode.enable();
      BackgroundMode.on('activate').subscribe(() => {
        BackgroundMode.disableWebViewOptimizations();
      });
    }

    if (isPlatform('ios') && isPlatform('capacitor')) {
      StatusBar.setStyle({ style: Style.Light });
    }

    CapacitorApp.addListener('backButton', () => {
      const path = globalThis.location.pathname;
      if (path === '/' || path === '/home') {
        AppMinimize.minimize();
      }
    });

    CapacitorApp.addListener('appStateChange', (appState) => {
      if (appState.isActive) {
        checkPermission();
        setIsAlways();
      }
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform() && authContext.user?.id && authContext.registered && !isActive) {
      registerPush(authContext.user.id);
    }
  }, [authContext, authContext.user, isActive, registerPush]);

  return (
    <IonApp>
      {authContext.isFetchingFromStorage ? (
        <IonLoading isOpen message={t('Loading...')} />
      ) : (
        <IonSplitPane contentId="main">
          {authContext.user?.id && <Menu />}
          <IonPage id="main">
            <Router />
          </IonPage>
        </IonSplitPane>
      )}
      <IonToast
        isOpen={!!toastContext.message}
        onDidDismiss={() => toastContext.dispatch({ type: 'DISMISS', message: '' })}
        message={toastContext.message}
        color={toastContext.color}
        duration={4200}
      />
    </IonApp>
  );
};

export { App };
