import { IonContent, IonPage } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '../../components/title/Title';

const DeviceRecordsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <Title title={t('Device records')} translucent menuButton />
      <IonContent>
        <Title title={t('Device records')} menuButton />
      </IonContent>
    </IonPage>
  );
};

export { DeviceRecordsPage };
