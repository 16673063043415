import { gql, useSubscription } from '@apollo/client';

const ONLINE_STATUS_SUBSCRIPTION = gql`
  subscription messagesSubscription($userId: Int!) {
    user_online(where: { _or: [{ _and: { userOnline: { id: { _eq: $userId } } } }] }) {
      id
      last_seen
    }
  }
`;

function useDoctorOnlineStatusSubscription(doctorUserId: string | undefined) {
  const { data: { user_online: userOnline } = {} } = useSubscription<{
    user_online: any[];
  }>(ONLINE_STATUS_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {
      userId: doctorUserId,
    },
    skip: !doctorUserId,
  });

  return userOnline && userOnline.length > 0;
}

export { useDoctorOnlineStatusSubscription };
