import React, { useState } from 'react';
import { IonButton, IonInput } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../UI/Modal/Modal';
import styles from './TimeSetter.module.scss';
import { convertToMinutes, convertToSeconds } from '../../utils/utils';

interface Props {
  setTimeHandler: (time: number) => void;
  seconds: number;
  closeModal: () => void;
}

const TimeSetter = ({ setTimeHandler, seconds, closeModal }: Props) => {
  const [time, setTime] = useState(seconds);
  const { t } = useTranslation();

  return (
    <Modal title={t('Define the duration')} closeModal={closeModal}>
      <div className={styles.changeNumberInputWrapper}>
        <div className={styles.changeNumberInput}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className={styles.button} onClick={() => setTime(time + 60)}>
            +
          </div>
          <div className={styles.durationInputWrapper}>
            <IonInput
              type="number"
              pattern="[0-9]*"
              min="0"
              placeholder="0"
              value={convertToMinutes(time)} // minutes
              className={styles.durationInputField}
              onIonChange={(e) => {
                if (Number(e.detail.value) > 0) {
                  setTime(convertToSeconds(time) + Number(e.detail.value) * 60);
                } else {
                  setTime(convertToSeconds(time) + -Number(e.detail.value) * 60);
                }
              }}
            />
            <span>{t('Minute(s)')}</span>
          </div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.button}
            onClick={() => {
              if (time >= 60) {
                setTime(time - 60);
              }
            }}
          >
            -{' '}
          </div>
        </div>

        <div className={styles.changeNumberInput}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.button}
            onClick={() => {
              if (convertToSeconds(time) === 59) {
                setTime(time - 59);
              } else {
                setTime(time + 1);
              }
            }}
          >
            +
          </div>

          <div className={styles.durationInputWrapper}>
            <IonInput
              pattern="[0-9]*"
              value={convertToSeconds(time)} // seconds
              className={styles.durationInputField}
              maxlength={2}
              placeholder="0"
              onIonChange={(e) => {
                if (Number(e.detail.value) < 60) {
                  setTime(convertToMinutes(time) * 60 + Number(e.detail.value));
                } else {
                  setTime(convertToMinutes(time) * 60 + 59);
                }
              }}
            />
            <span>{t('Second(s)')}</span>
          </div>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.button}
            onClick={() => {
              if (convertToSeconds(time) === 0) {
                setTime(time + 59);
              } else {
                setTime(time - 1);
              }
            }}
          >
            -{' '}
          </div>
        </div>
      </div>
      <div className={styles.validateButtons}>
        <IonButton
          color="primary"
          onClick={() => {
            setTimeHandler(time);
            closeModal();
          }}
        >
          {t('Confirm')}
        </IonButton>

        <IonButton onClick={closeModal} color="secondary">
          {t('Cancel')}
        </IonButton>
      </div>
    </Modal>
  );
};

export { TimeSetter };
