import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import {
  PatientMedicineType,
  usePatientMedicineWithMedicinesLazyQuery,
  useUpdatePatientMedicineMutation,
  useRemovePatientMedicineMutation,
} from '../../generated/graphql';
import { MedicineDetail, MedicineDetailOutput } from '../../components/medicine/MedicineDetail';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';

const MedicinePage = () => {
  const { id } = useParams() as { id: string };
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const toast = useToast();
  const [getPatientMedicineWithMedicines, { data, loading, error, refetch }] = usePatientMedicineWithMedicinesLazyQuery(
    {
      variables: { id },
      fetchPolicy: 'no-cache',
    }
  );

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  useEffect(() => {
    getPatientMedicineWithMedicines();
  }, [getPatientMedicineWithMedicines]);

  const [updatePatientMedicine, { loading: updatePatientMedicineLoading }] = useUpdatePatientMedicineMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const [removePatientMedicine, { loading: removePatientMedicineLoading }] = useRemovePatientMedicineMutation({
    onCompleted: () => {
      toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
      goBack();
    },
  });

  const handleFormSubmission = useCallback(
    async (medicineDetailOutput: MedicineDetailOutput) => {
      await updatePatientMedicine({
        variables: {
          input: { ...medicineDetailOutput, id },
        },
      });
    },
    [updatePatientMedicine, id]
  );

  const handleRemovePatientMedicine = useCallback(async () => {
    await removePatientMedicine({
      variables: {
        input: { id },
      },
    });
  }, [removePatientMedicine, id]);

  if (loading || !data?.patientMedicine) {
    return <IonLoading isOpen={loading} message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  const { patientMedicine } = data;

  return (
    <IonPage>
      <Title
        translucent
        title={patientMedicine.medicine.name}
        backButton
        delete={{
          deleteButton: true,
          callback: handleRemovePatientMedicine,
        }}
      />
      <IonContent>
        {(updatePatientMedicineLoading || removePatientMedicineLoading) && (
          <IonLoading isOpen message={t('Loading...')} />
        )}
        <MedicineDetail
          patientMedicine={patientMedicine as PatientMedicineType}
          formSubmitHandler={handleFormSubmission}
          submitButtonText={t('Save')}
        />
      </IonContent>
    </IonPage>
  );
};

export { MedicinePage };
