import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useLocation } from 'react-router';
import { useMeQuery, usePatientSeizuresQuery } from '../../generated/graphql';
import { Calendar } from '../../components/calendar/Calendar';
import { PatientSeizuresChart } from '../../components/patient-seizures-chart/PatientSeizuresChart';
import { Title } from '../../components/title/Title';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { convertHtmlToPdf } from '../../utils/pdf';
import styles from './Calendar.module.scss';

const CalendarPage = () => {
  const { t } = useTranslation();
  const { data, loading, error } = usePatientSeizuresQuery({
    fetchPolicy: 'no-cache',
  });
  const { data: name } = useMeQuery({
    fetchPolicy: 'no-cache',
  });
  const printStuff = useRef<any>(null);
  const { search } = useLocation();
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const daysOfWeekShortNames = [t('Mn'), t('Tu'), t('Wd'), t('Th'), t('Fr'), t('St'), t('Sn')];
  const monthNames = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];

  useIonViewWillEnter(() => {
    const downloadPDF = new URLSearchParams(search);
    const isDownloadPDF: boolean = JSON.parse(downloadPDF.get('downloadPDF') || 'false');

    if (isDownloadPDF) {
      setDownloadingPDF(true);
      setTimeout(() => {
        setDownloadingPDF(false);
        return convertHtmlToPdf(printStuff, name?.me.secondName);
      }, 2000);
    }
  }, [search, name?.me.secondName]);

  if (loading) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      {downloadingPDF && <IonLoading isOpen message={t('Loading...')} />}
      <Title
        translucent
        title="Calendar"
        color="primary"
        menuButton
        shareButton={{
          shareButton: !!data,
          callback: () => convertHtmlToPdf(printStuff, name?.me.secondName),
        }}
      />

      <IonContent fullscreen className={styles.purpleBackground}>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          <div className={styles.calendarWrapper}>
            {data && (
              <Calendar
                year={currentYear}
                month={currentMonth}
                data={data}
                daysOfWeekShortNames={daysOfWeekShortNames}
              />
            )}
            <div ref={printStuff}>{data && <PatientSeizuresChart data={data} monthNames={monthNames} />}</div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export { CalendarPage };
