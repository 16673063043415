import { useCallback, useState } from 'react';

const useToggleState = (initialValue: boolean) => {
  const [state, setState] = useState<boolean>(initialValue);

  const toggleState = useCallback(() => {
    setState((prevState) => !prevState);
  }, [setState]);

  return [state, toggleState, setState] as const; // Added `const` to return appropriate type
};

export { useToggleState };
