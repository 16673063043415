import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames/bind';
import { IonIcon } from '@ionic/react';
import { cloudUploadOutline, trashSharp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { FieldError } from 'react-hook-form';
import styles from './FileField.module.scss';

const cx = classNames.bind(styles);

interface IFileFieldProps {
  onFileSelect?: (file: File | null) => void;
  error?: FieldError;
  defaultValue?: string;
}

const FileField = ({ onFileSelect, error, defaultValue }: IFileFieldProps) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [preferDefaultValue, setPreferDefaultValue] = useState(!!defaultValue);
  const { t } = useTranslation();

  // Pass file to parent component
  useEffect(() => {
    if (selectedFile) {
      onFileSelect?.(selectedFile);
    } else {
      onFileSelect?.(null);
    }
  }, [onFileSelect, selectedFile, preferDefaultValue]);

  const removeFile = () => {
    if (preferDefaultValue) {
      setPreferDefaultValue(false);
    }
    fileRef.current!.value = '';
    setSelectedFile(undefined);
  };

  const launchFilePicker = () => {
    fileRef.current?.click();
  };

  const uploadedState = preferDefaultValue || selectedFile;

  return (
    <>
      <button
        type="button"
        className={cx(
          styles.container,
          {
            [styles.containerUploaded]: uploadedState,
          },
          { 'border-color-danger': error && !selectedFile }
        )}
        onClick={uploadedState ? removeFile : launchFilePicker}
      >
        <div className={cx(styles.addButton)}>
          <IonIcon className={styles.uploadIcon} icon={uploadedState ? trashSharp : cloudUploadOutline} />{' '}
          {uploadedState ? t('Tap to remove') : t('Upload file')}
        </div>
      </button>
      <input
        className={cx(styles.inputField)}
        onChange={(e) => {
          if (e.target.files && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
          }
        }}
        type="file"
        ref={fileRef}
      />
    </>
  );
};

FileField.defaultProps = {
  onFileSelect: () => {},
  error: undefined,
  defaultValue: undefined,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MemoFileField = React.memo(FileField);
export { MemoFileField as FileField };
