import React from 'react';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import { IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonRow } from '@ionic/react';
import { clockIcon, phoneIcon, rightArrow, placemark } from '../../assets/images/icons/icons';
import { ReviewClinicType } from '../../generated/graphql';
import styles from './ReviewClinicsItem.module.scss';
import { Image } from '../UI/Image/Image';

const cx = classNames.bind(styles);

interface IReviewClinicsItem {
  clinic: ReviewClinicType;
}

const ReviewClinicsItem = ({ clinic }: IReviewClinicsItem) => {
  const { push } = useHistory();
  const { t } = useTranslation();

  return (
    <IonCard button onClick={() => push(`./review-clinics/${clinic.id}`)} className={cx('ionCard')} mode="ios">
      <IonRow className={cx('ionCard__container')}>
        <div
          style={
            clinic.avatar?.url
              ? { backgroundImage: `url("${clinic.avatar?.url}")` }
              : { backgroundImage: 'url("../../assets/images/placeholder-clinics.png")' }
          }
          className={cx('ionCard__image')}
        />
        <IonCardContent className={cx('ionCard__content')}>
          <IonCardHeader className={cx('ionCard__header')}>
            <IonCardTitle className={cx('ionCard__header-title')}>{clinic.name}</IonCardTitle>
            <IonRow className={cx('ionCard__header-rating')}>
              <Rating
                className={cx('ionCard__header-ratingIcons')}
                size={20}
                fillColor="var(--ion-color-primary-tint)"
                allowFraction
                readonly
                initialValue={clinic.averageStars}
              />
              <div className={cx('ionCard__header-ratingLength')}>({clinic.reviews?.length})</div>
            </IonRow>
          </IonCardHeader>
          <div className={cx('ionCard__descriptions')}>
            <IonRow className={cx('ionCard__descriptions-item')}>
              <Image url={clockIcon} className={cx('ionCard__descriptions-itemIcon')} />
              <span className={cx('ionCard__descriptions-itemText')}>{clinic.workTime ? clinic.workTime : 'N/A'}</span>
            </IonRow>
            <IonRow className={cx('ionCard__descriptions-item')}>
              <Image url={phoneIcon} className={cx('ionCard__descriptions-itemIcon')} />
              <span className={cx('ionCard__descriptions-itemText')}>{clinic.phone ? clinic.phone : 'N/A'}</span>
            </IonRow>
            <IonRow className={cx('ionCard__descriptions-item')}>
              <Image url={placemark} className={cx('ionCard__descriptions-itemIcon')} />
              <span className={cx('ionCard__descriptions-itemText')}>{clinic.address ? clinic.address : 'N/A'}</span>
            </IonRow>
          </div>
          <IonRow className={cx('ionCard__footer')}>
            <span className={cx('ionCard__footer-text')}>{t('Detail')}</span>
            <Image url={rightArrow} className={cx('ionCard__footer-icon')} />
          </IonRow>
        </IonCardContent>
      </IonRow>
    </IonCard>
  );
};

export { ReviewClinicsItem };
