import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React from 'react';

// components
import { useTranslation } from 'react-i18next';
import { Prescriptions } from '../../components/prescriptions/Prescriptions';
import { AddButton } from '../../components/UI/AddButton/AddButton';

import { Title } from '../../components/title/Title';
import { usePatientPrescriptionsQuery, useRemovePrescriptionMutation } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';

const PrescriptionsPage: React.FC = () => {
  const { data, loading, error, refetch } = usePatientPrescriptionsQuery({
    fetchPolicy: 'no-cache',
  });
  const { t } = useTranslation();
  const [removePrescription, { loading: removePrescriptionLoading }] = useRemovePrescriptionMutation({
    onCompleted() {
      refetch();
    },
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const handleRemovePrescription = async (id: string) => {
    await removePrescription({
      variables: {
        input: { id },
      },
    });
  };

  if (loading) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title translucent menuButton sort={{ sortButton: true, callback: () => {} }} title="Prescriptions" />
      <IonContent>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          data && (
            <>
              <Prescriptions removeMedicine={handleRemovePrescription} data={data} />
              <AddButton routeLink="prescriptions/new" />
            </>
          )
        )}
      </IonContent>
      {!loading && removePrescriptionLoading && <IonLoading isOpen message={t('Loading...')} />}
    </IonPage>
  );
};

export { PrescriptionsPage };
