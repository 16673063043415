import React from 'react';
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, isPlatform, useIonAlert } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// styles
import classNames from 'classnames/bind';
import { navigate, navigateOutline } from 'ionicons/icons';
import styles from './SigninGeolocation.module.scss';
import { goToSettings } from '../../utils/device';

const cx = classNames.bind(styles);

interface Props {
  setToggleSkip?: (value: boolean) => void;
}

const SigninGeolocation = ({ setToggleSkip }: Props) => {
  const { t } = useTranslation();
  const [alert] = useIonAlert();
  const { goBack } = useHistory();

  return (
    <IonGrid className={cx(styles.ionGrid, 'ion-no-padding')}>
      <IonRow className={cx(styles.firstHalf, styles.imageRow)}>
        <IonCol size="auto">
          <IonIcon className={styles.geolocationImg} color="primary" icon={navigateOutline} />
        </IonCol>
      </IonRow>
      <div className={styles.secondHalf}>
        <IonRow className={cx(styles.textRow, 'ion-padding')}>
          {t(
            'Epihelper collects location data to notify patient about taking his meds with him even when the app is closed or not in use.'
          )}
          <br />
        </IonRow>

        <IonRow className={cx(styles.geolocationButtonRow, 'ion-padding')}>
          <IonCol>
            <IonButton
              className={styles.button}
              onClick={() => {
                alert({
                  header: t('Geolocation'),
                  // message: t("Epihelper does not have access to your location. To enable access, tap Settings > Location. And chose 'Always'."),
                  message: isPlatform('ios')
                    ? t(
                        "Epihelper does not have access to your location. Please tap 'Settings' button and you will be redirected to settings page of this app, then tap on 'Location' after which you should select 'Always' option."
                      )
                    : t(
                        "Epihelper does not have access to your location. Please tap 'Settings' button and you will be redirected to settings page where (in permissions) you should select 'Allow all the time' option."
                      ),

                  buttons: isPlatform('ios')
                    ? [
                        {
                          text: t('Cancel'),
                          role: 'Cancel',
                          // eslint-disable-next-line no-console
                          handler: () => console.log('cancel pressed'),
                        },
                        {
                          text: t('Skip'),
                          handler: () => {
                            setToggleSkip?.(true);
                            goBack();
                          },
                        },
                        {
                          text: t('Settings'),
                          handler: async () => {
                            await goToSettings();
                          },
                        },
                      ]
                    : [
                        {
                          text: t('Cancel'),
                          role: 'Cancel',
                          // eslint-disable-next-line no-console
                          handler: () => console.log('cancel pressed'),
                        },
                        {
                          text: t('Settings'),
                          handler: async () => {
                            await goToSettings();
                          },
                        },
                      ],
                });
              }}
              expand="block"
            >
              <IonIcon className={styles.buttonIcon} color="white" icon={navigate} />
              {t('Enable geolocation')}
            </IonButton>
            {/*  */}
          </IonCol>
        </IonRow>
      </div>
    </IonGrid>
  );
};

SigninGeolocation.defaultProps = {
  setToggleSkip: undefined,
};

export { SigninGeolocation };
