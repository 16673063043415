import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Seizures } from '../../components/seizures/Seizures';
import { SeizureType, usePatientSeizuresQuery, useRemoveSeizureMutation } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { AddButton } from '../../components/UI/AddButton/AddButton';

import { Title } from '../../components/title/Title';

const SeizuresPage: React.FC = () => {
  const { data, loading, error, refetch } = usePatientSeizuresQuery({
    fetchPolicy: 'no-cache',
  });
  const { t } = useTranslation();

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const [removeSeizure, { loading: removeSeizureLoading }] = useRemoveSeizureMutation({
    onCompleted() {
      refetch();
    },
  });

  const handleRemoveSeizure = useCallback(
    async (id: string) => {
      await removeSeizure({
        variables: {
          input: { id },
        },
      });
    },
    [removeSeizure]
  );

  if (loading) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        title={t('Seizures')}
        menuButton
        sort={{
          sortButton: true,
          callback: () => {},
        }}
      />
      <IonContent>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          <>
            <Seizures
              removeSeizure={handleRemoveSeizure}
              seizures={data?.patientSeizures.edges.map((edge) => edge.node as SeizureType) || []}
            />
            <AddButton routeLink="/seizures/new" />
          </>
        )}
      </IonContent>
      {!loading && removeSeizureLoading && <IonLoading isOpen message={t('Loading...')} />}
    </IonPage>
  );
};

export { SeizuresPage };
