import React, { useMemo } from 'react';
import { StoreActionPayloadType } from '../utils/device';
import { SettingsValueType } from '../generated/graphql';
import { useAuth } from './auth-context';
import { defaultState as defaultWatchDeviceState, useWatchDevice, WatchStateType } from './useWatchDevice';

const {
  REACT_APP_SENSOR_READ_PERIOD,
  REACT_APP_BATTERY_READ_PERIOD,
  REACT_APP_HISTORY_BUFFER_SIZE,
  REACT_APP_SEND_DATA_PERIOD,
  REACT_APP_RECONNECT_ATTEMPT_PERIOD,
} = process.env;

function toNumberOrUndefined(value?: string) {
  return value ? Number(value) : undefined;
}

export const DEVICE_ENV: Partial<SettingsValueType> = {
  sensorReadPeriod: toNumberOrUndefined(REACT_APP_SENSOR_READ_PERIOD),
  batteryReadPeriod: toNumberOrUndefined(REACT_APP_BATTERY_READ_PERIOD),
  historyBufferSize: toNumberOrUndefined(REACT_APP_HISTORY_BUFFER_SIZE),
  sendDataPeriod: toNumberOrUndefined(REACT_APP_SEND_DATA_PERIOD),
  reconnectAttemptPeriod: toNumberOrUndefined(REACT_APP_RECONNECT_ATTEMPT_PERIOD),
};

export interface Record {
  value: number[];
  timestamp: number;
}

export interface RecordBundle {
  temperatures: Record[];
  accelerations: Record[];
  gyros: Record[];
  ppg: Record[];
}

export interface ActionType {
  type: 'CONNECTION_CHANGE' | 'BATTERY' | 'APP_RUNNING' | 'BLUETOOTH_STATUS_CHANGE';
  payload?: Partial<StoreActionPayloadType>;
}

interface ContextType {
  state: WatchStateType;
}

const WatchDeviceContext = React.createContext<ContextType>({
  state: defaultWatchDeviceState,
});

const WatchDeviceProvider = (props: any) => {
  const authContext = useAuth();
  const [watchDeviceState] = useWatchDevice(authContext);

  const value = useMemo(() => ({ state: watchDeviceState }), [watchDeviceState]);

  return <WatchDeviceContext.Provider value={value} {...props} />;
};

const useWatchDeviceContext = () => React.useContext(WatchDeviceContext);

export { WatchDeviceProvider, useWatchDeviceContext };
