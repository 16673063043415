import {
  IonButton,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTextarea,
  IonToast,
} from '@ionic/react';
import React, { useState } from 'react';
import { calendarOutline, chevronDown, locationOutline, timeOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames/bind';
import { SeizureInputType, SeizureIntensity, SeizureType } from '../../generated/graphql';
import styles from './SeizureForm.module.scss';
import { WideButton } from '../UI/WideButton/WideButton';
import { TimeSetter } from '../timeSetter/TimeSetter';
import { convertToMinutes, convertToSeconds, formatDisplayDate, formatDisplayTime } from '../../utils/utils';
import { useToast } from '../../contexts/toast-context';

type FormManagerType = { control: any; handleSubmit: any; formState: any; watch: any; errors: any };

export type SeizureWithSeparateDateAndTimeType = Partial<SeizureType & { time: string }>;
export type SeizureWithSeparateDateAndTimeInputType = SeizureInputType & { time: string };

interface Props {
  seizure: SeizureWithSeparateDateAndTimeType;
  formSubmitHandler: (seizure: SeizureWithSeparateDateAndTimeInputType) => void;
  onAddressFieldClick?: () => void;
  submitButtonText: string;
  formManager?: FormManagerType;
}

const cx = classNames.bind(styles);

const SeizureForm = ({ seizure, formSubmitHandler, submitButtonText, onAddressFieldClick, ...props }: Props) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { dispatch: dispatchToast } = useToast();

  let formManager: FormManagerType = useForm({
    defaultValues: {
      ...seizure,
      date: seizure.date,
      time: seizure.time,
      location: seizure.location?.coordinates,
    },
    mode: 'onBlur',
  });

  formManager = props.formManager ?? formManager;

  const {
    duration: watchDurationValue,
    date: watchDateValue,
    time: watchTimeValue,
  } = formManager.watch(['duration', 'date', 'time']);

  if (!seizure) {
    return <IonToast isOpen message={t('Missing patient medicine in component parameter.')} position="top" />;
  }

  const intensitySuggestions = [
    { id: SeizureIntensity.A_1, name: t('Minor seizure') },
    { id: SeizureIntensity.A_2, name: t('Medium seizure') },
    { id: SeizureIntensity.A_3, name: t('Major seizure') },
    { id: SeizureIntensity.A_4, name: t('Severe seizure') },
  ];
  return (
    <form
      className={cx(styles.componentContainer)}
      onSubmit={(e: any) => {
        const addressFetched = seizure.location?.coordinates;
        if (!addressFetched) {
          dispatchToast({
            type: 'DANGER',
            message: t('Your address is not located yet. Please wait.'),
          });
        }
        formManager.handleSubmit((data: any) => {
          formSubmitHandler(data as SeizureWithSeparateDateAndTimeInputType);
        })(e);
      }}
    >
      <div>
        <IonItem
          lines="none"
          id="open-date-input"
          className={cx(styles.datetimeContainer, { 'border-color-danger': formManager.errors.date })}
        >
          <IonLabel>
            {watchDateValue ? (
              formatDisplayDate(watchDateValue)
            ) : (
              <span style={{ color: '#bdbdbd' }}>{`${t('Date of the attack')}:`}</span>
            )}
          </IonLabel>
          <Controller
            render={({ onChange, value, onBlur }) => (
              <IonPopover onDidDismiss={onBlur} trigger="open-date-input">
                <IonDatetime
                  slot="start"
                  onIonChange={(e) => onChange(e)}
                  presentation="date"
                  value={value}
                  className={styles.datetimeInput}
                  cancelText={t('Cancel')}
                  doneText={t('Done')}
                />
              </IonPopover>
            )}
            control={formManager.control}
            defaultValue=""
            name="date"
            rules={{ required: `${t('This is a required field')}` }}
          />
          <IonIcon slot="end" className={styles.iconInput} icon={calendarOutline} />
        </IonItem>
        <IonItem
          lines="none"
          id="open-time-input"
          className={cx(styles.datetimeContainer, { 'border-color-danger': formManager.errors.time })}
        >
          <IonLabel>
            {watchTimeValue ? (
              formatDisplayTime(watchTimeValue)
            ) : (
              <span style={{ color: '#bdbdbd' }}>{`${t('Time of the attack')}:`}</span>
            )}
          </IonLabel>
          <Controller
            render={({ onChange, value, onBlur }) => (
              <IonPopover onDidDismiss={onBlur} trigger="open-time-input">
                <IonDatetime
                  slot="start"
                  onIonChange={(e) => onChange(e)}
                  value={value}
                  presentation="time"
                  className={styles.datetimeInput}
                  cancelText={t('Cancel')}
                  doneText={t('Done')}
                  hour-cycle="h23"
                />
              </IonPopover>
            )}
            control={formManager.control}
            defaultValue=""
            name="time"
            rules={{ required: `${t('This is a required field')}` }}
          />
          <IonIcon slot="end" className={styles.iconInput} icon={timeOutline} />
        </IonItem>
        <Controller
          render={({ onChange }) =>
            showModal ? (
              <TimeSetter
                seconds={(formManager.watch('duration') as undefined | number) ?? 0}
                setTimeHandler={onChange}
                closeModal={() => {
                  setShowModal(false);
                }}
              />
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )
          }
          control={formManager.control}
          name="duration"
          defaultValue={0}
          rules={{ maxLength: 59 }}
        />

        <IonItem
          lines="none"
          className={cx(
            'inputContainer',
            { active: watchDurationValue },
            { 'border-color-danger': formManager.errors.duration }
          )}
          onClick={() => {
            setShowModal(true);
          }}
        >
          <IonLabel>{t('Duration')}</IonLabel>
          <IonLabel className={styles.durationShowField}>
            {watchDurationValue ? (
              <>
                <span>{convertToMinutes(watchDurationValue) ? `${convertToMinutes(watchDurationValue)} м ` : ''}</span>
                <span>{convertToSeconds(watchDurationValue) ? `${convertToSeconds(watchDurationValue)} с ` : ''}</span>
              </>
            ) : (
              <span className={cx('test')}>
                {t('Define')}
                <IonIcon icon={chevronDown} />
              </span>
            )}
          </IonLabel>
        </IonItem>
        <Controller
          render={({ onChange, value }) => (
            <IonSelect
              className={styles.listSelect}
              interface="action-sheet"
              value={value}
              placeholder={t('Intensity')}
              cancel-text={t('Cancel')}
              onIonChange={(e) => onChange(e.detail.value)}
            >
              {intensitySuggestions.map((sIntensity) => {
                return (
                  <IonSelectOption key={sIntensity.id} value={sIntensity.id}>
                    {sIntensity.name}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}
          control={formManager.control}
          defaultValue={null}
          name="intensity"
        />
        <IonItem lines="none" className={cx(styles.inputContainer, styles.inputContainerDescription)}>
          <Controller
            render={({ onChange, value }) => (
              <IonTextarea
                value={value}
                className={styles.descriptionInput}
                autoGrow
                onIonChange={(e) => onChange(e)}
                placeholder={t('Possible reason')}
              />
            )}
            control={formManager.control}
            defaultValue=""
            name="trigger"
          />
        </IonItem>
        <IonItem lines="none">
          <IonGrid className={cx(styles.geolocationContainer)}>
            <IonRow className={cx('ion-text-center', 'ion-justify-content-center', styles.geolocation)}>
              <span className={cx('ion-text-wrap', styles.geolocationTitle)}>
                <IonIcon className={styles.geolocationIcon} icon={locationOutline} />
                <span>
                  {/* Ваше местоположение во время приступа */}
                  {t('Your location')}
                </span>
              </span>
            </IonRow>
            <IonRow className={cx('ion-text-wrap', 'ion-justify-content-center', styles.geolocationStatus)}>
              {seizure.location?.coordinates ? (
                t('Address registered')
              ) : (
                <>
                  <IonText
                    color="tertiary"
                    style={{
                      marginRight: '3px',
                      lineHeight: '1.4',
                    }}
                  >
                    {t('Searching')}
                  </IonText>
                  <IonSpinner color="tertiary" name="dots" />
                </>
              )}
            </IonRow>
            {!!seizure.location?.coordinates && (
              <IonRow className={cx('ion-justify-content-center', 'ion-text-wrap')}>
                <IonButton className={cx(styles.geolocationChangeButton)} onClick={onAddressFieldClick} fill="outline">
                  {t('Change')}
                </IonButton>
              </IonRow>
            )}
          </IonGrid>
        </IonItem>
      </div>

      <WideButton type="submit" text={submitButtonText} />
    </form>
  );
};

SeizureForm.defaultProps = {
  onAddressFieldClick: undefined,
  formManager: undefined,
};

export { SeizureForm };
