import { IonItem, IonToast, IonInput } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import classNames from 'classnames/bind';
import { SosContactType } from '../../generated/graphql';
import styles from './SOSContactDetail.module.scss';
import { WideButton } from '../UI/WideButton/WideButton';

interface ISOSContactDetail {
  soscontact: SosContactType | object;
  formSubmitHandler: (soscontact: SosContactType | object) => void;
  submitButtonText: string;
}

const cx = classNames.bind(styles);

const SOSContactDetail = ({ soscontact, formSubmitHandler, submitButtonText }: ISOSContactDetail) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: { ...soscontact },
    mode: 'onBlur',
  });

  const { t } = useTranslation();
  if (!soscontact) {
    return <IonToast isOpen message={t('Missing patient sos contact in component parameter.')} position="top" />;
  }

  return (
    <form onSubmit={handleSubmit((data) => formSubmitHandler(data))}>
      <IonItem className={cx(styles.datetimeContainer, { 'border-color-danger': errors.firstName })} lines="none">
        <Controller
          render={({ onChange, value, onBlur }) => (
            <IonInput
              onIonChange={(e) => onChange(e)}
              onIonBlur={onBlur}
              value={value}
              className="ion-padding"
              placeholder={t('Name')}
              inputMode="text"
              type="text"
              autocapitalize="words"
            />
          )}
          control={control}
          defaultValue=""
          name="firstName"
          rules={{
            required: true,
          }}
        />
      </IonItem>

      <IonItem className={cx(styles.datetimeContainer)} lines="none">
        <Controller
          render={({ onChange, value }) => (
            <IonInput
              onIonChange={(e) => onChange(e)}
              value={value}
              className="ion-padding"
              placeholder={t('Surname')}
              inputMode="text"
              type="text"
              autocapitalize="words"
            />
          )}
          control={control}
          defaultValue=""
          name="secondName"
        />
      </IonItem>

      <IonItem className={cx(styles.datetimeContainer, { 'border-color-danger': errors.phone })} lines="none">
        <Controller
          render={({ onChange, value, onBlur }) => (
            <MaskedInput
              onBlur={onBlur}
              onChange={(e) => onChange(e)}
              value={value}
              className={styles.input}
              mask="+7 (999) 999 99 99"
              maskChar={null}
              placeholder="+7 (XXX) XXX XX XX"
              type="tel"
              inputMode="tel"
            />
          )}
          control={control}
          defaultValue=""
          name="phone"
          rules={{
            required: true,
            pattern: /^[+]?\d[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}([-\s.]?[0-9]{2}){2}$/i,
          }}
        />
      </IonItem>

      <IonItem className={cx(styles.datetimeContainer, { 'border-color-danger': errors.email })} lines="none">
        <Controller
          render={({ onChange, value, onBlur }) => (
            <IonInput
              onIonBlur={onBlur}
              onIonChange={(e) => onChange(e)}
              value={value}
              type="email"
              inputMode="email"
              placeholder={t('Enter your email')}
            />
          )}
          control={control}
          defaultValue=""
          name="email"
          rules={{
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          }}
        />
      </IonItem>

      <WideButton type="submit" text={submitButtonText} />
    </form>
  );
};

SOSContactDetail.defaultProps = {
  soscontact: {},
};

export { SOSContactDetail };
