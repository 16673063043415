import { IonContent, IonLoading } from '@ionic/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { YandexMap } from '../map/YandexMap';
import {
  SeizureForm,
  SeizureWithSeparateDateAndTimeInputType,
  SeizureWithSeparateDateAndTimeType,
} from './SeizureForm';
import { useLocation } from '../../contexts/location-context';
import { SearchView, IChosenLocation } from '../search-view/SearchView';
import { useSearchView } from '../search-view/useSearchView';
import { TopSearchField } from '../UI/TopSearchField/TopSearchField';
import { SeizureStatus } from '../../generated/graphql';

interface Props {
  onSubmit: (payload: SeizureWithSeparateDateAndTimeInputType) => void;
  submitting: boolean;
  seizure?: SeizureWithSeparateDateAndTimeType;
  submitButtonText: string;
}

const SeizureFormWithMap = ({ seizure, onSubmit, submitting, submitButtonText }: Props) => {
  const { t } = useTranslation();
  const [isYandexMapOpen, setIsYandexMapOpen] = useState(false);
  const { location } = useLocation();
  const [chosenLocation, setChosenLocation] = useState<IChosenLocation | null>();
  const [showSearchView, toggleSearchView] = useSearchView();

  const formManager = useForm({
    defaultValues: useMemo(() => {
      return {
        ...(seizure || {}),
        date: seizure?.date,
        time: seizure?.time,
        location: seizure?.location?.coordinates,
      };
    }, [seizure]),
    mode: 'onBlur',
  });

  useEffect(() => {
    formManager.reset({
      ...(seizure || {}),
      location: seizure?.location?.coordinates,
    });
  }, [seizure]); // eslint-disable-line react-hooks/exhaustive-deps

  // Passing current user location to map point
  useEffect(() => {
    if (seizure?.id) {
      setChosenLocation({
        coordinates: seizure.location?.coordinates,
        displayName: '',
      });
    } else if (location?.coords) {
      setChosenLocation({
        coordinates: [location?.coords.latitude, location?.coords.longitude],
        displayName: '',
      });
    }
  }, [location, setChosenLocation, seizure]);

  const toggleYandexMap = useCallback(() => {
    setIsYandexMapOpen((prevState) => !prevState);
  }, [setIsYandexMapOpen]);

  const onSeizureSubmit = useCallback(
    (payload: SeizureWithSeparateDateAndTimeInputType) => {
      if (chosenLocation) {
        const updatedPayload: SeizureWithSeparateDateAndTimeInputType & { confirmed?: string } = {
          ...payload,
          status: SeizureStatus.A_1,
          location: { coordinates: chosenLocation.coordinates, type: 'POINT' },
        };
        delete updatedPayload.confirmed;

        onSubmit(updatedPayload);
      }
    },
    [chosenLocation, onSubmit]
  );

  return (
    <>
      <IonContent style={{ display: isYandexMapOpen ? 'none' : 'block' }}>
        {submitting && <IonLoading isOpen message={t('Loading...')} />}
        <SeizureForm
          formSubmitHandler={onSeizureSubmit}
          seizure={{
            ...seizure,
            date: seizure?.date,
            time: seizure?.time,
            location: { coordinates: chosenLocation?.coordinates, type: 'POINT' },
          }}
          submitButtonText={t(submitButtonText)}
          onAddressFieldClick={toggleYandexMap}
          formManager={formManager}
        />
      </IonContent>
      {isYandexMapOpen && (
        <>
          <SearchView
            goBack={toggleSearchView}
            showView={showSearchView}
            onLocationSelect={(loc: IChosenLocation) => {
              toggleSearchView();
              setChosenLocation(loc);
            }}
          />
          {!showSearchView && (
            <TopSearchField
              onSearchFieldClick={toggleSearchView}
              value={chosenLocation?.displayName}
              onGoBackClick={() => {
                toggleYandexMap();
              }}
            />
          )}
          <YandexMap
            mapState={{
              center: chosenLocation?.coordinates,
              zoom: 19,
              controls: [],
            }}
            onAddressConfirm={(coordinates: [number, number]) => {
              setChosenLocation({
                coordinates,
                displayName: '',
              });
              toggleYandexMap();
            }}
          />
        </>
      )}
    </>
  );
};

SeizureFormWithMap.defaultProps = {
  seizure: {},
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export { SeizureFormWithMap };
