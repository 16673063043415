import React, { useState } from 'react';
import {
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonHeader,
  IonAlert,
  IonInput,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { arrowBackOutline, searchOutline, shareOutline, trash } from 'ionicons/icons';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';
import stylesRegular from './Title.module.scss';
import stylesTranslucent from './TitleTranslucent.module.scss';
import { ReviewSearchBar } from '../review-clinics/review-header/review-search/ReviewSearchBar';

const cxRegular = classNames.bind(stylesRegular);
const cxTranslucent = classNames.bind(stylesTranslucent);

type Func = (...args: any[]) => any;

interface Props {
  translucent: boolean;
  title: string;
  searchBar: boolean;
  menuButton: boolean;
  sort?: {
    sortButton: boolean;
    callback: Func;
  };
  searchButton?: boolean;
  backButton: boolean;
  backButtonPath?: string;
  color?: string;
  titleColor?: string;
  delete?: {
    deleteButton: boolean;
    callback: Func;
  };
  shareButton?: {
    shareButton: boolean;
    callback: Func;
  };
}

const Title = ({ ...props }: Props) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showReviewSearchBar, setShowReviewSearchBar] = useState(false);

  const { t } = useTranslation();
  const { push, goBack } = useHistory();

  const styles = props.translucent ? stylesTranslucent : stylesRegular;
  const cx = props.translucent ? cxTranslucent : cxRegular;

  const deleteConfirmation = (
    <IonAlert
      isOpen={showDeleteConfirmation}
      onDidDismiss={() => setShowDeleteConfirmation(false)}
      header={t('Delete')}
      message={t('Confirm deletion')}
      buttons={[
        {
          text: t('Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: t('Delete'),
          handler: () => {
            props.delete?.callback();
          },
        },
      ]}
    />
  );

  const backButton = (
    <IonButtons collapse={props.translucent} slot="start">
      <IonButton
        className={cxRegular('button')}
        onClick={() => (props.backButtonPath ? push(props.backButtonPath) : goBack())}
      >
        <IonIcon className={styles.menuIcon} slot="icon-only" icon={arrowBackOutline} />
      </IonButton>
    </IonButtons>
  );

  const deleteButton = (
    <IonButtons collapse={props.translucent} slot="end">
      <IonButton onClick={() => setShowDeleteConfirmation(true)}>
        <IonIcon className={styles.menuIcon} slot="icon-only" icon={trash} />
      </IonButton>
    </IonButtons>
  );

  const searchBar = (
    <IonToolbar className={cx(styles.toolbar, 'ion-no-padding')}>
      <IonInput className={styles.searchbar} placeholder={t('Enter description')} />
    </IonToolbar>
  );

  const reviewSearchBar = (
    <IonToolbar className={cx(styles.toolbar, 'ion-no-padding')}>
      <ReviewSearchBar />
    </IonToolbar>
  );

  const menuButton = (
    <IonButtons className={cxRegular('button')} collapse={props.translucent} slot="start">
      <IonMenuButton />
    </IonButtons>
  );

  const shareButton = (
    <IonButtons slot="end">
      <IonButton onClick={props.shareButton?.callback}>
        <IonIcon slot="icon-only" icon={shareOutline} />
      </IonButton>
    </IonButtons>
  );

  const searchButton = (
    <IonButtons collapse={props.translucent} slot="end">
      <IonButton onClick={() => setShowReviewSearchBar((current) => !current)}>
        <IonIcon slot="icon-only" icon={searchOutline} onClick={props.sort?.callback} />
      </IonButton>
    </IonButtons>
  );

  const toolbarStyle = props.color ? styles.toolbarReverseColor : styles.toolbar;

  return (
    <IonHeader className="ion-no-border">
      <IonToolbar
        color={props.color}
        className={cx(toolbarStyle)}
        style={{ color: props.titleColor, backgroundColor: props.color }}
        mode="ios"
      >
        <IonTitle className={styles.pageTitle}>{t(props.title)}</IonTitle>
        {props.menuButton ? menuButton : undefined}
        {props.backButton ? backButton : undefined}
        {props.searchButton ? searchButton : undefined}
        {props.shareButton?.shareButton ? shareButton : undefined}
        {props.delete?.deleteButton ? deleteButton : undefined}
      </IonToolbar>
      {props.searchBar ? searchBar : undefined}
      {showReviewSearchBar ? reviewSearchBar : undefined}
      {deleteConfirmation}
    </IonHeader>
  );
};

Title.defaultProps = {
  translucent: false,
  searchBar: false,
  searchButton: false,
  menuButton: false,
  sort: undefined,
  backButton: false,
  backButtonPath: undefined,
  titleColor: undefined,
  color: undefined,
  delete: undefined,
  shareButton: undefined,
};

export { Title };
