import React from 'react';
import classNames from 'classnames/bind';
import styles from './NoElementFound.module.scss';

interface Props {
  title: string;
}

const cx = classNames.bind(styles);

const NoElementFound: React.FC<Props> = ({ title }) => {
  return (
    <div className={cx(styles.container)}>
      <div className={cx(styles.styleImg)} />
      <div className={cx(styles.styleTitle)}>{title}</div>
    </div>
  );
};

export { NoElementFound };
