import { App as CapacitorApp } from '@capacitor/app';
import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { PluginListenerHandle } from '@capacitor/core';
import { AuthContextType } from '../contexts/auth-context';

const SUBMIT_ONLINE_STATUS = gql`
  mutation ($userId: Int!) {
    update_core_user_by_pk(pk_columns: { id: $userId }, _set: { last_seen: "now()" }) {
      id
    }
  }
`;

function usePatientOnlineMutation(authContext: AuthContextType) {
  const [onlineStatusMutation] = useMutation(SUBMIT_ONLINE_STATUS, {
    context: {
      clientName: 'chat',
    },
    variables: { userId: authContext.user?.id },
  });

  useEffect(() => {
    let intervalId: number;
    let capacitorAppStateChangeListener: Promise<PluginListenerHandle> & PluginListenerHandle;
    if (authContext.user?.id) {
      intervalId = setInterval(onlineStatusMutation, 30000);
      capacitorAppStateChangeListener = CapacitorApp.addListener('appStateChange', (appState) => {
        if (appState.isActive) {
          clearInterval(intervalId); // Redundant, but let it be here:)
          intervalId = setInterval(onlineStatusMutation, 30000);
        } else {
          clearInterval(intervalId);
        }
      });
    }

    return () => {
      if (capacitorAppStateChangeListener) {
        capacitorAppStateChangeListener.remove();
      }
      clearInterval(intervalId);
    };
  }, [authContext.user, onlineStatusMutation]);
}

export { usePatientOnlineMutation };
