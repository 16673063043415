import { FileOpener } from '@ionic-native/file-opener';
import { PDFGenerator, PDFGeneratorOptions } from '@ionic-native/pdf-generator';
import { Filesystem, Directory } from '@capacitor/filesystem';

const options: PDFGeneratorOptions = {
  type: 'base64',
};

const convertBase64ToBlob = (b64Data: string, contentType: string): Blob => {
  // eslint-disable-next-line no-param-reassign
  contentType = contentType || '';
  const sliceSize = 512;
  // eslint-disable-next-line no-param-reassign
  b64Data = b64Data.replace(/^[^,]+,/, '');
  // eslint-disable-next-line no-param-reassign
  b64Data = b64Data.replace(/\s/g, '');
  const byteCharacters = window.atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
};

const saveAndOpenPdf = (pdf: string, filename: string) => {
  Filesystem.writeFile({
    path: filename,
    recursive: true,
    directory: Directory.Documents,
    data: pdf,
  })
    .then(async (result) => {
      await FileOpener.open(result.uri, 'application/pdf');
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.log(error));
};

const convertHtmlToPdf = async (componentRef: any, name: string | undefined) => {
  const html = componentRef.current.innerHTML;
  const pdfBase64 = await PDFGenerator.fromData(html, options);
  if (name) {
    const date = new Date();
    const fileName = `/${name}${date.toLocaleDateString()}.pdf`.replace(/\//g, '');
    saveAndOpenPdf(pdfBase64, `pdfs/${fileName}`);
  }
};

export { saveAndOpenPdf, convertBase64ToBlob, convertHtmlToPdf };
