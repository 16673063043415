import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  ResponsiveContainerProps,
} from 'recharts';

interface IChart {
  data: {
    values: Array<{ x: string; y: number }>;
    label: string;
    color?: string;
  }[];
  containerProps?: Partial<ResponsiveContainerProps>;
}

function chartContainerHeight(): number {
  return (document.documentElement.clientHeight - 44) / 2;
}

const chartFontSize = { fontSize: 14 };

const Chart: React.FC<IChart> = ({ data, containerProps }) => {
  return (
    <ResponsiveContainer height={chartContainerHeight()} {...containerProps}>
      <LineChart
        margin={{
          top: 0,
          right: 20,
          left: -30,
          bottom: 0,
        }}
      >
        <CartesianGrid />
        <XAxis
          tick={chartFontSize}
          dataKey="x"
          interval={0}
          tickFormatter={(value) => {
            return value.slice(0, 3);
          }}
        />
        <YAxis tick={chartFontSize} allowDecimals={false} />
        <Tooltip
          labelStyle={chartFontSize}
          itemStyle={chartFontSize}
          formatter={(value, name, entry, index) => {
            return [value, data[index].label];
          }}
        />
        {/* eslint-disable react/no-unstable-nested-components */}
        <Legend
          verticalAlign="top"
          iconType="square"
          iconSize={12}
          wrapperStyle={{ left: 0 }}
          formatter={(value, entry, i) => {
            return <span style={chartFontSize}>{data[i!].label}</span>;
          }}
        />
        {data.map((datum) => {
          return (
            <Line
              key={datum.label}
              data={datum.values}
              type="monotone"
              dataKey="y"
              stroke={datum.color ?? '#8884d8'}
              fill={datum.color ?? '#8884d8'}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

Chart.defaultProps = {
  containerProps: undefined,
};

export { Chart };
