import React, { useMemo, useState, useCallback } from 'react';

// context
import classNames from 'classnames/bind';
import { ScreensSwitcherContext } from './screens-switcher-context';

// styles
import styles from './ScreensSwitcher.module.scss';

const cx = classNames.bind(styles);

interface IScreensSwitcher {
  children: JSX.Element[];
}

const ScreensSwitcher: React.FC<IScreensSwitcher> = ({ children }) => {
  const [screenNumber, setScreenNumber] = useState(0);

  const restart = useCallback(() => {
    setTimeout(() => {
      setScreenNumber(0);
    }, 1000);
  }, []);
  const toNextScreen = useCallback(() => setScreenNumber((prev) => prev + 1), []);
  const toPrevScreen = useCallback(() => setScreenNumber((prev) => prev - 1), []);

  const screensList = useMemo(() => {
    /* eslint-disable react/no-array-index-key */
    return children.map((item, index) => (
      <div
        key={index}
        className={cx(styles.screen, { [styles.screenActive]: screenNumber >= index })}
        style={{ zIndex: index * 1000 }}
      >
        {screenNumber === index && item}
      </div>
    ));
  }, [screenNumber, children]);

  const value = useMemo(
    () => ({
      screenNumber,
      toNextScreen,
      toPrevScreen,
      restart,
    }),
    [restart, screenNumber, toNextScreen, toPrevScreen]
  );

  return (
    <ScreensSwitcherContext.Provider value={value}>
      <div className={styles.headContent}>{screensList}</div>
    </ScreensSwitcherContext.Provider>
  );
};

export { ScreensSwitcher };
