import classNames from 'classnames/bind';
import styles from './icons.module.scss';
import { IIconComponentsProps } from './types';

const cx = classNames.bind(styles);

const MedicineIcon = ({ classname }: IIconComponentsProps) => {
  return (
    <svg className={cx(`FooterBar__icon`, classname)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M8.434 20.566c1.335 0 2.591-.52 3.535-1.464l7.134-7.133a5.008 5.008 0 0 0-.001-7.072 4.969 4.969 0 0 0-3.536-1.463c-1.335 0-2.59.52-3.534 1.464l-7.134 7.133a5.01 5.01 0 0 0-.001 7.072 4.971 4.971 0 0 0 3.537 1.463zm5.011-14.254a2.979 2.979 0 0 1 2.12-.878c.802 0 1.556.312 2.122.878a3.004 3.004 0 0 1 .001 4.243l-2.893 2.892-4.242-4.244 2.892-2.891z" />
    </svg>
  );
};

export { MedicineIcon };
