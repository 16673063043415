import { IonLoading, IonPage } from '@ionic/react';
import React from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// graphql
import { useMeQuery, UserType } from '../../generated/graphql';

// components
import { UserDataForm } from '../../components/user-data-form/UserDataForm';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';

const ProfileSettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useMeQuery({
    fetchPolicy: 'no-cache',
  });

  if (loading || !data?.me) {
    return <IonLoading isOpen />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title translucent title={t('Profile')} backButton />
      <UserDataForm me={data.me as UserType} />
    </IonPage>
  );
};

export { ProfileSettingsPage };
