import { IonContent, IonDatetime, IonIcon, IonItem, IonLoading, IonPage, IonRow, IonText } from '@ionic/react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';

// graphql
import classNames from 'classnames/bind';
import {
  usePatientMedicinesIntakesQuery,
  useCreateMedicineIntakeMutation,
  useRemoveMedicineIntakeMutation,
  GraphPrescriptionMedicineWithMedicineIntakeType,
  PatientMedicineType,
  CreateMedicineIntakeInputType,
  RemoveMedicineIntakeInputType,
} from '../../generated/graphql';

// styles
import styles from './MedicinesIntake.module.scss';

// components
import { Title } from '../../components/title/Title';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { MedicinesIntakeList } from '../../components/medicines-intake/MedicinesIntakeList';
import { PatientMedicinesList } from '../../components/medicines-intake/PatientMedicinesList';

const cx = classNames.bind(styles);

const MedicinesIntakePage: React.FC = () => {
  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const datetime = useRef<HTMLIonDatetimeElement>({} as HTMLIonDatetimeElement);
  const { t } = useTranslation();

  const {
    data,
    loading: getMedicinesIntakeLoading,
    error,
    refetch,
  } = usePatientMedicinesIntakesQuery({
    variables: {
      date: date.toISOString().slice(0, 10),
    },
    fetchPolicy: 'no-cache',
  });

  const handleMedicinesIntakeRefetch = async (value: string) => {
    await refetch({ date: value });
  };
  const [createMedicineIntake, { loading: createMedicineIntakeLoading }] = useCreateMedicineIntakeMutation({
    onCompleted() {
      handleMedicinesIntakeRefetch(date.toISOString().slice(0, 10));
    },
  });
  const [removeMedicineIntake, { loading: removeMedicineIntakeLoading }] = useRemoveMedicineIntakeMutation({
    onCompleted() {
      handleMedicinesIntakeRefetch(date.toISOString().slice(0, 10));
    },
  });

  const handleCreateMedicineIntake = async ({
    prescriptionMedicineId,
    medicineId,
    intakeDate,
  }: CreateMedicineIntakeInputType) => {
    await createMedicineIntake({
      variables: {
        input: {
          prescriptionMedicineId,
          medicineId,
          intakeDate,
        },
      },
    });
  };

  const handleRemoveMedicineIntake = async ({
    id,
    prescriptionMedicineId,
    medicineId,
    intakeDate,
  }: RemoveMedicineIntakeInputType) => {
    await removeMedicineIntake({
      variables: {
        input: {
          id,
          prescriptionMedicineId,
          medicineId,
          intakeDate,
        },
      },
    });
  };

  return (
    <IonPage>
      <Title translucent title={t('Medicines Intake')} menuButton />
      <IonContent>
        {error ? (
          <ErrorNetwork error={error} />
        ) : (
          <>
            <IonRow className="ion-padding">
              <IonItem
                className={styles.paginationItem}
                lines="none"
                onClick={() => setDate(new Date(date.valueOf() - 86400000))}
              >
                <IonIcon className={styles.paginationIcon} slot="start" icon={chevronBackOutline} />
                {t('Yesterday')}
              </IonItem>
              <IonItem
                mode="md"
                button
                onClick={() => {
                  setShowCalendar((prev) => !prev);
                  datetime.current.click();
                }}
                className={styles.dataPicker}
                lines="none"
              >
                <IonRow className={cx(styles.dataPickerInfo, 'ion-align-items-center')}>
                  <IonText className={styles.dataPickerData}>
                    {date.toLocaleDateString() === new Date().toLocaleDateString()
                      ? t('Today')
                      : date.toLocaleDateString()}
                  </IonText>
                  <IonText className={styles.indicateData} color="primary">
                    {t('Indicate the date')}
                  </IonText>
                </IonRow>
              </IonItem>
              <IonItem
                className={styles.paginationItem}
                lines="none"
                onClick={() => setDate(new Date(date.valueOf() + 86400000))}
              >
                <IonIcon className={styles.paginationIcon} slot="end" icon={chevronForwardOutline} />
                {t('Tomorrow')}
              </IonItem>
              <IonDatetime
                className={cx(!showCalendar && styles.isCalendarInvisible)}
                ref={datetime}
                size="cover"
                presentation="date"
                cancelText={t('Cancel')}
                doneText={t('Done')}
                min="2015-12-31"
                max="2025-12-31"
                onIonChange={(e) => {
                  const val = new Date(e.detail.value!);
                  setDate(val);
                  handleMedicinesIntakeRefetch(val.toISOString().slice(0, 10));
                  setShowCalendar(false);
                }}
                value={date.toISOString()}
              />
            </IonRow>
            {data && (
              <>
                <MedicinesIntakeList
                  date={date}
                  medicines={
                    data.patientMedicinesIntakes.medicines as GraphPrescriptionMedicineWithMedicineIntakeType[]
                  }
                  checkboxOnHandle={handleCreateMedicineIntake}
                  checkboxOffHandle={handleRemoveMedicineIntake}
                />
                <PatientMedicinesList
                  medicines={data.patientMedicinesIntakes.patientMedicines as PatientMedicineType[]}
                />
              </>
            )}
          </>
        )}
        {(getMedicinesIntakeLoading || createMedicineIntakeLoading || removeMedicineIntakeLoading) && (
          <IonLoading isOpen message={t('Loading...')} />
        )}
      </IonContent>
    </IonPage>
  );
};

export { MedicinesIntakePage };
