import { IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PatientMedicineType } from '../../generated/graphql';

import styles from './MedicinesIntake.module.scss';

interface IMedicinesIntake {
  medicines: PatientMedicineType[];
}

const PatientMedicinesList: React.FC<IMedicinesIntake> = ({ medicines }) => {
  const { t } = useTranslation();

  return (
    <IonList className={styles.list}>
      <IonListHeader className={styles.sectionHeader}>
        <IonLabel>{t('Stocks')}</IonLabel>
      </IonListHeader>
      {medicines.length ? (
        medicines.map((medicine, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <IonItem key={index} className={styles.listItem}>
            <div className={styles.decorCircle} />
            <IonLabel>
              {medicine.medicine.name}
              <span className={styles.floatLabel}>
                {medicine.quantity} {t('pcs')}
              </span>
            </IonLabel>
          </IonItem>
        ))
      ) : (
        <IonItem className={styles.listItem} lines="none">
          {t('No medicines stocks')}
        </IonItem>
      )}
    </IonList>
  );
};

export { PatientMedicinesList };
