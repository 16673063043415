import React from 'react';
import styles from './Modal.module.scss';

interface Props {
  closeModal: () => void;
  title?: string;
  children: React.ReactNode;
}

const Modal = ({ closeModal, title, children }: Props) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={styles.backdrop} onClick={closeModal}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h2 className={styles.title}>{title}</h2>
        {children}
      </div>
    </div>
  );
};

Modal.defaultProps = {
  title: '',
};

export { Modal };
