import React from 'react';
import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ReviewDoctorType, useReviewDoctorQuery } from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { ReviewDoctorDetail } from '../../components/review-doctor/ReviewDoctorDetail';

const ReviewDoctorPage = () => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();

  const { data, loading, error, refetch } = useReviewDoctorQuery({ variables: { id }, fetchPolicy: 'no-cache' });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  if (loading || !data?.reviewsDoctor) {
    return <IonLoading isOpen={loading} message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title translucent title={t('Doctor')} backButton />
      <IonContent>{data && <ReviewDoctorDetail doctor={data.reviewsDoctor as ReviewDoctorType} />}</IonContent>
    </IonPage>
  );
};

export { ReviewDoctorPage };
