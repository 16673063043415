import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  PatientFileUpdateInputType,
  usePatientFileQuery,
  usePatientFileUpdateMutation,
  useRemovePatientFileMutation,
} from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';
import { PatientFileDetail } from '../../components/patient-file/PatientFileDetail';

const PatientFilePage: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const toast = useToast();

  const { data, loading, error, refetch } = usePatientFileQuery({ variables: { id }, fetchPolicy: 'no-cache' });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data]);

  const [patientFileUpdate, { loading: patientFileUpdateLoading }] = usePatientFileUpdateMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const [removePatientFile, { loading: removePatientFileLoading }] = useRemovePatientFileMutation({
    onCompleted() {
      toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
      goBack();
    },
  });

  const updatePatientFile = useCallback(
    async (patientFile: Omit<PatientFileUpdateInputType, 'id'>) => {
      const payload = {
        ...patientFile,
        id,
      };

      await patientFileUpdate({
        variables: {
          input: payload,
        },
      });
    },
    [patientFileUpdate, id]
  );

  const handleRemovePatientFile = useCallback(async () => {
    await removePatientFile({
      variables: {
        input: { id },
      },
    });
  }, [removePatientFile, id]);

  if (loading || !data?.patientFile) {
    return <IonLoading isOpen={loading} message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        title={t('Edit')}
        backButton
        delete={{
          deleteButton: true,
          callback: handleRemovePatientFile,
        }}
      />
      <IonContent>
        {(patientFileUpdateLoading || removePatientFileLoading) && <IonLoading isOpen message={t('Loading...')} />}
        {data && (
          <PatientFileDetail
            formSubmitHandler={(value) => updatePatientFile(value)}
            defaultValue={{ title: data.patientFile.file.title, fileUrl: data.patientFile.file.url }}
            submitButtonText={t('Save')}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export { PatientFilePage };
