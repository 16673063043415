import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const YaBottomContainer = (props: any) => {
  const [DOMContainer, setDOMContainer] = useState<HTMLElement>();

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const container = document.getElementById('y-container')!;
      setDOMContainer(container);
    }, 200);
  }, []);

  // eslint-disable-next-line react/destructuring-assignment
  return DOMContainer ? ReactDOM.createPortal(props.children, DOMContainer) : null;
};

export { YaBottomContainer };
