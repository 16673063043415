import React, { useRef, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonItem,
  IonList,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
import classNames from 'classnames/bind';
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styles from './ReviewFilter.module.scss';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import { ClinicFilterInput } from '../../../../generated/graphql';

const cx = classNames.bind(styles);

interface IReviewFilter {
  trigger: string;
  clinicsLength: number;
}

const ReviewFilter = ({ trigger, clinicsLength }: IReviewFilter) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const searchParams = useSearchParams();
  const [filters, setFilters] = useState(
    JSON.parse(searchParams.get('filter') || '{"id": []}') as Partial<ClinicFilterInput>
  );
  const onHandleFilterChange = () => {
    modal.current?.dismiss();
  };

  const handleRadioButtonClick = (filterValue: number) => {
    setFilters({ avgStars: filterValue });
    // TODO : add reducer for filter state and change this
    searchParams.set('filter', JSON.stringify({ avgStars: filterValue }));
  };

  const onFiltersClearButton = () => {
    setFilters({ id: [] });
    searchParams.set('filter', JSON.stringify({ id: [] }));
    modal.current?.dismiss();
  };

  const { t } = useTranslation();

  return (
    <IonModal className={cx(styles.filterContainer)} ref={modal} trigger={trigger}>
      <IonToolbar mode="ios" className={cx('ionFilterModal__toolbar')}>
        <IonButtons className={cx('ionFilterModal__toolbar-buttons')} slot="start">
          <IonButton className={cx('ionFilterModal__toolbar-button')} onClick={() => modal.current?.dismiss()}>
            <IonIcon className={cx('ionFilterModal__toolbar-buttonIcon')} slot="icon-only" icon={arrowBackOutline} />
          </IonButton>
        </IonButtons>
        <IonTitle className={cx('ionFilterModal__toolbar-title')}>{t('Filter')}</IonTitle>
      </IonToolbar>

      <IonContent className={cx('ion-padding', 'ionFilterModal__container')}>
        <IonList className={cx('ionFilterModal__filter')}>
          <IonListHeader className={cx('ionFilterModal__filter-title')}>{t('Clinic rating')}:</IonListHeader>
          <IonRadioGroup value={filters.avgStars} className={cx('ionFilterModal__filterRadioGroup')}>
            {_.range(5, 0).map((item) => {
              return (
                <IonItem
                  key={item}
                  onClick={() => {
                    handleRadioButtonClick(item);
                  }}
                  lines="full"
                  detail={false}
                  className={cx('ionFilterModal__filter-rating')}
                  mode="ios"
                >
                  <Rating
                    className={cx('ionFilterModal__filter-ratingIcons')}
                    size={26}
                    fillColor="var(--ion-color-primary)"
                    allowFraction
                    readonly
                    initialValue={item}
                  />
                  <IonRadio
                    color="dark"
                    className={cx('ionFilterModal__filter-ratingRadio')}
                    slot="end"
                    value={item}
                    mode="ios"
                  />
                </IonItem>
              );
            })}
          </IonRadioGroup>
        </IonList>
        {/* <div className={cx('ionFilterModal__filter')}>
          <IonListHeader className={cx('ionFilterModal__filter-title')}>Placeholder</IonListHeader>
          <div className={cx('ionFilterModal__filter-placeholder')}>Пример карточки фильтров</div>
        </div> */}
        <IonRow className={cx('ionFilterModal__footer')}>
          <IonButton className={cx('ionFilterModal__footer-btn')} onClick={onHandleFilterChange}>
            <span className={cx('ionFilterModal__footer-btnText')}>{t('Show result')}</span>
            <span className={cx('ionFilterModal__footer-btnClinicLength')}>({clinicsLength})</span>
          </IonButton>
          <IonButton
            className={cx('ionFilterModal__footer-btn', 'ionFilterModal__footer-btnBordered')}
            onClick={onFiltersClearButton}
          >
            <span className={cx('ionFilterModal__footer-btnText')}>{t('Reset')}</span>
          </IonButton>
        </IonRow>
      </IonContent>
    </IonModal>
  );
};

export { ReviewFilter };
