import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Device } from '@capacitor/device';
import { DeviceMotion } from '@ionic-native/device-motion';
import _ from 'lodash';
import { Capacitor } from '@capacitor/core';
import { DEVICE_ENV, RecordBundle } from './watch-device-context';
import {
  DeviceDeviceType,
  DeviceType,
  DeviceTypeEdge,
  PatientTypeConnection,
  PatientTypeEdge,
  SettingsValueType,
  useCreateDeviceMutation,
  useCreateDeviceRecordsMutation,
} from '../generated/graphql';
import { AuthContextType } from './auth-context';

const defaultPhoneRecordBundleValue = {
  accelerations: [],
};

const usePhoneDevice = (authContext: AuthContextType) => {
  const [phoneDevice, setPhoneDevice] = useState<Partial<DeviceType>>();
  const [disablePhoneDeviceTracking, setDisablePhoneDeviceTracking] = useState(false);
  const phoneDeviceRecordBundleRef = useRef<Pick<RecordBundle, 'accelerations'>>(defaultPhoneRecordBundleValue);
  const [pushDeviceRecord, { error: noDeviceFoundError }] = useCreateDeviceRecordsMutation();
  const [createDeviceMutation] = useCreateDeviceMutation({
    fetchPolicy: 'no-cache',
  });

  const createOrFetchPhoneDevice = useCallback(
    async (authContextCb: AuthContextType) => {
      // TODO: maybe better device UUID receiving
      const { uuid: uuidInsteadOfMac } = await Device.getId();
      const user = authContextCb!.user!;

      // Get phone device from 'AuthContext' or create it.
      // TODO: if no device available
      const { node: phoneDeviceInAuthContext } =
        user.patientSet?.edges[0].node.deviceSet.edges.find(({ node: device }) => {
          return device?.mac === uuidInsteadOfMac;
        }) || ({} as Partial<DeviceTypeEdge>);
      let resultPhoneDevice: Partial<DeviceType> | undefined;
      if (phoneDeviceInAuthContext && !noDeviceFoundError) {
        resultPhoneDevice = phoneDeviceInAuthContext;
      } else {
        try {
          const fetchedData = await createDeviceMutation({
            variables: {
              input: {
                deviceType: DeviceDeviceType.A_2,
                mac: uuidInsteadOfMac,
              },
            },
          });
          // TODO: remove `any` and fix this problem
          resultPhoneDevice = fetchedData.data?.deviceCreate.device as any;
        } catch (e) {
          // TODO: fetch device and populate 'AuthContext'
        }
      }

      // Store phone device in 'AuthContext' and 'LocalStorage'.
      if (resultPhoneDevice && (!phoneDeviceInAuthContext || noDeviceFoundError)) {
        authContext.setUser({
          ...user,
          patientSet: {
            ...user.patientSet,
            edges: user.patientSet?.edges.map((edge) => {
              return {
                ...edge,
                node: {
                  ...edge.node,
                  deviceSet: {
                    ...edge.node.deviceSet,
                    edges: [
                      ...edge.node.deviceSet.edges,
                      {
                        node: resultPhoneDevice,
                        __typename: 'DeviceTypeEdge',
                      },
                    ].filter((el) => {
                      // Remove dangling (which doesn't exist in DB) `phone device` from  'localStorage'
                      if (phoneDeviceInAuthContext) {
                        return phoneDeviceInAuthContext.id !== el.node?.id;
                      }
                      return true;
                    }),
                  },
                },
              };
            }) as PatientTypeEdge[],
          } as PatientTypeConnection,
        });
      }

      // let resultPhoneDevice: Partial<DeviceType> | undefined = data?.deviceCreate.device || device;
      if (resultPhoneDevice) {
        setPhoneDevice(resultPhoneDevice);
      }
    },
    [authContext, createDeviceMutation, noDeviceFoundError]
  );

  // Set 'state' to 'undefined' if user logs out.
  useEffect(() => {
    if (!authContext.user?.id && phoneDevice) {
      setPhoneDevice(undefined);
    }
  }, [authContext.user?.id, phoneDevice]);

  useEffect(() => {
    if (!phoneDevice && authContext.user?.id && Capacitor.isNativePlatform()) {
      createOrFetchPhoneDevice(authContext);
    }
  }, [authContext, authContext.user?.id, createOrFetchPhoneDevice, phoneDevice]);

  const phoneDeviceConfig: SettingsValueType | null = useMemo(() => {
    if (phoneDevice) {
      return {
        historyBufferSize:
          ((phoneDevice.settings?.value as SettingsValueType)?.historyBufferSize ?? DEVICE_ENV.historyBufferSize) ||
          500,
        sendDataPeriod:
          ((phoneDevice.settings?.value as SettingsValueType)?.sendDataPeriod ?? DEVICE_ENV.sendDataPeriod) || 10000,
        reconnectAttemptPeriod:
          ((phoneDevice.settings?.value as SettingsValueType)?.reconnectAttemptPeriod ??
            DEVICE_ENV.reconnectAttemptPeriod) ||
          10000,
        sensorReadPeriod:
          ((phoneDevice.settings?.value as SettingsValueType)?.sensorReadPeriod ?? DEVICE_ENV.sensorReadPeriod) || 500,
        batteryReadPeriod:
          ((phoneDevice.settings?.value as SettingsValueType)?.batteryReadPeriod ?? DEVICE_ENV.batteryReadPeriod) ||
          10000,
      };
    }
    return null;
  }, [phoneDevice]);

  // Repeatedly send 'phone device' records to DB
  useEffect(() => {
    let timerID: NodeJS.Timeout | undefined;
    if (phoneDeviceConfig && phoneDevice && authContext.user?.id && !disablePhoneDeviceTracking) {
      timerID = setInterval(() => {
        pushDeviceRecord({
          variables: {
            input: {
              device: phoneDevice.id!,
              value: JSON.stringify(phoneDeviceRecordBundleRef.current),
              date: new Date(),
            },
          },
        }).catch(() => {
          setPhoneDevice(undefined);
        });

        phoneDeviceRecordBundleRef.current = defaultPhoneRecordBundleValue;
      }, phoneDeviceConfig.sendDataPeriod);
    }
    return () => {
      if (timerID) {
        clearInterval(timerID);
        timerID = undefined;
      }
    };
  }, [pushDeviceRecord, phoneDeviceConfig, phoneDevice, authContext.user?.id, disablePhoneDeviceTracking]);

  // Collect phone device accelerator data
  useEffect(() => {
    let subscription: any;
    if (!disablePhoneDeviceTracking) {
      subscription = DeviceMotion.watchAcceleration({
        frequency: 100,
      }).subscribe(({ x, y, z, timestamp }) => {
        const { accelerations } = phoneDeviceRecordBundleRef.current;
        const acceleration = {
          value: [x, y, z],
          timestamp,
        };

        phoneDeviceRecordBundleRef.current = {
          accelerations: [..._.takeRight(accelerations, 500), acceleration],
        };
      });
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [disablePhoneDeviceTracking]);

  return { phoneDevice, setPhoneDevice, disablePhoneDeviceTracking, setDisablePhoneDeviceTracking };
};

export { usePhoneDevice };
