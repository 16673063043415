import React from 'react';
import classNames from 'classnames/bind';
import { Rating } from 'react-simple-star-rating';
import { useHistory } from 'react-router-dom';
import { IonCard, IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { ReviewClinicType } from '../../../generated/graphql';
import { Image } from '../../UI/Image/Image';
import styles from './DoctorClinic.module.scss';

const cx = classNames.bind(styles);

interface IDoctorClinic {
  clinic: ReviewClinicType;
}

const DoctorClinic = ({ clinic }: IDoctorClinic) => {
  const { push } = useHistory();

  return (
    <IonCard onClick={() => push(`../review-clinics/${clinic.id}`)} className={cx(styles.ionCard)} mode="ios">
      <IonGrid>
        <IonRow>
          <Image url={clinic.avatar?.url} className={cx(styles.ionAvatar)} />
        </IonRow>
        <IonRow>
          <IonItem className={cx(styles.item)}>
            <Rating fillColor="var(--ion-color-primary)" allowFraction readonly initialValue={clinic.averageStars} />
          </IonItem>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem className={cx(styles.item)}>{`Name : ${clinic.name}`}</IonItem>
            <IonItem className={cx(styles.item)}>{`Dicription : ${clinic.discription}`}</IonItem>
            <IonItem className={cx(styles.item)}>{`Work time : ${clinic.workTime}`}</IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export { DoctorClinic };
