import React, { useRef, useState } from 'react';
import { IonItem, IonLabel, IonList, IonListHeader, IonModal, IonRadio, IonRadioGroup } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import styles from './ReviewSort.module.scss';

const cx = classNames.bind(styles);

interface IReviewSort {
  trigger: string;
  sortValues: {
    id: string;
    title: string;
    name: string;
  }[];
}

const ReviewSort = ({ trigger, sortValues }: IReviewSort) => {
  const queryParams = useSearchParams();
  const currentSortType = queryParams.get('sort');
  const [radioValue, setRadioValue] = useState(currentSortType);
  const modalSheet = useRef<HTMLIonModalElement>(null);

  const onSortChange = (sortName: string) => {
    queryParams.set('sort', sortName);
    setRadioValue(sortName);
  };

  const handleRadioButtonClick = (sortName: string) => {
    onSortChange(sortName);
    modalSheet.current?.dismiss();
  };

  const { t } = useTranslation();

  return (
    <IonModal
      trigger={trigger}
      mode="ios"
      ref={modalSheet}
      initialBreakpoint={0.35}
      breakpoints={[0, 0.35, 0.4]}
      className={cx('ionSortModal')}
    >
      <IonList className={cx('ionSortModal__container')} lines="full">
        <IonListHeader className={cx('ionSortModal__title', 'radioListTitle')}>{t('Sort by')}</IonListHeader>
        <IonRadioGroup value={radioValue} className={cx('ionSortModal__list')}>
          {sortValues &&
            sortValues.map((sortValue) => {
              return (
                <IonItem
                  key={sortValue.id}
                  detail={false}
                  className={cx('ionSortModal__list-item')}
                  onClick={() => {
                    handleRadioButtonClick(`${sortValue.name}`);
                  }}
                >
                  <IonLabel className={cx('ionSortModal__list-itemLabel')}>{t(`${sortValue.title}`)}</IonLabel>
                  <IonRadio
                    color="dark"
                    className={cx('ionSortModal__list-itemRadioBtn')}
                    slot="end"
                    value={sortValue.name}
                    mode="ios"
                  />
                </IonItem>
              );
            })}
        </IonRadioGroup>
      </IonList>
    </IonModal>
  );
};

export { ReviewSort };
