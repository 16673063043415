import { IonCheckbox, IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GraphPrescriptionMedicineWithMedicineIntakeType,
  PrescriptionMedicineIntakeState,
  CreateMedicineIntakeInputType,
  RemoveMedicineIntakeInputType,
} from '../../generated/graphql';

// styles
import styles from './MedicinesIntake.module.scss';

const medicineIntakeState = {
  [PrescriptionMedicineIntakeState.A_0]: 'Anytime',
  [PrescriptionMedicineIntakeState.A_1]: 'Hungry',
  [PrescriptionMedicineIntakeState.A_2]: 'Eating',
  [PrescriptionMedicineIntakeState.A_3]: 'Full',
  [PrescriptionMedicineIntakeState.A_4]: 'Before sleep',
  [PrescriptionMedicineIntakeState.A_5]: 'Awoke',
};

interface IMedicinesIntake {
  date: Date;
  medicines: GraphPrescriptionMedicineWithMedicineIntakeType[];
  checkboxOnHandle: (data: CreateMedicineIntakeInputType) => any;
  checkboxOffHandle: (data: RemoveMedicineIntakeInputType) => any;
}

const MedicinesIntakeList: React.FC<IMedicinesIntake> = ({ medicines, checkboxOnHandle, checkboxOffHandle, date }) => {
  const { t } = useTranslation();

  const medicinesIntakes = useMemo(() => {
    const typedMeds = medicines.reduce<{ [key: string]: GraphPrescriptionMedicineWithMedicineIntakeType[] }>(
      (acc, cur) => {
        const state = cur.prescriptionMedicine.intakeState;
        if (!acc[state]) {
          acc[state] = [];
        }
        acc[state].push(cur);
        return acc;
      },
      {}
    );

    return Object.keys(typedMeds).map((state, i) => {
      const meds = typedMeds[state];
      if (meds.length > 0) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <IonList key={i} className={styles.list}>
            <IonListHeader className={styles.listHeader}>
              <IonLabel>{t(medicineIntakeState[state as PrescriptionMedicineIntakeState])}</IonLabel>
            </IonListHeader>
            {meds.map((med) => {
              const [medicineIntake] = med.medicineIntakes;
              const medicine = med.prescriptionMedicine;

              return (
                <IonItem key={medicine.id} className={styles.listItem}>
                  <div className={styles.decorCircle} />
                  <IonLabel>
                    {medicine.medicine.name}
                    <span className={styles.floatLabel}>
                      {medicine.intakeQuantity} {t('pcs')}
                    </span>
                  </IonLabel>
                  <IonCheckbox
                    onClick={() => {
                      if (!medicineIntake) {
                        checkboxOnHandle({
                          prescriptionMedicineId: medicine.id,
                          medicineId: medicine.medicine.id,
                          intakeDate: date.toISOString().slice(0, 10),
                        });
                      } else {
                        checkboxOffHandle({
                          id: medicineIntake.id,
                          prescriptionMedicineId: medicine.id,
                          medicineId: medicine.medicine.id,
                          intakeDate: date.toISOString().slice(0, 10),
                        });
                      }
                    }}
                    className={styles.checkbox}
                    slot="end"
                    checked={!!medicineIntake}
                  />
                </IonItem>
              );
            })}
          </IonList>
        );
      }
      return null;
    });
  }, [medicines, t, checkboxOnHandle, checkboxOffHandle, date]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {medicines.length > 0 ? (
        medicinesIntakes
      ) : (
        <IonItem className={styles.listItem} lines="none">
          {t('No medicines intakes today')}
        </IonItem>
      )}
    </>
  );
};

export { MedicinesIntakeList };
