import React from 'react';
import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonRow } from '@ionic/react';

// form
import { Controller, useForm } from 'react-hook-form';

// graphql
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { UserRole, useTokenAuthMutation } from '../../generated/graphql';

// i18n

// context
import { useAuth } from '../../contexts/auth-context';
import { useScreensSwitcherContext } from '../../screensSwitcher/screens-switcher-context';

// styles
import styles from './SigninCodeInput.module.scss';

// components
import { ResendSMS } from './ResendSMS';

const cx = classNames.bind(styles);

interface CodeDataType {
  code: string;
}

const SigninCodeInput = () => {
  const authContext = useAuth();
  const { toNextScreen } = useScreensSwitcherContext();
  const { t } = useTranslation();
  const { handleSubmit, formState, control } = useForm<CodeDataType>({
    mode: 'onChange',
    defaultValues: { code: '' },
  });

  const [tokenAuth] = useTokenAuthMutation({
    onCompleted: (tokenData) => {
      authContext.login(tokenData);
      const isDoctorRegisteringAsPatient = tokenData.tokenAuth.user.patientSet.edges.length === 0;
      const isRegisteredeAsDoctorOrPatient = tokenData.tokenAuth.registered;
      if (!isRegisteredeAsDoctorOrPatient && isDoctorRegisteringAsPatient) {
        toNextScreen();
      }
    },
    fetchPolicy: 'no-cache',
  });

  async function handleAuthorization({ code }: CodeDataType) {
    try {
      await tokenAuth({
        variables: {
          phone: authContext.user!.phone!,
          securityCode: code,
          role: UserRole.A_3,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  return (
    <IonGrid className={cx(styles.ionGrid, 'ion-no-padding')}>
      <IonRow className={cx(styles.halfHeight, styles.textRow)}>{t('Authorization code sent')}</IonRow>
      <div className={styles.inputFieldsContainer}>
        <IonRow className={cx(styles.inputRow, 'ion-padding-horizontal')}>
          <IonCol size="12">
            <IonItem className={styles.inputItem} lines="none">
              <Controller
                render={({ onChange }) => (
                  <IonInput
                    className="ion-padding"
                    placeholder="XXXX"
                    inputMode="decimal"
                    onIonChange={(e) => onChange(e.detail.value)}
                    type="tel"
                    maxlength={4}
                  />
                )}
                control={control}
                name="code"
                rules={{
                  required: true,
                  pattern: /\d{4}/,
                }}
              />
            </IonItem>
          </IonCol>
          <IonCol className="ion-text-center " size="12">
            <ResendSMS />
          </IonCol>
          <IonCol size="12">
            <IonButton
              className={cx(styles.button, 'ion-no-margin')}
              onClick={handleSubmit(handleAuthorization)}
              disabled={!formState.isValid}
              expand="block"
            >
              {t('Next')}
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </IonGrid>
  );
};

export { SigninCodeInput };
