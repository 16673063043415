import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/react';
import { ReviewDoctorType, ReviewType } from '../../generated/graphql';
import styles from './ReviewDoctorDetail.module.scss';
import { DoctorReview } from './doctor-review/DoctorReview';
import { DoctorClinic } from './doctor-clinic/DoctorClinic';
import { Image } from '../UI/Image/Image';
import { DisclosureDropdown } from '../UI/DisclosureDropdown/DisclosureDropdown';

const cx = classNames.bind(styles);

interface IReviewDoctorDetail {
  doctor: ReviewDoctorType;
}

const ReviewDoctorDetail = ({ doctor }: IReviewDoctorDetail) => {
  const { t } = useTranslation();

  return (
    <IonGrid>
      <IonRow>
        <Image url={doctor.avatar?.url} className={cx(styles.ionAvatar)} />
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem className={cx(styles.item)}>{`Clinic secondName :${doctor.secondName}`}</IonItem>
          <IonItem className={cx(styles.item)}>{`Clinic firstName :${doctor.firstName}`}</IonItem>
          <IonItem className={cx(styles.item)}>{`Clinic thirdName :${doctor.thirdName}`}</IonItem>
          <IonItem className={cx(styles.item)}>{`Clinic specialization :${doctor.specialization}`}</IonItem>
          <IonItem className={cx(styles.item)}>{`Clinic workTime :${doctor.workTime}`}</IonItem>
          <IonItem className={cx(styles.item)}>{`Clinic address :${doctor.address}`}</IonItem>
          <IonItem className={cx(styles.item)}>{`Clinic phone :${doctor.phone}`}</IonItem>
        </IonCol>
      </IonRow>

      <DisclosureDropdown title={t('Reviews')}>
        {doctor.reviews.length > 0 ? (
          doctor.reviews.map((review: ReviewType) => <DoctorReview key={review.id} review={review} />)
        ) : (
          <IonItem>{t('No reviews')}</IonItem>
        )}
      </DisclosureDropdown>
      <DisclosureDropdown title={t('Clinics')}>
        {doctor.doctorclinicSet.edges.length > 0 ? (
          doctor.doctorclinicSet.edges.map((edge) => (
            <DoctorClinic key={edge.node.clinic?.id} clinic={edge.node.clinic} />
          ))
        ) : (
          <IonItem>{t('No clinics')}</IonItem>
        )}
      </DisclosureDropdown>
    </IonGrid>
  );
};

export { ReviewDoctorDetail };
