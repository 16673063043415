import {
  IonBadge,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToggle,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import React from 'react';

// context
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { useLocation } from '../../contexts/location-context';

// utils
import { goToSettings } from '../../utils/device';

// i18n

// graphql
import { MeQuery, UserSex } from '../../generated/graphql';

// styles
import styles from './Profile.module.scss';
import { useWatchDeviceContext } from '../../contexts/watch-device-context';
import { usePhoneDeviceContext } from '../../contexts/phone-device-context';

const cx = classNames.bind(styles);

interface Props {
  data: MeQuery;
}

const Profile: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { permission } = useLocation();
  const {
    state: { bluetooth },
  } = useWatchDeviceContext();
  const { togglePhoneDeviceTracking, disablePhoneDeviceTracking } = usePhoneDeviceContext();
  const [alert] = useIonAlert();

  const { firstName, secondName, thirdName, dateOfBirth, sex, email, phone, address } = data.me;

  return (
    <IonList className={cx(styles.container)}>
      {!!(firstName && secondName && thirdName) && (
        <IonListHeader className={styles.username}>
          <IonLabel className="ion-no-margin">{`${firstName} ${secondName} ${thirdName}`}</IonLabel>
        </IonListHeader>
      )}
      <IonItem className={styles.userInfoItem}>
        <div className={styles.userInfoWrapper}>
          <IonLabel>{t('Date of birth')}</IonLabel>
          <IonLabel>{new Date(dateOfBirth).toLocaleDateString()}</IonLabel>
        </div>
      </IonItem>
      <IonItem className={styles.userInfoItem}>
        <div className={styles.userInfoWrapper}>
          <IonLabel>{t('Sex')}</IonLabel>
          <IonLabel>{t(sex === UserSex.A_1 ? 'Male' : 'Female')}</IonLabel>
        </div>
      </IonItem>
      <IonItem className={styles.userInfoItem}>
        <div className={styles.userInfoWrapper}>
          <IonLabel>{t('Email')}</IonLabel>
          <IonLabel>{email}</IonLabel>
        </div>
      </IonItem>
      <IonItem className={styles.userInfoItem}>
        <div className={styles.userInfoWrapper}>
          <IonLabel>{t('Phone')}</IonLabel>
          <IonLabel>{phone}</IonLabel>
        </div>
      </IonItem>
      <IonItem className={styles.userInfoItem}>
        <div className={styles.userInfoWrapper}>
          <IonLabel>{t('Address')}</IonLabel>
          <IonLabel>{address.match(/[^ ]* [^ ]* [^ ]*$/g)}</IonLabel>
        </div>
      </IonItem>

      {!isPlatform('desktop') && (
        <IonItem className={styles.userInfoItem}>
          <div className={styles.userInfoWrapper}>
            <IonLabel>{t('Geolocation')}</IonLabel>
            {/* eslint-disable-next-line no-nested-ternary */}
            {permission === 'denied' ? (
              <IonBadge
                className={styles.badge}
                color="primary"
                onClick={async (e) => {
                  e.persist();
                  alert({
                    cssClass: 'my-css',
                    header: t('Geolocation'),
                    message: isPlatform('ios')
                      ? t(
                          "Epihelper does not have access to your location. Please tap Settings button and you will be redirected to settings page of this app, then tap on 'Location' after which you should select 'Always' option"
                        )
                      : t(
                          "Epihelper does not have access to your location. Please tap Settings button and you will be redirected to settings page where (in permissions) you should select 'Allow all the time' option"
                        ),
                    buttons: [
                      {
                        text: t('Cancel'),
                        role: 'Cancel',
                        // eslint-disable-next-line no-console
                        handler: () => console.log('cancel pressed'),
                      },
                      {
                        text: t('Settings'),
                        handler: async () => {
                          await goToSettings();
                        },
                      },
                    ],
                  });
                }}
              >
                {t('Off')}
              </IonBadge>
            ) : permission === 'granted' ? (
              <IonBadge className={styles.badge} color="primary">
                {t('On')}
              </IonBadge>
            ) : (
              <IonButton color="primary" className={styles.button} fill="clear">
                {t('Выкл')}
              </IonButton>
            )}
          </div>
        </IonItem>
      )}
      {!isPlatform('desktop') && (
        <IonItem className={styles.userInfoItem}>
          <div className={styles.userInfoWrapper}>
            <IonLabel>{t('Bluetooth')}</IonLabel>
            {!bluetooth ? (
              <IonBadge
                className={styles.badge}
                color="primary"
                onClick={async (e) => {
                  e.persist();
                  alert({
                    cssClass: 'my-css',
                    header: t('Bluetooth'),
                    message: t(
                      'Epihelper does not have access to your bluetooth. Check it in your phone, and if it turn. Tap Settings > Bluetooth'
                    ),
                    buttons: [
                      {
                        text: t('Cancel'),
                        role: 'Cancel',
                        // eslint-disable-next-line no-console
                        handler: () => console.log('cancel pressed'),
                      },
                      {
                        text: t('Settings'),
                        handler: async () => {
                          await goToSettings();
                        },
                      },
                    ],
                  });
                }}
              >
                {t('Off')}
              </IonBadge>
            ) : (
              <IonBadge className={styles.badge} color="primary">
                {t('On')}
              </IonBadge>
            )}
          </div>
        </IonItem>
      )}
      {!isPlatform('desktop') && (
        <IonItem className={styles.userInfoItem}>
          <div className={styles.userInfoWrapper}>
            <IonLabel>{t('Send accelerometer data')}</IonLabel>
            <IonToggle
              className={styles.phoneDataToggle}
              checked={!disablePhoneDeviceTracking}
              onIonChange={togglePhoneDeviceTracking}
            />
          </div>
        </IonItem>
      )}
    </IonList>
  );
};

export { Profile };
