import React from 'react';
import { useTranslation } from 'react-i18next';

import { PatientSeizuresQuery } from '../../generated/graphql';

import { getMonthName, takeYearBackMonthNames } from './PatientSeizuresChartFunctions';
import { Chart } from '../UI/chart/Chart';

interface IChart {
  monthNames: Array<string>;
  data: PatientSeizuresQuery;
}

const PatientSeizuresChart: React.FC<IChart> = ({ data, monthNames }) => {
  const { t } = useTranslation();

  const yearBackMonthNamesWithCounts = takeYearBackMonthNames(monthNames).reduce((acc, month) => {
    acc[month] = 0;
    return acc;
  }, {} as any);

  const today = new Date();
  const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));

  const seizuresPerMonth = data.patientSeizures.edges
    .map((item) => ({ ...item, date: new Date(item.node.date) }))
    .filter((item) => item.date >= oneYearAgo)
    .reduce((acc, item) => {
      const key = t(getMonthName(item.date, 'long'));
      // eslint-disable-next-line no-plusplus
      acc[key]++;
      return acc;
    }, yearBackMonthNamesWithCounts);

  const values = Object.keys(seizuresPerMonth).map((key) => ({ x: key, y: seizuresPerMonth[key] }));
  return <Chart data={[{ values, label: t('seizures') }]} />;
};

export { PatientSeizuresChart };
