import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { IonHeader, IonItem, IonRow } from '@ionic/react';
import { Rating } from 'react-simple-star-rating';
import { ReviewClinicType } from '../../generated/graphql';
import styles from './ReviewClinicDetail.module.scss';
import { ClinicDoctor } from './clinic-doctor/ClinicDoctor';
import { Image } from '../UI/Image/Image';
import { DisclosureDropdown } from '../UI/DisclosureDropdown/DisclosureDropdown';
import { ReviewByCharacteristics } from './review-by-characteristics/ReviewByCharacteristics';

const cx = classNames.bind(styles);

interface IReviewClinicDetail {
  clinic: ReviewClinicType;
}

const ReviewClinicDetail = ({ clinic }: IReviewClinicDetail) => {
  const { t } = useTranslation();
  const AvgStars = clinic.averageStars.toFixed(1);

  return (
    <div className={cx('ionClinicDetail')}>
      <IonHeader className={cx('ionClinicDetail__header')}>
        {clinic.avatar?.url ? (
          <Image url={clinic.avatar.url} className={cx('ionClinicDetail__header-img')} />
        ) : (
          <Image url="../../assets/images/placeholder-clinics.png" className={cx('ionClinicDetail_emptyImage')} />
        )}
        <div className={cx('ionClinicDetail__header-title')}>{clinic.name}</div>
      </IonHeader>

      <IonRow className={cx('ionClinicDetail__reviews')}>
        <div className={cx('ionClinicDetail__reviews-title')}>Средняя оценка: {AvgStars}</div>
        <div className={cx('ionClinicDetail__reviews-rating')}>
          <Rating
            className={cx('ionClinicDetail__reviews-ratingIcons')}
            size={22}
            fillColor="var(--ion-color-tertiary)"
            allowFraction
            readonly
            initialValue={clinic.averageStars}
          />
        </div>
        <div className={cx('ionClinicDetail__reviews-ratingDesc')}>
          На основе отзывов{' '}
          <span className={cx('ionClinicDetail__reviews-ratingLength')}>{clinic.reviews?.length}</span> пациентов
        </div>
      </IonRow>

      <IonRow className={cx('ionClinicDetail__content')}>
        <div className={cx('ionClinicDetail__content-item')}>
          <div className={cx('ionClinicDetail__content-itemTitle')}>{t('Description')}:</div>
          <div className={cx('ionClinicDetail__content-itemDescription')}>{clinic.discription}</div>
        </div>
        <div className={cx('ionClinicDetail__content-item')}>
          <div className={cx('ionClinicDetail__content-itemTitle')}>{t('Work time')}:</div>
          <div className={cx('ionClinicDetail__content-itemDescription')}>{clinic.workTime}</div>
        </div>
        <div className={cx('ionClinicDetail__content-item')}>
          <div className={cx('ionClinicDetail__content-itemTitle')}>{t('Address')}:</div>
          <div className={cx('ionClinicDetail__content-itemDescription')}>{clinic.address}</div>
        </div>
        <div className={cx('ionClinicDetail__content-item')}>
          <div className={cx('ionClinicDetail__content-itemTitle')}>{t('Phone')}:</div>
          <div className={cx('ionClinicDetail__content-itemDescription')}>{clinic.phone}</div>
        </div>
      </IonRow>

      <ReviewByCharacteristics clinicId={clinic.id} />

      <DisclosureDropdown title={t('Doctors')}>
        {clinic.doctorclinicSet.edges.length > 0 ? (
          clinic.doctorclinicSet.edges.map((edge) => (
            <ClinicDoctor key={edge.node.doctor?.id} doctor={edge.node.doctor} />
          ))
        ) : (
          <IonItem>{t('No doctors')}</IonItem>
        )}
      </DisclosureDropdown>
    </div>
  );
};

export { ReviewClinicDetail };
