import React, { useState } from 'react';
import { Camera, CameraResultType } from '@capacitor/camera';

import classNames from 'classnames/bind';
import { IonButton, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { Image } from '../UI/Image/Image';
import { ImageType } from '../../generated/graphql';
import styles from './PhotoLoader.module.scss';

interface Props {
  photo: ImageType | undefined;
  onPhotoChange: (image: File) => void;
  onPhotoRemove: () => void;
}

const PhotoLoader = ({ photo, onPhotoChange, onPhotoRemove }: Props) => {
  const [profilePhoto, setProfilePhoto] = useState<string | undefined>();
  const { t } = useTranslation();
  const cx = classNames.bind(styles);
  const base64toFile = async (url: RequestInfo) => {
    const res = await fetch(url);
    const blob = await res.blob();
    return new File([blob], `File name.`, { type: 'image/png' });
  };

  const takePhotoHandler = async () => {
    try {
      const receivedPhoto = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        promptLabelCancel: t('Cancel'),
        promptLabelHeader: t('Photo'),
        promptLabelPhoto: t('From Library'),
        promptLabelPicture: t('Take Picture'),
      });
      const url = `data:image/png;base64,${receivedPhoto.base64String}`;
      const photoFile = await base64toFile(url);
      setProfilePhoto(receivedPhoto.base64String);
      onPhotoChange(photoFile);
    } catch (error: any) {
      // Some error handling here
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {profilePhoto ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div onClick={takePhotoHandler} className={styles.profilePhotoContainer}>
            <img className={styles.profilePhoto} src={`data:image/png;base64,${profilePhoto}`} alt={`${photo?.name}`} />
          </div>
          <IonButton
            className={styles.switchRemoveAvatar}
            expand="block"
            onClick={() => {
              setProfilePhoto(undefined);
            }}
          >
            {t('Remove avatar')}
          </IonButton>
        </>
      ) : (
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div onClick={takePhotoHandler} className={cx(styles.profilePhotoContainer, styles.placeholderWrapper)}>
            <Image url={photo?.url} className={cx(styles.profilePhoto)} />
            <IonIcon color="primary" icon={addOutline} className={cx(styles.plusIcon)} />
          </div>
          {photo?.url && (
            <IonButton className={styles.switchRemoveAvatar} expand="block" onClick={onPhotoRemove}>
              {t('Remove avatar')}
            </IonButton>
          )}
        </>
      )}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export { PhotoLoader };
