import { IonContent, IonLoading, IonPage, useIonViewWillEnter } from '@ionic/react';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';

// i18n
import { useTranslation } from 'react-i18next';

// graphql
import {
  PrescriptionMedicineType,
  UpdatePrescriptionMedicineInputType,
  usePrescriptionWithMedicinesQuery,
  useRemovePrescriptionMedicineMutation,
  useUpdatePrescriptionMedicineMutation,
  MedicineTypeConnection,
} from '../../generated/graphql';

// components
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { PrescriptionMedicine } from '../../components/prescription-medicine/PrescriptionMedicine';
import { Title } from '../../components/title/Title';
import { useToast } from '../../contexts/toast-context';

const PrescriptionMedicinePage: React.FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { prescription_id, medicine_id } = useParams() as {
    prescription_id: string;
    medicine_id: string;
  };

  const {
    data,
    loading: prescriptionWithMedicinesLoading,
    error,
    refetch,
  } = usePrescriptionWithMedicinesQuery({
    fetchPolicy: 'no-cache',
    variables: { prescription_id },
  });

  useIonViewWillEnter(() => {
    if (data) refetch();
  }, [data, refetch]);

  const [updatePrescriptionMedicine, { loading: updatePrescriptionMedicineLoading }] =
    useUpdatePrescriptionMedicineMutation({
      onCompleted() {
        goBack();
      },
    });

  const [removePrescriptionMedicine, { loading: removePrescriptionMedicineLoading }] =
    useRemovePrescriptionMedicineMutation({
      onCompleted() {
        toast.dispatch({ type: 'SUCCESS', message: t('Successfully removed') });
        goBack();
      },
    });

  const handleUpdatePrescriptionMedicine = async (input: UpdatePrescriptionMedicineInputType) => {
    await updatePrescriptionMedicine({
      variables: {
        input: {
          ...input,
          id: medicine_id,
        },
      },
    });
  };

  const handleRemovePrescriptionMedicine = async () => {
    await removePrescriptionMedicine({
      variables: {
        input: { id: medicine_id },
      },
    });
  };

  const prescriptionMedicine = useMemo(
    () => data?.prescription.prescriptionmedicineSet.edges.find(({ node }) => node.id === medicine_id)?.node,
    [data, medicine_id]
  );

  const medicines = data?.medicines;

  if (prescriptionWithMedicinesLoading || !data) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  return (
    <IonPage>
      <Title
        translucent
        title="Medicine"
        backButton
        delete={{ deleteButton: true, callback: handleRemovePrescriptionMedicine }}
      />
      <IonContent>
        {(updatePrescriptionMedicineLoading || removePrescriptionMedicineLoading || !prescriptionMedicine) && (
          <IonLoading isOpen message={t('Loading...')} />
        )}
        {prescriptionMedicine && (
          <PrescriptionMedicine
            medicine={prescriptionMedicine as PrescriptionMedicineType}
            medicines={medicines as MedicineTypeConnection}
            mutation={handleUpdatePrescriptionMedicine}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export { PrescriptionMedicinePage };
