import React, { useContext } from 'react';

interface ContextType {
  screenNumber: number;
  restart(): void;
  toNextScreen(): void;
  toPrevScreen(): void;
}

const ScreensSwitcherContext = React.createContext<ContextType>({
  screenNumber: 0,
  restart() {},
  toNextScreen() {},
  toPrevScreen() {},
});

const useScreensSwitcherContext = () => useContext(ScreensSwitcherContext);

export { useScreensSwitcherContext, ScreensSwitcherContext };
