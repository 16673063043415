import React, { useCallback, useRef, useState } from 'react';
import { Button, Map, withYMaps } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';
import { IonButton, IonSpinner } from '@ionic/react';
import classnames from 'classnames/bind';
import './YandexMapGlobal.css';
import styles from './YandexMap.module.scss';
import { placemarkOrange } from '../../assets/images/icons/icons';
import { YaBottomContainer } from './controls/YaContainer/YaBottomContainer';
import { YaBottomContainerDOM } from './controls/YaContainer/YaBottomContainerDOM';
import { useEffectExceptOnMount } from '../../hooks/useEffectExceptOnMount';
import backButtonCircleIcon from '../../assets/images/icons/back-button-circle-icon.svg';

export type RoutePanelStateType = {
  from: string;
  to: string;
};

const cx = classnames.bind(styles);

const YandexMapComponent = ({ mapState, ymaps, backButtonHandle, onAddressConfirm }: any) => {
  const { t } = useTranslation();
  const [map, setMap] = useState<any>();
  const [isDragging, setIsDragging] = useState(false);
  const [isLoadingCoords, setIsLoadingCoords] = useState(false);
  const [moveUpPlacemarkTimeoutID, setMoveUpPlacemarkTimeoutID] = useState<any>();

  const toggleIsDragging = useCallback(
    (dragging: boolean) => {
      setIsDragging(dragging);
    },
    [setIsDragging]
  );

  // Change map centering when address change
  useEffectExceptOnMount(() => {
    if (map) {
      map.panTo(mapState.center);
    }
  }, [mapState.center]);

  const [coords, setCoords] = useState(mapState.center);
  const [addressName, setAddressName] = useState('');
  const centerMarkerRef = useRef<HTMLImageElement>(null);

  const moveUpPlacemark = useCallback(() => {
    const timeoutID = setTimeout(() => {
      centerMarkerRef.current!.style.transform = 'translate(0px, -10px)';
      toggleIsDragging(true);
      setIsLoadingCoords(true);
    }, 100);
    setMoveUpPlacemarkTimeoutID(timeoutID);
  }, [centerMarkerRef, toggleIsDragging, setMoveUpPlacemarkTimeoutID]);

  const moveDownPlacemark = useCallback(() => {
    centerMarkerRef.current!.style.transform = 'translate(0px, 0px)';
    toggleIsDragging(false);
    clearTimeout(moveUpPlacemarkTimeoutID);
  }, [centerMarkerRef, toggleIsDragging, moveUpPlacemarkTimeoutID]);

  const fetchAndSetAddressNameByCoords = useCallback(
    async (location: any) => {
      const geoResponse = await ymaps.geocode(location);
      const address = geoResponse.geoObjects.get(0).properties.get('text');
      setAddressName(address);
    },
    [setAddressName, ymaps]
  );

  const onBoundChange = useCallback(
    (e: any) => {
      const centerCoords = e.originalEvent.newCenter;
      fetchAndSetAddressNameByCoords(centerCoords);
      setCoords(centerCoords);
      setIsLoadingCoords(false);
    },
    [setCoords, setIsLoadingCoords, fetchAndSetAddressNameByCoords]
  );

  const isLoading = isDragging || isLoadingCoords;

  return (
    <div style={{ height: '100%', width: '100%' }} onPointerDown={moveUpPlacemark} onPointerUp={moveDownPlacemark}>
      <div className={styles.markerIcon}>
        <img ref={centerMarkerRef} src={placemarkOrange} alt="map" />
      </div>

      <Map
        instanceRef={(ref) => setMap(ref)}
        options={{
          suppressMapOpenBlock: true,
          suppressObsoleteBrowserNotifier: true,
          yandexMapDisablePoiInteractivity: true,
        }}
        style={{
          height: '100%',
          width: '100%',
          position: 'fixed',
          zIndex: 10,
          top: 0,
        }}
        onBoundsChange={onBoundChange}
        defaultState={mapState}
        onClick={(event: any) => {
          const location: number[] = event.get('coords') as number[];
          setCoords(location);
          map.setCenter(location);
        }}
      >
        {backButtonHandle && (
          <Button
            onClick={() => setTimeout(() => backButtonHandle(), 100)}
            options={{
              maxWidth: 128,
              selectOnClick: false,
              layout: ymaps.templateLayoutFactory.createClass(
                `<div title='ya-back-button' 
                  style='background-image: url("{{data.icon}}"); ' 
                  src={{data.icon}}/>`
              ),
            }}
            data={{ icon: backButtonCircleIcon }}
          />
        )}

        <YaBottomContainer>
          <IonButton
            color="primary"
            className={cx(styles.confirmAddressButton)}
            disabled={isLoading}
            onPointerUp={(e) => {
              e.stopPropagation();
              // Using setTimeout here - because tap event propagates
              // to component which is overlapped by this component
              setTimeout(() => {
                onAddressConfirm(coords, addressName);
                if (backButtonHandle) {
                  backButtonHandle();
                }
              }, 200);
            }}
            onPointerDown={(e: any) => e.stopPropagation()}
          >
            {isLoading && (
              <IonSpinner className={styles.confirmAddressButtonSpinner} color="secondary" name="crescent" />
            )}
            <span className={cx(styles.confirmText, { [styles.loadingOpacity]: isLoading })}>
              {t('Confirm location')}
            </span>
          </IonButton>
        </YaBottomContainer>

        {/* Mounts DOMNode in which <YaBottomContainer>'s children will be rendered */}
        <YaBottomContainerDOM />
      </Map>
    </div>
  );
};

YandexMapComponent.defaultProps = {
  mapState: {
    center: [55.75, 37.57],
    zoom: 12,
    controls: [],
  },
};

const YandexMap = withYMaps(YandexMapComponent, true);

export { YandexMap };
