import React from 'react';
import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonRow, IonText } from '@ionic/react';
import { Browser } from '@capacitor/browser';

// form
import { Controller, useForm } from 'react-hook-form';
import MaskedInput from 'react-input-mask';

// graphql
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { UserRegisterMutation, UserRegisterMutationVariables, useUserRegisterMutation } from '../../generated/graphql';

// i18n
// context
import { useAuth } from '../../contexts/auth-context';
import { useScreensSwitcherContext } from '../../screensSwitcher/screens-switcher-context';

// styles
import styles from './SigninNumberInput.module.scss';

const cx = classNames.bind(styles);

const SigninNumberInput: React.FC = () => {
  const authContext = useAuth();
  const { toNextScreen } = useScreensSwitcherContext();

  const { handleSubmit, control, errors } = useForm<UserRegisterMutationVariables>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      phone: '',
    },
  });

  const [userRegistration] = useUserRegisterMutation({
    onCompleted: (userData: UserRegisterMutation) => {
      authContext.register(userData);
      toNextScreen();
    },
  });

  const { t } = useTranslation();

  async function handleRegistration({ email, phone }: UserRegisterMutationVariables) {
    try {
      await userRegistration({
        variables: {
          phone,
          email,
        },
      });
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }

  const showTerms = async () => {
    await Browser.open({ url: process.env.REACT_APP_TERMS_URL || 'https://dev.api.epihelper.com/privacy/agreement/' });
  };

  return (
    <IonGrid className={cx(styles.ionGrid, 'ion-no-padding')}>
      <IonRow className={cx(styles.halfHeight, styles.textRow, 'align-items-start')}>
        <div>
          {t('Enter your phone number')}
          <br />
          {t('to receive authorization code')}.
          <br />
          {t('By authorizing you agree with')}
          <IonText color="primary">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span style={{ textDecoration: 'none' }} onClick={showTerms}>
              {' '}
              {t('our terms and conditions')}{' '}
            </span>
          </IonText>
        </div>
      </IonRow>
      <div className={styles.inputFieldsContainer}>
        <IonRow className={cx(styles.inputRow, 'ion-padding')}>
          <IonItem className={classNames(styles.inputItem, { [styles.dangerField]: !!errors.email })} lines="none">
            <Controller
              render={({ onChange, onBlur }) => (
                <IonInput
                  onIonChange={(e) => onChange(e.detail.value)}
                  type="email"
                  onIonBlur={onBlur}
                  inputMode="email"
                  placeholder={t('Enter your email')}
                />
              )}
              control={control}
              name="email"
              rules={{
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              }}
            />
          </IonItem>
        </IonRow>

        <IonRow className={cx('ion-padding-horizontal')}>
          <IonItem className={cx('inputItem', 'inputItemPhone', { [styles.dangerField]: !!errors.phone })} lines="none">
            <Controller
              render={({ onChange, onBlur }) => (
                <MaskedInput
                  className={styles.input}
                  mask="+7 (999) 999 99 99"
                  maskChar={null}
                  onBlur={onBlur}
                  placeholder="+7 (XXX) XXX XX XX"
                  type="tel"
                  inputMode="tel"
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                />
              )}
              control={control}
              name="phone"
              rules={{
                required: true,
                pattern: /^[+]?\d[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}([-\s.]?[0-9]{2}){2}$/i,
              }}
            />
          </IonItem>
        </IonRow>
        <IonRow className="ion-padding">
          <IonCol>
            <IonButton
              onClick={handleSubmit(handleRegistration)}
              className={cx(styles.button, 'ion-no-margin')}
              expand="block"
            >
              {t('Next')}
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </IonGrid>
  );
};

export { SigninNumberInput };
