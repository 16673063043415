import React from 'react';

import { useHistory } from 'react-router';
import { PatientMedicineType } from '../../../generated/graphql';
import { ItemSliding } from '../../UI/ItemSliding/ItemSliding';

interface Props {
  patientMedicine: PatientMedicineType;
  removeMedicine: (id: string) => void;
}

const MedicinesItem: React.FC<Props> = ({ removeMedicine, patientMedicine }) => {
  const { push } = useHistory();

  return (
    <ItemSliding
      label={patientMedicine.medicine.name}
      counter={patientMedicine.quantity}
      onItemClick={() => push(`/medicines/${patientMedicine.id}`)}
      onItemRemove={() => removeMedicine(patientMedicine.id)}
    />
  );
};

export { MedicinesItem };
