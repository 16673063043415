import React from 'react';
import { IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { ReviewClinicType } from '../../generated/graphql';
import styles from './ReviewClinicsList.module.scss';
import { NoElementFound } from '../empty-state/NoElementFound';
import { ReviewClinicsItem } from './ReviewClinicsItem';

const cx = classNames.bind(styles);

interface IReviewClinicList {
  clinics: ReviewClinicType[];
}

const ReviewClinicsList = ({ clinics }: IReviewClinicList) => {
  const { t } = useTranslation();

  if (clinics.length === 0) {
    return <NoElementFound title={t('Clinic list is empty')} />;
  }
  return (
    <IonList className={cx(styles.list)}>
      {clinics.map((clinic) => (
        <ReviewClinicsItem key={clinic.id} clinic={clinic} />
      ))}
    </IonList>
  );
};

ReviewClinicsList.defaultProps = {};

export { ReviewClinicsList };
