import '@capacitor/core';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { YMaps } from 'react-yandex-maps';
import { IonReactRouter } from '@ionic/react-router';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { ToastProvider } from './contexts/toast-context';
import { AuthProvider } from './contexts/auth-context';

// contexts
import { LocationProvider } from './contexts/location-context';
import { CustomApolloProvider } from './CustomApolloProvider';
import { WatchDeviceProvider } from './contexts/watch-device-context';

//
import { browserHistory } from './history';
import { PhoneDeviceProvider } from './contexts/phone-device-context';

if (process.env.NODE_ENV === 'production') {
  // Sentry setup
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;

// Create a root.
const root = ReactDOM.createRoot(container);

root.render(
  <ToastProvider>
    <AuthProvider>
      <CustomApolloProvider>
        <IonReactRouter history={browserHistory}>
          <WatchDeviceProvider>
            <PhoneDeviceProvider>
              <LocationProvider>
                <YMaps
                  query={{
                    ns: 'use-load-option',
                    load: 'package.full',
                    apikey: process.env.REACT_APP_YANDEX_MAP_KEY,
                  }}
                >
                  <App />
                </YMaps>
              </LocationProvider>
            </PhoneDeviceProvider>
          </WatchDeviceProvider>
        </IonReactRouter>
      </CustomApolloProvider>
    </AuthProvider>
  </ToastProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
