import { IonContent, IonLoading, IonPage } from '@ionic/react';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { MedicineDetail, MedicineDetailOutput } from '../../components/medicine/MedicineDetail';
import {
  MedicineType,
  useCreatePatientMedicineMutation,
  useMedicinesWithPatientMedicinesQuery,
} from '../../generated/graphql';
import { ErrorNetwork } from '../../components/error/ErrorNetwork';
import { Title } from '../../components/title/Title';

const NewMedicine: React.FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const { data, loading, error } = useMedicinesWithPatientMedicinesQuery({
    fetchPolicy: 'no-cache',
  });

  const [createPatientMedicine, { loading: createPatientMedicineLoading }] = useCreatePatientMedicineMutation({
    onCompleted: () => {
      goBack();
    },
  });

  const handleFormSubmission = useCallback(
    async (patientMedicine: MedicineDetailOutput) => {
      if (patientMedicine.medicineId === undefined) return;
      await createPatientMedicine({
        variables: {
          input: {
            medicineId: patientMedicine.medicineId,
            purchaseDate: patientMedicine.purchaseDate,
            quantity: patientMedicine.quantity,
          },
        },
      });
    },
    [createPatientMedicine]
  );
  //

  const medicines = useMemo(() => {
    let result: MedicineType[] | undefined;
    if (data) {
      const patientMedicinesIDs = data.patientMedicines.edges.map(({ node }) => node.medicine.id);
      result = data.medicines.edges
        .filter(({ node }) => !patientMedicinesIDs.includes(node.id))
        .map(({ node }) => node as MedicineType);
    }
    return result;
  }, [data]);

  if (error) {
    return <ErrorNetwork error={error} />;
  }

  if (loading || !data?.medicines) {
    return <IonLoading isOpen message={t('Loading...')} />;
  }

  return (
    <IonPage>
      <Title translucent title={t('Add medicine')} backButton />
      <IonContent>
        {createPatientMedicineLoading && <IonLoading isOpen message={t('Loading...')} />}
        <MedicineDetail
          medicines={medicines}
          formSubmitHandler={handleFormSubmission}
          showMedicineSuggestions
          submitButtonText={t('Create')}
        />
      </IonContent>
    </IonPage>
  );
};
export { NewMedicine };
