/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonBadge,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRow,
  IonText,
  isPlatform,
  useIonAlert,
} from '@ionic/react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { watchOutline } from 'ionicons/icons';
import classNames from 'classnames/bind';
import styles from './Menu.module.scss';
import { useAuth } from '../../contexts/auth-context';
import { Image } from '../UI/Image/Image';
import { AppPageLink } from '../../routes/Router';
import { useDetermineWomenCalendarNavItem } from '../../hooks/useDetermineWomenCalendarNavItem';
import { Review } from '../../pages/app-review/Review';
import { useToggleState } from '../../hooks/useToggleState';
import { useWatchDeviceContext } from '../../contexts/watch-device-context';
import { useMeQuery } from '../../generated/graphql';
import { goToSettings, isBluetoothConnect } from '../../utils/device';

const appPageLinks: AppPageLink[] = [
  {
    id: 1,
    title: 'Home',
    url: '/home',
  },
  {
    id: 2,
    title: 'Multiplicity and dosage of taking PEP',
    url: '/prescriptions',
  },
  {
    id: 3,
    title: 'Record a seizure',
    url: '/seizures',
  },
  {
    id: 4,
    title: 'SOS contacts',
    url: '/sos-contacts',
  },
  {
    id: 5,
    title: 'PEP stock control',
    url: '/medicines',
  },
  {
    id: 6,
    title: "Women's calendar",
    url: '/women-calendar',
  },
  {
    id: 7,
    title: 'Seizure statistic',
    url: '/calendar',
  },
  {
    id: 8,
    title: 'Video instruction on YouTube',
    url: 'https://www.youtube.com/user/epihelperl',
  },
  {
    id: 9,
    title: 'Safety rules',
    url: 'https://xn--e1aahnrdi9a7d.xn--p1ai/',
  },
  {
    id: 10,
    title: 'First aid for a seizure',
    url: 'https://xn--e1aahnrdi9a7d.xn--p1ai/',
  },
  {
    id: 11,
    title: 'Buy a bracelet',
    url: 'https://epihelper.com/',
  },
  {
    id: 12,
    title: 'Helping project',
    url: '',
  },
  {
    id: 13,
    title: 'Medicines Intake',
    url: '/medicines-intake',
  },
  {
    id: 14,
    title: 'Chat with doctor',
    url: '/chat',
  },
  {
    id: 15,
    title: 'Documents upload',
    url: '/patient-files',
  },
  {
    id: 16,
    title: 'Review clinics',
    url: '/review-clinics',
  },
];

const cx = classNames.bind(styles);

const Menu = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const [alert] = useIonAlert();
  const [isBLEConnectPermissionGranted, setIsBLEConnectPermissionGranted] = useState<boolean>(true);
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const {
    state: { connected, battery },
  } = useWatchDeviceContext();
  const authContext = useAuth();
  const { data: currentUserData } = useMeQuery({
    fetchPolicy: 'no-cache',
  });
  const { menuItems } = useDetermineWomenCalendarNavItem(appPageLinks, authContext);
  const [showReviewModal, toggleShowReviewModal] = useToggleState(false);
  useEffect(() => {
    if (isPlatform('android')) {
      isBluetoothConnect().then((result) => setIsBLEConnectPermissionGranted(result));
    }
  }, []);

  return (
    <>
      {showReviewModal && <Review onDismiss={toggleShowReviewModal} />}
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={() => setShowLogoutAlert(false)}
        header={t('Logout')}
        message={t('Confirm logout')}
        buttons={[
          {
            text: t('Cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('Logout'),
            handler: () => {
              authContext.logout();
            },
          },
        ]}
      />
      <IonMenu swipeGesture={authContext.registered} contentId="main" type="overlay">
        <IonContent className={styles.purpleBackground}>
          <div className={styles.menuContainer}>
            <IonList className="ion-no-padding" lines="full">
              <IonMenuToggle autoHide={false}>
                <IonGrid className={styles.menuHeaderContainer}>
                  <IonRow className={styles.menuHeader}>
                    <IonCol size="6" onClick={() => push('/profile')} className={styles.headerImgContainer}>
                      <Image url={currentUserData?.me?.avatar?.url} className={styles.headerImg} />
                    </IonCol>
                    <IonCol size="6" className={styles.headerInfoContainter}>
                      <Link to="/profile">
                        <IonRow>
                          <IonText className={styles.username}>{authContext.user?.firstName}</IonText>
                        </IonRow>
                        <IonRow>
                          <IonText className={styles.username}>{authContext.user?.secondName}</IonText>
                        </IonRow>
                      </Link>
                      <IonRow className={styles.connectionStatusContainer}>
                        <IonIcon
                          icon={watchOutline}
                          className={cx({ 'not-connected': !connected })}
                          style={{ color: 'white' }}
                        />
                        <IonText className={styles.connectionStatus}>
                          {connected ? (
                            `${t('connected')} ${battery ? `${battery}%` : '-'}`
                          ) : isBLEConnectPermissionGranted ? (
                            t('not connected')
                          ) : (
                            <IonBadge
                              className={styles.badge}
                              color="primary"
                              onClick={async (e) => {
                                e.persist();
                                alert({
                                  cssClass: 'my-css',
                                  header: t('Bluetooth connect permission'),
                                  message: t(
                                    'Epihelper does not have permission to your bluetooth connect. Check it in your phone and turn it. Tap Settings > Permissions > Nearby devices'
                                  ),
                                  buttons: [
                                    {
                                      text: t('Cancel'),
                                      role: 'Cancel',
                                      // eslint-disable-next-line no-console
                                      handler: () => console.log('cancel pressed'),
                                    },
                                    {
                                      text: t('Settings'),
                                      handler: async () => {
                                        await goToSettings();
                                      },
                                    },
                                  ],
                                });
                              }}
                            >
                              {t('Connect')}
                            </IonBadge>
                          )}
                        </IonText>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                {menuItems.map(({ id, url, title }) => {
                  if (url.includes('http')) {
                    return (
                      <a style={{ textDecoration: 'none' }} key={id} href={url}>
                        <IonItem
                          detail={false}
                          className={`/${pathname.split('/')[1]}` === url ? styles.lastElement : styles.pageButton}
                        >
                          <IonText>{t(title)}</IonText>
                        </IonItem>
                      </a>
                    );
                  }
                  return (
                    <Link style={{ textDecoration: 'none' }} key={id} to={url}>
                      <IonItem
                        detail={false}
                        className={`/${pathname.split('/')[1]}` === url ? styles.lastElement : styles.pageButton}
                        {...(id === 12 ? { onClick: toggleShowReviewModal } : {})}
                      >
                        <IonText>{t(title)}</IonText>
                      </IonItem>
                    </Link>
                  );
                })}
                <IonItem detail={false} className={styles.pageButton} onClick={() => setShowLogoutAlert(true)}>
                  {t('Logout')}
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </div>
        </IonContent>
      </IonMenu>
    </>
  );
};

export { Menu };
