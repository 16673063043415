import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { IonIcon, IonRow, IonText } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { arrowForwardOutline, chevronDownOutline, chevronUpOutline } from 'ionicons/icons';
import { toNumber } from 'lodash';
import {
  ClinicReviewsGroupByCharacteristicType,
  useClinicReviewsGroupByCharacteristicQuery,
} from '../../../generated/graphql';
import styles from './ReviewByCharacteristics.module.scss';
import { averageNumber } from '../../../utils/utils';
import { RatingBar } from '../../rating-bar';

const cx = classNames.bind(styles);

interface IReviewByCharacteristicsProps {
  clinicId: string;
}

const ReviewByCharacteristics = ({ clinicId }: IReviewByCharacteristicsProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const [showAllCharacteristics, setShowAllCharacteristics] = useState(false);
  const { data } = useClinicReviewsGroupByCharacteristicQuery({
    variables: {
      clinicId,
    },
    fetchPolicy: 'no-cache',
  });

  const tooltipArray = [t('Terrible'), t('Bad'), t('Satisfactorily'), t('Good'), t('Great')];

  const clinicCharacteristics = data?.clinicReviewsGroupByCharacteristic || [];
  const averageStarsByCharacteristics = averageNumber(clinicCharacteristics.map((edge) => edge.averageStars)) || [];
  const averageStarsInteger = Math.trunc(Number(averageStarsByCharacteristics));

  return data && clinicCharacteristics[0].characteristic ? (
    <div className={cx('reviewClinic')}>
      <IonRow className={cx('reviewClinic_header')} onClick={() => push(`../reviews/${clinicId}`)}>
        <IonRow className={cx('reviewClinic_headerContent')}>
          <IonText className={cx('reviewClinic_headerContent-rating')}>{averageStarsByCharacteristics}</IonText>
          <IonRow className={cx('reviewClinic_headerContent-text')}>
            <IonText className={cx('reviewClinic_headerContent-textTooltip')}>
              {tooltipArray[averageStarsInteger - 1]}
            </IonText>
            <IonText className={cx('reviewClinic_headerContent-textLink')}>{t('View all reviews')}</IonText>
          </IonRow>
        </IonRow>
        <IonIcon size="large" color="primary" className={cx('reviewClinic_header-icon')} icon={arrowForwardOutline} />
      </IonRow>
      <div className={cx('reviewClinic_characteristics')}>
        {clinicCharacteristics
          .slice(0, showAllCharacteristics ? clinicCharacteristics.length : 3)
          // opens and closes the full rating overview
          .map((characteristic: ClinicReviewsGroupByCharacteristicType) => (
            <div key={characteristic.characteristic} className={cx('reviewClinic_characteristics-item')}>
              <IonText className={cx('reviewClinic_characteristics-itemTitle')}>
                <span>{characteristic.characteristic}</span>
                <span>{characteristic.averageStars}</span>
              </IonText>
              <RatingBar rating={toNumber(characteristic.averageStars)} />
            </div>
          ))}
      </div>
      {clinicCharacteristics.length > 3 && (
        <IonRow
          className={cx('reviewClinic_showAll')}
          onClick={() => setShowAllCharacteristics((prevState) => !prevState)}
        >
          <IonText className={cx('reviewClinic_showAll-text')}>{t('Show All')}</IonText>
          <IonRow className={cx('reviewClinic_showAll-icons')}>
            <IonIcon className={cx('reviewClinic_showAll-iconsArrowUp')} icon={chevronUpOutline} />
            <IonIcon className={cx('reviewClinic_showAll-iconsArrowDown')} icon={chevronDownOutline} />
          </IonRow>
        </IonRow>
      )}
    </div>
  ) : (
    <IonRow className={cx('reviewClinic_empty')}>
      <IonRow className={cx('reviewClinic_emptyContent')}>
        <IonText className={cx('reviewClinic_emptyContent-rating')}>0</IonText>
        <IonText className={cx('reviewClinic_emptyContent-text')}>{t('No reviews')}</IonText>
      </IonRow>
      <IonRow className={cx('reviewClinic_characteristics-item')}>
        <IonText className={cx('reviewClinic_characteristics-itemTitle')}>
          <span>{t('Category')}</span>
          <span>0</span>
        </IonText>
        <RatingBar rating={0} />
      </IonRow>
    </IonRow>
  );
};

export { ReviewByCharacteristics };
