import React, { useMemo } from 'react';
import { IonList } from '@ionic/react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { SeizureItem } from './SeizureItem';
import { SeizureType } from '../../generated/graphql';
import styles from './Seizures.module.scss';
import { NoElementFound } from '../empty-state/NoElementFound';

interface Props {
  removeSeizure: (id: string) => void;
  sortDesc: boolean;
  seizures: SeizureType[];
}

const Seizures = ({ removeSeizure, sortDesc, seizures }: Props) => {
  const { t } = useTranslation();
  const sortedSeizures = useMemo(
    () => (sortDesc ? _.sortBy(seizures, (x) => x.date) : _.sortBy(seizures, (x) => x.date).reverse()),
    [sortDesc, seizures]
  );

  if (sortedSeizures.length === 0) {
    return <NoElementFound title={t('Seizures list is empty')} />;
  }
  return (
    <div>
      <IonList className={styles.listContainer}>
        {sortedSeizures.map((seizure) => (
          <SeizureItem deleteSeizure={removeSeizure} key={seizure.id} seizure={seizure} />
        ))}
      </IonList>
    </div>
  );
};

Seizures.defaultProps = {
  sortDesc: false,
};

export { Seizures };
