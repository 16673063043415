import React, { useState } from 'react';
import { IonCol, IonRow, IonText, IonGrid, IonIcon, IonItem, IonDatetime, IonPopover, IonLabel } from '@ionic/react';

import { arrowBackSharp, arrowForwardSharp } from 'ionicons/icons';

import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import styles from './Calendar.module.scss';

import { PatientSeizuresQuery, SeizureQuery } from '../../generated/graphql';

import { TDate, nextMonth, previousMonth, getMonthSeizures, getArraysWithDaysOfMonth } from './CalendarFunctions';

import { Day } from './Day';
import { DayDetailPopover } from './DayDetailPopover';

interface Props {
  year: number;
  month: number;
  data: PatientSeizuresQuery;
  daysOfWeekShortNames: Array<string>;
}

export type Seizure = Partial<SeizureQuery['seizure']>;

interface IPopover {
  show: boolean;
  seizures?: Array<Seizure>;
}

type TCalendar = TDate & { daysOfMonthes: Array<Array<number>> };

const Calendar = ({ year: yearProp, month: monthProp, data: PatientSeizuresData, daysOfWeekShortNames }: Props) => {
  const { t } = useTranslation();

  const [showPopover, setShowPopover] = useState<IPopover>({
    show: false,
  });

  const [date, setCalendar] = useState<TCalendar>({
    year: yearProp,
    month: monthProp,
    daysOfMonthes: getArraysWithDaysOfMonth(yearProp, monthProp),
  });

  const monthNames = [
    t('January'),
    t('February'),
    t('March'),
    t('April'),
    t('May'),
    t('June'),
    t('July'),
    t('August'),
    t('September'),
    t('October'),
    t('November'),
    t('December'),
  ];

  const monthSeizures = getMonthSeizures(PatientSeizuresData, date);

  const [previousMonthDays, currentMonthDays, nextMonthDays] = date.daysOfMonthes;

  return (
    <div className={styles.container}>
      <DayDetailPopover
        isOpen={showPopover.show}
        seizures={showPopover.seizures!}
        onDidDismiss={() => setShowPopover((prev) => ({ ...prev, show: false }))}
      />
      <IonGrid className={`${styles.drid} ion-no-padding`}>
        <div className={`${styles.top}`}>
          <IonRow className="ion-justify-content-center">
            <IonIcon
              className={styles.arrowIconLeft}
              icon={arrowBackSharp}
              onClick={() => {
                const { year, month } = previousMonth({
                  year: date.year,
                  month: date.month,
                });
                setCalendar({
                  year,
                  month,
                  daysOfMonthes: getArraysWithDaysOfMonth(year, month),
                });
              }}
            />
            <IonItem id="open-date-input" color="primary" lines="none">
              <IonLabel style={{ fontWeight: 700, fontSize: '20px' }}>
                {monthNames[date.month - 1]} - {date.year}
              </IonLabel>
              <IonPopover trigger="open-date-input">
                <IonDatetime
                  className={styles.month}
                  cancelText={t('Cancel')}
                  presentation="month-year"
                  doneText={t('Done')}
                  onIonChange={(e) => {
                    const parsedDate = parseISO(e.detail.value!);
                    const month = parsedDate.getMonth() + 1;
                    const year = parsedDate.getFullYear();
                    setCalendar({
                      year,
                      month,
                      daysOfMonthes: getArraysWithDaysOfMonth(year, month),
                    });
                  }}
                />
              </IonPopover>
            </IonItem>
            <IonIcon
              className={styles.arrowIconRight}
              icon={arrowForwardSharp}
              onClick={() => {
                const { year, month } = nextMonth({
                  year: date.year,
                  month: date.month,
                });
                setCalendar({
                  year,
                  month,
                  daysOfMonthes: getArraysWithDaysOfMonth(year, month),
                });
              }}
            />
          </IonRow>
          <IonRow className={styles.daysOfWeekShortNames}>
            {daysOfWeekShortNames.map((dayOfWeek, index) => (
              /* eslint-disable react/no-array-index-key */
              <IonCol key={index} className="ion-text-center">
                <IonText>{dayOfWeek}</IonText>
              </IonCol>
            ))}
          </IonRow>
        </div>
        <IonRow>
          {previousMonthDays.map((day, index) => {
            return <Day key={index} divStyle={styles.nonInteract} textDayStyle={styles.day} day={day} />;
          })}
          {currentMonthDays.map((day, index) => {
            const daySeizures = monthSeizures.filter((val) => val.day === day).map((val) => val.dataItem.node);
            return daySeizures.length > 0 ? (
              <Day
                key={index}
                divStyle={styles.active}
                textDayStyle={styles.day}
                day={day}
                cb={() => {
                  setShowPopover({ show: true, seizures: daySeizures });
                }}
              />
            ) : (
              <Day key={index} divStyle={styles.interact} textDayStyle={styles.day} day={day} />
            );
          })}
          {nextMonthDays.map((day, index) => {
            return <Day key={index} divStyle={styles.nonInteract} textDayStyle={styles.day} day={day} />;
          })}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export { Calendar };
